import React, { useState, useRef, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Title from "../../utils/Titles/Title";
import Buttons from '../../utils/Button';
import Table from '../../utils/Newtable/Table';
import { tableData } from '../../utils/json/tableData';
import { registrationUIDatas } from '../../utils/json/registrationUIDatas';
import Dropdown from '../../utils/Dropdown'
import DatePickers from '../../utils/DatePickers'
import TextFeildRadioBox from '../../utils/TextFeildRadioBox';
import InputPhone from '../../utils/InputPhone'
import TextInput from '../../utils/TextInput'
import * as getData from '../../../services/APIService';
import { useSelector, useDispatch } from "react-redux";
import { logoutFunction } from "../../../store/actions/LogoutActions";
import { SVGICON } from "../../constant/theme";
import { TextField } from "@mui/material";
import TextFeildCheckBox from '../../utils/TextFeildCheckBox'
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { NavMenuOn, NavMenuToggle } from "../../layouts/nav/NavHader";
import { ThemeContext } from "../../../context/ThemeContext";
import Searchdropdown from "../../utils/Searchdropdown";
import DeleteModal from "../Masters/Element/deleleModal";
// import Loader from "../Loader/Loader";
// import { setLoading } from "../../../store/actions/CommonActions";
const moment = require('moment');
const AppointmentList = ({setopenform,seteditpid, setSwitchPhoneCallModel}) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const [activeRadioField, setActiveRadioField] = useState([]);
	const [selectedOption, setSelectedOption] = useState("");
	const [apptdate, setapptdate] = useState(moment().format('YYYY-MM-DD'));
	const [deletepatientId, setdeletepatient_id] = useState('');
	const PhoneRegisterTableHead = tableData[0]?.register?.[0]?.PhoneresgisterDetails?.[0]?.tableHead || [];
	const userData = useSelector((state) => state.auth.auth);
	const [patientlist, setpatientlist] = useState([]);
	const [clientList, setClientList] = useState([]);
	const userToken = userData.idToken;
	const userId = userData.userId;
	const logId = parseInt(userData.logId);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	// const loading = useSelector((state) => state.common.showLoading)
	const hospital_detail_id = parseInt(userData.hospital_detail_id)
	const initialfilterData = {
		first_name: '',
		middle_name:'',
		last_name: '',
		contact_no: '+971',
		doctor_name: '',
		staff_name: '',
		referral_doctor_name: '',
		department_name: '',
	}
	
	const [filterFields, setFilterFields] = useState(initialfilterData)
	const { toggleSideMenu } = useContext(ThemeContext);
	const companyList = [];
	const doctorList = [];
	const shouldLog = useRef(true);
	useEffect(() => {
		document.title = 'AppointmentList';
		if (shouldLog.current) {
			shouldLog.current = false;
			// dispatch(setLoading(true))
			//Get patient List
			getClientListAPI();
			loadPatientListByToday();
			
		}
		// eslint-disable-next-line
	}, [shouldLog]);

	
	const getClientListAPI = async () => {
		await getData.getClientList(userToken).then((resp) => {
			setClientList(resp.data.data);
		}).catch((error) => {
			if (error.response.data.status === 2) {
				toast.error(error.response.data.message + ". Please log in again to continue.", {
					position: toast.POSITION.TOP_RIGHT,
					autoClose: 1000,
				});
				setTimeout(() => {
					dispatch(logoutFunction(userId, logId, userToken, navigate));
				}, 2000)
			} else {
				toast.error(error.response.data.message + " Please Try After Sometimes" ||
					"Too Many Attempts. Please Try after sometimes", {
					position: toast.POSITION.TOP_RIGHT,
					autoClose: 1000,
				});
			}
		});
	};
	const handlecategoryChange = (e) => {
	
		if(e.target.value === null) {
			setActiveRadioField([]);	
			setFilterFields(initialfilterData)
			setSelectedOption('');
		}
		else {
			setActiveRadioField([registrationUIDatas[0]?.registration[0]?.phoneregistrationListCategoryinputFilter[e.target.value]]);
			setFilterFields(initialfilterData)
			setSelectedOption(e.target.value);
		}
	}
	// update values of enabled text or phone input 
	const handleRadioValueModifier = (e) => {
		const { name, value, type, files } = e.target;
		const newValue = type === 'file' ? files[0] : value;
		setFilterFields({ [name]: newValue });
	}

	const handleedit = (patientId) => {
		seteditpid(patientId);
		setopenform(true);
		setSwitchPhoneCallModel(2);
	}
	const handleDeleteAction = async (patientId) => {
		setdeletepatient_id(patientId);
		setShowDeleteModal(true);
	
	};

	const handleCloseDeleteModal = () => {
		
		setShowDeleteModal(false);
	};

	const handleDelete = async () => {
		// Perform delete logic here
		// ...

		await getData
			.deletePatientEnquiry(userId, deletepatientId, userToken)
			.then((resp) => {
				// setRegionList(resp.data.data);

				if (resp.data.status === 1) {
					
					toast.success(resp.data.message, {
						position: toast.POSITION.TOP_RIGHT,
						autoClose: 1000,
					});

				setdeletepatient_id('');
					setShowDeleteModal(false);
					loadPatientListByToday();
				} else {
					setdeletepatient_id('');
					setShowDeleteModal(false);
					toast.error(resp.data.message, {
						position: toast.POSITION.TOP_RIGHT,
						autoClose: 1000,
					});
				}
			})
			.catch((error) => {
				// console.log(error)
				if (error.response.data.status === 2) {
					toast.error(
						error.response.data.message + ". Please log in again to continue.",
						{
							position: toast.POSITION.TOP_RIGHT,
							autoClose: 1000,
						}
					);
					setTimeout(() => {
						dispatch(logoutFunction(userId, logId, userToken, navigate));
					}, 2000);
				} else {
					toast.error(
						error.response.data.message + " Please Try After Sometimes" ||
							"Too Many Attempts. Please Try after sometimes",
						{
							position: toast.POSITION.TOP_RIGHT,
							autoClose: 1000,
						}
					);
				}
			});
	};
	const loadPatientListByToday = async () => {
		const postData = {
			"user_id": userId,
			"app_date":apptdate,
			"search_field": "",
			"search_value":""
		}
		await viewPatientList(postData)
	}
	const searchsubmit = async (e) => {

		//e.preventDefault();
		const postData = {
			"user_id": userId,
			"app_date": apptdate,
			"search_field": selectedOption,
			"search_value":filterFields[selectedOption]
		}
		await viewPatientList(postData)

		// dispatch(setLoading(false))
	}

	const viewPatientList = async (postData) => {
		await getData.ViewPatientEnquiry(postData, userToken).then((resp) => {
			if (resp.data && resp.data.status === 0) {
				// toast.error(resp.data.message, {
				// 	position: toast.POSITION.TOP_RIGHT,
				// 	autoClose: 2000,
				// });
				setpatientlist([])
			} else {
				const patients = resp.data.data;
				patients.forEach((patient, index) => {
				const { first_name, middle_name, last_name } = patient;
				const fullName = `${first_name} ${middle_name !== null ? middle_name : ""} ${last_name}`;
				patients[index]['patient_name'] = fullName;
			});
			

				setpatientlist(patients)
			}
		}).catch((error) => {
			if (error.response.data.status === 2) {
				toast.error(error.response.data.message + ". Please log in again to continue.", {
					position: toast.POSITION.TOP_RIGHT,
					autoClose: 1000,
				});
				setTimeout(() => {
					dispatch(logoutFunction(userId, logId, userToken, navigate));
				}, 2000)
			} else {
				toast.error(error.response.data.message + " Please Try After Sometimes" ||
					"Too Many Attempts. Please Try after sometimes", {
					position: toast.POSITION.TOP_RIGHT,
					autoClose: 1000,
				});
			}
		});
	}
	
	
	return (
		<>
			<div className="row">
				<div className="col-md-3 col-xs-12"><Title icon={SVGICON.edit} title="Patient List" /></div>
				<div className=" col-md-3 col-xs-12 ">
					<DatePickers name="appt_date" onChange={(val) => {setapptdate(val)}}
value={apptdate} nofuturedate={false}
/>
				</div>
				<div className=" col-md-6 col-xs-12 right-align">
					<Buttons.AddButtons name="Add" onClick={() => { seteditpid(0); setopenform(true); setSwitchPhoneCallModel(2)}} />
				</div>
			</div>
			<div className="bg_wrap" style={{ "padding": "10px 0px" }}>
			<div className="b-radius dashTable" style={{ margin: "8px 0px", padding: "0px 10px" }}>
					<div className="container-fluid">
						<div className="row">
							<div className="col-md-4 col-xs-12 padding_fixed">
								{registrationUIDatas[0]?.registration[0]?.phoneregistrationListCategoryFilter.map((input) => {
									return <Searchdropdown {...input} options={input.options} value={selectedOption}
										onChange={(e) => handlecategoryChange(e)}
									/>
								}
								)}
							</div>
							<div className="col-md-4 col-xs-12 padding_fixed">
								<div className="wrap-field">{activeRadioField && activeRadioField.map((input, tabIndex) => (
									<div key={input.id} >

										{(input.type === "text") ? <TextInput {...input}
											onChange={(e) => handleRadioValueModifier(e)}

											value={filterFields[input.name]}

										/> : 
											// (input.type === "select") ?

											// <Searchdropdown {...input}
											// 	options={input.name === "company_id" ? companyList : input.name === "doctor_id" ? doctorList : []}
											// 	onChange={(e) => handleRadioValueModifier(e)}

											// 	value={filterFields[input.name]}
											// />

											// : 
											(input.type === "datePicker") ? <DatePickers {...input}

												onChange={(date) => handleRadioValueModifier({ target: { name: 'register_date', value: date } })}

												selected={(input.label === "Regn Date") ? filterFields[input.name] : ""}
												value={filterFields[input.name]}

											/> : (input.type === "phone") ? <InputPhone {...input}
												onChange={(value) => handleRadioValueModifier({ target: { name: input.name, value: value } })}
												value={filterFields[input.name]}
											/>


												: ""}

									</div>

								))}</div>
							</div>
							<div className="col-md-4 col-xs-12 right-align padding_fixed">
								<Buttons.SearchButtons name="Search" onClick={searchsubmit} />
							</div>
						</div>
						<div className="row">
							<div className="col-md-12 col-xs-12 no_padding">
								<Table
									columns={PhoneRegisterTableHead}
									serialNoRequired={false}
									rowname={"patient_id"}
									rowdata={patientlist}
									handleOpenEditModal={handleedit}
									handleDeleteAction={handleDeleteAction}
									nodataalignment="start"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
			<DeleteModal
				show={showDeleteModal}
				handleClose={handleCloseDeleteModal}
				handleDelete={handleDelete}
			/>

			<ToastContainer />
		</>
	);
};
export default AppointmentList;