import React, { useContext, useEffect, useRef, useState } from "react";
import { Tab, Tooltip } from "@mui/material";
import DeleteModal from "../Masters/Element/deleleModal";
import SaveAndCloseModal from "../Masters/Element/saveCloseModal";

import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Image } from "react-bootstrap";
import { useNavigate, useSearchParams, Link } from "react-router-dom";
import Title from "../../utils/Titles/Title";
import * as getData from "../../../services/APIService";
import * as getAPIAction from "../../../store/actions/GetAllMasterList";
import { ALL_MASTER_ACTION_TYPES } from "../../../store/actions/AllMasterActionTypes";
import { registrationUIDatas } from "../../utils/json/registrationUIDatas";
import TextInput from "../../utils/TextInput";
import TextArea from "../../utils/Textarea";
import Dropdown from "../../utils/Dropdown";
import Multiselect from "../../utils/Multiselect";
import DatePickers from "../../utils/DatePickers";
import InputPhone from "../../utils/InputPhone";
import TextFeildCheckBox from "../../utils/TextFeildCheckBox";
import TextFeildRadioBox from "../../utils/TextFeildRadioBox";
import Fileinput from "../../utils/Fileinput";
import FileUploadButton from "../../utils/FileUploadButton";
import Buttons from "../../utils/Button";
import Table from "../../utils/Newtable/Table";
import { tableData } from "../../utils/json/tableData";
import Popup from "../Masters/Element/Popup";
import { SVGICON } from "../../constant/theme";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { logoutFunction } from "../../../store/actions/LogoutActions";
import { useDispatch, useSelector } from "react-redux";
import { matchIsValidTel } from "mui-tel-input";
import FormLabel from "@mui/material/FormLabel";
import DateTimePickers from "../../utils/DateTimePickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { setLoading } from "../../../store/actions/CommonActions";
import Loader from "../Loader/Loader";
import InputTable from "./InputTable";
import Searchdropdown from "../../utils/Searchdropdown";
import AppointmentList from "../AppointmentBook/Appointmentlist";
import AppointmentBook from "../AppointmentBook/AppointmentBook";
import ServiceCategory from "../Masters/ServiceCategory";
import _ from "lodash";
import { ThemeContext } from "../../../context/ThemeContext";
import { NavMenuOn } from "../../layouts/nav/NavHader";
import ServiceCategoryTable from "./ServiceCategoryTable";
import EIA from "../../../images/eia.png";

const moment = require("moment");
const Registration = () => {
  const { toggleSideMenu } = useContext(ThemeContext);

  const [detailTab, setDetailTab] = useState(1);
  const [personalTab, setPersonalTab] = useState(1);
  const navigate = useNavigate();
  const [gaurdianTab, setGaurdianTab] = useState(1);
  const [kinTab, setKinTab] = useState(1);
  const [insuranceTab, setInsuranceTab] = useState(1);
  const [onlineReferralTab, setOnlineReferralTab] = useState(1);
  const [showFieldLoad, setShowFieldLoad] = useState(false);
  const [ulMenuOpen, setUiMenuOpen] = useState(false);
  const [viewInsurance, SetViewInsurance] = useState(true);
  const [checkChanges, setCheckChanges] = useState(false);
  const [openform, setopenform] = useState(false);
  const [editpid, seteditpid] = useState(0);
  const [selectedRow, setSelectedRow] = useState(null);
  const [saveType, setSaveType] = useState(0);
  const [deleteKinIndex, setDeleteKinIndex] = useState(-1);
  const [deleteGuardianIndex, setDeleteGuardianIndex] = useState(-1);
  // const [showModal, setShowModal] = useState(false);
  // const [saveEvent, setSaveEvent] = useState(null);
  const [cancelProfile, setCancelProfile] = useState(true);

  const toggleViewInsuranceList = () => {
    resetInsuranceData();
    SetViewInsurance((prevState) => !prevState);
  };
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showKinDeleteModal, setShowKinDeleteModal] = useState(false);
  const [showGuardianDeleteModal, setShowGuardianDeleteModal] = useState(false);

  const closeInsuranceModal = () => {
    setShowDeleteInsuranceModal(false);
  };

  const deleteinsurance = async () => {
    if (selectedRow === null) {
      toast.error("Please Select Any one row for Edit", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    } else {
      await getData
        .deletePatientInsurance(
          userId,
          selectedRow.patient_detail_id,
          selectedRow.id,
          userToken
        )
        .then((resp) => {
          if (resp.data.status === 1) {
            toast.success(resp.data.message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 1000,
            });

            setSelectedRow(null);
            GetSinglePatient();
          } else {
            toast.error(resp.data.message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 1000,
            });
          }
        })
        .catch((error) => {
          // console.log(error)
          if (error.response.data.status === 2) {
            toast.error(
              error.response.data.message +
                ". Please log in again to continue.",
              {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 1000,
              }
            );
            setTimeout(() => {
              dispatch(logoutFunction(userId, logId, userToken, navigate));
            }, 2000);
          } else {
            toast.error(
              error.response.data.message + " Please Try After Sometimes" ||
                "Too Many Attempts. Please Try after sometimes",
              {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 1000,
              }
            );
          }
        });
    }

    closeInsuranceModal();
  };

  const editinsurance = () => {
    if (selectedRow === null) {
      toast.error("Please Select Any one row for Edit", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    } else {
      setEditExising(true);
      SetViewInsurance((prevState) => !prevState);
      dispatch(
        getAPIAction.GetInsSubCompanyList(
          userId,
          logId,
          userToken,
          navigate,
          selectedRow.insurance_company_detail_id
        )
      );
      dispatch(
        getAPIAction.GetNetworkInsCompanyWise(
          userId,
          logId,
          userToken,
          navigate,
          selectedRow.insurance_company_detail_id
        )
      );
      dispatch(
        getAPIAction.GetBenefitPackageCompanyWise(
          userId,
          logId,
          userToken,
          navigate,
          selectedRow.insurance_company_detail_id
        )
      );
      dispatch(
        getAPIAction.GetPlanNetworkWise(
          userId,
          logId,
          userToken,
          navigate,
          selectedRow.insurance_network_id
        )
      );
      let t = {
        user_id: userId,
        hospital_detail_id: hospital_detail_id,
        insurance_company_detail_id: selectedRow.insurance_company_detail_id,
        insurance_network_id: selectedRow.insurance_network_id,
        insurance_plan_id: selectedRow.insurance_plan_id,
      };
      setActiveInsuranceId(selectedRow.id);
      setGetExistingInsuranceDetails(selectedRow.insurance_detail);
      dispatch(
        getAPIAction.getServiceCategoryListBySelectedPlanAPI(
          userId,
          logId,
          userToken,
          navigate,
          t
        )
      );
      setinsurancedata({
        ...selectedRow,
        insurance_company_detail_id: selectedRow.insurance_company_detail_id,
        main_company_short_code: selectedRow.main_company_short_code,
        sub_company_detail_id: selectedRow.sub_company_detail_id,
        sub_company_short_code: selectedRow.sub_company_short_code,
        policy_holder: selectedRow.policy_holder,
        insurance_package_id: selectedRow.insurance_package_id,
        insurance_network_id: selectedRow.insurance_network_id,
        insurance_plan_id: selectedRow.insurance_plan_id,
        card_number: selectedRow.card_number,
        expiry_date: moment(selectedRow.expiry_date, "DD-MM-YYYY").format(
          "YYYY-MM-DD"
        ),
        max_ceilling: checkNullFormatter(selectedRow.max_ceilling, "int"),
        deduct_amount: checkNullFormatter(selectedRow.deduct_amount, "int"),
        co_pay_amount: checkNullFormatter(selectedRow.co_pay_amount, "int"),
        co_pay_all_service: selectedRow.co_pay_all_service,
        categories: [],
        is_status: selectedRow.is_status,
      });
    }
  };

  const toggleList = () => {
    setUiMenuOpen(!ulMenuOpen);
  };

  const handleDetailTab = (event, detailTab) => {
    setDetailTab(detailTab);
  };

  const handlePersonalTab = (event, personalTab) => {
    setPersonalTab(personalTab);
  };

  const handleRowClick = (rowid) => {
    setSelectedRow(rowid);
  };

  const handleGaurdianTab = (event, gaurdianTab) => {
    if (gaurdianTab === 1) {
      setSelectedOption("");
      setActiveRadioField([]);
      setsearchGuardiandata([]);
    }

    setGaurdianTab(gaurdianTab);
  };

  const handleKinTab = (event, kinTab) => {
    if (kinTab === 1) {
      setSelectedOption("");
      setActiveRadioField([]);
      setsearchkindata([]);
    }
    setKinTab(kinTab);
  };

  const handleInsuranceTab = (event, insuranceTab) => {
    setInsuranceTab(insuranceTab);
    if (insuranceTab === 2) SetViewInsurance(true);
  };

  const handleOnlineReferralTab = (event, onlineReferralTab) => {
    setOnlineReferralTab(onlineReferralTab);
  };

  const handlesearchresult = (data, tab) => {
    if (gaurdianTab === 2) {
      dispatch(
        getAPIAction.getRegionListAPI2(
          userId,
          logId,
          userToken,
          navigate,
          data.country_id
        )
      );
      dispatch(
        getAPIAction.getCityListAPI2(
          userId,
          logId,
          userToken,
          navigate,
          data.country_id,
          data.region_id
        )
      );
      setguardiandata(data);
      setGaurdianTab(tab);
      setsearchGuardiandata([]);
    } else {
      dispatch(
        getAPIAction.getRegionListAPI3(
          userId,
          logId,
          userToken,
          navigate,
          data.country_id
        )
      );
      dispatch(
        getAPIAction.getCityListAPI3(
          userId,
          logId,
          userToken,
          navigate,
          data.country_id,
          data.region_id
        )
      );
      setKindata(data);
      setKinTab(tab);
      setsearchkindata([]);
    }

    setSelectedOption("");
    setFilterFields(initialfilterData);
    setActiveRadioField([]);
  };

  const [detailsData1, setDetailsData1] = useState([]);
  const InsuranceDetailsTableHead =
    tableData[0]?.register?.[0]?.InsuranceDetails?.[0]?.tableHead || [];
  const ViewInsuranceDetailsTableHead =
    tableData[0]?.register?.[0]?.InsuranceDetails?.[1]?.viewListTableHead || [];
  const GaurdianTableHead =
    tableData[0]?.register?.[0]?.parentGardian?.[0]?.tableHead || [];
  const KinTableHead =
    tableData[0]?.register?.[0]?.NextOfKin?.[0]?.tableHead || [];
  const GaurdiansearchTableHead =
    tableData[0]?.register?.[0]?.Gardiansearch?.[0]?.tableHead || [];
  const kinsearchTableHead =
    tableData[0]?.register?.[0]?.Gardiansearch?.[0]?.tableHead || [];
  const OnlineReferralTableHead =
    tableData[0]?.register?.[0]?.OnlineReferralDetails?.[0]?.tableHead || [];
  const ResidantTableHead =
    tableData[0]?.register?.[0]?.ResidantId?.[0]?.tableHead || [];
  const DepartmentListTableHead =
    tableData?.[0]?.plan_detail?.find((master) =>
      master.hasOwnProperty("service_category")
    )?.service_category?.[0]?.tableHead || [];
  const [clientList, setClientList] = useState([]);
  const [ServiceCategorylist, setServiceCategoryList] = useState([
    "General Consultation",
    "Specialist Visit",
    "Lab Tests",
    "Imaging Services",
    "Emergency Services",
  ]);
  const [insurancetableData, setinsurancetableData] = useState(
    ServiceCategorylist.reduce((acc, _, index) => {
      acc[`copay_${index}`] = "";
      acc[`deductible_${index}`] = "";
      acc[`perInvoice_${index}`] = "";
      acc[`maxCeiling_${index}`] = "";
      return acc;
    }, {})
  );

  const handletableInputChange = (e) => {
    const { name, value } = e.target;
    setinsurancetableData({
      ...insurancetableData,
      [name]: value,
    });
  };
  const [imagePath, setImagePath] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [editProfileImg, setEditProfileImg] = useState(false);
  const [editpreviewProfileImg, seteditpreviewProfileImg] = useState("");
  const [editfrontImg, seteditfrontImg] = useState(false);
  const [editbackImg, seteditbackImg] = useState(false);

  const [activeRadioField, setActiveRadioField] = useState([]);

  // const [personalList, setpersonalList] = useState([{ id: 1, name: "test1" }, { id: 2, name: "test2" }]);
  const countryList = useSelector((state) => state.allMasterList.countryList);
  const titleAllList = useSelector((state) => state.allMasterList.titleAllList);
  const genderAllList = useSelector(
    (state) => state.allMasterList.genderAllList
  );
  const regionList = useSelector((state) => state.allMasterList.regionList);
  const regionList1 = useSelector((state) => state.allMasterList.regionList1);
  const regionList2 = useSelector((state) => state.allMasterList.regionList2);
  const regionList3 = useSelector((state) => state.allMasterList.regionList3);
  const cityList = useSelector((state) => state.allMasterList.cityList);
  const cityList1 = useSelector((state) => state.allMasterList.cityList1);
  const cityList2 = useSelector((state) => state.allMasterList.cityList2);
  const cityList3 = useSelector((state) => state.allMasterList.cityList3);
  const maritalStatusList = useSelector(
    (state) => state.allMasterList.maritalStatusList
  );
  const bloodgrouplist = useSelector(
    (state) => state.allMasterList.bloodgrouplist
  );
  const patientClassList = useSelector(
    (state) => state.allMasterList.patientClassList
  );
  const religionList = useSelector((state) => state.allMasterList.religionList);
  const languageList = useSelector((state) => state.allMasterList.languageList);
  const incomeRangeList = useSelector(
    (state) => state.allMasterList.incomeRangeList
  );
  const nationalityList = useSelector(
    (state) => state.allMasterList.nationalityList
  );
  const occupationList = useSelector(
    (state) => state.allMasterList.occupationList
  );
  const educationList = useSelector(
    (state) => state.allMasterList.educationList
  );
  const ethnicList = useSelector((state) => state.allMasterList.ethnicList);
  const industryList = useSelector((state) => state.allMasterList.industryList);
  const relationshipList = useSelector(
    (state) => state.allMasterList.relationshipList
  );
  const referralSourceList = useSelector(
    (state) => state.allMasterList.referralSourceList
  );
  const referralChannelList = useSelector(
    (state) => state.allMasterList.referralChannelList
  );
  const companytypelist = useSelector(
    (state) => state.allMasterList.companytypelist
  );

  const loading = useSelector((state) => state.common.showLoading);

  const [AskNavigationOpenModal, setAskNavigationOpenModal] = useState(false);
  const [AskSavePatientOpenModal, setAskSavePatientOpenModal] = useState(false);
  const [CoPayOpenModal, setCoPayOpenModal] = useState(false);
  const [toggleOnlineReferralModal, setToggleOnlineReferralModal] =
    useState(false);
  const [togglePhoneCallRegModal, setTogglePhoneCallRegModal] = useState(false);
  const [switchPhoneCallRegModal, setSwitchPhoneCallRegModal] = useState(0);
  const [showConfirmEditModal, setConfirmEditModal] = useState(false);
  const [enableEditModal, setEnableEditModal] = useState(false);
  const [showSaveaAndCloseModal, setShowSaveaAndCloseModal] = useState(false);
  const [saveCheckInsuranceConfirmModal, setSaveCheckInsuranceConfirmModal] =
    useState(false);
  const [showDeleteInsuranceModal, setShowDeleteInsuranceModal] =
    useState(false);
  const [showCopayConditionedModal, setShowCopayConditionedModal] =
    useState(false);
  const [checkChangesInsuranceData, setCheckChangesInsuranceData] =
    useState(false);
  const [showAutoFillEmiratesModal, setShowAutoFillEmiratesModal] =
    useState(false);

  const [existInsuranceDataModal, setExistInsuranceDataModal] = useState(false);
  const [existInsuranceDataIndex, setExistInsuranceDataIndex] = useState(-1);
  const [askNewPlanModal, setAskNewPlanModal] = useState(false);

  const whatsupNumberList = useState([
    { id: "1", name: "Primary Contact" },
    { id: "2", name: "Secondary contact" },
  ]);
  const paymentModeList = useState([
    { id: "1", name: "Cash" },
    { id: "2", name: "Company" },
  ]);
  const [scanResidentPopup, setScanResidentPopup] = useState(false);
  //const [patient_id, setpatient_id] = useState(0);
  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.auth.auth);
  const userToken = userData.idToken;
  const userId = userData.userId;
  // const roleId = parseInt(userData.rollId);
  const logId = parseInt(userData.logId);
  const hospital_detail_id = parseInt(userData.hospital_detail_id);
  const fullname = userData.fullName;
  const hash = window.location.hash;

  const queryString = hash.split("?")[1];

  const params = new URLSearchParams(queryString);

  const [patient_id1, setpatient_id] = useState(
    params.size > 0 ? params.get("patientId") : 0
  );
  const currentDate = moment();

  // Format current date as "DD-MM-YYYY"
  const formattedDate = currentDate.format("DD-MM-YYYY");
  const formattedTime = currentDate.format("h:mm A");
  const formattedDateTime = new AdapterDayjs().date(new Date());
  const openScanResidentPopup = () => {
    setScanResidentPopup(true);
  };

  const closeScanResidentPopup = () => {
    setScanResidentPopup(false);
  };
  const initialFormData = {
    user_id: userId,
    patient_id: patient_id1,
    hospital_detail_id: "",
    register_date: formattedDate,
    register_time: formattedTime,
    register_datetime: formattedDateTime,
    patient_class_id: "",
    ethnic_id: "",
    member_type: "",
    patient_status: "Alive",
    deceased_date: "",
    education_id: "",
    occupation_id: "",
    register_no: "",
    industry_id: "",
    mr_number: "",
    patient_age: "",
    title_id: "",
    gender_id: "",
    user_created: "",
    marital_status_id: "",
    first_name: "",
    middle_name: "",
    last_name: "",
    father_name: "",

    religion_id: "",
    arabic_name: "",
    primary_email: "",
    primary_mobile: "+971",
    nationality_id: "",
    //  username: '',
    secondary_mobile: "+971",
    date_of_birth: "",
    blood_group_id: 2,
    // secondary_email: '',
    language_id: [],
    passport_no: "",
    income_range_id: "",
    payment_mode_id: "1",
    profile_image: "",
    medical_tourism: "",
    visitor_type: "",
    referral_source_id: "",
    // whatsup_number:""
  };

  const initialemiratedata = {
    emirate_ids: "___-____-_______-_",
    expiry_date: "",
    front_page: "",
    back_page: "",
  };
  const initialothersdata = {
    unified_no: "",
    mothers_eid: "___-____-_______-_",
    multiple_birth: 0,
    birth_order: "",
    birth_place: "",
  };
  const initialaddressdata = {
    address: "",
    country_id: "",
    region_id: "",
    city_id: "",
    religion_id: "",
    post_box_no: "",
    primary_contact_code: "+971",
    secondary_contact_code: "+971",
    primary_contact: "+971",
    secondary_contact: "+971",
    home_telephone: "+971",
    work_telephone: "+971",
    email: "",
    no_email_available: 1,
    whatsup_number: "",
  };

  const initalreferraldata = {
    referral_source_id: "",
    referral_channel_id: "",
    doctor_name: "",
    clinic_name: "",
    license_no: "",
  };

  const initialGuardian = {
    relationship_id: "",
    firstname: "",
    middlename: "",
    lastname: "",
    mr_number: "",
    mobile_no: "+971",
    email: "",
    address: "",
    country_id: "",
    region_id: "",
    city_id: "",
    // post_box_no: '',
    // landline_number: '+971',
    // office_phone: '+971',
  };

  const initialKin = {
    relationship_id: "",
    firstname: "",
    middlename: "",
    lastname: "",
    mr_number: "",
    mobile_no: "+971",
    email: "",
    address: "",
    country_id: "",
    region_id: "",
    city_id: "",
    // post_box_no: '',
    // landline_number: '+971',
    // office_phone: '+971',
  };

  const initialfilterData = {
    mr_number: "",
    first_name: "",
    last_name: "",
    mobile_no: "+971",
  };

  const initialonlinereferraldata = {
    from_date: "",
    to_date: "",
  };

  const initialinsurancedata = {
    company_type_id: "3",
    insurance_company_detail_id: "",
    main_company_short_code: "",
    sub_company_detail_id: "",
    sub_company_short_code: "",
    policy_holder: "",
    insurance_package_id: "",
    insurance_network_id: "",
    insurance_plan_id: "",
    card_number: "",
    expiry_date: "",
    max_ceilling: "",
    deduct_amount: "",
    co_pay_amount: "",
    co_pay_all_service: 0,
    with_consultation: 0,
    categories: [],
    is_status: 1,
    // still fields have to add
  };

  const initialscanresidentdata = {
    from_date: "",
    to_date: "",
  };

  const [formData, setFormData] = useState(initialFormData);
  const [filterFields, setFilterFields] = useState(initialfilterData);
  const dashboardTableHead =
    tableData[0]?.dashboard?.[0]?.recentOrders?.[0]?.tableHead || [];

  const [emiratedata, setemiratedata] = useState(initialemiratedata);
  const [othersdata, setothersdata] = useState(initialothersdata);
  const [addressdata, setaddressdata] = useState(initialaddressdata);
  const [referraldata, setreferraldata] = useState(initalreferraldata);
  const [guardiandata, setguardiandata] = useState(initialGuardian);
  const [onlinereferraldata, setonlinereferraldata] = useState(
    initialonlinereferraldata
  );
  const [insurancedata, setinsurancedata] = useState(initialinsurancedata);
  const [scanresidentdata, setscanresidentdata] = useState(
    initialscanresidentdata
  );
  const [activeInsuranceId, setActiveInsuranceId] = useState(0);
  const [multipleinsurancedata, setmultipleinsurancedata] = useState([]);
  const [multipleGuardiandata, setMultipleGuardiandata] = useState([]);
  // const [multipleDepartmentListdata, setMultipleDepartmentListdata] = useState([]);
  const [plandetaildata, setplandetaildata] = useState({});
  const [getExistingInsuranceDetails, setGetExistingInsuranceDetails] =
    useState([]);
  const [searchGuardiandata, setsearchGuardiandata] = useState([]);
  const [searchKindata, setsearchkindata] = useState([]);
  const [kindata, setKindata] = useState(initialKin);
  const [multipleKindata, setMultipleKindata] = useState([]);
  const [onlinereferraltabledata, setOnlineReferralTableData] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");
  const [searchvalue, setsearchvalue] = useState("");
  const [searchhospitalid, setsearchhospitalid] = useState(0);

  const [prevProfileImage, setPrevProfileImage] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [editExisiting, setEditExising] = useState(true);
  const [guardianIndex, setGuardianIndex] = useState(-1);
  const [kinIndex, setKinIndex] = useState(-1);
  const [regionLoader, setRegionLoader] = useState(false);
  const [cityLoader, setCityLoader] = useState(false);

  const [title, setTitle] = useState("New");

  const maincompanylist = useSelector(
    (state) => state.allMasterList.maincompanylist
  );
  const subcompanylist = useSelector(
    (state) => state.allMasterList.subcompanylist
  );
  const networklist = useSelector(
    (state) => state.allMasterList.insnetworklist
  );
  const networkplanlist = useSelector(
    (state) => state.allMasterList.networkplanlist
  );
  const benefitlist = useSelector((state) => state.allMasterList.benefitlist);
  const planservicecategorylist = useSelector(
    (state) => state.allMasterList.planservicecategorylist
  );
  const inputRef = useRef(null);

  const [maxSizeException, setMaxSizeException] = useState(0);

  // useEffect(() => {
  //     // This effect runs after every render, ensuring that the input is focused initially and after each render
  //     if (inputRef.current) {
  //         inputRef.current.focus();
  //     }
  // }, []);

  useEffect(() => {
    document.title = "Registration";
    if (Object.keys(userData).length !== 0) {
      setsearchhospitalid(hospital_detail_id);
    }

    setFormData((prevdata) => ({
      ...prevdata,
      hospital_detail_id: hospital_detail_id,
      user_created: fullname,
    }));
  }, [userData]);

  const handleOptionChange = (event, name) => {
    setFormData({ ...formData, [name]: event.target.value });
    // Update the selected option when a radio button is clicked
  };

  const handlesearchoption = (event) => {
    setSelectedOption(event.target.value);
  };

  const shouldLog = useRef(true);
  useEffect(() => {
    if (shouldLog.current) {
      getClientListAPI();
      dispatch(
        getAPIAction.getCountryListAPI(userId, logId, userToken, navigate)
      );
      dispatch(
        getAPIAction.GetInsMainCompanyList(userId, logId, userToken, navigate)
      );
      dispatch(
        getAPIAction.getAllTitleListAPI(userId, logId, userToken, navigate)
      );
      dispatch(
        getAPIAction.getAllGenderListAPI(userId, logId, userToken, navigate)
      );
      dispatch(
        getAPIAction.getMaritalStatusListAPI(userId, logId, userToken, navigate)
      );
      dispatch(
        getAPIAction.getPatientClassListAPI(userId, logId, userToken, navigate)
      );
      dispatch(
        getAPIAction.getBloodGroupListAPI(userId, logId, userToken, navigate)
      );
      dispatch(
        getAPIAction.getReligionListAPI(userId, logId, userToken, navigate)
      );
      dispatch(
        getAPIAction.getLanguageListAPI(userId, logId, userToken, navigate)
      );
      dispatch(
        getAPIAction.getIncomeRangeListAPI(userId, logId, userToken, navigate)
      );
      dispatch(
        getAPIAction.getNationalityListAPI(userId, logId, userToken, navigate)
      );
      dispatch(
        getAPIAction.getEthnicListAPI(userId, logId, userToken, navigate)
      );
      dispatch(
        getAPIAction.getIndustryListAPI(userId, logId, userToken, navigate)
      );
      dispatch(
        getAPIAction.getOccupationListAPI(userId, logId, userToken, navigate)
      );
      dispatch(
        getAPIAction.getEducationListAPI(userId, logId, userToken, navigate)
      );
      dispatch(
        getAPIAction.getRelationshipListAPI(userId, logId, userToken, navigate)
      );
      dispatch(
        getAPIAction.getReferralSourceListAPI(
          userId,
          logId,
          userToken,
          navigate
        )
      );
      dispatch(
        getAPIAction.getReferralChannelListAPI(
          userId,
          logId,
          userToken,
          navigate
        )
      );
      dispatch(
        getAPIAction.getCompanyTpyeListAPI(userId, logId, userToken, navigate)
      );

      dispatch({
        type: ALL_MASTER_ACTION_TYPES.SET_PLAN_SERVICE_CATEGORY_LIST,
        payload: [],
      });

      toggleSideMenu(true);
      NavMenuOn();
    }

    if (params.size > 0) {
      setTitle("Edit");
    } else {
      setEditExising(false);
    }
    // setShowFieldLoad(true);

    // // After 5 seconds, set showFieldLoad back to false
    // const timer = setTimeout(() => {
    //   setShowFieldLoad(false);
    // }, 5000); // 5000 milliseconds = 5 seconds

    // // Clean up the timer to prevent memory leaks
    // return () => clearTimeout(timer);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (patient_id1 === 0) {
      loadPatientClass();
    }
  }, [patientClassList]);

  const loadPatientClass = () => {
    if (patientClassList.length !== 0) {
      const patient_class_id = patientClassList.find(
        (e) => e.short_code === "O"
      );
      setFormData((prevdata) => ({
        ...prevdata,
        patient_class_id: patient_class_id.id,
      }));
    }
  };

  useEffect(() => {
    if (patient_id1 === 0) {
      loadCountryUAE();
    }
  }, [countryList]);

  const loadCountryUAE = () => {
    if (countryList && countryList?.length !== 0) {
      const country_id = countryList.find((e) => e.short_name === "AE");
      setaddressdata((prevdata) => ({
        ...prevdata,
        country_id: country_id.id,
        region_id: "",
        city_id: "",
      }));
      dispatch({ type: ALL_MASTER_ACTION_TYPES.SET_REGION_LIST1, payload: [] });
      dispatch(
        getAPIAction.getRegionListAPI(
          userId,
          logId,
          userToken,
          navigate,
          countryList[1]?.id
        )
      );
    }
  };

  useEffect(() => {
    if (regionList && regionList?.length !== 0 && patient_id1 === 0) {
      setaddressdata((prevdata) => ({
        ...prevdata,
        region_id: regionList[0]?.id,
        city_id: "",
      }));
      dispatch({ type: ALL_MASTER_ACTION_TYPES.SET_CITY_LIST1, payload: [] });
      dispatch(
        getAPIAction.getCityListAPI(
          userId,
          logId,
          userToken,
          navigate,
          countryList[1]?.id,
          regionList[0]?.id
        )
      );
    }
  }, [regionList]);

  useEffect(() => {
    if (cityList && cityList?.length !== 0 && patient_id1 === 0) {
      setaddressdata((prevdata) => ({ ...prevdata, city_id: cityList[0]?.id }));
    }
  }, [cityList]);

  //Text button Modifier - to enable the text or phone input
  const handleTextChange = (e) => {
    setActiveRadioField(
      gaurdianTab === 2
        ? [
            registrationUIDatas[0]?.registration[0]?.guardianSearchListFilter[
              e.target.value
            ],
          ]
        : [
            registrationUIDatas[0]?.registration[0]?.kinSearchListFilter[
              e.target.value
            ],
          ]
    );
    setFilterFields(initialfilterData);
    setSelectedOption(e.target.value);
  };

  //Radio button Modifier - to enable the text or phone input
  const handleRadioChange = (e) => {
    setActiveRadioField(
      gaurdianTab === 2
        ? [
            registrationUIDatas[0]?.registration[0]?.guardianSearchListFilter[
              e.target.value
            ],
          ]
        : [
            registrationUIDatas[0]?.registration[0]?.kinSearchListFilter[
              e.target.value
            ],
          ]
    );
    setFilterFields(initialfilterData);
    setSelectedOption(e.target.value);
  };

  const handleEmiratesOptionChange = (e) => {
    setemiratedata((prevdata) => ({
      ...prevdata,
      emirate_ids: e.target.value,
    }));
    setShowAutoFillEmiratesModal(false);
    setErrors((prevdata) => ({ ...prevdata, emirate_ids: "" }));
  };

  // update values of enabled text or phone input
  const handleRadioValueModifier = (e) => {
    const { name, value, type, files } = e.target;
    const newValue = type === "file" ? files[0] : value;
    setFilterFields({ [name]: newValue });
  };

  // useEffect(() => {
  //     if(maincompanylist.length > 0  && patient_id1 === 0) {
  //         // console.log(maincompanylist);
  //         setinsurancedata(prevdata => ({...prevdata , company_id : maincompanylist[0].company_id, main_company_short_code : maincompanylist[0].short_code}))
  //         dispatch(getAPIAction.GetInsSubCompanyList(userId, logId, userToken, navigate, maincompanylist[0].company_id));
  //         dispatch(getAPIAction.GetNetworkInsCompanyWise(userId, logId, userToken, navigate, maincompanylist[0].company_id));

  //         dispatch(getAPIAction.GetBenefitPackageCompanyWise(userId, logId, userToken, navigate, maincompanylist[0].company_id));

  //     }
  // }, [maincompanylist])

  // useEffect(() => {
  //     if(subcompanylist.length > 0  && patient_id1 === 0) {
  //         setinsurancedata(prevdata => ({...prevdata , payer_id : subcompanylist[0].company_id, sub_company_short_code : subcompanylist[0].short_code}))
  //       //  console.log(subcompanylist)
  //     }
  // }, [subcompanylist])

  // useEffect(() => {
  //     if(networklist.length > 0  && patient_id1 === 0) {
  //         setinsurancedata(prevdata => ({...prevdata , network_id : networklist[0].id}))
  //         dispatch(getAPIAction.GetPlanNetworkWise(userId, logId, userToken, navigate, networklist[0].id));
  //     }
  // }, [networklist])

  // useEffect(() => {
  //     if(benefitlist.length > 0  && patient_id1 === 0) {
  //         setinsurancedata(prevdata => ({...prevdata , benefitpackage_id : benefitlist[0].package_id}))
  //     }
  // }, [benefitlist])

  useEffect(() => {
    if (shouldLog.current) {
      shouldLog.current = false;
      if (patient_id1 !== 0) {
        console.log("vee");
        GetSinglePatient();
      }
    }

    if (patient_id1 !== 0) {
      setFormData({ ...formData, ["register_no"]: patient_id1 });
    }
    // eslint-disable-next-line
  }, [patient_id1]);

  const entryChecker = () => {
    const Textfields = [
      "first_name",
      "middle_name",
      "last_name",
      "date_of_birth",
      "member_type",
      "medical_tourism",
      "visitor_type",
    ];
    const Numberfields = [
      "gender_id",
      "title_id",
      "marital_status_id",
      "nationality_id",
      "religion_id",
      "occupation_id",
      "education_id",
    ];
    const Arrayfields = ["language_id"];

    const textStatus = Textfields.find((field) => formData[field] !== "");

    const numberStatus = Numberfields.find(
      (field) => formData[field] !== "" || formData[field] > 0
    );

    const arrayStatus = Arrayfields.find((field) => formData[field].length > 0);

    if (
      textStatus == undefined &&
      numberStatus == undefined &&
      arrayStatus == undefined
    ) {
      return false;
    }

    return true;
  };

  //Handle function of all form inputs
  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    const maxFileSize = 2 * 1024 * 1024;
    const newValue =
      type === "file"
        ? files[0]
        : type === "text"
        ? value.toUpperCase()
        : value;
    // console.log(name,value)
    setCheckChanges(true);
    if (
      name === "first_name" ||
      name === "middle_name" ||
      name === "last_name"
    ) {
      const sanitizedInput = newValue.replace(/[^a-zA-Z]/g, "");
      setFormData({ ...formData, [name]: sanitizedInput });
    } else if (name === "mr_number") {
      const sanitizedInput = newValue.replace(/[^0-9]/g, "");
      setFormData({ ...formData, [name]: sanitizedInput });
    } else if (name === "passport_no") {
      const sanitizedInput = newValue.replace(/[^a-zA-Z0-9]/g, "");
      if (sanitizedInput.length > 12) {
        return;
      }
      setFormData({ ...formData, [name]: sanitizedInput.toUpperCase() });
    } else if (name === "payment_mode_id") {
      setFormData({ ...formData, [name]: newValue });
      setinsurancedata(initialinsurancedata);

      dispatch({
        type: ALL_MASTER_ACTION_TYPES.SET_SUB_COMPANY_LIST,
        payload: [],
      });
      dispatch({ type: ALL_MASTER_ACTION_TYPES.SET_NETWORK_LIST, payload: [] });
      dispatch({ type: ALL_MASTER_ACTION_TYPES.SET_BENEFIT_LIST, payload: [] });
      dispatch({
        type: ALL_MASTER_ACTION_TYPES.SET_PLAN_NETWORK_LIST,
        payload: [],
      });
      dispatch({
        type: ALL_MASTER_ACTION_TYPES.SET_PLAN_SERVICE_CATEGORY_LIST,
        payload: [],
      });

      // setMultipleDepartmentListdata([]);
      setplandetaildata({});
    } else {
      setFormData({ ...formData, [name]: newValue });
    }

    if (name === "country_id") {
      setFormData((prevFormData) => ({
        ...prevFormData,
        region_id: "",
        city_id: "",
      }));
      dispatch({ type: ALL_MASTER_ACTION_TYPES.SET_REGION_LIST, payload: [] });
      //Get State/Region Data
      dispatch(
        getAPIAction.getRegionListAPI(
          userId,
          logId,
          userToken,
          navigate,
          newValue
        )
      );
    } else if (name === "region_id") {
      setFormData((prevFormData) => ({
        ...prevFormData,
        city_id: "",
      }));
      dispatch({ type: ALL_MASTER_ACTION_TYPES.SET_CITY_LIST, payload: [] });

      dispatch(
        getAPIAction.getCityListAPI(
          userId,
          logId,
          userToken,
          navigate,
          formData.country_id,
          newValue
        )
      );
    } else if (type === "file" && files[0] && name === "profile_image") {
      if (files[0].size > maxFileSize) {
        setMaxSizeException(files[0].size);

        setEditProfileImg(false);
        setFormData({ ...formData, [name]: "" });
        setCancelProfile(false);
        setTimeout(() => {
          setCancelProfile(true);
          setMaxSizeException(0);
        }, 500);

        return false;
      }
      setPrevProfileImage(newValue);
      setEditProfileImg(true);
      setFormData({ ...formData, [name]: newValue });
      setErrors({ ...errors, [name]: "" });
      setCancelProfile(false);
      setTimeout(() => {
        setCancelProfile(true);
      }, 500);
    } else if (type === "file" && name === "profile_image") {
      // if (newValue.size > maxFileSize) {
      //     toast.error('File size should be less than 2 MB', {
      //         position: toast.POSITION.TOP_RIGHT,
      //         autoClose: 1000,
      //     });
      //     //setErrors({ ...errors, [name]: 'File size should be less than 2 MB' });
      //     return false
      //   }
      if (newValue === undefined) {
        seteditpreviewProfileImg(prevProfileImage);
        setFormData({ ...formData, [name]: prevProfileImage });
      }
    } else if (name === "medical_tourism") {
      // console.log(name, newValue)
      if (newValue === "Resident")
        setFormData({ ...formData, [name]: newValue, visitor_type: "" });
      else setFormData({ ...formData, [name]: newValue });
      setErrors({ ...errors, [name]: "" });
    } else if (name === "visitor_type") {
      setFormData({ ...formData, [name]: newValue });
      setErrors({ ...errors, [name]: "" });
      setDetailTab(2);

      setemiratedata({ ...emiratedata, ["emirate_ids"]: "999-9999-9999999-9" });
    } else if (name === "date_of_birth") {
      var age = calculateAge(newValue);
      console.log(newValue);
      setFormData({ ...formData, [name]: newValue, ["patient_age"]: age });
      setErrors({ ...errors, [name]: "" });
    } else {
      setErrors({ ...errors, [name]: "" });
    }
  };
  const resetcenter = (e) => {
    setsearchhospitalid(0);
  };

  useEffect(() => {
    const maxFileSize = 2 * 1024 * 1024;
    if (maxSizeException > maxFileSize) {
      toast.error("File size should be less than 2 MB", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    }
  }, [maxSizeException]);

  const patternMaker = (newValue) => {
    // Replace the '0's in the pattern with user input
    let newPattern = "___-____-_______-_";
    let digitsEntered = 0;

    // Build the new pattern based on user input
    for (
      let i = 0;
      i < newValue.length && digitsEntered < newPattern.length;
      i++
    ) {
      if (newValue[i] >= "0" && newValue[i] <= "9") {
        // Replace the first '0' in the pattern with the current digit
        const hypenIndex = newPattern.indexOf("_");
        if (hypenIndex !== -1) {
          newPattern =
            newPattern.slice(0, hypenIndex) +
            newValue[i] +
            newPattern.slice(hypenIndex + 1);
          digitsEntered++;
        }
      }
    }

    //     if (inputRef.current) {
    //         const lastDigitIndex = newPattern.lastIndexOf(newPattern.replace(/[^0-9]/g, '').slice(-1));
    //         const cursorPosition = lastDigitIndex !== -1 ? lastDigitIndex + 1 : 0;

    //         if (inputRef.current.setSelectionRange)
    //         {
    //             inputRef.current.setSelectionRange(cursorPosition, cursorPosition);
    //         }

    //       }

    return newPattern;
  };

  const subhandleChange = (e, tab, info = null) => {
    const { name, value, type, files } = e.target;
    const newValue = type === "file" ? files[0] : value;
    // console.log(name, value);
    if (type === "file" && files.length === 0) {
      return;
    }
    setCheckChanges(true);
    if (tab === "E.ID") {
      if (type === "file" && files[0]) {
        if (name === "front_page") {
          seteditfrontImg(true);
        } else if (name === "back_page") {
          seteditbackImg(true);
        }
      }

      if (name === "emirate_ids") {
        const newPattern = patternMaker(newValue);
        setemiratedata({ ...emiratedata, [name]: newPattern });
        // State update
        if (!/_/.test(newPattern)) {
          setErrors({});
        } else {
          setErrors({ ...errors, [name]: "Fill the emirated Id properly" });
        }

        // Keep track of the caret position
        setTimeout(() => {
          if (inputRef.current) {
            // Check if the input element is still available
            // const selectionStart = e.target.selectionStart;
            const lastDigitIndex = newPattern.lastIndexOf(
              newPattern.replace(/[^0-9]/g, "").slice(-1)
            );
            const cursorPosition =
              lastDigitIndex !== -1 ? lastDigitIndex + 1 : 0;
            // inputRef.current.selectionStart = inputRef.current.selectionEnd = selectionStart;
            e.target.setSelectionRange(cursorPosition, cursorPosition);
          }
        }, 0);
      } else if (name === "expiry_date") {
        const enteredDate = new Date(newValue);
        const currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0);
        if (enteredDate < currentDate) {
          setErrors({ ...errors, [name]: "Past Date is not allowed" });
        } else {
          setErrors({ ...errors, [name]: "" });
        }
        setemiratedata({ ...emiratedata, [name]: newValue });
      } else {
        setemiratedata({ ...emiratedata, [name]: newValue });
      }
    } else if (tab === "Contact Details") {
      if (name === "country_id") {
        setaddressdata((prevFormData) => ({
          ...prevFormData,
          [name]: newValue,
          region_id: "",
          city_id: "",
        }));

        setErrors((prevdata) => ({
          ...prevdata,
          [name]: "",
          region_id: "",
          city_id: "",
        }));

        dispatch({
          type: ALL_MASTER_ACTION_TYPES.SET_REGION_LIST1,
          payload: [],
        });
        dispatch({ type: ALL_MASTER_ACTION_TYPES.SET_CITY_LIST1, payload: [] });
        //Get State/Region Data
        dispatch(
          getAPIAction.getRegionListAPI(
            userId,
            logId,
            userToken,
            navigate,
            newValue
          )
        );
        return;
      } else if (name === "region_id") {
        setaddressdata((prevFormData) => ({
          ...prevFormData,
          [name]: newValue,
          city_id: "",
        }));

        setErrors((prevdata) => ({ ...prevdata, [name]: "", city_id: "" }));
        dispatch({ type: ALL_MASTER_ACTION_TYPES.SET_CITY_LIST1, payload: [] });
        dispatch(
          getAPIAction.getCityListAPI(
            userId,
            logId,
            userToken,
            navigate,
            addressdata.country_id,
            newValue
          )
        );
        return;
      } else if (name === "address") {
        const sanitizedInput = newValue.replace(/[^0-9a-zA-Z//#., ]/g, "");
        setaddressdata((prevdata) => ({
          ...prevdata,
          address: sanitizedInput,
        }));
      } else if (name === "email") {
        const sanitizedInput = newValue.replace(/[^a-z0-9._@]/g, "");

        if (emailValidator(sanitizedInput) || sanitizedInput === "")
          setErrors({ ...errors, [name]: "" });
        else setErrors({ ...errors, [name]: "Email is invalid" });
        setaddressdata((prevdata) => ({ ...prevdata, email: sanitizedInput }));
        if (sanitizedInput !== "") {
          setaddressdata((prevdata) => ({
            ...prevdata,
            no_email_available: 0,
          }));
        } else {
          setaddressdata((prevdata) => ({
            ...prevdata,
            no_email_available: 1,
          }));
        }
      } else if (name === "whatsup_number") {
        setErrors({ ...errors, [name]: "" });
      }
      // else if (name === 'whatsup_number'){}
      else if (
        name === "primary_contact" ||
        name === "secondary_contact" ||
        name === "home_telephone" ||
        name === "work_telephone"
      ) {
        // console.log(info)
        // const mobile = newValue.split(' ').slice(1).join('');
        const countrycode = info.countryCallingCode;
        const mobileInput = info.nationalNumber;

        if (name === "primary_contact") {
          setaddressdata((prevdata) => ({
            ...prevdata,
            [name]: newValue,
            primary_contact_code: countrycode,
          }));
        } else if (name === "secondary_contact") {
          setaddressdata((prevdata) => ({
            ...prevdata,
            [name]: newValue,
            secondary_contact_code: countrycode,
          }));
        }
        if (
          mobileInput?.length > 10 ||
          newValue.split(" ").join("").length > 13
        ) {
          return;
        }
        if (
          matchIsValidTel(newValue, {
            onlyCountryies: [],
            excludedCountryies: [],
            continents: [],
          }) ||
          newValue === ""
        ) {
          setErrors({ ...errors, [name]: "" });
        } else {
          if (name === "home_telephone" || name === "work_telephone") {
            setErrors({ ...errors, [name]: "Enter valid telephone number" });
          } else {
            setErrors({ ...errors, [name]: "Enter valid mobile number" });
          }
        }
      }
      if (
        name !== "email" &&
        name !== "address" &&
        name !== "primary_contact" &&
        name !== "secondary_contact"
      )
        setaddressdata({ ...addressdata, [name]: newValue });
    } else if (tab === "Others") {
      if (name === "unified_no") {
        const sanitizedInput = newValue.replace(/[^0-9]/g, "");
        setothersdata({ ...othersdata, [name]: sanitizedInput });
      } else if (name === "birth_place") {
        const sanitizedInput = newValue.replace(/[^a-zA-Z]/g, "");
        setothersdata({ ...othersdata, [name]: sanitizedInput });
      } else if (name === "multiple_birth") {
        setothersdata({ ...othersdata, [name]: e.target.checked ? 1 : 0 });
      } else if (name === "birth_order") {
        const sanitizedInput = newValue.replace(/[^0-9]/g, "");
        if (sanitizedInput.length > 1) {
          return;
        } else {
          if (othersdata.mothers_eid === "___-____-_______-_") {
            setErrors({
              ...errors,
              [name]: "",
              mothers_eid: "Fill the mothers emirated Id properly",
            });
          } else {
            setErrors({ ...errors, [name]: "" });
          }
        }

        setothersdata({ ...othersdata, [name]: sanitizedInput });
      } else if (name === "mothers_eid") {
        const newPattern = patternMaker(newValue);

        // State update
        setothersdata({ ...othersdata, [name]: newPattern });

        // State update
        if (!/_/.test(newPattern)) {
          if (
            othersdata.birth_order === null ||
            othersdata.birth_order === 0 ||
            othersdata.birth_order === ""
          ) {
            setErrors({
              ...errors,
              [name]: "",
              birth_order: "Fill birth_order",
            });
          } else {
            setErrors({ ...errors, [name]: "" });
          }
        } else {
          if (
            othersdata.birth_order === null ||
            othersdata.birth_order === 0 ||
            othersdata.birth_order === ""
          ) {
            setErrors({
              ...errors,
              [name]: "Fill the mothers emirated Id properly",
              birth_order: "Fill birth_order",
            });
          } else {
            setErrors({
              ...errors,
              [name]: "Fill the mothers emirated Id properly",
            });
          }
        }
        // Keep track of the caret position
        setTimeout(() => {
          if (inputRef.current) {
            // Check if the input element is still available
            // const selectionStart = e.target.selectionStart;
            const lastDigitIndex = newPattern.lastIndexOf(
              newPattern.replace(/[^0-9]/g, "").slice(-1)
            );
            const cursorPosition =
              lastDigitIndex !== -1 ? lastDigitIndex + 1 : 0;
            // inputRef.current.selectionStart = inputRef.current.selectionEnd = selectionStart;
            e.target.setSelectionRange(cursorPosition, cursorPosition);
          }
        }, 0);
      } else {
        setothersdata({ ...othersdata, [name]: newValue });
      }
    } else if (tab === "Referral") {
      setreferraldata({ ...referraldata, [name]: newValue });
    } else if (tab === "Parent/Guardian") {
      if (
        name === "firstname" ||
        name === "middlename" ||
        name === "lastname"
      ) {
        let sanitizedInput = newValue.replace(/[^a-zA-Z]/g, "");
        setguardiandata((prevFormData) => ({
          ...prevFormData,
          [name]: sanitizedInput.toUpperCase(),
        }));
        setErrors({ ...errors, [name]: "" });
      } else if (name === "address") {
        let sanitizedInput = newValue.replace(/[^0-9a-zA-Z//#., ]/g, "");
        setguardiandata((prevFormData) => ({
          ...prevFormData,
          [name]: sanitizedInput,
        }));
      } else if (name === "country_id") {
        setguardiandata((prevFormData) => ({
          ...prevFormData,
          region_id: "",
          city_id: "",
        }));

        setErrors({ ...errors, [name]: "" });

        dispatch({
          type: ALL_MASTER_ACTION_TYPES.SET_REGION_LIST2,
          payload: [],
        });
        //Get State/Region Data
        dispatch(
          getAPIAction.getRegionListAPI2(
            userId,
            logId,
            userToken,
            navigate,
            newValue
          )
        );
      } else if (name === "region_id") {
        setguardiandata((prevFormData) => ({
          ...prevFormData,
          city_id: "",
        }));
        setErrors({ ...errors, [name]: "" });
        dispatch({ type: ALL_MASTER_ACTION_TYPES.SET_CITY_LIST2, payload: [] });
        dispatch(
          getAPIAction.getCityListAPI2(
            userId,
            logId,
            userToken,
            navigate,
            guardiandata.country_id,
            newValue
          )
        );
      } else if (name === "email") {
        const sanitizedInput = newValue.replace(/[^a-z0-9._@]/g, "");

        if (emailValidator(sanitizedInput) || sanitizedInput === "")
          setErrors({ ...errors, [name]: "" });
        else setErrors({ ...errors, [name]: "Email is invalid" });
        setguardiandata((prevdata) => ({ ...prevdata, email: sanitizedInput }));
      } else if (name === "mr_number") {
        const sanitizedInput = newValue.replace(/[^0-9]/g, "");
        setErrors({ ...errors, [name]: "" });
        setguardiandata({ ...guardiandata, [name]: sanitizedInput });
      } else if (name === "mobile_no") {
        // const mobileInput = newValue.split(' ').slice(1).join('');;
        const mobileInput = info.nationalNumber;
        if (
          mobileInput?.length > 10 ||
          newValue.split(" ").join("").length > 13
        ) {
          return;
        }
        if (
          matchIsValidTel(newValue, {
            onlyCountryies: [],
            excludedCountryies: [],
            continents: [],
          }) ||
          newValue === ""
        ) {
          setErrors({ ...errors, [name]: "" });
        } else {
          setErrors({ ...errors, [name]: "Enter valid mobile number" });
        }
      } else {
        setErrors({ ...errors, [name]: "" });
      }

      if (
        name !== "email" &&
        name !== "mr_number" &&
        name !== "firstname" &&
        name !== "middlename" &&
        name !== "lastname" &&
        name !== "address"
      ) {
        setguardiandata({ ...guardiandata, [name]: newValue });
      }
    } else if (tab === "Kin") {
      if (
        name === "firstname" ||
        name === "middlename" ||
        name === "lastname"
      ) {
        let sanitizedInput = newValue.replace(/[^a-zA-Z]/g, "");
        setKindata((prevFormData) => ({
          ...prevFormData,
          [name]: sanitizedInput.toUpperCase(),
        }));
        setErrors({ ...errors, [name]: "" });
      } else if (name === "country_id") {
        setKindata((prevFormData) => ({
          ...prevFormData,
          region_id: "",
          city_id: "",
        }));

        setErrors({ ...errors, [name]: "" });

        dispatch({
          type: ALL_MASTER_ACTION_TYPES.SET_REGION_LIST3,
          payload: [],
        });
        //Get State/Region Data
        dispatch(
          getAPIAction.getRegionListAPI3(
            userId,
            logId,
            userToken,
            navigate,
            newValue
          )
        );
      } else if (name === "region_id") {
        setKindata((prevFormData) => ({
          ...prevFormData,
          city_id: "",
        }));
        setErrors({ ...errors, [name]: "" });
        dispatch({ type: ALL_MASTER_ACTION_TYPES.SET_CITY_LIST3, payload: [] });
        dispatch(
          getAPIAction.getCityListAPI3(
            userId,
            logId,
            userToken,
            navigate,
            kindata.country_id,
            newValue
          )
        );
      } else if (name === "email") {
        const sanitizedInput = newValue.replace(/[^a-z0-9._@]/g, "");

        if (emailValidator(sanitizedInput) || sanitizedInput === "")
          setErrors({ ...errors, [name]: "" });
        else setErrors({ ...errors, [name]: "Email is invalid" });

        setKindata((prevdata) => ({ ...prevdata, email: sanitizedInput }));
      } else if (name === "mr_number") {
        const sanitizedInput = newValue.replace(/[^0-9]/g, "");
        setErrors({ ...errors, [name]: "" });
        setKindata({ ...kindata, [name]: sanitizedInput });
      } else if (name === "mobile_no") {
        const mobileInput = info.nationalNumber;
        if (
          mobileInput?.length > 10 ||
          newValue.split(" ").join("").length > 13
        ) {
          return;
        }
        if (
          matchIsValidTel(newValue, {
            onlyCountryies: [],
            excludedCountryies: [],
            continents: [],
          }) ||
          newValue === ""
        ) {
          setErrors({ ...errors, [name]: "" });
        } else {
          setErrors({ ...errors, [name]: "Enter valid mobile number" });
        }
      } else {
        setErrors({ ...errors, [name]: "" });
      }

      if (
        name !== "email" &&
        name !== "mr_number" &&
        name !== "firstname" &&
        name !== "middlename" &&
        name !== "lastname"
      )
        setKindata({ ...kindata, [name]: newValue });
    } else if (tab === "Search") {
      setsearchhospitalid(newValue);
    }
  };

  const AskSavePatientOpenModalController = async () => {
    if (patient_id1 == "0") {
      setAskSavePatientOpenModal(true);
      return;
    }
    await handlesubmitinsurancedetails(patient_id1);
  };

  const formSubmitController = async (e, type) => {
    // If patient is new
    if (patient_id1 == "0" && formData.payment_mode_id == "2") {
      setSaveType(type);
      setAskSavePatientOpenModal(true);
      return;
    }

    // automatically runs if patient is existing
    await formsubmit(e, type);
  };

  const checkCoPayOfAllServiceIsNotCheckedIfCopayIsEntered = () => {
    // console.log((insurancedata.co_pay_amount !== "" && parseInt(insurancedata.co_pay_amount) > 0), parseInt(insurancedata.co_pay_all_service) === 0)
    return (
      insurancedata.co_pay_amount !== "" &&
      parseInt(insurancedata.co_pay_amount) > 0 &&
      parseInt(insurancedata.co_pay_all_service) === 0
    );
  };

  const checkIfServiceCategoryNoCoPayPercentageIsEntered = () => {
    let isCopayEntered = false;
    for (let i = 0; i < plandetaildata.main_list.length; i++) {
      for (
        let j = 0;
        j < plandetaildata.main_list[i].category_list.length;
        j++
      ) {
        if (
          plandetaildata.main_list[i].category_list[j].co_pay_percentage !==
            "" &&
          parseInt(
            plandetaildata.main_list[i].category_list[j].co_pay_percentage
          ) > 0
        ) {
          isCopayEntered = true;
        }
      }
    }

    for (let i = 0; i < plandetaildata.sub_list.length; i++) {
      for (
        let j = 0;
        j < plandetaildata.sub_list[i].category_list.length;
        j++
      ) {
        if (
          plandetaildata.sub_list[i].category_list[j].co_pay_percentage !==
            "" &&
          parseInt(
            plandetaildata.sub_list[i].category_list[j].co_pay_percentage
          ) > 0
        ) {
          isCopayEntered = true;
        }
      }
    }

    return isCopayEntered;
  };

  const insuranceSubmitController = async (
    patient_id = patient_id1,
    withInsurance = true
  ) => {
    if (enableEditModal) {
      setConfirmEditModal(true);
      setEnableEditModal(false);
      return;
    }

    const validationErrors = insuranceValidationForm();

    if (Object.keys(validationErrors).length === 0) {
      console.log("CHECK");
      // console.log(checkCoPayOfAllServiceIsNotCheckedIfCopayIsEntered())
      // console.log(checkIfServiceCategoryNoCoPayPercentageIsEntered())
      if (
        checkCoPayOfAllServiceIsNotCheckedIfCopayIsEntered() &&
        !checkIfServiceCategoryNoCoPayPercentageIsEntered()
      ) {
        console.log("Copay is entered but not checked");
        setShowCopayConditionedModal(true);
        return;
      }

      const isExistReturnIndexOrNeg1 = checkExistingInsurance();
      // if existing insurance with new values
      if (isExistReturnIndexOrNeg1 !== -1 && activeInsuranceId === 0) {
        setExistInsuranceDataModal(true);
        setExistInsuranceDataIndex(isExistReturnIndexOrNeg1);
        setActiveInsuranceId(
          multipleinsurancedata[isExistReturnIndexOrNeg1].id
        );

        return;
      }
      // else new insurnace company itself
      else {
        await handlesubmitinsurancedetails(patient_id, withInsurance);
      }
    } else {
      setErrors(validationErrors);
      dispatch(setLoading(false));
    }
    handleCloseConfirmEditModal();
  };

  const handleCloseConfirmEditModal = () => {
    setEnableEditModal(true);
    setConfirmEditModal(false);
  };

  const checkExistingInsurance = () => {
    const getMultiplieInsuranceDataList = _.cloneDeep(multipleinsurancedata);

    const result = getMultiplieInsuranceDataList.findIndex(
      (data) =>
        parseInt(data.insurance_company_detail_id) ===
          parseInt(insurancedata.insurance_company_detail_id) &&
        parseInt(data.insurance_network_id) ===
          parseInt(insurancedata.insurance_network_id) &&
        parseInt(data.insurance_plan_id) ===
          parseInt(insurancedata.insurance_plan_id)
    );

    // console.log("CHECK EXISTING INSURANCE")
    // console.log(result)

    return result;
  };

  const UpdateInsuranceData = async () => {
    await handlesubmitinsurancedetails(patient_id1, true);
    setExistInsuranceDataIndex(-1);
    setExistInsuranceDataModal(false);
  };

  const AskToCreateAsNewPlan = () => {
    setAskNewPlanModal(true);
    setExistInsuranceDataModal(false);
    setActiveInsuranceId(0);
  };

  const createNewPlan = async () => {
    await handlesubmitinsurancedetails(patient_id1, true);
    setAskNewPlanModal(false);
  };

  const checkMrNumber = async (e) => {
    // console.log("mr.no",e.target.value)
    const { value } = e.target;
    dispatch(setLoading(true));
    // console.log("mr.no",value)

    const postmrdata = {
      user_id: userId,
      patient_detail_id: patient_id1,
      mr_number: value,
    };
    console.log(postmrdata);

    await getData
      .CheckMrNumber(postmrdata, userToken)
      .then((resp) => {
        if (resp.data.status === 1) {
          // toast.success(resp.data.message, {
          //     position: toast.POSITION.TOP_RIGHT,
          //     autoClose: 1000,
          // });
        } else {
          setFormData((prevdata) => ({ ...prevdata, mr_number: "" }));

          // setLoading(false);
          toast.error(resp.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
        }

        dispatch(setLoading(false));
      })
      .catch((error) => {
        if (error.response.data.status === 2) {
          toast.error(
            error.response.data.message + ". Please log in again to continue.",
            {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 1000,
            }
          );
          setTimeout(() => {
            dispatch(logoutFunction(userId, logId, userToken, navigate));
          }, 2000);
        } else {
          toast.error(
            error.response.data.message + " Please Try After Sometimes" ||
              "Too Many Attempts. Please Try after sometimes",
            {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 1000,
            }
          );
        }
        dispatch(setLoading(false));
      });
  };

  const checkPolicyNumber = async (e) => {
    const { value } = e.target;

    dispatch(setLoading(true));

    const postpolicydata = {
      user_id: userId,
      patient_insurance_id: activeInsuranceId,
      card_number: value,
    };

    // console.log(postpolicydata)

    await getData
      .CheckPolicyNumber(postpolicydata, userToken)
      .then((resp) => {
        if (resp.data.status === 1) {
          // toast.success(resp.data.message, {
          //     position: toast.POSITION.TOP_RIGHT,
          //     autoClose: 1000,
          // });
        } else {
          // setLoading(false);
          toast.error(resp.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
        }

        dispatch(setLoading(false));
      })
      .catch((error) => {
        if (error.response.data.status === 2) {
          toast.error(
            error.response.data.message + ". Please log in again to continue.",
            {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 1000,
            }
          );
          setTimeout(() => {
            dispatch(logoutFunction(userId, logId, userToken, navigate));
          }, 2000);
        } else {
          toast.error(
            error.response.data.message + " Please Try After Sometimes" ||
              "Too Many Attempts. Please Try after sometimes",
            {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 1000,
            }
          );
        }
        dispatch(setLoading(false));
      });
  };

  const checkEmirateID = async (e) => {
    // console.log("mr.no",e.target.value)
    const { value } = e.target;
    // console.log("E.ID",value)

    const emirate_id = value.replaceAll("-", "").replaceAll("_", "");

    if (
      emirate_id === "999999999999999" ||
      emirate_id === "111111111111111" ||
      emirate_id === "000000000000000" ||
      emirate_id === "222222222222222" ||
      emirate_id.length < 15
    ) {
      return;
    }

    dispatch(setLoading(true));

    const postemiratedata = {
      user_id: userId,
      patient_detail_id: patient_id1,
      emirate_ids: emirate_id,
    };
    console.log(postemiratedata);

    await getData
      .checkEmirateID(postemiratedata, userToken)
      .then((resp) => {
        if (resp.data.status === 1) {
          // toast.success(resp.data.message, {
          //     position: toast.POSITION.TOP_RIGHT,
          //     autoClose: 1000,
          // });
        } else {
          setemiratedata((prevdata) => ({
            ...prevdata,
            emirate_ids: "___-____-_______-_",
          }));

          // setLoading(false);
          toast.error(resp.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
        }

        dispatch(setLoading(false));
      })
      .catch((error) => {
        if (error.response.data.status === 2) {
          toast.error(
            error.response.data.message + ". Please log in again to continue.",
            {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 1000,
            }
          );
          setTimeout(() => {
            dispatch(logoutFunction(userId, logId, userToken, navigate));
          }, 2000);
        } else {
          toast.error(
            error.response.data.message + " Please Try After Sometimes" ||
              "Too Many Attempts. Please Try after sometimes",
            {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 1000,
            }
          );
        }
        dispatch(setLoading(false));
      });

    dispatch(setLoading(false));
  };

  const handlesubmitinsurancedetails = async (
    patientId = 0,
    getPatientApiCall = false
  ) => {
    dispatch(setLoading(true));
    const categories = [];

    // have to comment
    // const formatmultipleDepartmentListdata = _.cloneDeep(multipleDepartmentListdata);

    // for(let i=0;i<formatmultipleDepartmentListdata.length;i++) {
    //     delete formatmultipleDepartmentListdata[i].department_name;
    // }
    // have to comment end

    // check plan detail data

    const formatplandetaildata = _.cloneDeep(plandetaildata);
    const delete_ids = [];

    if (
      Object.keys(formatplandetaildata).length == 0 ||
      formatplandetaildata.main_list.length == 0 ||
      formatplandetaildata.sub_list.length == 0
    ) {
      toast.error("Service Category is empty", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });

      return;
    }

    for (let i = 0; i < formatplandetaildata.main_list.length; i++) {
      for (
        let j = 0;
        j < formatplandetaildata.main_list[i].category_list.length;
        j++
      ) {
        delete formatplandetaildata.main_list[i].category_list[j]
          .department_name;

        categories.push(formatplandetaildata.main_list[i].category_list[j]);
      }
    }

    for (let i = 0; i < formatplandetaildata.sub_list.length; i++) {
      for (
        let j = 0;
        j < formatplandetaildata.sub_list[i].category_list.length;
        j++
      ) {
        delete formatplandetaildata.sub_list[i].category_list[j]
          .department_name;
        delete formatplandetaildata.sub_list[i].category_list[j].active;

        if (plandetaildata.sub_list[i].category_list[j].active) {
          categories.push(formatplandetaildata.sub_list[i].category_list[j]);
        }

        console.log(plandetaildata.sub_list[i].category_list[j]);
        if (
          plandetaildata.sub_list[i].category_list[j].hasOwnProperty("id") &&
          !plandetaildata.sub_list[i].category_list[j].active
        ) {
          delete_ids.push(plandetaildata.sub_list[i].category_list[j].id);
        }
      }

      if (existInsuranceDataIndex !== -1) {
        // console.log(multipleinsurancedata)
        multipleinsurancedata[existInsuranceDataIndex].insurance_detail.map(
          (data) => {
            delete_ids.push(data.id);
          }
        );
      }
    }
    const cleanedCategories = categories.map((category) => {
      const cleanedCategory = { ...category };
      for (const key in cleanedCategory) {
        if (Number.isNaN(cleanedCategory[key])) {
          cleanedCategory[key] = null;
        }
      }
      return cleanedCategory;
    });
    // console.log(categories);
    // return false;
    const postinsurancedata = {
      user_id: userId,
      company_type_id: insurancedata.company_type_id,
      patient_detail_id: patient_id1 != "0" ? patient_id1 : patientId,
      patient_insurance_id: activeInsuranceId,
      insurance_company_detail_id: insurancedata.insurance_company_detail_id,
      delete_ids: delete_ids,
      // main_company_short_code : insurancedata.main_company_short_code,
      sub_company_detail_id: insurancedata.sub_company_detail_id,
      // sub_company_short_code:insurancedata.sub_company_short_code,
      policy_holder: insurancedata.policy_holder,
      insurance_package_id: insurancedata.insurance_package_id,
      insurance_network_id: insurancedata.insurance_network_id,
      insurance_plan_id: insurancedata.insurance_plan_id,
      card_number: insurancedata.card_number,
      expiry_date: insurancedata.expiry_date,
      max_ceilling: insurancedata.max_ceilling,
      deduct_amount: insurancedata.deduct_amount,
      co_pay_amount: insurancedata.co_pay_amount,
      co_pay_all_service: insurancedata.co_pay_all_service,
      with_consultation: insurancedata.with_consultation,
      categories: cleanedCategories,
      is_status: insurancedata.is_status,
    };

    console.log(postinsurancedata);

    await getData
      .SavePatientInsurance(postinsurancedata, userToken)
      .then((resp) => {
        if (resp.data.status === 1) {
          //setpatient_id(resp.data.patient_id);
          // console.log(patient_id1, patientId);
          setActiveInsuranceId(resp.data.patient_insurance_id);
          setinsurancedata((prevState) => ({
            ...prevState,
            patient_insurance_id: resp.data.patient_insurance_id,
          }));
          setCheckChangesInsuranceData(false);
          if (getPatientApiCall) {
            GetSinglePatient();
          }
          // console.log(resp);
          // setLoading(false);
          toast.success(resp.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
        } else {
          // setLoading(false);
          toast.error(resp.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
        }

        dispatch(setLoading(false));
      })
      .catch((error) => {
        if (error.response.data.status === 2) {
          toast.error(
            error.response.data.message + ". Please log in again to continue.",
            {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 1000,
            }
          );
          setTimeout(() => {
            dispatch(logoutFunction(userId, logId, userToken, navigate));
          }, 2000);
        } else {
          toast.error(
            error.response.data.message + " Please Try After Sometimes" ||
              "Too Many Attempts. Please Try after sometimes",
            {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 1000,
            }
          );
        }
        dispatch(setLoading(false));
      });
  };

  useEffect(() => {
    console.log(insurancedata);
  }, [insurancedata]);

  useEffect(() => {
    console.log(activeInsuranceId);
  }, [activeInsuranceId]);

  const handleInsuranceChange = (e, tab) => {
    const { name, value, type, files, checked } = e.target;
    const newValue =
      type === "file"
        ? files[0]
        : type === "checkbox"
        ? checked
          ? 1
          : 0
        : type === "text"
        ? value.toUpperCase()
        : value;
    const patient_class_id = patientClassList.find((e) => e.short_code === "O");

    // console.log(name, newValue)
    // have to update the function
    setCheckChanges(true);
    setCheckChangesInsuranceData(true);
    if (tab === "Insurance Company") {
      if (name === "insurance_company_detail_id") {
        if (maincompanylist.length > 0 && newValue != null) {
          const selectedcompany = maincompanylist.filter(
            (item) => item.company_id === newValue
          );
          setinsurancedata((prevFormData) => ({
            ...prevFormData,
            sub_company_detail_id: "",
            sub_company_short_code: "",
            insurance_network_id: "",
            insurance_package_id: "",
            insurance_plan_id: "",
            [name]: newValue,
            main_company_short_code: selectedcompany[0].short_code,
          }));
          removeSelectorToInsuranceValidation(
            "#insurance-validator1",
            ".Insurance"
          );
          // console.log(selectedcompany[0].short_code);

          dispatch({
            type: ALL_MASTER_ACTION_TYPES.SET_SUB_COMPANY_LIST,
            payload: [],
          });
          dispatch({
            type: ALL_MASTER_ACTION_TYPES.SET_NETWORK_LIST,
            payload: [],
          });
          dispatch({
            type: ALL_MASTER_ACTION_TYPES.SET_BENEFIT_LIST,
            payload: [],
          });
          dispatch({
            type: ALL_MASTER_ACTION_TYPES.SET_PLAN_NETWORK_LIST,
            payload: [],
          });
          dispatch({
            type: ALL_MASTER_ACTION_TYPES.SET_PLAN_SERVICE_CATEGORY_LIST,
            payload: [],
          });
          // setMultipleDepartmentListdata([]);
          setplandetaildata({});
          //Get Subcompany Data, Network Data, Benefit Package Data
          dispatch(
            getAPIAction.GetInsSubCompanyList(
              userId,
              logId,
              userToken,
              navigate,
              newValue
            )
          );
          dispatch(
            getAPIAction.GetNetworkInsCompanyWise(
              userId,
              logId,
              userToken,
              navigate,
              newValue
            )
          );
          dispatch(
            getAPIAction.GetBenefitPackageCompanyWise(
              userId,
              logId,
              userToken,
              navigate,
              newValue
            )
          );
          return;
        } else {
          setinsurancedata((prevFormData) => ({
            ...prevFormData,
            sub_company_detail_id: "",
            sub_company_short_code: "",
            insurance_network_id: "",
            insurance_package_id: "",
            insurance_plan_id: "",
            [name]: "",
            main_company_short_code: "",
          }));

          dispatch({
            type: ALL_MASTER_ACTION_TYPES.SET_SUB_COMPANY_LIST,
            payload: [],
          });
          dispatch({
            type: ALL_MASTER_ACTION_TYPES.SET_NETWORK_LIST,
            payload: [],
          });
          dispatch({
            type: ALL_MASTER_ACTION_TYPES.SET_BENEFIT_LIST,
            payload: [],
          });
          dispatch({
            type: ALL_MASTER_ACTION_TYPES.SET_PLAN_NETWORK_LIST,
            payload: [],
          });
          dispatch({
            type: ALL_MASTER_ACTION_TYPES.SET_PLAN_SERVICE_CATEGORY_LIST,
            payload: [],
          });
          // setMultipleDepartmentListdata([]);
          setplandetaildata({});
          return;
        }
      }
      if (name === "sub_company_detail_id") {
        if (subcompanylist.length > 0 && newValue != null) {
          const selectedsubcompany = subcompanylist.filter(
            (item) => item.company_id === newValue
          );
          setinsurancedata((prevdata) => ({
            ...prevdata,
            [name]: newValue,
            sub_company_short_code: selectedsubcompany[0].short_code,
          }));
        }
        return;
      }
      if (name === "insurance_network_id") {
        setplandetaildata({});
        dispatch({
          type: ALL_MASTER_ACTION_TYPES.SET_PLAN_NETWORK_LIST,
          payload: [],
        });
        dispatch({
          type: ALL_MASTER_ACTION_TYPES.SET_PLAN_SERVICE_CATEGORY_LIST,
          payload: [],
        });
        dispatch(
          getAPIAction.GetPlanNetworkWise(
            userId,
            logId,
            userToken,
            navigate,
            newValue
          )
        );

        removeSelectorToInsuranceValidation(
          "#insurance-validator2",
          ".Network"
        );

        setinsurancedata({
          ...insurancedata,
          [name]: newValue,
          insurance_plan_id: "",
        });

        return;
      }
      // console.log(insurancedata);
      if (name === "insurance_plan_id") {
        dispatch({
          type: ALL_MASTER_ACTION_TYPES.SET_PLAN_SERVICE_CATEGORY_LIST,
          payload: [],
        });
        setplandetaildata({});
        // setMultipleDepartmentListdata([]);

        let finalValue = newValue;

        if (newValue === null) finalValue = "";

        let t = {
          user_id: userId,
          hospital_detail_id: hospital_detail_id,
          insurance_company_detail_id:
            insurancedata.insurance_company_detail_id,
          insurance_network_id: insurancedata.insurance_network_id,
          insurance_plan_id: finalValue,
        };
        removeSelectorToInsuranceValidation("#insurance-validator2", ".Plan");

        setinsurancedata({ ...insurancedata, [name]: finalValue });

        if (finalValue !== "")
          dispatch(
            getAPIAction.getServiceCategoryListBySelectedPlanAPI(
              userId,
              logId,
              userToken,
              navigate,
              t
            )
          );

        return;
      }
      if (name === "card_number") {
        const sanitizedInput = newValue.replace(/[^a-zA-Z0-9]/g, "");
        if (sanitizedInput.length > 16) {
          return;
        }
        removeSelectorToInsuranceValidation("#insurance-validator2", ".Card");
        setinsurancedata({ ...insurancedata, [name]: sanitizedInput });
        setErrors((prevState) => ({ ...prevState, [name]: "" }));
        return;
      }
      if (name === "policy_holder") {
        const sanitizedInput = newValue.replace(/[^a-zA-Z0-9]/g, "");
        setinsurancedata({ ...insurancedata, [name]: sanitizedInput });
        setErrors((prevState) => ({ ...prevState, [name]: "" }));
        return;
      }
      if (
        name === "max_ceilling" ||
        name === "deduct_amount" ||
        name === "co_pay_amount"
      ) {
        const sanitizedInput = newValue.replace(/[^0-9]/g, "");
        setinsurancedata({ ...insurancedata, [name]: sanitizedInput });
        setErrors((prevState) => ({ ...prevState, [name]: "" }));
        return;
      }

      if (name === "co_pay_all_service") {
        if (
          newValue == "1" &&
          insurancedata.co_pay_amount !== "" &&
          insurancedata.co_pay_amount != 0
        ) {
          CopayOnOtherPlanDetailData();
          setinsurancedata({
            ...insurancedata,
            [name]: newValue,
            with_consultation: 0,
          });
          return;
        }
        setinsurancedata({ ...insurancedata, [name]: newValue });
        return;
      }

      if (name === "is_status") {
        setinsurancedata({ ...insurancedata, [name]: newValue });
        return;
      }

      if (name === "with_consultation") {
        if (
          newValue == "1" &&
          insurancedata.co_pay_amount !== "" &&
          insurancedata.co_pay_amount != 0
        ) {
          CopayOnWithConsultationPlanDetailData();
        }

        setinsurancedata({ ...insurancedata, [name]: newValue });
        return;
      }

      if (name === "expiry_date") {
        removeSelectorToInsuranceValidation("#insurance-validator2", ".Expiry");
      }

      if (
        name !== "company_id" &&
        name !== "insurance_company_detail_id" &&
        name !== "card_number" &&
        name !== "policy_holder" &&
        name !== "max_ceilling" &&
        name !== "deduct_amount" &&
        name !== "co_pay_amount" &&
        name !== "co_pay_all_service"
      ) {
        setinsurancedata({ ...insurancedata, [name]: value });
        setErrors((prevState) => ({ ...prevState, [name]: "" }));
      }
    } else if (tab === "") {
    }
  };

  const CopayOnOtherPlanDetailData = () => {
    const modify_category_list = _.cloneDeep(plandetaildata);
    if (Object.keys(modify_category_list).length === 0) {
      toast.error("Service Category is empty", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
      return;
    }

    setCoPayOpenModal(true);

    for (let i = 0; i < modify_category_list.sub_list.length; i++) {
      for (
        let j = 0;
        j < modify_category_list.sub_list[i].category_list.length;
        j++
      ) {
        if (modify_category_list.sub_list[i].category_list[j].active)
          modify_category_list.sub_list[i].category_list[j].co_pay_percentage =
            insurancedata.co_pay_amount;
      }
    }

    setplandetaildata(modify_category_list);
  };

  const CopayOnWithConsultationPlanDetailData = () => {
    const modify_category_list = _.cloneDeep(plandetaildata);
    if (Object.keys(modify_category_list).length === 0) {
      toast.error("Service Category is empty", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
      return;
    }

    for (let i = 0; i < modify_category_list.main_list.length; i++) {
      for (
        let j = 0;
        j < modify_category_list.main_list[i].category_list.length;
        j++
      ) {
        // condition to specify of specific consultant goes here
        modify_category_list.main_list[i].category_list[j].co_pay_percentage =
          insurancedata.co_pay_amount;
      }
    }

    setplandetaildata(modify_category_list);
  };

  // useEffect(()=> console.log(insurancedata), [insurancedata])

  useEffect(() => {
    // if(planservicecategorylist?.length > 0) {
    //     if(planservicecategorylist[0].hasOwnProperty("category_list") && planservicecategorylist?.[0]?.category_list?.length > 0){

    //         let categorylist = [];
    //         for(let i=0;i<planservicecategorylist[0].category_list.length;i++) {
    //             let t = {
    //                 department_id : planservicecategorylist[0].category_list[i].department_id,
    //                 department_name : planservicecategorylist[0].category_list[i].department_name,
    //                 co_pay_percentage : parseInt(planservicecategorylist[0].category_list[i].co_pay_percentage),
    //                 deductible_amount : parseInt(planservicecategorylist[0].category_list[i].dedcut_amount),
    //                 per_invoice : 0,
    //                 max_ceilling : 0,
    //             };
    //             categorylist.push(t);
    //         }

    //         setMultipleDepartmentListdata(categorylist);
    //     }
    //     else
    //         setMultipleDepartmentListdata([])
    // }

    // On first time of edit
    if (editExisiting) {
      if (
        planservicecategorylist === undefined ||
        planservicecategorylist === null ||
        planservicecategorylist === ""
      ) {
        setplandetaildata({});
      }
      if (
        getExistingInsuranceDetails.length > 0 &&
        Object.keys(planservicecategorylist).length > 0
      ) {
        let modify_category_list = _.cloneDeep(planservicecategorylist);

        if (
          modify_category_list?.hasOwnProperty("main_list") &&
          modify_category_list?.main_list?.length > 0
        ) {
          for (let i = 0; i < modify_category_list.main_list.length; i++) {
            for (
              let j = 0;
              j < modify_category_list.main_list[i].category_list.length;
              j++
            ) {
              for (let k = 0; k < getExistingInsuranceDetails.length; k++) {
                if (
                  modify_category_list.main_list[i].category_list[j]
                    .department_id ===
                  getExistingInsuranceDetails[k].department_id
                ) {
                  let t = {
                    department_id:
                      modify_category_list.main_list[i].category_list[j]
                        .department_id,
                    department_name:
                      modify_category_list.main_list[i].category_list[j]
                        .department_name,
                    co_pay_percentage: checkNullFormatter(
                      getExistingInsuranceDetails[k].co_pay_percentage,
                      "float"
                    ),
                    deductible_amount: checkNullFormatter(
                      getExistingInsuranceDetails[k].deductible_amount,
                      "float"
                    ),
                    per_invoice: checkNullFormatter(
                      getExistingInsuranceDetails[k].per_invoice,
                      "float"
                    ),
                    max_ceilling: checkNullFormatter(
                      getExistingInsuranceDetails[k].max_ceilling,
                      "float"
                    ),
                  };

                  modify_category_list.main_list[i].category_list[j] = t;
                }
              }
            }
          }
        }

        if (
          modify_category_list?.hasOwnProperty("sub_list") &&
          modify_category_list?.sub_list?.length > 0
        ) {
          for (let i = 0; i < modify_category_list.sub_list.length; i++) {
            for (
              let j = 0;
              j < modify_category_list.sub_list[i].category_list.length;
              j++
            ) {
              let isNotAvailable = true;

              for (let k = 0; k < getExistingInsuranceDetails.length; k++) {
                if (
                  modify_category_list.sub_list[i].category_list[j]
                    .department_id ===
                  getExistingInsuranceDetails[k].department_id
                ) {
                  let t = {
                    ...getExistingInsuranceDetails[k],
                    department_id:
                      modify_category_list.sub_list[i].category_list[j]
                        .department_id,
                    department_name:
                      modify_category_list.sub_list[i].category_list[j]
                        .department_name,
                    co_pay_percentage: checkNullFormatter(
                      getExistingInsuranceDetails[k].co_pay_percentage,
                      "float"
                    ),
                    deductible_amount: checkNullFormatter(
                      getExistingInsuranceDetails[k].deductible_amount,
                      "float"
                    ),
                    per_invoice: checkNullFormatter(
                      getExistingInsuranceDetails[k].per_invoice,
                      "float"
                    ),
                    max_ceilling: checkNullFormatter(
                      getExistingInsuranceDetails[k].max_ceilling,
                      "float"
                    ),
                    active: 1,
                  };

                  // console.log(checkNullFormatter(getExistingInsuranceDetails[k].per_invoice, "float"), isNaN(getExistingInsuranceDetails[k].per_invoice), getExistingInsuranceDetails[k].per_invoice === null, getExistingInsuranceDetails[k].per_invoice === undefined, getExistingInsuranceDetails[k].per_invoice)

                  isNotAvailable = false;

                  modify_category_list.sub_list[i].category_list[j] = t;
                }
              }

              if (isNotAvailable) {
                let t = {
                  department_id:
                    modify_category_list.sub_list[i].category_list[j]
                      .department_id,
                  department_name:
                    modify_category_list.sub_list[i].category_list[j]
                      .department_name,
                  co_pay_percentage: "",
                  deductible_amount: "",
                  per_invoice: "",
                  max_ceilling: "",
                  active: 0,
                };

                modify_category_list.sub_list[i].category_list[j] = t;
              }
            }
          }
        }

        setplandetaildata(modify_category_list);
        console.log(modify_category_list);
      }
    } else {
      if (
        planservicecategorylist === undefined ||
        planservicecategorylist === null ||
        planservicecategorylist === ""
      ) {
        setplandetaildata({});
      } else if (Object.keys(planservicecategorylist).length > 0) {
        let modify_category_list = _.cloneDeep(planservicecategorylist);

        if (
          modify_category_list?.hasOwnProperty("main_list") &&
          modify_category_list?.main_list?.length > 0
        ) {
          for (let i = 0; i < modify_category_list.main_list.length; i++) {
            let categorylist = [];

            for (
              let j = 0;
              j < modify_category_list.main_list[i].category_list.length;
              j++
            ) {
              let t = {
                department_id:
                  modify_category_list.main_list[i].category_list[j]
                    .department_id,
                department_name:
                  modify_category_list.main_list[i].category_list[j]
                    .department_name,
                co_pay_percentage: checkNullFormatter(
                  modify_category_list.main_list[i].category_list[j]
                    .co_pay_percentage,
                  "float"
                ),
                deductible_amount: checkNullFormatter(
                  modify_category_list.main_list[i].category_list[j]
                    .dedcut_amount,
                  "float"
                ),
                per_invoice: "",
                max_ceilling: "",
              };

              categorylist.push(t);
            }

            modify_category_list.main_list[i].category_list = [...categorylist];
          }
        }

        if (
          modify_category_list?.hasOwnProperty("sub_list") &&
          modify_category_list?.sub_list?.length > 0
        ) {
          for (let i = 0; i < modify_category_list.sub_list.length; i++) {
            let categorylist = [];

            for (
              let j = 0;
              j < modify_category_list.sub_list[i].category_list.length;
              j++
            ) {
              let t = {
                ...modify_category_list.sub_list[i].category_list[j],
                department_id:
                  modify_category_list.sub_list[i].category_list[j]
                    .department_id,
                department_name:
                  modify_category_list.sub_list[i].category_list[j]
                    .department_name,
                co_pay_percentage: "",
                deductible_amount: "",
                per_invoice: "",
                max_ceilling: "",
                active: 1,
              };

              if (
                modify_category_list.sub_list[i].category_list[
                  j
                ].hasOwnProperty("id")
              ) {
                t = {
                  ...modify_category_list.sub_list[i].category_list[j],
                  ...t,
                };
              }

              categorylist.push(t);
            }

            modify_category_list.sub_list[i].category_list = [...categorylist];
          }
        }

        setplandetaildata(modify_category_list);
        console.log(modify_category_list);
      }
    }
  }, [planservicecategorylist]);

  const handleOnlineReferralChange = (e) => {
    const { name, value } = e.target;
    const newValue = value;

    setonlinereferraldata((prevValue) => ({ ...prevValue, [name]: newValue }));

    // have to write function to searh online referral data
  };

  const emailValidator = (email) => {
    const Regexp = new RegExp(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
    );
    return Regexp.test(email);
  };

  //Get Center name API call
  const getClientListAPI = async () => {
    await getData
      .getClientList(userToken)
      .then((resp) => {
        setClientList(resp.data.data);
      })
      .catch((error) => {
        if (error.response.data.status === 2) {
          toast.error(
            error.response.data.message + ". Please log in again to continue.",
            {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 1000,
            }
          );
          setTimeout(() => {
            dispatch(logoutFunction(userId, logId, userToken, navigate));
          }, 2000);
        } else {
          toast.error(
            error.response.data.message + " Please Try After Sometimes" ||
              "Too Many Attempts. Please Try after sometimes",
            {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 1000,
            }
          );
        }
      });
  };

  const validateGuardianForm = () => {
    const validationErrors = {};
    if (!guardiandata.relationship_id) {
      validationErrors.relationship_id = "Choose Relationship";
    }
    if (!guardiandata.firstname) {
      validationErrors.firstname = "FirstName is required";
    }
    if (!guardiandata.lastname) {
      validationErrors.lastname = "LastName is required";
    }
    // if (!guardiandata.mr_number) {
    //     validationErrors.mr_number = 'M.R.No is required';
    // }
    // if (!guardiandata.email) {
    //     validationErrors.email = 'Email is required';
    // }
    if (!emailValidator(guardiandata.email) && guardiandata.email !== "") {
      validationErrors.email = "Email is invalid";
    }
    if (!guardiandata.country_id) {
      validationErrors.country_id = "Country is required";
    }
    if (!guardiandata.region_id) {
      validationErrors.region_id = "State is required";
    }
    if (!guardiandata.city_id) {
      validationErrors.city_id = "City is required";
    }
    if (
      !matchIsValidTel(guardiandata.mobile_no, {
        onlyCountryies: [],
        excludedCountryies: [],
        continents: [],
      })
    ) {
      validationErrors.mobile_no = "Enter valid mobile number";
    }
    return validationErrors;
  };

  const addGuardianArray = (e) => {
    e.preventDefault();
    const validationErrors = validateGuardianForm();
    if (Object.keys(validationErrors).length === 0) {
      if (editMode) {
        if (guardianIndex == -1) return;
        let modify_guardiandata = Object.assign({}, guardiandata);

        let relation = relationshipList.filter(
          (item) => item.id == modify_guardiandata.relationship_id
        );
        modify_guardiandata["relationship_name"] = relation[0].name;
        modify_guardiandata["fullname"] =
          modify_guardiandata.firstname +
          " " +
          modify_guardiandata.middlename +
          " " +
          modify_guardiandata.lastname;

        let newMultipleGuardianData = [...multipleGuardiandata];
        newMultipleGuardianData[guardianIndex] = modify_guardiandata;
        setMultipleGuardiandata(newMultipleGuardianData);

        setEditMode(false);
        setOpenModal(false);
        setGuardianIndex(-1);
        setguardiandata(initialGuardian);
        toast.success("Guardian Updated Successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      } else {
        let modify_guardiandata = Object.assign({}, guardiandata);

        let relation = relationshipList.filter(
          (item) => item.id == modify_guardiandata.relationship_id
        );
        modify_guardiandata["relationship_name"] = relation[0].name;
        modify_guardiandata["fullname"] =
          modify_guardiandata.firstname +
          " " +
          modify_guardiandata.middlename +
          " " +
          modify_guardiandata.lastname;
        setMultipleGuardiandata([...multipleGuardiandata, modify_guardiandata]);

        setOpenModal(false);
        setguardiandata(initialGuardian);
        toast.success("Guardian Added Successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      }
    } else {
      setErrors(validationErrors);
    }
  };

  const handleOpenGuardianEditModal = (rowdata, index) => {
    setRegionLoader(true);
    setCityLoader(true);

    dispatch({ type: ALL_MASTER_ACTION_TYPES.SET_REGION_LIST2, payload: [] });
    //Get State/Region Data
    dispatch(
      getAPIAction.getRegionListAPI2(
        userId,
        logId,
        userToken,
        navigate,
        rowdata.country_id
      )
    );

    dispatch({ type: ALL_MASTER_ACTION_TYPES.SET_CITY_LIST2, payload: [] });
    dispatch(
      getAPIAction.getCityListAPI2(
        userId,
        logId,
        userToken,
        navigate,
        rowdata.country_id,
        rowdata.region_id
      )
    );

    let editguardiandata = {
      relationship_id: rowdata.relationship_id,
      firstname: rowdata.firstname,
      middlename: rowdata.middlename,
      lastname: rowdata.lastname,
      mr_number: checkNull(rowdata.mr_number),
      mobile_no: rowdata.mobile_no,
      email: checkNull(rowdata.email),
      address: checkNull(rowdata.address),
      country_id: rowdata.country_id,
      region_id: rowdata.region_id,
      city_id: rowdata.city_id,
    };
    setguardiandata(editguardiandata);
    setGuardianIndex(index);
    setEditMode(true);
    handleAddPopUp();
  };

  const handleOpenGuardianDeleteData = () => {
    const index = deleteGuardianIndex;
    if (index == -1) {
      return;
    }
    setCheckChanges(true);
    let updateGuardianData = Object.assign([], multipleGuardiandata);
    updateGuardianData.splice(index, 1);

    setMultipleGuardiandata(updateGuardianData);
    handleCloseGuardianDeleteModal();
  };

  const handleOpenGuardianDeleteModal = async (rowdata, index) => {
    setDeleteGuardianIndex(index);
    setShowGuardianDeleteModal(true);
  };

  const handleCloseGuardianDeleteModal = () => {
    setDeleteGuardianIndex(-1);
    setShowGuardianDeleteModal(false);
  };
  useEffect(() => {
    if (regionList2 && regionList2.length !== 0) {
      setRegionLoader(false);
    }
  }, [regionList2]);

  useEffect(() => {
    if (regionList3 && regionList3.length !== 0) {
      setRegionLoader(false);
    }
  }, [regionList3]);

  useEffect(() => {
    if (cityList2 && cityList2.length !== 0) {
      setCityLoader(false);
    }
  }, [cityList2]);

  useEffect(() => {
    if (cityList3 && cityList3.length !== 0) {
      setCityLoader(false);
    }
  }, [cityList3]);

  const validateKinForm = () => {
    const validationErrors = {};
    if (!kindata.relationship_id) {
      validationErrors.relationship_id = "Choose Responsible person";
    }
    if (!kindata.firstname) {
      validationErrors.firstname = "FirstName is required";
    }
    if (!kindata.lastname) {
      validationErrors.lastname = "LastName is required";
    }
    // if (!kindata.mr_number) {
    //     validationErrors.mr_number = 'M.R.No is required';
    // }
    // if (!kindata.email) {
    //     validationErrors.email = 'Email is required';
    // }
    if (!emailValidator(kindata.email) && kindata.email !== "") {
      validationErrors.email = "Email is invalid";
    }
    if (!kindata.country_id) {
      validationErrors.country_id = "Country is required";
    }
    if (!kindata.region_id) {
      validationErrors.region_id = "State is required";
    }
    if (!kindata.city_id) {
      validationErrors.city_id = "City is required";
    }
    if (
      !matchIsValidTel(kindata.mobile_no, {
        onlyCountryies: [],
        excludedCountryies: [],
        continents: [],
      })
    ) {
      validationErrors.mobile_no = "Enter valid mobile number";
    }
    // if (!kindata.mobile_no) {
    //     validationErrors.mobile_no = 'Mobile is required';
    // }
    // if (!kindata.email) {
    //     validationErrors.email = 'email is required';
    // }
    return validationErrors;
  };

  const addKinArray = (e) => {
    e.preventDefault();
    const validationErrors = validateKinForm();
    if (Object.keys(validationErrors).length === 0) {
      if (editMode) {
        if (kinIndex == -1) return;

        let modify_kindata = Object.assign({}, kindata);

        let relation = relationshipList.filter(
          (item) => item.id == modify_kindata.relationship_id
        );
        modify_kindata["relationship_name"] = relation[0].name;
        modify_kindata["fullname"] =
          modify_kindata.firstname +
          " " +
          modify_kindata.middlename +
          " " +
          modify_kindata.lastname;

        let newMultipleKinData = [...multipleKindata];
        newMultipleKinData[kinIndex] = modify_kindata;
        setMultipleKindata(newMultipleKinData);

        setEditMode(false);
        setOpenModal(false);
        setKinIndex(-1);
        setKindata(initialKin);
        toast.success("KIN Updated Successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      } else {
        let modify_kindata = Object.assign({}, kindata);

        let relation = relationshipList.filter(
          (item) => item.id == modify_kindata.relationship_id
        );
        modify_kindata["relationship_name"] = relation[0].name;
        modify_kindata["fullname"] =
          modify_kindata.firstname +
          " " +
          modify_kindata.middlename +
          " " +
          modify_kindata.lastname;

        setMultipleKindata([...multipleKindata, modify_kindata]);

        setOpenModal(false);
        setKindata(initialKin);
        toast.success("KIN Added Successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      }
    } else {
      setErrors(validationErrors);
    }
  };

  const handleOpenKinEditModal = (rowdata, index) => {
    setRegionLoader(true);
    setCityLoader(true);

    dispatch({ type: ALL_MASTER_ACTION_TYPES.SET_REGION_LIST3, payload: [] });
    //Get State/Region Data
    dispatch(
      getAPIAction.getRegionListAPI3(
        userId,
        logId,
        userToken,
        navigate,
        rowdata.country_id
      )
    );

    dispatch({ type: ALL_MASTER_ACTION_TYPES.SET_CITY_LIST3, payload: [] });
    dispatch(
      getAPIAction.getCityListAPI3(
        userId,
        logId,
        userToken,
        navigate,
        rowdata.country_id,
        rowdata.region_id
      )
    );

    let editkindata = {
      relationship_id: rowdata.relationship_id,
      firstname: rowdata.firstname,
      middlename: rowdata.middlename,
      lastname: rowdata.lastname,
      mr_number: checkNull(rowdata.mr_number),
      mobile_no: rowdata.mobile_no,
      email: checkNull(rowdata.email),
      address: checkNull(rowdata.address),
      country_id: rowdata.country_id,
      region_id: rowdata.region_id,
      city_id: rowdata.city_id,
    };
    setKindata(editkindata);
    setKinIndex(index);
    setEditMode(true);
    handleAddPopUp();
  };

  const handleRemoveKinDeleteData = () => {
    const index = deleteKinIndex;
    if (index == -1) {
      return;
    }
    setCheckChanges(true);
    let updateKinData = Object.assign([], multipleKindata);
    updateKinData.splice(index, 1);

    setMultipleKindata(updateKinData);
    handleCloseKinDeleteModal();
  };

  const handleOpenKinDeleteModal = async (rowdata, index) => {
    setDeleteKinIndex(index);
    setShowKinDeleteModal(true);
  };

  const handleCloseKinDeleteModal = () => {
    setDeleteKinIndex(-1);
    setShowKinDeleteModal(false);
  };
  const checkNull = (value) => {
    return value === null || value === undefined ? "" : value;
  };

  const checkNullFormatter = (value, type) => {
    if (type === "int")
      return value === null ||
        value === undefined ||
        isNaN(value) ||
        value === ""
        ? ""
        : parseInt(value);
    else if (type === "float")
      return value === null ||
        value === undefined ||
        isNaN(value) ||
        value === ""
        ? ""
        : parseFloat(value);
  };

  const GetSinglePatient = async () => {
    setShowFieldLoad(true);

    await getData
      .GetSinglePatient(userId, patient_id1, userToken)
      .then((resp) => {
        if (resp.data && resp.data.status === 0) {
          toast.error(resp.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });
        } else {
          var detailsData = resp.data.data.patient[0];
          var insuranceDetailsData = resp.data.data.insurance;

          // var dob = "";
          // var deceased = "";
          // if (detailsData.date_of_birth !== "") {
          //     var date = detailsData.date_of_birth.split("-");
          //     dob = new Date(date[1] + "-" + date[0] + "-" + date[2]);
          // } else if (detailsData.deceased_date !== "") {
          //     var date1 = detailsData.deceased_date.split("-");
          //     deceased = new Date(date1[1] + "-" + date1[0] + "-" + date1[2]);
          // }
          setImagePath(resp.data.data.image_path);
          setDetailsData1(resp.data.data.patient[0]);
          seteditpreviewProfileImg(detailsData.profile_image);
          setFormData({
            ...detailsData,
            user_id: userId,
            register_no: detailsData.id,
            loggin_user_id: userId,
            patient_id: patient_id1,
            register_datetime: moment(
              `${detailsData.register_date} ${detailsData.register_time}`,
              "DD-MM-YYYY h:mm A"
            ).format("YYYY-MM-DD HH:mm:ss"),
            language_id:
              detailsData.language_id !== null
                ? detailsData.language_id.split(",").map(Number)
                : [],
            date_of_birth:
              detailsData.date_of_birth !== null
                ? moment(detailsData.date_of_birth, "DD-MM-YYYY").format(
                    "YYYY-MM-DD"
                  )
                : null,
            patient_age: calculateAge(
              detailsData.date_of_birth !== null
                ? moment(detailsData.date_of_birth, "DD-MM-YYYY").format(
                    "YYYY-MM-DD"
                  )
                : null
            ),
            deceased_date:
              detailsData.deceased_date !== null
                ? moment(detailsData.deceased_date, "DD-MM-YYYY").format(
                    "YYYY-MM-DD"
                  )
                : null,
            profile_image: "",
            user_created: detailsData.user_created,
          });
          if (detailsData.emirate.length > 0) {
            // var exp = "";
            // if (detailsData.emirate[0].expiry_date !== "") {
            //     var date2 = detailsData.emirate[0].expiry_date.split("-");
            //     exp = new Date(date2[1] + "-" + date2[0] + "-" + date2[2]);
            // }

            setemiratedata({
              ...emiratedata,
              emirate_ids: patternMaker(detailsData.emirate[0].emirate_ids),
              expiry_date:
                detailsData.emirate[0].expiry_date !== null
                  ? moment(
                      detailsData.emirate[0].expiry_date,
                      "DD-MM-YYYY"
                    ).format("YYYY-MM-DD")
                  : "",
              front_page: detailsData.emirate[0].emirate_ids_front,
              back_page: detailsData.emirate[0].emirate_ids_back,
            });
          }
          if (detailsData.others.length > 0) {
            setothersdata({
              ...othersdata,
              unified_no: checkNull(detailsData.others[0].unified_no),
              mothers_eid: patternMaker(detailsData.others[0].mothers_eid),
              multiple_birth: detailsData.others[0].multiple_birth,
              birth_order: checkNull(detailsData.others[0].birth_order),
              birth_place: checkNull(detailsData.others[0].birth_place),
            });
          }
          if (detailsData.sub_address.length > 0) {
            setaddressdata({
              ...addressdata,
              address: checkNull(detailsData.sub_address[0].address),
              country_id: detailsData.sub_address[0].country_id,
              region_id: detailsData.sub_address[0].region_id,
              city_id: detailsData.sub_address[0].city_id,
              email: checkNull(detailsData.sub_address[0].email),
              no_email_available:
                detailsData.sub_address[0].email !== null ? 0 : 1,
              post_box_no: checkNull(detailsData.sub_address[0].post_box_no),
              whatsup_number: detailsData.sub_address[0].whatsup_number,
              home_telephone: checkNull(
                detailsData.sub_address[0].home_telephone
              ),
              work_telephone: checkNull(
                detailsData.sub_address[0].work_telephone
              ),
              primary_contact_code:
                detailsData.sub_address[0].primary_contact_code,
              secondary_contact_code:
                detailsData.sub_address[0].secondary_contact_code,
              // primary_contact: detailsData.sub_address[0].primary_contact,
              // secondary_contact: detailsData.sub_address[0].secondary_contact,
              primary_contact:
                "+" +
                detailsData.sub_address[0].primary_contact_code +
                detailsData.sub_address[0].primary_contact,
              secondary_contact:
                "+" +
                detailsData.sub_address[0].secondary_contact_code +
                detailsData.sub_address[0].secondary_contact,
            });

            dispatch(
              getAPIAction.getRegionListAPI(
                userId,
                logId,
                userToken,
                navigate,
                detailsData.sub_address[0].country_id
              )
            );
            dispatch(
              getAPIAction.getCityListAPI(
                userId,
                logId,
                userToken,
                navigate,
                detailsData.sub_address[0].country_id,
                detailsData.sub_address[0].region_id
              )
            );
          }

          if (detailsData.referral.length > 0) {
            setreferraldata({
              ...referraldata,
              referral_source_id: detailsData.referral[0].referral_source_id,
              referral_channel_id: detailsData.referral[0].referral_channel_id,
              doctor_name: detailsData.referral[0].doctor_name,
              clinic_name: detailsData.referral[0].clinic_name,
              license_no: detailsData.referral[0].license_no,
            });
          }

          if (detailsData.guardian.length > 0) {
            const newData = detailsData.guardian.map((item) => ({
              relationship_id: item.relationship_id,
              relationship_name: item.relationship_name,
              firstname: item.firstname,
              middlename: checkNull(item.middlename),
              lastname: item.lastname,
              fullname:
                item.firstname +
                " " +
                checkNull(item.middlename) +
                " " +
                item.lastname,
              mr_number: checkNull(item.mr_number),
              mobile_no: item.mobile_no,
              email: checkNull(item.email),
              address: checkNull(item.address),
              country_id: item.country_id,
              region_id: item.region_id,
              city_id: item.city_id,
              post_box_no: item.post_box_no,
              landline_number: item.landline_number,
              office_phone: item.office_phone,
            }));
            setMultipleGuardiandata(newData);
          }

          if (detailsData.patientkin.length > 0) {
            const newData = detailsData.patientkin.map((item) => ({
              relationship_id: item.relationship_id,
              relationship_name: item.relationship_name,
              firstname: item.firstname,
              middlename: checkNull(item.middlename),
              lastname: item.lastname,
              fullname:
                item.firstname +
                " " +
                checkNull(item.middlename) +
                " " +
                item.lastname,
              mr_number: checkNull(item.mr_number),
              mobile_no: item.mobile_no,
              email: checkNull(item.email),
              address: checkNull(item.address),
              country_id: item.country_id,
              region_id: item.region_id,
              city_id: item.city_id,
              post_box_no: item.post_box_no,
              landline_number: item.landline_number,
              office_phone: item.office_phone,
            }));
            setMultipleKindata(newData);
          }

          if (insuranceDetailsData.length === 0) {
            setEditExising(false);
          }

          console.log("insuranceDetailsData", insuranceDetailsData);

          if (insuranceDetailsData.length > 0) {
            setinsurancedata({
              ...insuranceDetailsData[0],
              insurance_company_detail_id:
                insuranceDetailsData[0].insurance_company_detail_id,
              main_company_short_code:
                insuranceDetailsData[0].main_company_short_code,
              sub_company_detail_id:
                insuranceDetailsData[0].sub_company_detail_id,
              sub_company_short_code:
                insuranceDetailsData[0].sub_company_short_code,
              policy_holder: insuranceDetailsData[0].policy_holder,
              insurance_package_id:
                insuranceDetailsData[0].insurance_package_id,
              insurance_network_id:
                insuranceDetailsData[0].insurance_network_id,
              insurance_plan_id: insuranceDetailsData[0].insurance_plan_id,
              card_number: insuranceDetailsData[0].card_number,
              expiry_date: moment(
                insuranceDetailsData[0].expiry_date,
                "DD-MM-YYYY"
              ).format("YYYY-MM-DD"),
              max_ceilling: checkNullFormatter(
                insuranceDetailsData[0].max_ceilling,
                "int"
              ),
              deduct_amount: checkNullFormatter(
                insuranceDetailsData[0].deduct_amount,
                "int"
              ),
              co_pay_amount: checkNullFormatter(
                insuranceDetailsData[0].co_pay_amount,
                "int"
              ),
              co_pay_all_service: insuranceDetailsData[0].co_pay_all_service,
              categories: [],
              is_status: insuranceDetailsData[0].is_status,
            });

            let allinsurancedata = [];
            for (let i = 0; i < insuranceDetailsData.length; i++) {
              let insurancedetaillist = [];
              for (
                let j = 0;
                j < insuranceDetailsData[i].insurance_detail.length;
                j++
              ) {
                let t = {
                  ...insuranceDetailsData[i].insurance_detail[j],
                  department_id:
                    insuranceDetailsData[i].insurance_detail[j].department_id,
                  department_name:
                    insuranceDetailsData[i].insurance_detail[j].department_name,
                  co_pay_percentage: checkNullFormatter(
                    insuranceDetailsData[i].insurance_detail[j]
                      .co_pay_percentage,
                    "float"
                  ),
                  deductible_amount: checkNullFormatter(
                    insuranceDetailsData[i].insurance_detail[j]
                      .deductible_amount,
                    "float"
                  ),
                  per_invoice: checkNullFormatter(
                    insuranceDetailsData[i].insurance_detail[j].per_invoice,
                    "float"
                  ),
                  max_ceilling: checkNullFormatter(
                    insuranceDetailsData[i].insurance_detail[j].max_ceilling,
                    "float"
                  ),
                };
                insurancedetaillist.push(t);
              }

              let singleinsurance = {
                ...insuranceDetailsData[i],
                patient_insurance_id: insuranceDetailsData[i].id,
                insurance_company_detail_id:
                  insuranceDetailsData[i].insurance_company_detail_id,
                main_company_short_code:
                  insuranceDetailsData[i].main_company_short_code,
                sub_company_detail_id:
                  insuranceDetailsData[i].sub_company_detail_id,
                sub_company_short_code:
                  insuranceDetailsData[i].sub_company_short_code,
                policy_holder: insuranceDetailsData[i].policy_holder,
                insurance_package_id:
                  insuranceDetailsData[i].insurance_package_id,
                insurance_network_id:
                  insuranceDetailsData[i].insurance_network_id,
                insurance_plan_id: insuranceDetailsData[i].insurance_plan_id,
                card_number: insuranceDetailsData[i].card_number,
                expiry_date: moment(
                  insuranceDetailsData[i].expiry_date,
                  "DD-MM-YYYY"
                ).format("YYYY-MM-DD"),
                max_ceilling: checkNullFormatter(
                  insuranceDetailsData[i].max_ceilling,
                  "int"
                ),
                deduct_amount: checkNullFormatter(
                  insuranceDetailsData[i].deduct_amount,
                  "int"
                ),
                co_pay_amount: checkNullFormatter(
                  insuranceDetailsData[i].co_pay_amount,
                  "int"
                ),
                co_pay_all_service: insuranceDetailsData[i].co_pay_all_service,
                // categories: [],
                is_status: insuranceDetailsData[i].is_status,
                insurance_detail: insurancedetaillist,
              };

              allinsurancedata.push(singleinsurance);
            }
            setActiveInsuranceId(insuranceDetailsData[0].id);

            // view list insurance table data
            setmultipleinsurancedata(allinsurancedata);

            if (allinsurancedata.length > 1) {
              SetViewInsurance(false);
            }
            setEditExising(true);
            setGetExistingInsuranceDetails(
              allinsurancedata[0].insurance_detail
            );
            setEnableEditModal(true);

            let t = {
              user_id: userId,
              hospital_detail_id: hospital_detail_id,
              insurance_company_detail_id:
                insuranceDetailsData[0].insurance_company_detail_id,
              insurance_network_id:
                insuranceDetailsData[0].insurance_network_id,
              insurance_plan_id: insuranceDetailsData[0].insurance_plan_id,
            };
            dispatch(
              getAPIAction.getServiceCategoryListBySelectedPlanAPI(
                userId,
                logId,
                userToken,
                navigate,
                t
              )
            );

            dispatch(
              getAPIAction.GetInsSubCompanyList(
                userId,
                logId,
                userToken,
                navigate,
                insuranceDetailsData[0].insurance_company_detail_id
              )
            );
            dispatch(
              getAPIAction.GetBenefitPackageCompanyWise(
                userId,
                logId,
                userToken,
                navigate,
                insuranceDetailsData[0].insurance_company_detail_id
              )
            );
            dispatch(
              getAPIAction.GetNetworkInsCompanyWise(
                userId,
                logId,
                userToken,
                navigate,
                insuranceDetailsData[0].insurance_company_detail_id
              )
            );
            dispatch(
              getAPIAction.GetPlanNetworkWise(
                userId,
                logId,
                userToken,
                navigate,
                insuranceDetailsData[0].insurance_network_id
              )
            );
          }

          // if(detailsData.insurance_detail.length > 0) {
          //     let categorylist = [];
          //     for(let i=0;i<detailsData.insurance_detail.length;i++) {
          //         let t = {
          //             department_id : detailsData.insurance_detail[i].department_id,
          //             department_name : detailsData.insurance_detail[i].department_name,
          //             co_pay_percentage : detailsData.insurance_detail[i].co_pay_percentage,
          //             deductible_amount : detailsData.insurance_detail[i].deductible_amount,
          //             per_invoice : detailsData.insurance_detail[i].per_invoice,
          //             max_ceilling : detailsData.insurance_detail[i].max_ceilling,
          //         };
          //         categorylist.push(t);
          //     }

          //     setGetExistingInsuranceDetails(categorylist);
          //     setMultipleDepartmentListdata(categorylist);
          // }

          // // console.log(resp.data.data.users[0])
          // setLoading(false)
          // setViewModal(true);
        }

        setShowFieldLoad(false);
      })
      .catch((error) => {
        //   console.log(error);
        //   return false;
        if (error.response.data.status === 2) {
          toast.error(
            error.response.data.message + ". Please log in again to continue.",
            {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 1000,
            }
          );
          setTimeout(() => {
            dispatch(logoutFunction(userId, logId, userToken, navigate));
          }, 2000);
        } else {
          toast.error(
            error.response.data.message + " Please Try After Sometimes" ||
              "Too Many Attempts. Please Try after sometimes",
            {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 1000,
            }
          );
        }
      });
  };

  useEffect(() => {
    // console.log("hai", multipleinsurancedata)
  }, [multipleinsurancedata]);
  useEffect(() => {
    // console.log("Insurance : ", insurancedata)
  }, [insurancedata]);

  const validateForm = () => {
    const validationErrors = {};

    let validationLabels = [];
    let constraintLabels = [];

    if (!formData.patient_class_id) {
      validationErrors.patient_class_id = "Patient Class is Required";
      validationLabels.push("Patient class");
    }
    if (!formData.hospital_detail_id) {
      validationErrors.hospital_detail_id = "Location is required";
      validationLabels.push("Location");
    }
    if (!formData.member_type) {
      validationErrors.member_type = "Member Type is required";
      validationLabels.push("Member Type");
    }
    if (!formData.mr_number) {
      validationErrors.mr_number = "M.R.No is required";
      validationLabels.push("M.R.No");
    }
    // if (!formData.address) {
    //     validationErrors.address = 'Address is required';
    // }
    if (!formData.title_id) {
      validationErrors.title_id = "Title is required";
      validationLabels.push("Title");
    }
    if (!formData.gender_id) {
      validationErrors.gender_id = "Gender is required";
      validationLabels.push("Gender");
    }

    // if (!formData.country_id) {
    //     validationErrors.country_id = 'Country is required';
    // }
    // if (!formData.city_id) {
    //     validationErrors.city_id = 'City is required';
    // }
    // if (!formData.region_id) {
    //     validationErrors.region_id = 'Region is required';
    // }
    // if (!formData.father_name) {
    //     validationErrors.father_name = 'father_name name is required';
    // }
    if (!addressdata.country_id) {
      validationErrors.country_id = "Country is required";
      validationLabels.push("Country");
    }
    if (!addressdata.city_id) {
      validationErrors.city_id = "City is required";
      validationLabels.push("City");
    }
    if (!addressdata.region_id) {
      validationErrors.region_id = "Region is required";
      validationLabels.push("Region");
    }
    if (!formData.medical_tourism) {
      validationErrors.medical_tourism = "Medical Tourism is required";
      validationLabels.push("Medical Tourism");
    }
    if (formData.medical_tourism && formData.medical_tourism !== "Resident") {
      if (!formData.visitor_type) {
        validationErrors.visitor_type = "Visitor Type is required";
        validationLabels.push("Visitor Type");
      }
    }
    if (!formData.first_name) {
      validationErrors.first_name = "First name is required";
      validationLabels.push("First name");
    }
    if (!formData.last_name) {
      validationErrors.last_name = "Last name is required";
      validationLabels.push("Last name");
    }
    if (!formData.marital_status_id) {
      validationErrors.marital_status_id = "Marital Status is required";
      validationLabels.push("Marital Status");
    }
    // if (!formData.primary_email) {
    //     validationErrors.primary_email = 'Email is required';
    // }
    // if (formData.primary_mobile === "") {
    //     validationErrors.primary_mobile = 'Mobile number is required';
    // }
    // let checkPrimary = false; to make atleast one of the contact is mandatory instead of both
    if (
      !matchIsValidTel(addressdata.primary_contact, {
        onlyCountryies: [],
        excludedCountryies: [],
        continents: [],
      })
    ) {
      // checkPrimary = true;
      validationErrors.primary_contact = "Primary Mobile number is invalid";
      constraintLabels.push("Primary Mobile Number");
    }
    // if (!formData.arabic_name) {
    //     validationErrors.arabic_name = 'Arabic Full Name is required';
    // }
    if (!formData.nationality_id) {
      validationErrors.nationality_id = "Nationality is required";
      validationLabels.push("Nationality");
    }
    if (!addressdata.whatsup_number) {
      validationErrors.whatsup_number = "Whatsapp Number is required";
      validationLabels.push("Whatsapp Number");
    }
    // if (formData.secondary_mobile === "") {
    //     validationErrors.secondary_mobile = 'Secondary Phone number is required';
    // }
    if (
      !matchIsValidTel(addressdata.secondary_contact, {
        onlyCountryies: [],
        excludedCountryies: [],
        continents: [],
      })
      //  && !checkPrimary
    ) {
      validationErrors.secondary_contact = "Secondary Mobile Number is invalid";
      constraintLabels.push("Secondary Mobile Number");
    }
    if (!formData.date_of_birth) {
      validationErrors.date_of_birth = "Date Of Birth is required";
      validationLabels.push("Date Of Birth");
    }
    // if (!formData.blood_group_id) {
    //     validationErrors.blood_group_id = 'blood Group is required';
    // }
    if (!formData.occupation_id) {
      validationErrors.occupation_id = "Occupation is required";
      validationLabels.push("Occupation");
    }
    if (!formData.education_id) {
      validationErrors.education_id = "Education is required";
      validationLabels.push("Education");
    }
    if (!formData.religion_id) {
      validationErrors.religion_id = "Religion is required";
      validationLabels.push("Religion");
    }
    if (formData.language_id.length === 0) {
      validationErrors.language_id = "Language is required";
      validationLabels.push("Language");
    }
    if (emiratedata.emirate_ids === "___-____-_______-_") {
      validationErrors.emirate_ids = "Emirates Id is required";
      validationLabels.push("Emirates Id");
    } else if (emiratedata.emirate_ids !== "___-____-_______-_") {
      if (/_/.test(emiratedata.emirate_ids)) {
        validationErrors.emirate_ids = "Fill the emirated Id properly";
        constraintLabels.push("Emirates ID");
      }
    }
    if (othersdata.mothers_eid !== "___-____-_______-_") {
      if (/_/.test(othersdata.mothers_eid)) {
        validationErrors.mothers_eid = "Fill the mothers emirated Id properly";
        constraintLabels.push("Mother's Emirates ID");
      }
    }

    // if(validationLabels.length !== 0 && constraintLabels.length !== 0) {
    //     toaster(validationLabels.join(', ').concat(" is missing! Please check " +  constraintLabels.join(",")));
    // }
    if (validationLabels.length !== 0) {
      toaster(validationLabels.join(", ").concat(" is missing! "));
    } else if (constraintLabels.length !== 0) {
      toaster("Please check " + constraintLabels.join(","));
    }

    const allValidationErrors = {
      ...validationErrors,
      ...insuranceValidationForm(),
    };

    return allValidationErrors;
  };

  const insuranceValidationForm = () => {
    const validationErrors = {};
    let validationLabels = [];
    let constraintLabels = [];

    if (formData.payment_mode_id == 2) {
      if (!insurancedata.insurance_company_detail_id) {
        validationErrors.insurance_company_detail_id =
          "Insurance Company is required";
        validationLabels.push("Insurance Company");
        addSelectorToInsuranceValidation("#insurance-validator1", ".Insurance");
        // toaster(validationErrors.insurance_company_detail_id)
      }
      // if(!insurancedata.insurance_package_id) {
      //     validationErrors.insurance_package_id = 'Benefit Package is required';
      //     validationLabels.push("Benefit Package");
      //     // toaster(validationErrors.insurance_package_id)
      // }
      if (!insurancedata.insurance_network_id) {
        validationErrors.insurance_network_id = "Network is required";
        validationLabels.push("Network");
        addSelectorToInsuranceValidation("#insurance-validator2", ".Network");
        // toaster(validationErrors.insurance_network_id)
      }
      if (!insurancedata.insurance_plan_id) {
        validationErrors.insurance_plan_id = "Plan is required";
        validationLabels.push("Plan Type");
        addSelectorToInsuranceValidation("#insurance-validator2", ".Plan");
        // toaster(validationErrors.insurance_plan_id)
      }
      if (!insurancedata.card_number) {
        validationErrors.card_number = "Card Number is required";
        validationLabels.push("Card Number");
        addSelectorToInsuranceValidation("#insurance-validator2", ".Card");
        // toaster(validationErrors.card_number)
      }
      // if(!insurancedata.policy_holder) {
      //     validationErrors.policy_holder = 'Policy Holder is required';
      //     validationLabels.push("Policy Holder / Insurer");
      //     // toaster(validationErrors.policy_holder)
      // }
      // if(!insurancedata.max_ceilling) {
      //     validationErrors.max_ceilling = 'Max Ceiling is required';
      //     validationLabels.push("Max Ceiling");
      //     // toaster(validationErrors.max_ceilling)
      // }
      // if(!insurancedata.deduct_amount) {
      //     validationErrors.deduct_amount = 'Deduct Amout is required';
      //     validationLabels.push("Deduct Amout");
      //     // toaster(validationErrors.max_ceilling)
      // }
      // if(!insurancedata.co_pay_amount) {
      //     validationErrors.co_pay_amount = 'Co Pay Amount is required';
      //     validationLabels.push("Co Pay Amount");
      //     // toaster(validationErrors.max_ceilling)
      // }
      if (!insurancedata.expiry_date) {
        validationErrors.expiry_date = "Expiry Date is required";
        validationLabels.push("Expiry Date");
        addSelectorToInsuranceValidation("#insurance-validator2", ".Expiry");
        // toaster(validationErrors.expiry_date)
      } else if (insurancedata.expiry_date < Date.now()) {
        validationErrors.expiry_date = "Expiry Date should not be past date";
        constraintLabels.push(validationErrors.expiry_date);
        addSelectorToInsuranceValidation("#insurance-validator2", ".Expiry");
      }
      // const insurancefileds = Object.keys(insurancedata);
      // if(Object.keys(validationErrors).find((item) => insurancefileds.includes(item))){
      //     toaster("Please complete all required fields of Insurance Details");
      // }
      if (validationLabels.length !== 0) {
        toaster(
          validationLabels
            .join(", ")
            .concat(" is missing! " + constraintLabels.join(","))
        );
      } else if (constraintLabels.length !== 0) {
        toaster(constraintLabels.join(","));
      }

      if (validationLabels.length === 0 && constraintLabels.length === 0) {
        // const network_selector = doc_ins_val.querySelector(".Network");
        // network_selector.setAttribute('class', network_selector.getAttribute("class") + " input-error")
        removeSelectorToInsuranceValidation(
          "#insurance-validator1",
          ".Insurance"
        );
        removeSelectorToInsuranceValidation(
          "#insurance-validator2",
          ".Network"
        );
        removeSelectorToInsuranceValidation("#insurance-validator2", ".Plan");
        removeSelectorToInsuranceValidation("#insurance-validator2", ".Card");
        removeSelectorToInsuranceValidation("#insurance-validator2", ".Expiry");
      }
    }
    return validationErrors;
  };

  const addSelectorToInsuranceValidation = (id, classname) => {
    const doc_ins_val = document.querySelector(id);

    if (doc_ins_val === null) return;

    const selector = doc_ins_val.querySelector(classname);

    if (selector === null) return;

    if (!selector.getAttribute("class").includes("input-error"))
      selector.setAttribute(
        "class",
        selector.getAttribute("class") + " input-error"
      );
  };

  const removeSelectorToInsuranceValidation = (id, classname) => {
    const doc_ins_val = document.querySelector(id);

    if (doc_ins_val === null) return;

    const selector = doc_ins_val.querySelector(classname);

    if (selector === null) return;

    let classList = selector.getAttribute("class");
    if (classList.includes("input-error"))
      classList = classList.replace("input-error", "");
    selector.setAttribute("class", classList);
  };

  const findLabel = (data, value) => {
    const item = data.find((item) => item.value === value);
    return item ? item.label : ""; // Returns the label if found, or an empty string if not found
  };
  const toaster = (message) => {
    toast.error(message, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 1000,
      toastId: "Insurance Company Form Validation",
    });
  };
  const searchsubmit = async (e) => {
    e.preventDefault();
    const postData = {
      user_id: userId,
      hospital_detail_id: searchhospitalid, //All location means send value as 0
      search_from: gaurdianTab === 2 ? "Guardian" : "Kin",
      search_field: selectedOption, //"first_name,last_name,mr_number,primary_mobile"
      search_value: filterFields[selectedOption],
    };
    await getData
      .SearchPatientRelation(postData, userToken)
      .then((resp) => {
        if (resp.data.status === 0) {
          toast.error(resp.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
        } else {
          let relation = resp.data.map((rel) => {
            rel["fullname"] =
              rel.firstname +
              " " +
              checkNull(rel.middlename) +
              " " +
              rel.lastname;
            return rel;
          });

          if (gaurdianTab === 2) {
            setsearchGuardiandata(relation);
          } else {
            setsearchkindata(relation);
          }
        }
      })
      .catch((error) => {
        if (error.response.data.status === 2) {
          toast.error(
            error.response.data.message + ". Please log in again to continue.",
            {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 1000,
            }
          );
          setTimeout(() => {
            dispatch(logoutFunction(userId, logId, userToken, navigate));
          }, 2000);
        } else {
          toast.error(
            error.response.data.message + " Please Try After Sometimes" ||
              "Too Many Attempts. Please Try after sometimes",
            {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 1000,
            }
          );
        }
      });
  };

  const searchonlinereferralsubmit = () => {};

  const validateErrorState = () => {
    const errorObj = Object.keys(errors);

    let validateError = {};

    for (let i = 0; i < errorObj.length; i++) {
      if (errors[errorObj[i]] != "") {
        validateError = {
          ...validateError,
          [errorObj[i]]: errors[errorObj[i]],
        };
      }
    }

    console.log(validateError);
    return validateError;
  };

  // const handleSaveClick = (e) => {
  // 	e.preventDefault();
  //     setSaveEvent(e);
  // 	setShowModal(true); // Show the modal
  // };

  // const handleConfirm = async () => {
  // 	setShowModal(false);
  // 	await formsubmit(saveEvent, 0, true); // Proceed with saving
  // };

  // const handleClose = () => {
  // 	setShowModal(false); // Close the modal without saving
  // };

  const formsubmit = async (
    e = null,
    type,
    isInsurance = false,
    tabClose = 0
  ) => {
    // tabclose - 0(close) 1(continue)

    if (e !== null) e.preventDefault();
    var anyerror = 0;
    // dispatch(setLoading(true))
    const validationErrors = validateForm();
    const error = validateErrorState();
    console.log("error ", error);
    console.log("type = ", type);
    if (
      Object.keys(validationErrors).length === 0 &&
      Object.keys(error).length === 0
    ) {
      // Modal Configuration
      if (checkChangesInsuranceData && multipleinsurancedata.length >= 1) {
        setSaveCheckInsuranceConfirmModal(true);
        return;
      }

      if (
        checkCoPayOfAllServiceIsNotCheckedIfCopayIsEntered() &&
        !checkIfServiceCategoryNoCoPayPercentageIsEntered()
      ) {
        console.log("Copay is entered but not checked");
        setShowCopayConditionedModal(true);
        return;
      }

      if (!showSaveaAndCloseModal) {
        setShowSaveaAndCloseModal(true);
        return;
      }

      dispatch(setLoading(true));

      if (patient_id1 === 0) {
        let FormData = Object.assign({}, formData);
        //   console.log(FormData)
        if (Array.isArray(FormData["language_id"])) {
          FormData["language_id"] = FormData["language_id"].join(",");
        }
        await getData
          .CreatePatient(FormData, userToken)
          .then(async (resp) => {
            FormData["language_id"] = FormData["language_id"]
              .split(",")
              .map(Number);
            if (resp.data.status === 1) {
              // setpatient_id(resp.data.patient_id);
              formData["user_created"] = userId;
              // setLoading(false);
              emiratedata["emirate_ids"] = emiratedata["emirate_ids"].replace(
                /-/g,
                ""
              );
              othersdata["mothers_eid"] = othersdata["mothers_eid"].replace(
                /-/g,
                ""
              );

              let updateMultipleGuardianData = multipleGuardiandata.map(
                (item) => {
                  delete item.fullname;
                  return item;
                }
              );

              let updateMultipleKinData = multipleKindata.map((item) => {
                delete item.fullname;
                return item;
              });

              let updateAddressData = _.cloneDeep(addressdata);

              if (updateAddressData.primary_contact_code.includes("+")) {
                updateAddressData.primary_contact_code =
                  updateAddressData.primary_contact_code.split("+")[1];
              }

              // if primary_contact is empty
              if (updateAddressData.primary_contact === "") {
                updateAddressData.primary_contact = "";
                updateAddressData.primary_contact_code = "";
              }
              // if primary_contact and primary_contact_code is same, (i.e. only has countryCode)
              else if (
                updateAddressData.primary_contact ===
                updateAddressData.primary_contact_code
              ) {
                updateAddressData.primary_contact = "";
              }
              // if primary_contact and primary_contact_code is different, some number format is achieved
              else {
                updateAddressData.primary_contact =
                  updateAddressData.primary_contact.split(
                    updateAddressData.primary_contact_code
                  )[1];
              }

              if (updateAddressData.secondary_contact_code.includes("+")) {
                updateAddressData.secondary_contact_code =
                  updateAddressData.secondary_contact_code.split("+")[1];
              }

              // if secondary_contact is empty
              if (updateAddressData.secondary_contact === "") {
                updateAddressData.secondary_contact = "";
                updateAddressData.secondary_contact_code = "";
              }
              // if secondary_contact and secondary_contact_code is same, (i.e. only has countryCode)
              else if (
                updateAddressData.secondary_contact ===
                updateAddressData.secondary_contact_code
              ) {
                updateAddressData.secondary_contact = "";
              }
              // if secondary_contact and secondary_contact_code is different, some number format is achieved
              else {
                updateAddressData.secondary_contact =
                  updateAddressData.secondary_contact.split(
                    updateAddressData.secondary_contact_code
                  )[1];
              }

              const postsubdata = {
                user_id: userId,
                patient_id: resp.data.patient_id,
                emirate: emiratedata,
                others: othersdata,
                patient_address: updateAddressData,
                guardian: updateMultipleGuardianData,
                nextkin: updateMultipleKinData,
              };

              const referralSubData = {
                user_id: userId,
                patient_detail_id: resp.data.patient_id,
                // referral_source_id: referraldata.referral_source_id,
                referral_channel_id: referraldata.referral_channel_id,
                clinic_name: referraldata.clinic_name,
                doctor_name: referraldata.doctor_name,
                license_no: referraldata.license_no,
              };

              await getData
                .SavePatientSubdData(postsubdata, userToken)
                .then((resp1) => {
                  emiratedata["emirate_ids"] = patternMaker(
                    emiratedata["emirate_ids"]
                  );
                  othersdata["mothers_eid"] = patternMaker(
                    othersdata["mothers_eid"]
                  );
                  if (resp1.data.status === 1) {
                    //setpatient_id(resp.data.patient_id);

                    // setLoading(false);
                    toast.success(resp1.data.message, {
                      position: toast.POSITION.TOP_RIGHT,
                      autoClose: 1000,
                    });
                  } else {
                    // setLoading(false);
                    toast.error(resp1.data.message, {
                      position: toast.POSITION.TOP_RIGHT,
                      autoClose: 1000,
                    });
                    anyerror = 1;
                    return false;
                  }
                })
                .catch((error) => {
                  // console.log(error); return false;
                  if (error.response.data.status === 2) {
                    toast.error(
                      error.response.data.message +
                        ". Please log in again to continue.",
                      {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 1000,
                      }
                    );
                    setTimeout(() => {
                      dispatch(
                        logoutFunction(userId, logId, userToken, navigate)
                      );
                    }, 2000);
                  } else {
                    toast.error(
                      error.response.data.message +
                        " Please Try After Sometimes" ||
                        "Too Many Attempts. Please Try after sometimes",
                      {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 1000,
                      }
                    );
                  }
                  dispatch(setLoading(false));
                });

              // if referral has no data entered, do not call Save Referral API
              if (
                referraldata.referral_channel_id !== "" ||
                referraldata.clinic_name !== "" ||
                referraldata.doctor_name !== "" ||
                referraldata.license_no !== ""
              ) {
                await getData
                  .SaveReferral(referralSubData, userToken)
                  .then((resp1) => {
                    if (resp1.data.status !== 1) {
                      //setpatient_id(resp.data.patient_id);

                      // setLoading(false);
                      // toast.success(resp.data.message, {
                      //     position: toast.POSITION.TOP_RIGHT,
                      //     autoClose: 1000,
                      // });
                      setLoading(false);
                      toast.error(resp1.data.message, {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 1000,
                      });
                      anyerror = 1;
                      return false;
                    }
                    // else {
                    //     // setLoading(false);
                    //     toast.error(resp1.data.message, {
                    //         position: toast.POSITION.TOP_RIGHT,
                    //         autoClose: 1000,
                    //     });
                    // }
                  })
                  .catch((error) => {
                    // console.log(error)
                    dispatch(setLoading(false));
                    if (error.response.data.status === 2) {
                      toast.error(
                        error.response.data.message +
                          ". Please log in again to continue.",
                        {
                          position: toast.POSITION.TOP_RIGHT,
                          autoClose: 1000,
                        }
                      );
                      setTimeout(() => {
                        dispatch(
                          logoutFunction(userId, logId, userToken, navigate)
                        );
                      }, 2000);
                    } else {
                      toast.error(
                        error.response.data.message +
                          " Please Try After Sometimes" ||
                          "Too Many Attempts. Please Try after sometimes",
                        {
                          position: toast.POSITION.TOP_RIGHT,
                          autoClose: 1000,
                        }
                      );
                      anyerror = 1;
                      return false;
                    }
                  });
              }

              if (formData.payment_mode_id == 2 && isInsurance) {
                console.log("patient_id ", resp.data.patient_id);
                // await handlesubmitinsurancedetails(resp.data.patient_id);
                await insuranceSubmitController(resp.data.patient_id, false);
              }

              dispatch(setLoading(false));

              if (type === 0) {
                // setTimeout(() => {
                //     navigate('/registration-list')
                // }, 2000);

                // Manual reset by calling setState
                // setFormData(initialFormData);
                // setFilterFields(initialfilterData)

                // setemiratedata(initialemiratedata);
                // setothersdata(initialothersdata);
                // setaddressdata(initialaddressdata);
                // setreferraldata(initalreferraldata);
                // setguardiandata(initialGuardian);
                // setonlinereferraldata(initialonlinereferraldata);
                // setinsurancedata(initialinsurancedata);

                // Functionality (Test)
                handleAllFormReset();
              } else {
                setFormData({
                  ...formData,
                  ["patient_id"]: resp.data.patient_id,
                });
                navigate("/Registration?patientId=" + resp.data.patient_id);
              }

              // if(tabClose === 0) {
              //     setTimeout(() => {
              //         window.close();
              //     }, 500)
              // }
            } else {
              dispatch(setLoading(false));
              toast.error(resp.data.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 1000,
              });
              anyerror = 1;
              return false;
            }
          })
          .catch((error) => {
            // console.log(error)
            if (error.response.data.status === 2) {
              toast.error(
                error.response.data.message +
                  ". Please log in again to continue.",
                {
                  position: toast.POSITION.TOP_RIGHT,
                  autoClose: 1000,
                }
              );
              setTimeout(() => {
                dispatch(logoutFunction(userId, logId, userToken, navigate));
              }, 2000);
            } else {
              toast.error(
                error.response.data.message + " Please Try After Sometimes" ||
                  "Too Many Attempts. Please Try after sometimes",
                {
                  position: toast.POSITION.TOP_RIGHT,
                  autoClose: 1000,
                }
              );
            }
            dispatch(setLoading(false));
            anyerror = 1;
            return false;
          });
      } else {
        let FormData = Object.assign({}, formData);
        if (Array.isArray(FormData["language_id"])) {
          FormData["language_id"] = FormData["language_id"].join(",");
        }
        await getData
          .UpdatePatient(FormData, userToken)
          .then(async (resp) => {
            FormData["language_id"] = FormData["language_id"]
              .split(",")
              .map(Number);
            if (resp.data.status === 1) {
              // setpatient_id(resp.data.patient_id);

              emiratedata["emirate_ids"] = emiratedata["emirate_ids"].replace(
                /-/g,
                ""
              );
              othersdata["mothers_eid"] = othersdata["mothers_eid"].replace(
                /-/g,
                ""
              );

              // let updateMultipleGuardianData = Object.assign([], multipleGuardiandata);
              let updateMultipleGuardianData =
                _.cloneDeep(multipleGuardiandata);
              updateMultipleGuardianData = updateMultipleGuardianData.map(
                (item) => {
                  delete item.fullname;
                  return item;
                }
              );
              // let updateMultipleKinData = Object.assign([], multipleKindata);
              let updateMultipleKinData = _.cloneDeep(multipleKindata);
              updateMultipleKinData = updateMultipleKinData.map((item) => {
                delete item.fullname;
                return item;
              });

              let updateAddressData = _.cloneDeep(addressdata);

              if (updateAddressData.primary_contact_code.includes("+")) {
                updateAddressData.primary_contact_code =
                  updateAddressData.primary_contact_code.split("+")[1];
              }

              // if primary_contact is empty
              if (updateAddressData.primary_contact === "") {
                updateAddressData.primary_contact = "";
                updateAddressData.primary_contact_code = "";
              }
              // if primary_contact and primary_contact_code is same, (i.e. only has countryCode)
              else if (
                updateAddressData.primary_contact ===
                updateAddressData.primary_contact_code
              ) {
                updateAddressData.primary_contact = "";
              }
              // if primary_contact and primary_contact_code is different, some number format is achieved
              else {
                updateAddressData.primary_contact =
                  updateAddressData.primary_contact.split(
                    updateAddressData.primary_contact_code
                  )[1];
              }

              if (updateAddressData.secondary_contact_code.includes("+")) {
                updateAddressData.secondary_contact_code =
                  updateAddressData.secondary_contact_code.split("+")[1];
              }

              // if secondary_contact is empty
              if (updateAddressData.secondary_contact === "") {
                updateAddressData.secondary_contact = "";
                updateAddressData.secondary_contact_code = "";
              }
              // if secondary_contact and secondary_contact_code is same, (i.e. only has countryCode)
              else if (
                updateAddressData.secondary_contact ===
                updateAddressData.secondary_contact_code
              ) {
                updateAddressData.secondary_contact = "";
              }
              // if secondary_contact and secondary_contact_code is different, some number format is achieved
              else {
                updateAddressData.secondary_contact =
                  updateAddressData.secondary_contact.split(
                    updateAddressData.secondary_contact_code
                  )[1];
              }

              // setLoading(false);
              const postsubdata = {
                user_id: userId,
                patient_id: patient_id1,
                emirate: emiratedata,
                others: othersdata,
                patient_address: updateAddressData,
                guardian: updateMultipleGuardianData,
                nextkin: updateMultipleKinData,
                //
              };

              const referralSubData = {
                user_id: userId,
                patient_detail_id: patient_id1,
                // referral_source_id: referraldata.referral_source_id,
                referral_channel_id: referraldata.referral_channel_id,
                clinic_name: referraldata.clinic_name,
                doctor_name: referraldata.doctor_name,
                license_no: referraldata.license_no,
              };

              await getData
                .SavePatientSubdData(postsubdata, userToken)
                .then((resp1) => {
                  emiratedata["emirate_ids"] = patternMaker(
                    emiratedata["emirate_ids"]
                  );
                  othersdata["mothers_eid"] = patternMaker(
                    othersdata["mothers_eid"]
                  );
                  if (resp1.data.status === 1) {
                    //setpatient_id(resp.data.patient_id);

                    // setLoading(false);
                    toast.success(resp.data.message, {
                      position: toast.POSITION.TOP_RIGHT,
                      autoClose: 1000,
                    });
                  } else {
                    setLoading(false);
                    toast.error(resp1.data.message, {
                      position: toast.POSITION.TOP_RIGHT,
                      autoClose: 1000,
                    });
                    anyerror = 1;
                    return false;
                  }
                })
                .catch((error) => {
                  if (error.response.data.status === 2) {
                    toast.error(
                      error.response.data.message +
                        ". Please log in again to continue.",
                      {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 1000,
                      }
                    );
                    setTimeout(() => {
                      dispatch(
                        logoutFunction(userId, logId, userToken, navigate)
                      );
                    }, 2000);
                  } else {
                    toast.error(
                      error.response.data.message +
                        " Please Try After Sometimes" ||
                        "Too Many Attempts. Please Try after sometimes",
                      {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 1000,
                      }
                    );
                  }
                  dispatch(setLoading(false));
                  anyerror = 1;
                  return false;
                });
              await getData
                .SaveReferral(referralSubData, userToken)
                .then((resp1) => {
                  if (resp1.data.status !== 1) {
                    //setpatient_id(resp.data.patient_id);

                    // setLoading(false);
                    // toast.success(resp.data.message, {
                    //     position: toast.POSITION.TOP_RIGHT,
                    //     autoClose: 1000,
                    // });
                    setLoading(false);
                    toast.error(resp1.data.message, {
                      position: toast.POSITION.TOP_RIGHT,
                      autoClose: 1000,
                    });
                    anyerror = 1;
                    return false;
                  }
                  // else {
                  //     // setLoading(false);
                  //     toast.error(resp1.data.message, {
                  //         position: toast.POSITION.TOP_RIGHT,
                  //         autoClose: 1000,
                  //     });
                  // }
                })
                .catch((error) => {
                  // console.log(error)
                  if (error.response.data.status === 2) {
                    toast.error(
                      error.response.data.message +
                        ". Please log in again to continue.",
                      {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 1000,
                      }
                    );
                    setTimeout(() => {
                      dispatch(
                        logoutFunction(userId, logId, userToken, navigate)
                      );
                    }, 2000);
                  } else {
                    toast.error(
                      error.response.data.message +
                        " Please Try After Sometimes" ||
                        "Too Many Attempts. Please Try after sometimes",
                      {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 1000,
                      }
                    );
                  }
                  dispatch(setLoading(false));
                  anyerror = 1;
                  return false;
                });

              if (type === 0) {
                // setTimeout(() => {
                //     navigate('/registration-list')
                // }, 2000);
                // navigate('/registration-list')
              }
              dispatch(setLoading(false));

              // if(tabClose === 0) {
              //     setTimeout(() => {
              //         window.close();
              //     }, 500)
              // }
            } else {
              // setLoading(false);
              toast.error(resp.data.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 1000,
              });
              dispatch(setLoading(false));
              anyerror = 1;
              return false;
            }
          })
          .catch((error) => {
            // console.log(error)
            dispatch(setLoading(false));

            if (error.response.data.status === 2) {
              toast.error(
                error.response.data.message +
                  ". Please log in again to continue.",
                {
                  position: toast.POSITION.TOP_RIGHT,
                  autoClose: 1000,
                }
              );
              setTimeout(() => {
                dispatch(logoutFunction(userId, logId, userToken, navigate));
              }, 2000);
            } else {
              toast.error(
                error.response.data.message + " Please Try After Sometimes" ||
                  "Too Many Attempts. Please Try after sometimes",
                {
                  position: toast.POSITION.TOP_RIGHT,
                  autoClose: 1000,
                }
              );
              anyerror = 1;
              return false;
            }
          });
      }
    } else {
      // const mergedErrors = { ...errors, ...validationErrors };
      // setErrors(mergedErrors);
      setErrors({ ...validationErrors, ...error });
      dispatch(setLoading(false));
      anyerror = 1;
      return false;
    }
    setShowSaveaAndCloseModal(false);
    if (tabClose === 0) {
      setTimeout(() => {
        if (anyerror === 0) {
          // console.log(anyerror)
          window.close();
        }
      }, 2000);
    }
  };

  const popupSetToGuardianSearch = () => {
    handleAddPopUp();
    setDetailTab(4);
    setGaurdianTab(2);
  };

  const popupSetToKinSearch = () => {
    handleAddPopUp();
    setDetailTab(5);
    setKinTab(2);
  };

  const handleAddPopUp = () => {
    setOpenModal(true);
    setKinTab(1);
    setGaurdianTab(1);
  };

  const handleClosePopUp = () => {
    setOpenModal(false);
    setguardiandata(initialGuardian);
    setActiveRadioField([]);
    setKindata(initialKin);
    setErrors({});
  };

  const handleClosePopUpGuardianCustom = () => {
    setGaurdianTab(1);
    // setguardiandata(initialGuardian);
    setsearchGuardiandata([]);
    setActiveRadioField([]);
    // setKindata(initialKin);
    setErrors({});
  };

  const handleClosePopUpKinCustom = () => {
    setKinTab(1);
    // setguardiandata(initialGuardian);
    setsearchkindata([]);
    setActiveRadioField([]);
    // setKindata(initialKin);
    setErrors({});
  };

  const AskNavigationCloseModal = () => {
    navigate("/registration-list");
    setAskNavigationOpenModal(false);
  };

  // Not using now --skip
  const AskSavePatientCloseModal = async (type, withInsurance) => {
    setAskSavePatientOpenModal(false);

    await formsubmit(null, type, withInsurance);
  };

  const handleReset = () => {
    setguardiandata(initialGuardian);
    setKindata(initialKin);
    setErrors({});
  };

  const handleAllFormReset = () => {
    setFormData(initialFormData);
    setemiratedata(initialemiratedata);
    setaddressdata(initialaddressdata);
    setothersdata(initialothersdata);
    setguardiandata(initialGuardian);
    setKindata(initialKin);
    setreferraldata(initalreferraldata);
    setonlinereferraldata(initialonlinereferraldata);
    setFilterFields(initialfilterData);
    setFormData((prevdata) => ({
      ...initialFormData,
      hospital_detail_id: hospital_detail_id,
      user_created: fullname,
    }));

    setPrevProfileImage("");
    seteditpreviewProfileImg("");
    setEditProfileImg(false);
    setMultipleGuardiandata([]);
    setMultipleKindata([]);
    setErrors({});

    dispatch({ type: ALL_MASTER_ACTION_TYPES.SET_REGION_LIST, payload: [] });
    dispatch({ type: ALL_MASTER_ACTION_TYPES.SET_REGION_LIST1, payload: [] });
    dispatch({ type: ALL_MASTER_ACTION_TYPES.SET_REGION_LIST2, payload: [] });
    dispatch({ type: ALL_MASTER_ACTION_TYPES.SET_REGION_LIST3, payload: [] });

    dispatch({ type: ALL_MASTER_ACTION_TYPES.SET_CITY_LIST, payload: [] });
    dispatch({ type: ALL_MASTER_ACTION_TYPES.SET_CITY_LIST1, payload: [] });
    dispatch({ type: ALL_MASTER_ACTION_TYPES.SET_CITY_LIST2, payload: [] });
    dispatch({ type: ALL_MASTER_ACTION_TYPES.SET_CITY_LIST3, payload: [] });

    // Insurance

    resetInsuranceData();
  };

  const openOnlineRegistration = () => {
    setToggleOnlineReferralModal(true);
    toggleList();
  };

  const openPhoneRegistation = () => {
    setSwitchPhoneCallRegModal(1);
    setTogglePhoneCallRegModal(true);
    toggleList();
  };

  const memberOptions = [
    { id: "Normal", name: "Normal" },
    { id: "Demo Patient", name: "Demo Patient" },
    { id: "Family Member", name: "Family Member" },
    { id: "Group", name: "Group" },
  ];

  const EmiratesOptions = [
    {
      label: "UAE National w/o card: 000-0000-0000000-0",
      value: "000-0000-0000000-0",
    },
    {
      label: "Expat. Resident w/o card: 111-1111-1111111-1",
      value: "111-1111-1111111-1",
    },
    {
      label: "Non-National, Non-Expat. resident w/o card: 222-2222-2222222-2",
      value: "222-2222-2222222-2",
    },
    {
      label: "Un-known statsu w/o card: 999-9999-9999999-9",
      value: "999-9999-9999999-9",
    },
  ];

  const searchByOptions = [
    { id: "MR Number", name: "MR Number" },
    { id: "FirstName", name: "FirstName" },
    { id: "LastName", name: "LastName" },
    { id: "MobileNo", name: "MobileNo" },
  ];
  const calculateAge = (selectedDate) => {
    if (selectedDate) {
      const today = new Date();
      const birthDate = new Date(selectedDate);

      let years = today.getFullYear() - birthDate.getFullYear();
      let months = today.getMonth() - birthDate.getMonth();
      let days = today.getDate() - birthDate.getDate();

      // Adjust for negative days
      if (days < 0) {
        months--;
        const prevMonth = new Date(today.getFullYear(), today.getMonth(), 0);
        days += prevMonth.getDate();
      }

      // Adjust for negative months
      if (months < 0) {
        years--;
        months += 12;
      }

      // Function to format output with proper pluralization
      const formatOutput = (value, singularUnit, pluralUnit) => {
        if (value === 0) {
          return `00 ${singularUnit}`;
        }
        return `${value >= 10 ? value : "0" + value} ${
          value === 1 ? singularUnit : pluralUnit
        }`;
      };

      const yearOutput = formatOutput(years, "year", "years");
      const monthOutput = formatOutput(months, "month", "months");
      const dayOutput = formatOutput(days, "day", "days");

      return `${yearOutput} / ${monthOutput} / ${dayOutput}`;
    }

    return null;
  };

  const patternHandler = (e, newValue, tab) => {
    let lastDigitIndex = newValue.length - 1;
    // Find the index of the last digit
    while (
      lastDigitIndex >= 0 &&
      (newValue[lastDigitIndex] < "0" || newValue[lastDigitIndex] > "9")
    ) {
      lastDigitIndex--;
    }
    if (lastDigitIndex !== -1) {
      const modifyValue =
        newValue.substring(0, lastDigitIndex) +
        "_" +
        newValue.substring(lastDigitIndex + 1);
      // Restore the caret position after React updates the DOM

      if (!/_/.test(modifyValue) || modifyValue == "___-____-_______-_") {
        // setErrors({});
        setErrors({
          ...errors,
          ...(tab === "Others"
            ? { mothers_eid: "", birth_order: "" }
            : { emirate_ids: "" }),
        });
      } else {
        setErrors({
          ...errors,
          [tab === "Others" ? "mothers_eid" : "emirate_ids"]:
            tab === "Others"
              ? "Fill the mothers emirated Id properly"
              : "Fill the emirated Id properly",
        });
      }
      setTimeout(() => {
        if (inputRef.current) {
          // const selectionStart = lastDigitIndex;
          const lastDigitIndex = newValue.lastIndexOf(
            newValue.replace(/[^0-9]/g, "").slice(-1)
          );
          const cursorPosition = lastDigitIndex !== -1 ? lastDigitIndex : 0;
          // inputRef.current.selectionStart = inputRef.current.selectionEnd = selectionStart;
          e.target.setSelectionRange(cursorPosition, cursorPosition);
        }
      }, 0);
      return modifyValue;
    }
    return newValue;
  };

  const handleKeyDown = (e, tab) => {
    if (e.key === "Backspace") {
      e.preventDefault();
      // Replace the last entered digit with '-'

      if (tab === "E.ID") {
        const newPattern = patternHandler(e, emiratedata.emirate_ids, tab);
        setemiratedata({ ...emiratedata, emirate_ids: newPattern });
      } else if (tab === "Others") {
        const newPattern = patternHandler(e, othersdata.mothers_eid, tab);
        setothersdata({ ...othersdata, mothers_eid: newPattern });
      }
    }
  };

  const AskNavigation = () => {
    const entryCheckerStatus = entryChecker();
    console.log(entryCheckerStatus);
    if (!entryCheckerStatus) {
      navigate("/registration-list");
      return;
    }
    setAskNavigationOpenModal(true);
  };

  // const checkboxChange = (e, focusData, accessor) => {
  //     focusData[accessor] = e.target.value == 1 ? 0 : 1;

  //     const mulipledeptlistdata = Object.assign([], multipleDepartmentListdata);
  //     const focusDataIndex = mulipledeptlistdata.findIndex((data , index) => data.department_id === focusData.department_id);
  //     mulipledeptlistdata[focusDataIndex] = focusData;
  //     setMultipleDepartmentListdata(mulipledeptlistdata);
  // }

  // const handleInputChange = (e,focusDataIndex, focusData, accessor) => {
  //     setCheckChanges(true);
  //     // console.log(e , focusDataIndex, focusData, accessor);
  //     focusData[accessor] = e.target.value;

  //     const mulipledeptlistdata = Object.assign([], multipleDepartmentListdata);
  //     mulipledeptlistdata[focusDataIndex] = focusData;
  //     setMultipleDepartmentListdata(mulipledeptlistdata);
  // }

  const checkboxChangeSC = (
    index,
    subIndex,
    list_name,
    e,
    focusData,
    accessor
  ) => {
    // focusData[accessor] = e.target.value == 1 ? 0 : 1;

    // const mulipledeptlistdata = Object.assign([], multipleDepartmentListdata);
    // const focusDataIndex = mulipledeptlistdata.findIndex((data , index) => data.department_id === focusData.department_id);
    // mulipledeptlistdata[focusDataIndex] = focusData;
    // setMultipleDepartmentListdata(mulipledeptlistdata);

    const updateplandetail = _.cloneDeep(plandetaildata);
    const value = e.target.value == 1 ? 0 : 1;
    updateplandetail[list_name][index].category_list[subIndex][accessor] =
      value;

    if (list_name === "sub_list" && accessor === "active" && value === 0) {
      let t = _.cloneDeep(
        updateplandetail[list_name][index].category_list[subIndex]
      );

      t.co_pay_percentage = "";
      t.deductible_amount = "";
      t.per_invoice = "";
      t.max_ceilling = "";

      updateplandetail[list_name][index].category_list[subIndex] = t;
    }

    setplandetaildata(updateplandetail);
  };

  const handleInputChangeSC = (
    e,
    index,
    subIndex,
    list_name,
    focusData,
    accessor
  ) => {
    setCheckChanges(true);
    // console.log(e , focusDataIndex, focusData, accessor);
    // focusData[accessor] = e.target.value;

    // const mulipledeptlistdata = Object.assign([], multipleDepartmentListdata);
    // mulipledeptlistdata[focusDataIndex] = focusData;
    // setMultipleDepartmentListdata(mulipledeptlistdata);

    const updateplandetail = _.cloneDeep(plandetaildata);

    const value = e.target.value;
    updateplandetail[list_name][index].category_list[subIndex][accessor] =
      value;

    setplandetaildata(updateplandetail);
  };

  useEffect(() => {
    // console.log(plandetaildata)
  }, [plandetaildata]);

  const fileInputRef = useRef(null);

  const handleCancelImage = () => {
    setFormData({ ...formData, profile_image: "" });
    seteditpreviewProfileImg("default.png");

    // Reset the file input value
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };
  const handleClearProfileImage = () => {
    setPrevProfileImage("");
    setEditProfileImg(false);
    setFormData({ ...formData, profile_image: "" });
  };

  // const handleFileChange = (e) => {
  //     const file = e.target.files[0];
  //     if (file) {
  //         setFormData({ ...formData, profile_image: file });
  //         seteditpreviewProfileImg(URL.createObjectURL(file));
  //     }
  // };

  const resetInsuranceData = () => {
    setEnableEditModal(false);
    setEditExising(false);
    setinsurancedata(initialinsurancedata);
    setActiveInsuranceId(0);
    setplandetaildata({});
    dispatch({
      type: ALL_MASTER_ACTION_TYPES.SET_SUB_COMPANY_LIST,
      payload: [],
    });
    dispatch({ type: ALL_MASTER_ACTION_TYPES.SET_NETWORK_LIST, payload: [] });
    dispatch({ type: ALL_MASTER_ACTION_TYPES.SET_BENEFIT_LIST, payload: [] });
    dispatch({
      type: ALL_MASTER_ACTION_TYPES.SET_PLAN_NETWORK_LIST,
      payload: [],
    });
    dispatch({
      type: ALL_MASTER_ACTION_TYPES.SET_PLAN_SERVICE_CATEGORY_LIST,
      payload: [],
    });
  };

  const callToaster = () => {
    toast.error("Enter Email Address to uncheck No Email Available checkbox", {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 1000,
    });
  };

  return (
    <>
      <div className="row newResgiterCustomTitle">
        <div className="col-10 col-md-10 col-xs-8 col-sm-8">
          <Title title={`${title} Registration`} />
        </div>
        <div
          className="col-2 col-md-2 col-xs-4 col-sm-4 padding_fixed d-flex justify-content-end"
          style={{
            textAlign: "right",
            paddingRight: "12px",
            position: "relative",
          }}
        >
          <Buttons.DocumentList name={SVGICON.caretDown} onClick={toggleList} />
          {ulMenuOpen && (
            <ul className="dropdown_ul">
              {/* <li onClick={() => { navigate('/appointment-book'); }}>{SVGICON.referalIcon} Phone Call Registartion</li> */}
              <li onClick={() => openPhoneRegistation()}>
                {SVGICON.referalIcon} Phone Call Registartion
              </li>
              <li onClick={() => openOnlineRegistration()}>
                {SVGICON.referalIcon} Online Registartion
              </li>
              {/* <li onClick={() => setToggleOnlineReferralModal(true)}>{SVGICON.referalIcon} Online Registartion</li> */}
            </ul>
          )}
          {/* <div className="cursor-pointer" style={{paddingLeft:20}} onClick={() => AskNavigation()}>{SVGICON.close}</div> */}
        </div>
      </div>

      <div
        className="row bg_wrap b-radius  m-tab no_margin"
        style={{ padding: "18px 0px" }}
      >
        <div className="col-md-12">
          <div>
            {registrationUIDatas[0]?.registration.map((formFeilds) => (
              <>
                <div className="row">
                  <div className="col-md-12 col-xs-12 padding_fixed">
                    <div className="row no_margin">
                      {formFeilds.medicalCenter &&
                        formFeilds.medicalCenter.map((input) => (
                          <div
                            className={`col-md-${
                              input.name === "hospital_detail_id" ||
                              input.name === "register_datetime"
                                ? "3"
                                : input.label === "Time"
                                ? "1"
                                : "2"
                            } col-xs-12 padding_fixed`}
                            key={input.id}
                          >
                            {input.type === "text" ? (
                              <TextInput
                                {...input}
                                onClick={
                                  input.name === "mr_number"
                                    ? popupSetToGuardianSearch
                                    : null
                                }
                                onBlur={
                                  input.label === "M.R.No"
                                    ? (e) => checkMrNumber(e)
                                    : null
                                }
                                //options={input.type === "select" ? input.name === "personal_id" ? personalList : input.name === "title_id" ? titleAllList : input.name === "gender_id" ? genderAllList : input.name === "country_id" ? countryList : input.name === "region_id" ? regionList : input.name === "marital_status_id" ? maritalStatusList : input.name === "city_id" ? cityList : input.name === "religion_id" ? religionList : input.name === "blood_group_id" ? bloodgrouplist : input.name === "language_id" ? languageList : input.name === "income_range_id" ? incomeRangeList : input.name === "payment_mode_id" ? paymentModeList : input.name === "nationality_id" ? nationalityList : a : null}
                                onChange={handleChange}
                                showFieldLoad={showFieldLoad}
                                //value={input.name==="personal_id"?formData.personal_id:input.name==="mr_number"?formData.mr_number:input.name==="address"?formData.address:input.name==="title_id"?formData.title_id:input.name==="gender_id"?formData.gender_id:input.name==="country_id"?formData.country_id:input.name==="first_name"?formData.first_name:input.name==="father_name"?formData.father_name:input.name==="region_id"?formData.region_id:input.name==="middle_name"?formData.middle_name:input.name==="marital_status_id"?formData.marital_status_id:input.name==="city_id"?formData.city_id:input.name==="last_name"?formData.last_name:input.name==="religion_id"?formData.religion_id:input.name==="mobile"?formData.mobile:input.name==="arabic_name"?formData.arabic_name:input.name==="nationality_id"?formData.nationality_id:input.name==="secondary_mobile"?formData.secondary_mobile:input.name==="date_of_birth"?formData.date_of_birth:input.name==="blood_group_id"?formData.blood_group_id:input.name==="email"?formData.email:input.name==="s_email"?formData.s_email:input.name==="language_id"?formData.language_id:input.name==="passport_no"?formData.passport_no:input.name==="income_range_id"?formData.income_range_id:input.name==="payment_mode_id"?formData.payment_mode_id:input.name==="vip"?formData.vip:input.name==="demo_pat"?formData.demo_pat:input.name==="family"?formData.family:""}
                                value={formData[input.name]}
                                error={errors[input.name]}
                              />
                            ) : input.type === "select" &&
                              input.name !== "visitor_type" ? (
                              <Searchdropdown
                                {...input}
                                options={
                                  input.name === "patient_class_id"
                                    ? patientClassList
                                    : input.name === "title_id"
                                    ? titleAllList
                                    : input.name === "gender_id"
                                    ? genderAllList
                                    : input.name === "country_id"
                                    ? countryList
                                    : input.name === "region_id"
                                    ? regionList
                                    : input.name === "marital_status_id"
                                    ? maritalStatusList
                                    : input.name === "city_id"
                                    ? cityList
                                    : input.name === "religion_id"
                                    ? religionList
                                    : input.name === "blood_group_id"
                                    ? bloodgrouplist
                                    : input.name === "language_id"
                                    ? languageList
                                    : input.name === "income_range_id"
                                    ? incomeRangeList
                                    : input.name === "payment_mode_id"
                                    ? paymentModeList[0]
                                    : input.name === "nationality_id"
                                    ? nationalityList
                                    : input.name === "education_id"
                                    ? educationList
                                    : input.name === "occupation_id"
                                    ? occupationList
                                    : input.name === "hospital_detail_id"
                                    ? clientList
                                    : input.name === "member_type"
                                    ? memberOptions
                                    : input.name === "medical_tourism"
                                    ? input.options
                                    : []
                                }
                                onChange={handleChange}
                                //value={input.name==="personal_id"?formData.personal_id:input.name==="mr_number"?formData.mr_number:input.name==="address"?formData.address:input.name==="title_id"?formData.title_id:input.name==="gender_id"?formData.gender_id:input.name==="country_id"?formData.country_id:input.name==="first_name"?formData.first_name:input.name==="father_name"?formData.father_name:input.name==="region_id"?formData.region_id:input.name==="middle_name"?formData.middle_name:input.name==="marital_status_id"?formData.marital_status_id:input.name==="city_id"?formData.city_id:input.name==="last_name"?formData.last_name:input.name==="religion_id"?formData.religion_id:input.name==="mobile"?formData.mobile:input.name==="arabic_name"?formData.arabic_name:input.name==="nationality_id"?formData.nationality_id:input.name==="secondary_mobile"?formData.secondary_mobile:input.name==="date_of_birth"?formData.date_of_birth:input.name==="blood_group_id"?formData.blood_group_id:input.name==="email"?formData.email:input.name==="s_email"?formData.s_email:input.name==="language_id"?formData.language_id:input.name==="passport_no"?formData.passport_no:input.name==="income_range_id"?formData.income_range_id:input.name==="payment_mode_id"?formData.payment_mode_id:input.name==="vip"?formData.vip:input.name==="demo_pat"?formData.demo_pat:input.name==="family"?formData.family:""}
                                showFieldLoad={showFieldLoad}
                                value={formData[input.name]}
                                disabled={
                                  (input.name === "payment_mode_id" &&
                                    patient_id1 !== 0) ||
                                  input.name === "hospital_detail_id"
                                }
                                error={errors[input.name]}
                              />
                            ) : input.name === "visitor_type" &&
                              formData.medical_tourism === "Visitor" ? (
                              <Searchdropdown
                                {...input}
                                options={
                                  input.name === "visitor_type"
                                    ? input.options
                                    : []
                                }
                                onChange={handleChange}
                                showFieldLoad={showFieldLoad}
                                value={formData[input.name]}
                                error={errors[input.name]}
                              />
                            ) : input.type === "dateTimePicker" ? (
                              <DateTimePickers
                                {...input}
                                value={formData[input.name]}
                                error={errors[input.name]}
                                showFieldLoad={showFieldLoad}
                                disabled={input.name === "register_datetime"}
                              />
                            ) : (
                              ""
                            )}
                          </div>
                        ))}
                    </div>
                  </div>
                </div>

                <div className="row reg-relative">
                  <div className="col-md-9 col-xs-12 padding_fixed last_child_width relative">
                    <div className="col-md-12 col-xs-12 padding_fixed">
                      <div
                        className="no_margin padding_fixed b-radius tabBG personal-details"
                        style={{ marginBottom: "10px" }}
                      >
                        {/* <legend class="MuiFormLabel-root MuiFormLabel-colorPrimary css-u4tvz2-MuiFormLabel-root">Patient Details</legend> */}
                        <TabContext value={personalTab}>
                          <TabList
                            onChange={handlePersonalTab}
                            aria-label="Tab"
                            variant="scrollable"
                            scrollButtons={false}
                          >
                            {registrationUIDatas[0]?.registration?.map(
                              (labelList) => (
                                <Tab
                                  key={labelList.value}
                                  value={labelList.value}
                                  label={labelList.label}
                                />
                              )
                            )}
                          </TabList>
                          {registrationUIDatas[0]?.registration?.map(
                            (tab, index) => (
                              <TabPanel key={tab.value} value={tab.value}>
                                <div
                                  className="row no_margin"
                                  style={{
                                    display:
                                      tab.label === "Personal Details"
                                        ? "block"
                                        : "none",
                                  }}
                                >
                                  <div className="row no_margin no_padding emid-align">
                                    {formFeilds.data &&
                                      formFeilds.data.map((input) => (
                                        <div
                                          className={`col-md-${
                                            input.name === "language_id"
                                              ? "6"
                                              : input.label ===
                                                "How did you know about us?"
                                              ? "6"
                                              : "3"
                                          } col-xs-12 padding_fixed`}
                                          key={input.id}
                                        >
                                          {input.type === "text" ? (
                                            <TextInput
                                              {...input}
                                              showFieldLoad={showFieldLoad}
                                              className="select"
                                              //options={input.type === "select" ? input.name === "personal_id" ? personalList : input.name === "title_id" ? titleAllList : input.name === "gender_id" ? genderAllList : input.name === "country_id" ? countryList : input.name === "region_id" ? regionList : input.name === "marital_status_id" ? maritalStatusList : input.name === "city_id" ? cityList : input.name === "religion_id" ? religionList : input.name === "blood_group_id" ? bloodgrouplist : input.name === "language_id" ? languageList : input.name === "income_range_id" ? incomeRangeList : input.name === "payment_mode_id" ? paymentModeList : input.name === "nationality_id" ? nationalityList : a : null}
                                              onChange={handleChange}
                                              //value={input.name==="personal_id"?formData.personal_id:input.name==="mr_number"?formData.mr_number:input.name==="address"?formData.address:input.name==="title_id"?formData.title_id:input.name==="gender_id"?formData.gender_id:input.name==="country_id"?formData.country_id:input.name==="first_name"?formData.first_name:input.name==="father_name"?formData.father_name:input.name==="region_id"?formData.region_id:input.name==="middle_name"?formData.middle_name:input.name==="marital_status_id"?formData.marital_status_id:input.name==="city_id"?formData.city_id:input.name==="last_name"?formData.last_name:input.name==="religion_id"?formData.religion_id:input.name==="mobile"?formData.mobile:input.name==="arabic_name"?formData.arabic_name:input.name==="nationality_id"?formData.nationality_id:input.name==="secondary_mobile"?formData.secondary_mobile:input.name==="date_of_birth"?formData.date_of_birth:input.name==="blood_group_id"?formData.blood_group_id:input.name==="email"?formData.email:input.name==="s_email"?formData.s_email:input.name==="language_id"?formData.language_id:input.name==="passport_no"?formData.passport_no:input.name==="income_range_id"?formData.income_range_id:input.name==="payment_mode_id"?formData.payment_mode_id:input.name==="vip"?formData.vip:input.name==="demo_pat"?formData.demo_pat:input.name==="family"?formData.family:""}
                                              value={formData[input.name]}
                                              error={errors[input.name]}
                                            />
                                          ) : input.type === "select" ? (
                                            input.name === "language_id" ? (
                                              <Multiselect
                                                {...input}
                                                className="multiselect"
                                                options={languageList}
                                                onChange={handleChange}
                                                showFieldLoad={showFieldLoad}
                                                value={formData[input.name]}
                                                error={errors[input.name]}
                                              />
                                            ) : (
                                              <Searchdropdown
                                                {...input}
                                                showFieldLoad={showFieldLoad}
                                                options={
                                                  input.name ===
                                                  "patient_class_id"
                                                    ? patientClassList
                                                    : input.name === "title_id"
                                                    ? titleAllList
                                                    : input.name === "gender_id"
                                                    ? genderAllList
                                                    : input.name ===
                                                      "country_id"
                                                    ? countryList
                                                    : input.name === "region_id"
                                                    ? regionList
                                                    : input.name ===
                                                      "nationality_id"
                                                    ? nationalityList
                                                    : input.name ===
                                                      "marital_status_id"
                                                    ? maritalStatusList
                                                    : input.name === "city_id"
                                                    ? cityList
                                                    : input.name ===
                                                      "religion_id"
                                                    ? religionList
                                                    : input.name ===
                                                      "blood_group_id"
                                                    ? bloodgrouplist
                                                    : input.name ===
                                                      "language_id"
                                                    ? languageList
                                                    : input.name ===
                                                      "income_range_id"
                                                    ? incomeRangeList
                                                    : input.name ===
                                                      "payment_mode_id"
                                                    ? paymentModeList[0]
                                                    : input.name ===
                                                      "nationality_id"
                                                    ? nationalityList
                                                    : input.name ===
                                                      "education_id"
                                                    ? educationList
                                                    : input.name ===
                                                      "occupation_id"
                                                    ? occupationList
                                                    : input.name === "ethnic_id"
                                                    ? ethnicList
                                                    : input.name ===
                                                      "industry_id"
                                                    ? industryList
                                                    : input.name ===
                                                      "referral_source_id"
                                                    ? referralSourceList
                                                    : []
                                                }
                                                onChange={handleChange}
                                                //value={input.name==="personal_id"?formData.personal_id:input.name==="mr_number"?formData.mr_number:input.name==="address"?formData.address:input.name==="title_id"?formData.title_id:input.name==="gender_id"?formData.gender_id:input.name==="country_id"?formData.country_id:input.name==="first_name"?formData.first_name:input.name==="father_name"?formData.father_name:input.name==="region_id"?formData.region_id:input.name==="middle_name"?formData.middle_name:input.name==="marital_status_id"?formData.marital_status_id:input.name==="city_id"?formData.city_id:input.name==="last_name"?formData.last_name:input.name==="religion_id"?formData.religion_id:input.name==="mobile"?formData.mobile:input.name==="arabic_name"?formData.arabic_name:input.name==="nationality_id"?formData.nationality_id:input.name==="secondary_mobile"?formData.secondary_mobile:input.name==="date_of_birth"?formData.date_of_birth:input.name==="blood_group_id"?formData.blood_group_id:input.name==="email"?formData.email:input.name==="s_email"?formData.s_email:input.name==="language_id"?formData.language_id:input.name==="passport_no"?formData.passport_no:input.name==="income_range_id"?formData.income_range_id:input.name==="payment_mode_id"?formData.payment_mode_id:input.name==="vip"?formData.vip:input.name==="demo_pat"?formData.demo_pat:input.name==="family"?formData.family:""}
                                                value={formData[input.name]}
                                                error={errors[input.name]}
                                              />
                                            )
                                          ) : input.type === "datePicker" ? (
                                            <>
                                              <DatePickers
                                                {...input}
                                                onChange={(date) =>
                                                  handleChange({
                                                    target: {
                                                      name: "date_of_birth",
                                                      value: date,
                                                    },
                                                  })
                                                }
                                                showFieldLoad={showFieldLoad}
                                                selected={formData[input.name]}
                                                value={formData[input.name]}
                                                error={errors[input.name]}
                                              />{" "}
                                            </>
                                          ) : input.type === "phone" ? (
                                            <InputPhone
                                              {...input}
                                              showFieldLoad={showFieldLoad}
                                              onChange={(value) =>
                                                handleChange({
                                                  target: {
                                                    name: input.name,
                                                    value: value,
                                                  },
                                                })
                                              }
                                              value={formData[input.name]}
                                              error={errors[input.name]}
                                            />
                                          ) : input.type === "radio" ? (
                                            <TextFeildRadioBox
                                              label={input.label}
                                              options={input.options}
                                              onChange={(e) =>
                                                handleOptionChange(
                                                  e,
                                                  input.name
                                                )
                                              }
                                              value={formData[input.name]}
                                              error={errors[input.name]}
                                            />
                                          ) : input.type === "age" ? (
                                            <TextInput
                                              {...input}
                                              value={formData[input.name]}
                                              showFieldLoad={showFieldLoad}
                                            />
                                          ) : (
                                            // <div class="wrap-field">
                                            //     <div className="box_age"> <div className="age_label">Age*</div>  <input type="text" className="ageBox" placeholder="yy" value={calculateAge(formData['date_of_birth'], "year")} readonly />{" / "}<input type="text" className="ageBox" placeholder="dd" readonly value={calculateAge(formData['date_of_birth'], "month")} />{" / "}<input type="text" className="ageBox" placeholder="dd" value={calculateAge(formData['date_of_birth'], "day")} readonly /></div>
                                            // </div>
                                            ""
                                          )}
                                        </div>
                                      ))}
                                  </div>
                                </div>
                              </TabPanel>
                            )
                          )}
                        </TabContext>
                      </div>
                    </div>

                    <div
                      className={`col-md-${
                        formData.payment_mode_id === "2" ? "12" : "12"
                      } col-xs-12 padding_fixed Tab_minHeight_detailTab`}
                    >
                      <div
                        className="bg_wrap b-radius m-tab tabBG relative contact-section"
                        style={{ marginBottom: "10px" }}
                      >
                        <TabContext value={detailTab}>
                          <TabList
                            onChange={handleDetailTab}
                            aria-label="Tab"
                            variant="scrollable"
                            scrollButtons={false}
                          >
                            {registrationUIDatas[0]?.registration[0]?.DetailsTab.map(
                              (labelList) => (
                                <Tab
                                  key={labelList.value}
                                  value={labelList.value}
                                  label={labelList.label}
                                />
                              )
                            )}
                          </TabList>
                          {registrationUIDatas[0]?.registration[0]?.DetailsTab.map(
                            (tab, index) => (
                              <TabPanel key={tab.value} value={tab.value}>
                                <div
                                  className="row no_margin"
                                  style={{
                                    display:
                                      tab.label === "Guardian"
                                        ? "block"
                                        : "none",
                                  }}
                                >
                                  <div className="col-md-12 col-xs-12 no_padding tableMinHeightTab Guardian">
                                    <Table
                                      key={GaurdianTableHead.id}
                                      columns={GaurdianTableHead}
                                      rowname={"id"}
                                      relationeditclick={
                                        handleOpenGuardianEditModal
                                      }
                                      relationdeleteclick={
                                        handleOpenGuardianDeleteModal
                                      }
                                      hide={true}
                                      rowdata={multipleGuardiandata}
                                      serialNoRequired={tab.serialNoRequired}
                                      actionStyle={{ width: "125px" }}
                                      headerStyle={{ minWidth: "100px" }}
                                      tableScrollHeight={"180px"}
                                    />
                                    <Popup
                                      show={showGuardianDeleteModal}
                                      handleClose={() =>
                                        setShowGuardianDeleteModal(false)
                                      }
                                      size="md"
                                    >
                                      <div className="text-start">
                                        <p className="fs-18 px-3">
                                          Are you sure to delete the Guardian?
                                        </p>

                                        <div class="row">
                                          <div className="modal-footer">
                                            <div className="d-flex justify-content-center">
                                              <Buttons.SaveButtons
                                                name="Yes"
                                                onClick={(e) =>
                                                  handleOpenGuardianDeleteData()
                                                }
                                              />
                                              <Buttons.ResetButtons
                                                name="No"
                                                onClick={() =>
                                                  handleCloseGuardianDeleteModal()
                                                }
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </Popup>
                                    {/* <DeleteModal
                                                                        show={showGuardianDeleteModal}
                                                                        handleClose={handleCloseGuardianDeleteModal}
                                                                        handleDelete={handleOpenGuardianDeleteData}
                                                                    /> */}
                                    <div className="right-align btn_bottom">
                                      <Buttons.AddButtons
                                        name="Add"
                                        onClick={handleAddPopUp}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="row no_margin"
                                  style={{
                                    display:
                                      tab.label === "Kin" ? "block" : "none",
                                  }}
                                >
                                  <div className="col-md-12 col-xs-12 no_padding tableMinHeightTab">
                                    <Table
                                      key={KinTableHead.id}
                                      columns={KinTableHead}
                                      rowname={"id"}
                                      relationeditclick={handleOpenKinEditModal}
                                      relationdeleteclick={
                                        handleOpenKinDeleteModal
                                      }
                                      hide={true}
                                      rowdata={multipleKindata}
                                      serialNoRequired={tab.serialNoRequired}
                                      actionStyle={{ width: "125px" }}
                                      headerStyle={{ minWidth: "100px" }}
                                      tableScrollHeight={"180px"}
                                    />
                                    <Popup
                                      show={showKinDeleteModal}
                                      handleClose={() =>
                                        setShowKinDeleteModal(false)
                                      }
                                      size="md"
                                    >
                                      <div className="text-start">
                                        <p className="fs-18 px-3">
                                          Are you sure to delete the Kin?
                                        </p>

                                        <div class="row">
                                          <div className="modal-footer">
                                            <div className="d-flex justify-content-center">
                                              <Buttons.SaveButtons
                                                name="Yes"
                                                onClick={(e) =>
                                                  handleRemoveKinDeleteData()
                                                }
                                              />
                                              <Buttons.ResetButtons
                                                name="No"
                                                onClick={() =>
                                                  handleCloseKinDeleteModal()
                                                }
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </Popup>
                                    {/* <DeleteModal
                                                                        show={showKinDeleteModal}
                                                                        handleClose={handleCloseKinDeleteModal}
                                                                        handleDelete={handleRemoveKinDeleteData}
                                                                    /> */}
                                    <div className="right-align btn_bottom">
                                      <Buttons.AddButtons
                                        name="Add"
                                        onClick={handleAddPopUp}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="row no_margin"
                                  style={{
                                    display:
                                      tab.label === "Guardian" ||
                                      tab.label === "Kin"
                                        ? "none"
                                        : "block",
                                  }}
                                >
                                  <div className="row no_margin no_padding emid-align">
                                    {tab.TabBody &&
                                      tab.TabBody.map((input, tabIndex) => (
                                        <div
                                          className={`col-md-${
                                            input.label === "Address" ||
                                            input.label === "P.O.Box"
                                              ? "6"
                                              : input.name === "email"
                                              ? "6"
                                              : input.label === "Emirates ID"
                                              ? "6"
                                              : input.label === "Unified No"
                                              ? "3"
                                              : input.label ===
                                                "How did you know about us?"
                                              ? "6"
                                              : input.label ===
                                                "Channel Source Of Referral"
                                              ? "6"
                                              : input.label === "Mother's EID"
                                              ? "3"
                                              : input.label === "Birth Order"
                                              ? "3"
                                              : input.label === "Expiry Date"
                                              ? "6"
                                              : input.name === "multiple_birth"
                                              ? "2"
                                              : input.label === "Birth Place"
                                              ? "3"
                                              : input.name === "front_page"
                                              ? "6"
                                              : input.name === "back_page"
                                              ? "6"
                                              : "3"
                                          } col-xs-12 padding_fixed`}
                                          key={input.id}
                                        >
                                          {input.type === "text" ? (
                                            <TextInput
                                              {...input}
                                              onChange={(e) =>
                                                subhandleChange(e, tab.label)
                                              }
                                              showFieldLoad={showFieldLoad}
                                              onClick={
                                                tab.label === "E.ID" &&
                                                input.name === "emirate_ids"
                                                  ? (e) =>
                                                      setShowAutoFillEmiratesModal(
                                                        true
                                                      )
                                                  : null
                                              }
                                              value={
                                                tab.label === "E.ID"
                                                  ? emiratedata[input.name]
                                                  : tab.label === "Others"
                                                  ? othersdata[input.name]
                                                  : tab.label ===
                                                    "Contact Details"
                                                  ? addressdata[input.name]
                                                  : tab.label === "Referral"
                                                  ? referraldata[input.name]
                                                  : ""
                                              }
                                              inputref={
                                                (tab.label === "E.ID" &&
                                                  input.name ===
                                                    "emirate_ids") ||
                                                (tab.label === "Others" &&
                                                  input.name === "mothers_eid")
                                                  ? inputRef
                                                  : null
                                              }
                                              onKeyDown={
                                                (tab.label === "E.ID" &&
                                                  input.name ===
                                                    "emirate_ids") ||
                                                (tab.label === "Others" &&
                                                  input.name === "mothers_eid")
                                                  ? (e) =>
                                                      handleKeyDown(
                                                        e,
                                                        tab.label
                                                      )
                                                  : null
                                              }
                                              onBlur={
                                                tab.label === "E.ID" &&
                                                input.name === "emirate_ids"
                                                  ? (e) => checkEmirateID(e)
                                                  : null
                                              }
                                              error={errors[input.name]}
                                            />
                                          ) : input.type === "select" ? (
                                            <Searchdropdown
                                              {...input}
                                              options={
                                                input.name === "country_id"
                                                  ? countryList
                                                  : input.name === "region_id"
                                                  ? regionList
                                                  : input.name === "city_id"
                                                  ? cityList
                                                  : input.name ===
                                                    "whatsup_number"
                                                  ? whatsupNumberList[0]
                                                  : input.name === "ethnic_id"
                                                  ? ethnicList
                                                  : input.name ===
                                                    "education_id"
                                                  ? educationList
                                                  : input.name ===
                                                    "occupation_id"
                                                  ? occupationList
                                                  : input.name ===
                                                    "referral_source_id"
                                                  ? referralSourceList
                                                  : input.name ===
                                                    "referral_channel_id"
                                                  ? referralChannelList
                                                  : []
                                              }
                                              onChange={(e) =>
                                                subhandleChange(e, tab.label)
                                              }
                                              showFieldLoad={showFieldLoad}
                                              value={
                                                tab.label === "Contact Details"
                                                  ? addressdata[input.name]
                                                  : tab.label === "Referral"
                                                  ? referraldata[input.name]
                                                  : ""
                                              }
                                              error={errors[input.name]}
                                            />
                                          ) : input.type === "datePicker" ? (
                                            <DatePickers
                                              {...input}
                                              onChange={(date) =>
                                                subhandleChange(
                                                  {
                                                    target: {
                                                      name: "expiry_date",
                                                      value: date,
                                                    },
                                                  },
                                                  tab.label
                                                )
                                              }
                                              showFieldLoad={showFieldLoad}
                                              selected={
                                                tab.label === "E.ID"
                                                  ? emiratedata[input.name]
                                                  : ""
                                              }
                                              value={
                                                tab.label === "E.ID"
                                                  ? emiratedata[input.name]
                                                  : ""
                                              }
                                              error={errors[input.name]}
                                            />
                                          ) : input.type === "phone" ? (
                                            <InputPhone
                                              {...input}
                                              showFieldLoad={showFieldLoad}
                                              onChange={(value, info) =>
                                                subhandleChange(
                                                  {
                                                    target: {
                                                      name: input.name,
                                                      value: value,
                                                    },
                                                  },
                                                  tab.label,
                                                  info
                                                )
                                              }
                                              value={
                                                tab.label === "Contact Details"
                                                  ? addressdata[input.name]
                                                  : ""
                                              }
                                              error={errors[input.name]}
                                            />
                                          ) : input.type === "checkBox" &&
                                            input.label ===
                                              "No Email available" ? (
                                            <Tooltip
                                              placement="top"
                                              title="This checkbox is disabled and operates automatically, requiring no manual adjustments."
                                            >
                                              <div>
                                                <TextFeildCheckBox
                                                  {...input}
                                                  onChange={(e) =>
                                                    subhandleChange(
                                                      e,
                                                      tab.label
                                                    )
                                                  }
                                                  checked={
                                                    tab.label ===
                                                    "Contact Details"
                                                      ? addressdata[input.name]
                                                      : othersdata[
                                                          input.name
                                                        ] == 1
                                                      ? true
                                                      : false
                                                  }
                                                />
                                              </div>
                                            </Tooltip>
                                          ) : input.type === "checkBox" ? (
                                            <TextFeildCheckBox
                                              {...input}
                                              onChange={(e) =>
                                                subhandleChange(e, tab.label)
                                              }
                                              checked={
                                                tab.label === "Contact Details"
                                                  ? addressdata[input.name]
                                                  : othersdata[input.name] == 1
                                                  ? true
                                                  : false
                                              }
                                            />
                                          ) : input.type === "file" ? (
                                            <Fileinput
                                              {...input}
                                              onChange={(e) =>
                                                subhandleChange(e, tab.label)
                                              }
                                            />
                                          ) : input.type === "textarea" ? (
                                            <TextArea
                                              {...input}
                                              onChange={(e) =>
                                                subhandleChange(e, tab.label)
                                              }
                                              value={
                                                tab.label === "Contact Details"
                                                  ? addressdata[input.name]
                                                  : ""
                                              }
                                            />
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      ))}
                                  </div>
                                </div>
                                <div
                                  className="minHeightPopUp"
                                  style={{
                                    display:
                                      tab.label === "Guardian" ||
                                      tab.label === "Kin"
                                        ? "block"
                                        : "none",
                                  }}
                                >
                                  <Popup
                                    show={openModal}
                                    handleClose={
                                      tab.label === "Guardian"
                                        ? gaurdianTab === 2
                                          ? handleClosePopUpGuardianCustom
                                          : handleClosePopUp
                                        : kinTab === 2
                                        ? handleClosePopUpKinCustom
                                        : handleClosePopUp
                                    }
                                    modalTitle={
                                      tab.label === "Guardian"
                                        ? "Edit Parent/Guardian Information"
                                        : "Edit Kin Information"
                                    }
                                    size="xl"
                                  >
                                    <TabContext
                                      value={
                                        tab.label === "Guardian"
                                          ? gaurdianTab
                                          : kinTab
                                      }
                                    >
                                      <TabList
                                        onChange={
                                          tab.label === "Guardian"
                                            ? handleGaurdianTab
                                            : handleKinTab
                                        }
                                        aria-label="Tab"
                                      >
                                        {tab.label === "Guardian"
                                          ? registrationUIDatas[0]?.registration[0]?.GaurdianTab.map(
                                              (labelList) => (
                                                <Tab
                                                  key={labelList.value}
                                                  value={labelList.value}
                                                  label={labelList.label}
                                                />
                                              )
                                            )
                                          : registrationUIDatas[0]?.registration[0]?.KinTab.map(
                                              (labelList) => (
                                                <Tab
                                                  key={labelList.value}
                                                  value={labelList.value}
                                                  label={labelList.label}
                                                />
                                              )
                                            )}
                                      </TabList>
                                      {tab.label === "Guardian"
                                        ? registrationUIDatas[0]?.registration[0]?.GaurdianTab.map(
                                            (tab, index) => (
                                              <TabPanel
                                                key={tab.value}
                                                value={tab.value}
                                              >
                                                {/*<div style={{ display: tab.label === "Guardian" || tab.label === "Kin" ? "none" : "block" }}>*/}
                                                <div className="row no_margin no_padding">
                                                  {/* <div className="row" > */}
                                                  {tab.TabBody &&
                                                    tab.TabBody.map(
                                                      (input, tabIndex) => (
                                                        <div
                                                          className={`col-md-3 col-xs-12 padding_fixed`}
                                                          key={input.id}
                                                        >
                                                          {input.type ===
                                                          "text" ? (
                                                            <TextInput
                                                              onClick={
                                                                input.name ===
                                                                "mr_number"
                                                                  ? popupSetToGuardianSearch
                                                                  : null
                                                              }
                                                              {...input}
                                                              showFieldLoad={
                                                                showFieldLoad
                                                              }
                                                              onChange={(e) =>
                                                                subhandleChange(
                                                                  e,
                                                                  tab.label
                                                                )
                                                              }
                                                              value={
                                                                guardiandata[
                                                                  input.name
                                                                ]
                                                              }
                                                              error={
                                                                errors[
                                                                  input.name
                                                                ]
                                                              }
                                                            />
                                                          ) : input.type ===
                                                            "select" ? (
                                                            <Searchdropdown
                                                              {...input}
                                                              options={
                                                                input.name ===
                                                                "country_id"
                                                                  ? countryList
                                                                  : input.name ===
                                                                    "region_id"
                                                                  ? regionList2
                                                                  : input.name ===
                                                                    "city_id"
                                                                  ? cityList2
                                                                  : input.name ===
                                                                    "relationship_id"
                                                                  ? relationshipList
                                                                  : input.name ===
                                                                    "center_name"
                                                                  ? clientList
                                                                  : input.name ===
                                                                    "search_by"
                                                                  ? input.options
                                                                  : []
                                                              }
                                                              showFieldLoad={
                                                                input.name ===
                                                                "region_id"
                                                                  ? regionLoader
                                                                  : input.name ===
                                                                    "city_id"
                                                                  ? cityLoader
                                                                  : false
                                                              }
                                                              value={
                                                                input.name ===
                                                                "center_name"
                                                                  ? searchhospitalid
                                                                  : guardiandata[
                                                                      input.name
                                                                    ]
                                                              }
                                                              onChange={
                                                                input.name ===
                                                                "search_by"
                                                                  ? (e) =>
                                                                      handleTextChange(
                                                                        e
                                                                      )
                                                                  : (e) =>
                                                                      subhandleChange(
                                                                        e,
                                                                        tab.label
                                                                      )
                                                              }
                                                              error={
                                                                errors[
                                                                  input.name
                                                                ]
                                                              }
                                                            />
                                                          ) : input.type ===
                                                            "datePicker" ? (
                                                            <DatePickers
                                                              showFieldLoad={
                                                                showFieldLoad
                                                              }
                                                              {...input}
                                                            />
                                                          ) : input.type ===
                                                            "phone" ? (
                                                            <InputPhone
                                                              {...input}
                                                              showFieldLoad={
                                                                showFieldLoad
                                                              }
                                                              onChange={(
                                                                value,
                                                                info
                                                              ) =>
                                                                subhandleChange(
                                                                  {
                                                                    target: {
                                                                      name: input.name,
                                                                      value:
                                                                        value,
                                                                    },
                                                                  },
                                                                  tab.label,
                                                                  info
                                                                )
                                                              }
                                                              value={
                                                                guardiandata[
                                                                  input.name
                                                                ]
                                                              }
                                                              error={
                                                                errors[
                                                                  input.name
                                                                ]
                                                              }
                                                            />
                                                          ) : input.type ===
                                                            "radio" ? (
                                                            <>
                                                              <TextFeildRadioBox
                                                                {...input}
                                                                label={
                                                                  input.label
                                                                }
                                                                options={
                                                                  input.options
                                                                }
                                                                onChange={(e) =>
                                                                  handleRadioChange(
                                                                    e
                                                                  )
                                                                }
                                                                // onChange = {(e) => handleRadioChange(e)}
                                                              />
                                                            </>
                                                          ) : input.type ===
                                                            "checkBox" ? (
                                                            <TextFeildCheckBox
                                                              {...input}
                                                              checked={
                                                                searchhospitalid ===
                                                                0
                                                                  ? true
                                                                  : false
                                                              }
                                                              onChange={
                                                                resetcenter
                                                              }
                                                            />
                                                          ) : (
                                                            ""
                                                          )}
                                                        </div>
                                                      )
                                                    )}
                                                  {/* </div> */}
                                                </div>
                                                <div className="row no_margin no_padding justify-content-start">
                                                  {activeRadioField &&
                                                    activeRadioField.map(
                                                      (input, tabIndex) => (
                                                        <div
                                                          className="col-md-4 col-xs-12 padding_fixed"
                                                          key={input.id}
                                                        >
                                                          {input.type ===
                                                          "text" ? (
                                                            <TextInput
                                                              {...input}
                                                              onChange={(e) =>
                                                                handleRadioValueModifier(
                                                                  e,
                                                                  input.label
                                                                )
                                                              }
                                                              showFieldLoad={
                                                                showFieldLoad
                                                              }
                                                              value={
                                                                filterFields[
                                                                  input.name
                                                                ]
                                                              }
                                                            />
                                                          ) : input.type ===
                                                            "phone" ? (
                                                            <InputPhone
                                                              {...input}
                                                              showFieldLoad={
                                                                showFieldLoad
                                                              }
                                                              onChange={(
                                                                value
                                                              ) =>
                                                                handleRadioValueModifier(
                                                                  {
                                                                    target: {
                                                                      name: input.name,
                                                                      value:
                                                                        value,
                                                                    },
                                                                  },
                                                                  input.label
                                                                )
                                                              }
                                                              value={
                                                                filterFields[
                                                                  input.name
                                                                ]
                                                              }
                                                            />
                                                          ) : (
                                                            ""
                                                          )}
                                                        </div>
                                                      )
                                                    )}
                                                </div>
                                                {gaurdianTab === 2 && (
                                                  <>
                                                    <Buttons.SearchButtons
                                                      name="Search"
                                                      onClick={searchsubmit}
                                                    />
                                                    {/*</div>*/}
                                                    <div
                                                      className="b-radius dashTable"
                                                      style={{
                                                        margin: "8px 0px",
                                                        padding: "0px 10px",
                                                      }}
                                                    >
                                                      <div className="container-fluid">
                                                        <div className="row">
                                                          <div className="col-md-12 col-xs-12 no_padding">
                                                            <Table
                                                              key={
                                                                GaurdiansearchTableHead.id
                                                              }
                                                              columns={
                                                                GaurdiansearchTableHead
                                                              }
                                                              search={true}
                                                              hide={true}
                                                              setsearch={
                                                                handlesearchresult
                                                              }
                                                              rowname={"id"}
                                                              rowdata={
                                                                searchGuardiandata
                                                              }
                                                            />
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </>
                                                )}
                                              </TabPanel>
                                            )
                                          )
                                        : registrationUIDatas[0]?.registration[0]?.KinTab.map(
                                            (tab, index) => (
                                              <TabPanel
                                                key={tab.value}
                                                value={tab.value}
                                              >
                                                {/*<div style={{ display: tab.label === "Guardian" || tab.label === "Kin" ? "none" : "block" }}>*/}
                                                <div className="row no_margin no_padding">
                                                  {/* <div className="row" > */}
                                                  {tab.TabBody &&
                                                    tab.TabBody.map(
                                                      (input, tabIndex) => (
                                                        <div
                                                          className="col-md-3 col-xs-12 padding_fixed"
                                                          key={input.id}
                                                        >
                                                          {input.type ===
                                                          "text" ? (
                                                            <TextInput
                                                              onClick={
                                                                input.name ===
                                                                "mr_number"
                                                                  ? popupSetToKinSearch
                                                                  : null
                                                              }
                                                              {...input}
                                                              showFieldLoad={
                                                                showFieldLoad
                                                              }
                                                              onChange={(e) =>
                                                                subhandleChange(
                                                                  e,
                                                                  tab.label
                                                                )
                                                              }
                                                              value={
                                                                kindata[
                                                                  input.name
                                                                ]
                                                              }
                                                              error={
                                                                errors[
                                                                  input.name
                                                                ]
                                                              }
                                                            />
                                                          ) : input.type ===
                                                            "select" ? (
                                                            <Searchdropdown
                                                              {...input}
                                                              options={
                                                                input.name ===
                                                                "country_id"
                                                                  ? countryList
                                                                  : input.name ===
                                                                    "region_id"
                                                                  ? regionList3
                                                                  : input.name ===
                                                                    "city_id"
                                                                  ? cityList3
                                                                  : input.name ===
                                                                    "relationship_id"
                                                                  ? relationshipList
                                                                  : input.name ===
                                                                    "center_name"
                                                                  ? clientList
                                                                  : input.name ===
                                                                    "search_by"
                                                                  ? input.options
                                                                  : []
                                                              }
                                                              showFieldLoad={
                                                                input.name ===
                                                                "region_id"
                                                                  ? regionLoader
                                                                  : input.name ===
                                                                    "city_id"
                                                                  ? cityLoader
                                                                  : false
                                                              }
                                                              value={
                                                                input.name ===
                                                                "center_name"
                                                                  ? searchhospitalid
                                                                  : kindata[
                                                                      input.name
                                                                    ]
                                                              }
                                                              onChange={
                                                                input.name ===
                                                                "search_by"
                                                                  ? (e) =>
                                                                      handleTextChange(
                                                                        e
                                                                      )
                                                                  : (e) =>
                                                                      subhandleChange(
                                                                        e,
                                                                        tab.label
                                                                      )
                                                              }
                                                              error={
                                                                errors[
                                                                  input.name
                                                                ]
                                                              }
                                                            />
                                                          ) : input.type ===
                                                            "datePicker" ? (
                                                            <DatePickers
                                                              showFieldLoad={
                                                                showFieldLoad
                                                              }
                                                              {...input}
                                                            />
                                                          ) : input.type ===
                                                            "phone" ? (
                                                            <InputPhone
                                                              {...input}
                                                              showFieldLoad={
                                                                showFieldLoad
                                                              }
                                                              onChange={(
                                                                value,
                                                                info
                                                              ) =>
                                                                subhandleChange(
                                                                  {
                                                                    target: {
                                                                      name: input.name,
                                                                      value:
                                                                        value,
                                                                    },
                                                                  },
                                                                  tab.label,
                                                                  info
                                                                )
                                                              }
                                                              value={
                                                                kindata[
                                                                  input.name
                                                                ]
                                                              }
                                                              error={
                                                                errors[
                                                                  input.name
                                                                ]
                                                              }
                                                            />
                                                          ) : input.type ===
                                                            "radio" ? (
                                                            <>
                                                              <TextFeildRadioBox
                                                                {...input}
                                                                label={
                                                                  input.label
                                                                }
                                                                options={
                                                                  input.options
                                                                }
                                                                onChange={(e) =>
                                                                  handleRadioChange(
                                                                    e
                                                                  )
                                                                }
                                                                // onChange = {(e) => handleRadioChange(e)}
                                                              />
                                                            </>
                                                          ) : input.type ===
                                                            "checkBox" ? (
                                                            <TextFeildCheckBox
                                                              {...input}
                                                              checked={
                                                                searchhospitalid ===
                                                                0
                                                                  ? true
                                                                  : false
                                                              }
                                                              onChange={
                                                                resetcenter
                                                              }
                                                            />
                                                          ) : (
                                                            ""
                                                          )}
                                                        </div>
                                                      )
                                                    )}
                                                  {/* </div> */}
                                                </div>
                                                {/*</div>*/}
                                                <div className="row no_margin no_padding justify-content-start">
                                                  {activeRadioField &&
                                                    activeRadioField.map(
                                                      (input, tabIndex) => (
                                                        <div
                                                          className="col-md-8 col-xs-12 padding_fixed"
                                                          key={input.id}
                                                        >
                                                          {input.type ===
                                                          "text" ? (
                                                            <TextInput
                                                              {...input}
                                                              showFieldLoad={
                                                                showFieldLoad
                                                              }
                                                              onChange={(e) =>
                                                                handleRadioValueModifier(
                                                                  e,
                                                                  input.label
                                                                )
                                                              }
                                                              value={
                                                                filterFields[
                                                                  input.name
                                                                ]
                                                              }
                                                            />
                                                          ) : input.type ===
                                                            "phone" ? (
                                                            <InputPhone
                                                              {...input}
                                                              showFieldLoad={
                                                                showFieldLoad
                                                              }
                                                              onChange={(
                                                                value
                                                              ) =>
                                                                handleRadioValueModifier(
                                                                  {
                                                                    target: {
                                                                      name: input.name,
                                                                      value:
                                                                        value,
                                                                    },
                                                                  },
                                                                  input.label
                                                                )
                                                              }
                                                              value={
                                                                filterFields[
                                                                  input.name
                                                                ]
                                                              }
                                                            />
                                                          ) : (
                                                            ""
                                                          )}
                                                        </div>
                                                      )
                                                    )}
                                                </div>
                                                {kinTab === 2 && (
                                                  <>
                                                    <Buttons.SearchButtons
                                                      name="Search"
                                                      onClick={searchsubmit}
                                                    />
                                                    {/*</div>*/}
                                                    <div
                                                      className="b-radius dashTable"
                                                      style={{
                                                        margin: "8px 0px",
                                                        padding: "0px 10px",
                                                      }}
                                                    >
                                                      <div className="container-fluid">
                                                        <div className="row">
                                                          <div className="col-md-12 col-xs-12 no_padding">
                                                            <Table
                                                              key={
                                                                kinsearchTableHead.id
                                                              }
                                                              columns={
                                                                kinsearchTableHead
                                                              }
                                                              search={true}
                                                              hide={true}
                                                              setsearch={
                                                                handlesearchresult
                                                              }
                                                              rowname={"id"}
                                                              rowdata={
                                                                searchKindata
                                                              }
                                                            />
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </>
                                                )}
                                              </TabPanel>
                                            )
                                          )}
                                      {gaurdianTab === 2 ||
                                      kinTab === 2 ? null : (
                                        <div class="row">
                                          <div className="modal-footer">
                                            {/*<div className="grid-child btn_bottom">*/}
                                            <div className="col-md-12 grid-child">
                                              <Buttons.SaveButtons
                                                name="Save"
                                                onClick={
                                                  tab.label === "Guardian"
                                                    ? addGuardianArray
                                                    : addKinArray
                                                }
                                              />
                                              <Buttons.ResetButtons
                                                name="Reset"
                                                onClick={handleReset}
                                              />
                                            </div>
                                            {/*</div>*/}
                                          </div>
                                        </div>
                                      )}
                                    </TabContext>
                                  </Popup>
                                </div>
                                <div
                                  style={{
                                    display:
                                      tab.label === "E.ID" ? "block" : "none",
                                  }}
                                >
                                  <div className="row no_margin">
                                    <div className="col-md-6 col-xs-12 padding_fixed">
                                      <div className="preview_box_wrap">
                                        {emiratedata.front_page !== "" &&
                                        emiratedata.front_page !==
                                          "default.png" ? (
                                          <Image
                                            src={
                                              editfrontImg
                                                ? URL.createObjectURL(
                                                    emiratedata.front_page
                                                  )
                                                : imagePath +
                                                  "" +
                                                  emiratedata.front_page
                                            }
                                            style={{
                                              width: "100%",
                                              height: "100%",
                                              justifyContent: "center",
                                            }}
                                            alt="Profile Preview"
                                          />
                                        ) : null}
                                      </div>
                                    </div>
                                    <div className="col-md-6 col-xs-12 padding_fixed">
                                      <div className="preview_box_wrap">
                                        {emiratedata.back_page !== "" &&
                                        emiratedata.back_page !==
                                          "default.png" ? (
                                          <Image
                                            src={
                                              editbackImg
                                                ? URL.createObjectURL(
                                                    emiratedata.back_page
                                                  )
                                                : imagePath +
                                                  "" +
                                                  emiratedata.back_page
                                            }
                                            style={{
                                              width: "100%",
                                              height: "100%",
                                              justifyContent: "center",
                                            }}
                                            alt="Profile Preview"
                                          />
                                        ) : null}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </TabPanel>
                            )
                          )}
                        </TabContext>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3 col-xs-12 padding_fixed">
                    <div className="row no_margin">
                      <div className="col-md-12 col-xs-12 padding_fixed">
                        <div
                          className="profile_box"
                          style={{ position: "relative" }}
                        >
                          {(editpreviewProfileImg !== "" &&
                            editpreviewProfileImg !== "default.png") ||
                          (formData.profile_image !== "" &&
                            formData.profile_image !== "default.png") ? (
                            <>
                              <Image
                                src={
                                  editProfileImg
                                    ? URL.createObjectURL(
                                        formData.profile_image
                                      )
                                    : imagePath + "" + editpreviewProfileImg
                                }
                                style={{
                                  maxWidth: "100%",
                                  height: "100%",
                                  justifyContent: "center",
                                }}
                                alt="Profile Preview"
                              />
                              <button
                                onClick={handleClearProfileImage}
                                className="cancel-icon"
                              >
                                {" "}
                                &#x2715;
                              </button>
                            </>
                          ) : (
                            <div className="dummyProfile">
                              {SVGICON.profileDummy}
                            </div>
                          )}
                        </div>
                      </div>

                      {formFeilds.patientLiveDetails &&
                        formFeilds.patientLiveDetails.map((input, idx) => (
                          <div
                            className="col-md-12 col-xs-12 padding_fixed patient-head"
                            key={idx}
                          >
                            {input.type === "text" ? (
                              <TextInput
                                {...input}
                                showFieldLoad={showFieldLoad}
                              />
                            ) : input.type === "file" ? (
                              <FileUploadButton
                                {...input}
                                onChange={handleChange}
                                reload={cancelProfile}
                              />
                            ) : input.type === "test" ? (
                              formData["date_of_birth"] && (
                                <div class="wrap-field">
                                  <FormLabel component="legend">
                                    Age (yy/mm/dd)*{" "}
                                    <input
                                      type="text"
                                      style={{ width: "45px", height: "35px" }}
                                      value={calculateAge(
                                        formData["date_of_birth"],
                                        "year"
                                      )}
                                      readonly
                                    />
                                    {" / "}
                                    <input
                                      type="text"
                                      style={{ width: "45px", height: "35px" }}
                                      readonly
                                      value={calculateAge(
                                        formData["date_of_birth"],
                                        "month"
                                      )}
                                    />
                                    {" / "}
                                    <input
                                      type="text"
                                      style={{ width: "45px", height: "35px" }}
                                      value={calculateAge(
                                        formData["date_of_birth"],
                                        "day"
                                      )}
                                      readonly
                                    />
                                  </FormLabel>
                                </div>
                              )
                            ) : (
                              ""
                            )}
                          </div>
                        ))}

                      <div className="col-md-12 col-xs-12 padding_fixed patient-head-margin b-radius tabBG" style={{ height: 'auto', minHeight: '100px' }}>
                        {formFeilds.patientLiveDetailsContd &&
                          formFeilds.patientLiveDetailsContd.map(
                            (input, idx) => (
                              <div
                                className="col-md-12 col-xs-12 padding_fixed"
                                key={idx}
                              >
                                {input.type === "text" ? (
                                  <TextInput
                                    {...input}
                                    showFieldLoad={showFieldLoad}
                                  />
                                ) : input.type === "select" ? (
                                  <Dropdown {...input} />
                                ) : input.type === "datePicker" &&
                                  formData.patient_status === "Deceased" ? (
                                    <div style={{ marginTop: '40px' }}> 
                                  <DatePickers
                                    {...input}
                                    onChange={(date) =>
                                      handleChange({
                                        target: {
                                          name: input.name,
                                          value: date,
                                        },
                                      })
                                    }
                                    value={formData[input.name]}
                                    selected={formData[input.name]}
                                    error={errors[input.name]}
                                  />
                                  </div>
                                ) : input.type === "file" ? (
                                  <FileUploadButton
                                    {...input}
                                    onChange={handleChange}
                                  />
                                ) : input.type === "radio" ? (
                                  <TextFeildRadioBox
                                    label={input.label}
                                    options={input.options}
                                    onChange={(e) =>
                                      handleOptionChange(e, input.name)
                                    }
                                    value={formData[input.name]}
                                    error={errors[input.name]}
                                  />
                                ) : input.type === "test" ? (
                                  formData["date_of_birth"] && (
                                    <div class="wrap-field">
                                      <FormLabel component="legend">
                                        Age (yy/mm/dd)*{" "}
                                        <input
                                          type="text"
                                          style={{
                                            width: "45px",
                                            height: "35px",
                                          }}
                                          value={calculateAge(
                                            formData["date_of_birth"],
                                            "year"
                                          )}
                                          readonly
                                        />
                                        {" / "}
                                        <input
                                          type="text"
                                          style={{
                                            width: "45px",
                                            height: "35px",
                                          }}
                                          readonly
                                          value={calculateAge(
                                            formData["date_of_birth"],
                                            "month"
                                          )}
                                        />
                                        {" / "}
                                        <input
                                          type="text"
                                          style={{
                                            width: "45px",
                                            height: "35px",
                                          }}
                                          value={calculateAge(
                                            formData["date_of_birth"],
                                            "day"
                                          )}
                                          readonly
                                        />
                                      </FormLabel>
                                    </div>
                                  )
                                ) : (
                                  ""
                                )}
                              </div>
                            )
                          )}
                      </div>
                      <div class="center-align residant-id mt-2 padding_fixed">
                        <Buttons.ImageTextButton
                          image={EIA}
                          width={50}
                          name="Scan Resident Id"
                          onClick={openScanResidentPopup}
                          styles={{
                            border: "5px solid #397b65",
                            backgroundColor: "#397b6514",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ))}
          </div>
        </div>
        {formData.payment_mode_id == "2" ? (
          <div
            className={`col-md-${
              formData.payment_mode_id === "2" ? "9" : "9"
            } col-xs-12 padding_fixed Tab_minHeight`}
          >
            <div className="bg_wrap b-radius m-tab tabBG overflow iconColortheme">
              <TabContext value={insuranceTab}>
                <TabList onChange={handleInsuranceTab} aria-label="Tab">
                  {registrationUIDatas[0]?.registration[0]?.InsuranceTab.map(
                    (labelList) => (
                      <Tab
                        key={labelList.value}
                        value={labelList.value}
                        label={labelList.label}
                      />
                    )
                  )}
                </TabList>
                {registrationUIDatas[0]?.registration[0]?.InsuranceTab.map(
                  (tab, index) => (
                    <TabPanel key={tab.value} value={tab.value}>
                      <div
                        className="row no_margin no_padding"
                        id="insurance-validator1"
                      >
                        {viewInsurance ? (
                          <>
                            {/* on implementation of Private Company, have to copy this map function below to seperate Private Company fields to avoid unreadability of code */}
                            {tab.TabBody &&
                              tab.TabBody.map(
                                (input, tabIndex) =>
                                  tabIndex <= 7 && (
                                    <div
                                      className={`col-md-${
                                        input.col ||
                                        (tab.label === "Insurance Details"
                                          ? "4"
                                          : "6")
                                      } col-xs-12 padding_fixed flex`}
                                    >
                                      {input.type === "text" && (
                                        <TextInput
                                          {...input}
                                          value={insurancedata[input.name]}
                                          onChange={(e) =>
                                            handleInsuranceChange(e, tab.label)
                                          }
                                        />
                                      )}

                                      {input.type === "select" &&
                                      input.label === "Company Type" ? (
                                        <>
                                          <Searchdropdown
                                            {...input}
                                            options={companytypelist}
                                            value={insurancedata[input.name]}
                                            onChange={(e) =>
                                              handleInsuranceChange(
                                                e,
                                                tab.label
                                              )
                                            }
                                          />
                                        </>
                                      ) : input.label ===
                                        "Insurance Company" ? (
                                        <>
                                          <Searchdropdown
                                            {...input}
                                            options={maincompanylist}
                                            value={insurancedata[input.name]}
                                            onChange={(e) =>
                                              handleInsuranceChange(
                                                e,
                                                tab.label
                                              )
                                            }
                                          />
                                        </>
                                      ) : input.label === "Payer" ? (
                                        <>
                                          <Searchdropdown
                                            options={subcompanylist}
                                            {...input}
                                            value={insurancedata[input.name]}
                                            onChange={(e) =>
                                              handleInsuranceChange(
                                                e,
                                                tab.label
                                              )
                                            }
                                          />
                                          {/* <Buttons.NewButton name="New" /> */}
                                          <Buttons.SaveIcon
                                            icon={SVGICON.save}
                                            onClick={() =>
                                              window.open(
                                                "/#/insurance",
                                                "_blank"
                                              )
                                            }
                                          />
                                        </>
                                      ) : input.label === "Benifit Package" ? (
                                        <>
                                          <Searchdropdown
                                            options={benefitlist}
                                            onChange={(e) =>
                                              handleInsuranceChange(
                                                e,
                                                tab.label
                                              )
                                            }
                                            value={insurancedata[input.name]}
                                            {...input}
                                          />
                                          <Buttons.SaveIcon
                                            icon={SVGICON.save}
                                            onClick={() =>
                                              window.open(
                                                "/#/benefit",
                                                "_blank"
                                              )
                                            }
                                          />
                                        </>
                                      ) : input.label === "Company Name" ? (
                                        <>
                                          <Searchdropdown
                                            {...input}
                                            value={insurancedata[input.name]}
                                            onChange={(e) =>
                                              handleInsuranceChange(
                                                e,
                                                tab.label
                                              )
                                            }
                                          />
                                        </>
                                      ) : (
                                        []
                                      )}
                                      {input.type === "datePicker" && (
                                        <DatePickers
                                          {...input}
                                          value={insurancedata[input.name]}
                                          onChange={(e) =>
                                            handleInsuranceChange(e, tab.label)
                                          }
                                        />
                                      )}
                                      {input.type === "phone" && (
                                        <InputPhone
                                          {...input}
                                          value={insurancedata[input.name]}
                                          onChange={(e) =>
                                            handleInsuranceChange(e, tab.label)
                                          }
                                        />
                                      )}
                                      {input.type === "checkBox" && (
                                        <TextFeildCheckBox
                                          {...input}
                                          value={insurancedata[input.name]}
                                          onChange={(e) =>
                                            handleInsuranceChange(e, tab.label)
                                          }
                                        />
                                      )}
                                      {input.type === "file" && (
                                        <Fileinput
                                          {...input}
                                          value={insurancedata[input.name]}
                                          onChange={(e) =>
                                            handleInsuranceChange(e, tab.label)
                                          }
                                        />
                                      )}
                                    </div>
                                  )
                              )}
                            <div className="row no_margin no_padding align_item">
                              <div className="col-md-7 no_padding">
                                <div
                                  className="col-md-12 col-xs-12 padding_fixed scroll-x"
                                  style={{
                                    display:
                                      tab.label === "Insurance Company"
                                        ? "block"
                                        : "none",
                                  }}
                                >
                                  {/* <InputTable formData={insurancetableData} datas={ServiceCategorylist} handletableInputChange={handletableInputChange} /> */}
                                  {/* <Table
                                                                key={DepartmentListTableHead.id}
                                                                columns={DepartmentListTableHead}
                                                                rowname={"id"}
                                                            
                                                                hide={true}
                                                                rowdata={multipleDepartmentListdata}
                                                                serialNoRequired={true}

                                                                checkboxchange={checkboxChange}
                                                                handleInputChange={handleInputChange}

                                                            /> */}
                                  <ServiceCategoryTable
                                    key={DepartmentListTableHead.id}
                                    columns={DepartmentListTableHead}
                                    rowname={"id"}
                                    rowdata={plandetaildata}
                                    serialNoRequired={true}
                                    nodataalignment="center"
                                    checkboxchange={checkboxChangeSC}
                                    handleInputChange={handleInputChangeSC}
                                    listingInfo={{
                                      listingLabel: "category_list",
                                      listingField: "category",
                                      headerCheckbox: false,
                                      dataCheckbox: true,
                                    }}
                                  />
                                </div>
                              </div>
                              <div
                                className={`col-md-5 padding_fixed`}
                                id="insurance-validator2"
                              >
                                {/* Right column with remaining fields and buttons */}
                                {tab.TabBody &&
                                  tab.TabBody.map(
                                    (input, tabIndex) =>
                                      tabIndex > 7 && (
                                        // <div className={`col-md-${(input.col || input.label === "Expiry Date") ? "6" : "12"} col-xs-12 no_padding flex`}>
                                        <div
                                          className={`col-md-${
                                            input.label === "Active"
                                              ? "4"
                                              : input.label === "Expiry Date" ||
                                                input.label === "Max.Ceiling" ||
                                                input.label === "Ded. Amt" ||
                                                input.label === "Co-Pay" ||
                                                input.label ===
                                                  "Co pay on all services" ||
                                                input.label ===
                                                  "With Consultation" ||
                                                input.label === "Card No" ||
                                                input.label === "dummy"
                                              ? "6"
                                              : "8"
                                          } col-xs-12 ${
                                            input.type !== "checkBox"
                                              ? "flex no_padding"
                                              : "d-flex"
                                          }`}
                                          style={{
                                            float:
                                              input.label === "Expiry Date" ||
                                              input.label === "Card No" ||
                                              input.label === "Max.Ceiling" ||
                                              input.label === "Ded. Amt" ||
                                              input.label === "Co-Pay" ||
                                              input.label ===
                                                "Co pay on all services" ||
                                              input.label ===
                                                "With Consultation"
                                                ? "left"
                                                : "none",
                                            width:
                                              input.label === "Expiry Date" ||
                                              input.label === "Max.Ceiling" ||
                                              input.label === "Co-Pay" ||
                                              input.label === "Company Name" ||
                                              input.label ===
                                                "Application No" ||
                                              input.label === "Vessel Name" ||
                                              input.label === "Rank" ||
                                              input.label ===
                                                "Co pay on all services" ||
                                              input.label ===
                                                "With Consultation"
                                                ? "50%"
                                                : input.label === "Ded. Amt"
                                                ? "49%"
                                                : input.label === "Card No"
                                                ? "70%"
                                                : input.label === "Ded. Amt"
                                                ? "49%"
                                                : input.label === "Active"
                                                ? "30%"
                                                : input.label ===
                                                  "With Consultation"
                                                ? "56%"
                                                : "100%",
                                            marginRight:
                                              input.label === "Ded. Amt"
                                                ? "1%"
                                                : "0",
                                          }}
                                        >
                                          <>
                                            {input.type === "text" && (
                                              <TextInput
                                                {...input}
                                                value={
                                                  insurancedata[input.name]
                                                }
                                                onChange={(e) =>
                                                  handleInsuranceChange(
                                                    e,
                                                    tab.label
                                                  )
                                                }
                                                onBlur={
                                                  input.label === "Card No"
                                                    ? (e) =>
                                                        checkPolicyNumber(e)
                                                    : null
                                                }
                                              />
                                            )}
                                            {input.type === "select" &&
                                            input.label === "Network" ? (
                                              <>
                                                <Searchdropdown
                                                  {...input}
                                                  options={networklist}
                                                  value={
                                                    insurancedata[input.name]
                                                  }
                                                  onChange={(e) =>
                                                    handleInsuranceChange(
                                                      e,
                                                      tab.label
                                                    )
                                                  }
                                                />
                                                <Buttons.SaveIcon
                                                  icon={SVGICON.searchDoc}
                                                  onClick={() =>
                                                    window.open(
                                                      "/#/network",
                                                      "_blank"
                                                    )
                                                  }
                                                />
                                              </>
                                            ) : input.label === "Plan Type" ? (
                                              <>
                                                <Searchdropdown
                                                  {...input}
                                                  options={networkplanlist}
                                                  value={
                                                    insurancedata[input.name]
                                                  }
                                                  onChange={(e) =>
                                                    handleInsuranceChange(
                                                      e,
                                                      tab.label
                                                    )
                                                  }
                                                />
                                                <Buttons.SaveIcon
                                                  icon={SVGICON.folderOpen}
                                                  onClick={() =>
                                                    window.open(
                                                      "/#/plan-details-list",
                                                      "_blank"
                                                    )
                                                  }
                                                />
                                              </>
                                            ) : (
                                              []
                                            )}
                                            {input.type === "datePicker" && (
                                              <DatePickers
                                                {...input}
                                                value={
                                                  insurancedata[input.name]
                                                }
                                                onChange={(date) =>
                                                  handleInsuranceChange(
                                                    {
                                                      target: {
                                                        name: input.name,
                                                        value: date,
                                                      },
                                                    },
                                                    tab.label
                                                  )
                                                }
                                              />
                                            )}
                                            {input.type === "phone" && (
                                              <InputPhone {...input} />
                                            )}
                                            {input.type === "checkBox" &&
                                              input.label !==
                                                "With Consultation" && (
                                                <TextFeildCheckBox
                                                  {...input}
                                                  checked={
                                                    insurancedata[input.name] ==
                                                    1
                                                      ? true
                                                      : false
                                                  }
                                                  value={
                                                    insurancedata[input.name]
                                                  }
                                                  onChange={(e) =>
                                                    handleInsuranceChange(
                                                      e,
                                                      tab.label
                                                    )
                                                  }
                                                />
                                              )}
                                            {input.type === "checkBox" &&
                                              input.label ===
                                                "With Consultation" &&
                                              insurancedata.co_pay_all_service ==
                                                "1" && (
                                                <TextFeildCheckBox
                                                  {...input}
                                                  checked={
                                                    insurancedata[input.name] ==
                                                    "1"
                                                      ? true
                                                      : false
                                                  }
                                                  value={
                                                    insurancedata[input.name]
                                                  }
                                                  onChange={(e) =>
                                                    handleInsuranceChange(
                                                      e,
                                                      tab.label
                                                    )
                                                  }
                                                />
                                              )}
                                            {input.type === "file" && (
                                              <Fileinput {...input} />
                                            )}
                                          </>
                                        </div>
                                      )
                                  )}
                              </div>

                              {/* Buttons */}
                              <div
                                className="col-md-12 col-xs-12 padding_fixed"
                                style={{ textAlign: "right" }}
                              >
                                {tab.label === "Insurance Company" ? (
                                  <>
                                    {/* <Buttons.SaveButtons name="Save" onClick={AskSavePatientOpenModalController} disable={(patient_id1===0)?true:false} /> */}
                                    <Buttons.SaveButtons
                                      name="SAVE INSURANCE"
                                      onClick={insuranceSubmitController}
                                      disable={patient_id1 === 0 ? true : false}
                                    />
                                    <Buttons.ViewList
                                      name="View List"
                                      onClick={toggleViewInsuranceList}
                                    />
                                    <Buttons.UpdateButtons
                                      name="Add New"
                                      onClick={resetInsuranceData}
                                    />
                                  </>
                                ) : null}
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            {tab.label === "Insurance Company" && (
                              <div className="row no_margin no_padding align_item">
                                <div className="col-md-12 no_padding insurance-view-list-table">
                                  <Table
                                    key={ViewInsuranceDetailsTableHead.id}
                                    columns={ViewInsuranceDetailsTableHead}
                                    rowname={"id"}
                                    hide={true}
                                    enablerowselect={true}
                                    handleRowClick={handleRowClick}
                                    rowdata={multipleinsurancedata}
                                    selectedRow={selectedRow}
                                  />
                                </div>
                                <div
                                  className="col-md-6 col-xs-12 padding_fixed"
                                  style={{
                                    textAlign: "left",
                                    position: "absolute",
                                    bottom: 0,
                                    left: "10px",
                                  }}
                                >
                                  <div>
                                    <div
                                      style={{
                                        marginTop: "20px",
                                        padding: "10px",
                                      }}
                                    >
                                      <p style={{ lineHeight: "20px" }}>
                                        <strong>Note:</strong> You can add
                                        multiple Insurance Companies for a
                                        Patient. (OR) You can also add multiple
                                        plans to one Insurance Company.
                                      </p>
                                    </div>
                                    <Buttons.UpdateButtons name="Update Plans" />
                                  </div>
                                </div>
                                <div
                                  className="col-md-6 col-xs-12 padding_fixed"
                                  style={{
                                    textAlign: "right",
                                    position: "absolute",
                                    bottom: 0,
                                    right: "10px",
                                  }}
                                >
                                  <>
                                    <Buttons.AddButtons
                                      name="Add"
                                      onClick={toggleViewInsuranceList}
                                    />
                                    <Buttons.EditButtons
                                      name="Edit"
                                      onClick={editinsurance}
                                    />
                                    <Buttons.DeleteButtons
                                      name="Delete"
                                      disable={
                                        multipleinsurancedata.length <= 1
                                          ? true
                                          : false
                                      }
                                      bigIcon
                                      onClick={() =>
                                        setShowDeleteInsuranceModal(true)
                                      }
                                    />
                                  </>
                                </div>
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    </TabPanel>
                  )
                )}
              </TabContext>
            </div>
          </div>
        ) : null}

        <div className="grid-child magrinTB">
          <div className="col-md-8 grid-child">
            {/* without Modal -- here the 3rd parameter is true, so isInsurance is always true but payment_mode_id helps to make the condition works properly */}
            <Buttons.SaveButtons
              onClick={(e) => formsubmit(e, 0, true)}
              name="Save Patient"
              disable={!checkChanges}
            />
            {/* <Buttons.EditButtons onClick={(e) => formsubmit(e, 1, true)} name="Save Patient And Continue" disable={!checkChanges} /> */}
            {/* To invoke Modal use below formSubmitController */}
            {/* <Buttons.SaveButtons onClick={(e) => formSubmitController(e, 0)} name="Save Patient" disable={!checkChanges} />
                        <Buttons.EditButtons onClick={(e) => formSubmitController(e, 1)} name="Save Patient And Continue" disable={!checkChanges} /> */}
            <Buttons.ResetButtons
              name="Reset"
              onClick={handleAllFormReset}
              disabled={!checkChanges}
            />
            <Buttons.BackButton
              name="close"
              className="close-btn"
              onClick={() => AskNavigation()}
            />
          </div>
        </div>
        <Popup
          show={showAutoFillEmiratesModal}
          handleClose={() => setShowAutoFillEmiratesModal(false)}
          size="md"
          modalTitle={"Resident ID Information"}
        >
          <div className="text-start">
            <div className="fs-18 px-3 emirates-radio-option">
              <TextFeildRadioBox
                // {...input}
                label=""
                options={EmiratesOptions}
                onChange={(e) => handleEmiratesOptionChange(e)}
                // onChange = {(e) => handleRadioChange(e)}
              />
            </div>
          </div>
        </Popup>
        <Popup
          show={showCopayConditionedModal}
          handleClose={() => setShowCopayConditionedModal(false)}
          size="md"
        >
          <div className="text-start">
            <p className="fs-18 px-3">
              You have entered co-insurance percentage.
              <br />
              Please select whether copay for all services or enter the copay
              for a particular service.
            </p>

            <div class="row">
              <div className="modal-footer">
                <div className="d-flex justify-content-center">
                  <Buttons.ResetButtons
                    name="Ok"
                    onClick={(e) => setShowCopayConditionedModal(false)}
                  />
                </div>
              </div>
            </div>
          </div>
        </Popup>
        <Popup
          show={showDeleteInsuranceModal}
          handleClose={() => setShowDeleteInsuranceModal(false)}
          size="md"
        >
          <div className="text-start">
            <p className="fs-18 px-3">Are you sure to delete the Insurance?</p>

            <div class="row">
              <div className="modal-footer">
                <div className="d-flex justify-content-center">
                  <Buttons.SaveButtons
                    name="Yes"
                    onClick={(e) => deleteinsurance()}
                  />
                  <Buttons.ResetButtons
                    name="No"
                    onClick={() => closeInsuranceModal()}
                  />
                </div>
              </div>
            </div>
          </div>
        </Popup>
        <Popup
          show={showSaveaAndCloseModal}
          handleClose={() => setShowSaveaAndCloseModal(false)}
          size="md"
        >
          <div className="text-start">
            <p className="fs-18 px-3">
              Do you want to save your changes and close this tab?
            </p>
            <p className="fs-18 px-3">
              Yes - Save changes and close <br /> No - Save changes only
            </p>

            <div class="row">
              <div className="modal-footer">
                <div className="d-flex justify-content-center">
                  <Buttons.SaveButtons
                    name="Yes"
                    onClick={(e) => formsubmit(e, 0, true, 0)}
                  />
                  <Buttons.ResetButtons
                    name="No"
                    onClick={(e) => formsubmit(e, 0, true, 1)}
                  />
                </div>
              </div>
            </div>
          </div>
        </Popup>
        <Popup
          show={saveCheckInsuranceConfirmModal}
          handleClose={() => setSaveCheckInsuranceConfirmModal(false)}
          size="md"
        >
          <div className="text-center">
            <p className="fs-18">
              You have unsaved changes to the insurance plan. Please click 'Save
              Insurance' button.
            </p>

            <div class="row">
              <div className="modal-footer">
                <div className="d-flex justify-content-center">
                  <Buttons.ResetButtons
                    name="Ok"
                    onClick={() => setSaveCheckInsuranceConfirmModal(false)}
                  />
                </div>
              </div>
            </div>
          </div>
        </Popup>
        {/* <SaveAndCloseModal
                show={showModal}
                handleClose={handleClose}
                handleConfirm={handleConfirm}
                size="md"
                className="save-and-close-modal"
            /> */}

        <Popup
          show={showConfirmEditModal}
          handleClose={() => setConfirmEditModal(false)}
          size="md"
        >
          <div className="text-center">
            <p className="fs-18">Do you want to edit the plan details?</p>

            <div class="row">
              <div className="modal-footer">
                <div className="d-flex justify-content-center">
                  <Buttons.SaveButtons
                    name="Yes"
                    onClick={() => insuranceSubmitController()}
                  />
                  <Buttons.ResetButtons
                    name="No"
                    onClick={() => handleCloseConfirmEditModal()}
                  />
                </div>
              </div>
            </div>
          </div>
        </Popup>

        <Popup
          show={CoPayOpenModal}
          handleClose={() => setCoPayOpenModal(false)}
          size="md"
        >
          <div className="text-center">
            <p className="fs-18">
              Co-Insurance of{" "}
              {insurancedata.co_pay_amount ? insurancedata.co_pay_amount : 0}%
              will be deducted from all services
            </p>

            <div class="row">
              <div className="modal-footer">
                <div className="d-flex justify-content-center">
                  <Buttons.SaveButtons
                    name="Ok"
                    onClick={() => setCoPayOpenModal(false)}
                  />
                </div>
              </div>
            </div>
          </div>
        </Popup>

        <Popup
          show={AskNavigationOpenModal}
          handleClose={() => setAskNavigationOpenModal(false)}
          size="md"
        >
          <div className="text-center">
            <p className="fs-18">
              Do you want to close, You have some unsaved changes ?
            </p>

            <div class="row">
              <div className="modal-footer">
                <div className="d-flex justify-content-center">
                  <Buttons.SaveButtons
                    name="Yes"
                    onClick={() => AskNavigationCloseModal()}
                  />
                  <Buttons.ResetButtons
                    name="No"
                    onClick={() => setAskNavigationOpenModal(false)}
                  />
                </div>
              </div>
            </div>
          </div>
        </Popup>
        <Popup
          show={scanResidentPopup}
          handleClose={() => closeScanResidentPopup(false)}
          size="xl"
          modalTitle={"Emirates ID List"}
        >
          <div className="modal-header bg-primary reg-head">
            <div className="emirates-section">
              <h3>List of scanned Emirates ID</h3>
            </div>
          </div>

          <div className="modal-body add-reg-popup">
            <Table
              key={ResidantTableHead.id}
              columns={ResidantTableHead}
              rowname={"id"}
              hide={true}
              rowdata={multipleGuardiandata}
              isPaginator={false}
            />
          </div>
          <div className="text-center modal-footer-section">
            <div className="row w-100">
              <div className="col-md-12 col-xs-12 padding_fixed">
                <div className="row no_margin">
                  {registrationUIDatas[0].registration[0].scanResident &&
                    registrationUIDatas[0].registration[0].scanResident.map(
                      (input) => (
                        <div className={`col-md-3 col-xs-12`} key={input.id}>
                          {input.type === "datePicker" ? (
                            <>
                              <DatePickers
                                {...input}
                                onChange={(date) => {}}
                                showFieldLoad={showFieldLoad}
                                selected={scanresidentdata[input.name]}
                                value={scanresidentdata[input.name]}
                                error={errors[input.name]}
                              />{" "}
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                      )
                    )}

                  <div className={`col-md-2 col-xs-12`}>
                    <Buttons.CancelButtons name="Load Data"></Buttons.CancelButtons>
                  </div>

                  <div className="col-md-2 col-xs-12 p-0 mt-2">
                    <span className="fs-small">
                      **Double click to select from the list"
                    </span>
                  </div>

                  <div className={`col-md-2 col-xs-12 text-md-end`}>
                    <Buttons.BackButton
                      name="Close"
                      className="close-btn"
                      onClick={() => closeScanResidentPopup()}
                    />
                  </div>

                  {/* <div className='col-md-6 col-xs-12'>
                                        <div className='row no_margin'>
                                            <div className={`col-md-5 col-xs-12`}>
                                                <Buttons.CancelButtons name="Load Data"></Buttons.CancelButtons>
                                            </div>

                                            <div className='col-md-3 col-xs-12'>
                                                <span class="load-data-text">**Double click to select from the list"</span>
                                            </div>

                                            <div className={`col-md-3 col-xs-12`}>
                                                <Buttons.BackButton name="Close" className="close-btn" onClick={() => setToggleOnlineReferralModal(false)} />
                                            </div>
                                        </div> 
                                    </div> */}
                </div>
              </div>
            </div>
            {/* <div class="row">
                            <div className="modal-footer">
                                <div className="row d-flex justify-content-center align-items-center">                                	
                                        <div className="col-6 pr-0 pl-0 d-flex justify-content-center align-items-center"> 
                                            <span className='d-flex col-md-6 res-date-picker'>
                                                <span className='res-date-picker-label'>From Date</span>                                 
                                                     <DatePickers showFieldLoad={showFieldLoad}
                                                      {...input}
                                                    />
                                            </span>    
                                            <span className='d-flex col-md-6 res-date-picker'>
                                                <span className='res-date-picker-label label-to'>To</span>                                 
                                                <DatePickers showFieldLoad={showFieldLoad}
                                                {...input}
                                                />
                                            </span> 	                             
						            	</div>                       
                                        <div className="col-5 d-flex pr-0 pl-0 res-date-picker">
                                            {/* <button class="bg-primary-btn">Load Data</button> */}
            {/* <Buttons.SaveButtons name="Load Data"></Buttons.SaveButtons>
                                            <span class="load-data-text">**Double click to select from the list"</span>
                                        </div> 
                                        <div className="col-1 pr-0 pl-0 res-date-picker res-close-btn">   
                                        <button type="button" class="btn-close" aria-label="Close"></button>                             
                                            <Buttons.CancelButtons name="Close" className="close-btn" onClick={() => setToggleOnlineReferralModal(false)} />
                                        </div>
                                </div>
                            </div>
                        </div> */}
          </div>
        </Popup>
        <Popup
          show={AskSavePatientOpenModal}
          handleClose={() => setAskSavePatientOpenModal(false)}
          size="md"
        >
          <div className="text-center">
            <p className="fs-18">
              New patient details found. Click "Yes" to Save and proceed with
              insurance. Click "No" to Save and proceed without insurance.
            </p>

            <div class="row">
              <div className="modal-footer">
                <div className="d-flex justify-content-center">
                  <Buttons.SaveButtons
                    name="Yes"
                    onClick={() => AskSavePatientCloseModal(saveType, true)}
                  />
                  <Buttons.ResetButtons
                    name="No"
                    onClick={() => AskSavePatientCloseModal(saveType, false)}
                  />
                </div>
              </div>
            </div>
          </div>
        </Popup>

        <Popup
          show={existInsuranceDataModal}
          handleClose={() => setExistInsuranceDataModal(false)}
          size="md"
        >
          <div className="text-center">
            <p className="fs-18">
              Do you want to edit the Network name or create new one under the
              same Insurance?
            </p>
            <p className="fs-18">
              Yes - Edit same Network: No - Create New Network
            </p>
            <div class="row">
              <div className="modal-footer">
                <div className="d-flex justify-content-center">
                  <Buttons.SaveButtons
                    name="Yes"
                    onClick={() => UpdateInsuranceData()}
                  />
                  <Buttons.ResetButtons
                    name="No"
                    onClick={() => AskToCreateAsNewPlan()}
                  />
                </div>
              </div>
            </div>
          </div>
        </Popup>

        <Popup
          show={askNewPlanModal}
          handleClose={() => setAskNewPlanModal(false)}
          size="md"
        >
          <div className="text-center">
            <p className="fs-18">Set up as a new plan?</p>

            <div class="row">
              <div className="modal-footer">
                <div className="d-flex justify-content-center">
                  <Buttons.SaveButtons
                    name="Yes"
                    onClick={() => createNewPlan()}
                  />
                  <Buttons.ResetButtons
                    name="No"
                    onClick={() => setAskNewPlanModal(false)}
                  />
                </div>
              </div>
            </div>
          </div>
        </Popup>
        {/* Online Registration */}
        <Popup
          show={toggleOnlineReferralModal}
          handleClose={() => setToggleOnlineReferralModal(false)}
          modalTitle={"Online Referral"}
          size="xl"
          draggable={true}
        >
          <TabContext value={onlineReferralTab}>
            <TabList onChange={handleOnlineReferralTab} aria-label="Tab">
              {registrationUIDatas[0]?.registration[0]?.OnlineReferralTab.map(
                (labelList) => (
                  <Tab
                    key={labelList.value}
                    value={labelList.value}
                    label={labelList.label}
                  />
                )
              )}
            </TabList>

            <TabPanel key={onlineReferralTab} value={onlineReferralTab}>
              <div className="row no_margin no_padding">
                {registrationUIDatas[0]?.registration[0]?.OnlineReferralTab &&
                  registrationUIDatas[0]?.registration[0]?.OnlineReferralTab.map(
                    (tab, tabIndex) => (
                      <div>
                        <div className="row">
                          {tab.TabBody &&
                            tab.TabBody.map((input, inputIndex) => (
                              <div
                                className={`col-md-3 col-xs-12 padding_fixed`}
                                key={input.id}
                              >
                                {input.type === "datePicker" ? (
                                  <DatePickers
                                    custommindate={
                                      input.name === "to_date"
                                        ? onlinereferraldata["from_date"]
                                        : null
                                    }
                                    onChange={(date) =>
                                      handleOnlineReferralChange({
                                        target: {
                                          name: input.name,
                                          value: date,
                                        },
                                      })
                                    }
                                    showFieldLoad={showFieldLoad}
                                    value={onlinereferraldata[input.name]}
                                    {...input}
                                  />
                                ) : null}
                              </div>
                            ))}
                          <Buttons.SearchButtons
                            name="Search"
                            onClick={searchonlinereferralsubmit}
                          />
                        </div>
                        <div
                          className="b-radius dashTable"
                          style={{
                            margin: "8px 0px",
                            padding: "0px 10px",
                            overflowX: "scroll",
                          }}
                        >
                          <div className="container-fluid">
                            <div className="row">
                              <div className="col-md-12 col-xs-12 no_padding">
                                <Table
                                  key={OnlineReferralTableHead.id}
                                  columns={OnlineReferralTableHead}
                                  hide={true}
                                  rowname={"id"}
                                  isPaginator={false}
                                  rowdata={onlinereferraltabledata}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  )}
              </div>
            </TabPanel>
          </TabContext>

          <div class="row">
            <div className="modal-footer">
              <div className="d-flex justify-content-center">
                <Buttons.CancelButtons
                  name="Close"
                  onClick={() => setToggleOnlineReferralModal(false)}
                />
              </div>
            </div>
          </div>
        </Popup>
        {/* phone enquiry Registration */}
        <Popup
          show={togglePhoneCallRegModal}
          handleClose={() => {
            if (switchPhoneCallRegModal === 2) {
              setSwitchPhoneCallRegModal(1);
            } else if (switchPhoneCallRegModal === 1) {
              setTogglePhoneCallRegModal(false);
              setSwitchPhoneCallRegModal(0);
            }
          }}
          bigpopup={true}
          modalTitle={"Phone Call Registartion"}
          size="xl"
          draggable={true}
        >
          {switchPhoneCallRegModal === 1 ? (
            <AppointmentList
              setopenform={setopenform}
              setSwitchPhoneCallModel={setSwitchPhoneCallRegModal}
              seteditpid={seteditpid}
            />
          ) : switchPhoneCallRegModal === 2 ? (
            <AppointmentBook
              setopenform={setopenform}
              setSwitchPhoneCallModel={setSwitchPhoneCallRegModal}
              editpid={editpid}
            />
          ) : (
            <></>
          )}
          {/* <div class="row">
                        <div className="modal-footer">
                            <div className="d-flex justify-content-center">
                                <Buttons.CancelButtons name="Close" onClick={() => setTogglePhoneCallRegModal(false)} />
                            </div>
                        </div>
                    </div> */}
        </Popup>
        {/* < ToastContainer /> */}
        <DeleteModal
          show={showDeleteModal}
          // handleClose={handleCloseDeleteModal}
          // handleDelete={handleDeleteInsurancePlanDetailsAction}
        />
      </div>
      {loading ? <Loader /> : null}
    </>
  );
};
export default Registration;
