import React from "react"; 
import { Form } from "react-bootstrap";

export const tabData = [
		{
			name: "Apptmnt",
			icon: "home",    
			// content2:"Raw denim you probably haven't heard of them jean shorts Austin. Nesciunt tofu stumptown aliqua, retro synth master cleanse. Mustache cliche tempor, williamsburg carles vegan helvetica. ",
			content:
				<div>
					<div className="row">
						<div className="col-xl-6">
							<Form.Group className="form-group" controlId="clinic">
								<Form.Label>Clinic</Form.Label>
								<Form.Control
									as="select"
									name="clinic"
									value=""
									onChange=""
								>
									<option value="" >Select Clinic</option>
									<option value="clinic1">clinic 1</option>
									<option value="clinic2">clinic 2</option>
								</Form.Control>
							</Form.Group>
							{/*{errors.income && (<div className="text-danger fs-12">{errors.income}</div>)}*/}
						</div>
					</div>
					<div className="row">
						<div className="col-xl-6">
							<Form.Group className="form-group" controlId="doctor">
								<Form.Label>Doctor</Form.Label>
								<Form.Control
									as="select"
									name="doctor"
									value=""
									onChange=""
								>
									<option value="" >Select Doctor</option>
									<option value="doctor1">doctor 1</option>
									<option value="doctor2">doctor 2</option>
								</Form.Control>
							</Form.Group>
							{/*{errors.income && (<div className="text-danger fs-12">{errors.income}</div>)}*/}
						</div>
					</div>
					<Form.Label>App.Time</Form.Label>
					<div className="row">
						<div className="col-xl-2">
							<Form.Group controlId="FromTime">
								<Form.Label>From</Form.Label>
								<Form.Control
									type="time"
									name="fromTIme"
									value=""
									onChange=""
								/>
							</Form.Group>
						</div>
								{/*{errors.income && (<div className="text-danger fs-12">{errors.income}</div>)}*/}
						<div className="col-xl-2">
							<Form.Group controlId="ToTime">
								<Form.Label>To</Form.Label>
								<Form.Control
									type="time"
									name="toTime"
									value=""
									onChange=""
								/>
							</Form.Group>
						</div>
						<div className="col-xl-2">
							<button
								type="button"
								className="btn btn-primary light"
								onClick=""
							>
								Apply Current Time
							</button>
						</div>
					</div>
				</div>,
		},
		{
			name: "Guardian",
			icon: "user",
			content:"",

		},
		{
			name: "Referral",
			icon: "phone",
			content:
				<div>
					<div className="row">
						<div className="col-xl-6">
							<Form.Group className="form-group" controlId="knowAboutUs">
								<Form.Label>How do you know about us ?</Form.Label>
								<Form.Control
									as="select"
									name="knowAboutUs"
									value=""
									onChange=""
								>
									<option value="" >Select</option>
									<option value="refferel1">refferel 1</option>
									<option value="refferel2">refferel 2</option>
								</Form.Control>
							</Form.Group>
							{/*{errors.income && (<div className="text-danger fs-12">{errors.income}</div>)}*/}
						</div>
					</div>
					<div className="row">
						<div className="col-xl-6">
							<Form.Group className="form-group" controlId="chanalSource">
								<Form.Label>Chanal / Source of Refferel</Form.Label>
								<Form.Control
									as="select"
									name="chanalSource"
									value=""
									onChange=""
								>
									<option value="" >Select</option>
									<option value="chanalSource1">Chanal/Source 1</option>
									<option value="chanalSource2">Chanal/Source 2</option>
								</Form.Control>
							</Form.Group>
							{/*{errors.income && (<div className="text-danger fs-12">{errors.income}</div>)}*/}
						</div>
					</div>

					<h6>Outside Refferel Doctor</h6>
					<div className="row">
						<div className="col-xl-6">
							<Form.Group className="form-group" controlId="doctor">
								<Form.Label>Doctor</Form.Label>
								<Form.Control
									as="select"
									name="doctor"
									value=""
									onChange=""
								>
									<option value="" >Select Doctor</option>
									<option value="doctor1">doctor 1</option>
									<option value="doctor2">doctor 2</option>
								</Form.Control>
							</Form.Group>
							{/*{errors.income && (<div className="text-danger fs-12">{errors.income}</div>)}*/}
						</div>
					</div>
					<div className="row">
						<div className="col-xl-6 form-group">
							<Form.Group controlId="refferelClinic">
								<Form.Label>Clinic Name</Form.Label>
								<Form.Control
									type="text"
									name="refferelClinic"
									value=""
									onChange=""
								/>
							</Form.Group>
						</div>
					</div>
					<div className="row">
						<div className="col-xl-6 form-group">
							<Form.Group controlId="refferelLicenseNo">
								<Form.Label>License No</Form.Label>
								<Form.Control
									type="text"
									name="refferelLicenseNo"
									value=""
									onChange=""
								/>
							</Form.Group>
						</div>
					</div>
				</div>,
		},
	
		{
			name: "Others",
			icon: "envelope",
			content:
				<div>
					<div className="row">
						<div className="col-xl-6 form-group">
							<Form.Group controlId="unifiedNo">
								<Form.Label>Unified No</Form.Label>
								<Form.Control
									type="text"
									name="unifiedNo"
									value=""
									onChange=""
								/>
							</Form.Group>
						</div>
					</div>
					<div className="row">
						<div className="col-xl-6 form-group">
							<Form.Group controlId="motherEID">
								<Form.Label>Mother's EID</Form.Label>
								<Form.Control
									type="text"
									name="motherEID"
									value=""
									onChange=""
								/>
							</Form.Group>
						</div>
					</div>
					<div className="row">
						<div className="col-xl-6 form-group">
							<Form.Group controlId="multipleBirth">

								<input type="checkbox" className="form-check-input" id="multipleBirth"/>
								<label className="form-check-label" htmlFor="multipleBirth" style={{marginTop:'2%'}}>Multiple Birth</label>
							</Form.Group>
						</div>
					</div>
					<div className="row">
						<div className="col-xl-6 form-group">
							<Form.Group controlId="birthOrder">
								<Form.Label>Birth Order</Form.Label>
								<Form.Control
									type="text"
									name="birthOrder"
									value=""
									onChange=""
								/>
							</Form.Group>
						</div>
					</div>
					<div className="row">
						<div className="col-xl-6 form-group">
							<Form.Group controlId="birthPlace">
								<Form.Label>Birth Place</Form.Label>
								<Form.Control
									type="text"
									name="birthPlace"
									value=""
									onChange=""
								/>
							</Form.Group>
						</div>
					</div>
				</div>,
		},
		{
			name: "Resident ID",
			icon: "envelope",
			content2:"Raw denim you probably haven't heard of them jean shorts Austin. Nesciunt tofu stumptown aliqua, retro synth master cleanse. Mustache cliche tempor, williamsburg carles vegan helvetica. ",
			content:
				"Raw denim you probably haven't heard of them jean shorts Austin. Nesciunt tofu stumptown aliqua, retro synth master cleanse. Mustache cliche tempor.      ",
		},
		{
			name: "Next of Kin",
			icon: "envelope", 
			content2:"Raw denim you probably haven't heard of them jean shorts Austin. Nesciunt tofu stumptown aliqua, retro synth master cleanse. Mustache cliche tempor, williamsburg carles vegan helvetica. ",
			content:
				"Raw denim you probably haven't heard of them jean shorts Austin. Nesciunt tofu stumptown aliqua, retro synth master cleanse. Mustache cliche tempor.      ",
		},
	];