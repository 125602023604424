// store/actions/cityActionTypes.js

export const REGION_ACTION_TYPES = {
    SET_DETAILS_DATA: 'SET_DETAILS_DATA',
    SET_REGION_TITLE:"SET_REGION_TITLE",
    SET_REGION_SHORTNAME:"SET_REGION_SHORTNAME",
    SET_REGION_ID:"SET_REGION_ID",
    SET_COUNTRY_ID:"SET_COUNTRY_ID",
    SET_LOADING:"SET_LOADING",
    SET_OPEN_MODAL:"SET_OPEN_MODAL",
    SET_SHOW_DELETE_MODAL:"SET_SHOW_DELETE_MODAL"
};
  