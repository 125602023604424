
const baseURL = 'http://api.unicaredev.in/api';


//Auth
export const loginURL = baseURL+'/v1/login';
export const loginWithCenterNameURL = baseURL+'/v1/signin';
export const logoutURL = baseURL+'/user/Logout';
export const changePasswordURL = baseURL+'/user/UpdatePassword';
export const sendOtpResetPasswordURL = baseURL+'/user/SendOtpResetPassword';
export const resetUserPasswordURL = baseURL+'/user/ResetUserPassword';
export const getCenterListURL =baseURL+'/user/GetCenterList';

export const profileURL = baseURL+'/user/GetProfile';
export const getClientPostURL = baseURL+'/hospital/CreateClient';
export const updateClientPostURL = baseURL+'/hospital/UpdateClient';
export const getUserPostURL = baseURL+'/user/CreateUser';
export const updateUserPostURL = baseURL+'/user/UpdateUser';
export const getUserListDataURL = baseURL+'/user/GetUserListData';
export const getUserSingleDataURL = baseURL+'/user/GetSingleUser';
export const getHealthAuthorityRegionListURL = baseURL+'/master/GetHealthAuthorityRegionList';
export const getClientDataURL = baseURL+'/hospital/GetClientDetailData';
export const getSingleClientDataURL = baseURL+'/hospital/GetSingleClient';

//master
export const getCountryURL = baseURL+'/master/GetCountryList';
export const getRegionListURL = baseURL+'/master/GetRegionList';
export const getCityListURL = baseURL+'/master/GetCityList';
export const getAllCityListURL = baseURL+'/master/GetAllCityList';
export const getAllRegionListURL =baseURL+'/master/GetAllRegionList';
export const getGenderListURL =baseURL+'/master/GetGenderList';
export const getAllDepartmentCategoryListURL =baseURL+'/master/GetDeptCategoryList';
export const getClientListURL =baseURL+'/hospital/GetClientList';
export const getDepartmentListURL =baseURL+'/master/GetDepartmentList';
export const getRoleListURL =baseURL+'/master/GetRoleList';
export const getTitleListURL =baseURL+'/master/GetTitleList';
export const getJobTitlListURL =baseURL+'/master/GetJobTitlList';
export const getAllDepartmentListURL =baseURL+'/master/GetAllDepartmentList';
export const getNationalityListURL =baseURL+'/master/GetNationalityList';
export const getMaritalStatusListURL =baseURL+'/master/GetMaritalStatusList';
export const getPatientClassListURL =baseURL+'/master/GetPatientClassList';
export const getOccupationListURL =baseURL+'/master/GetOccupationList';
export const getEducationListURL =baseURL+'/master/GetEducationList';
export const getReligionListURL =baseURL+'/master/GetReligionList';
export const getEthnicListURL =baseURL+'/master/GetEthnicList';
export const getIndustryListURL =baseURL+'/master/GetIndustryList';
export const getLanguageListURL =baseURL+'/master/GetLanguageList';
export const getIncomeRangeListURL =baseURL+'/master/GetIncomeRangeList';
export const getBloodGroupListURL =baseURL+'/master/GetBloodGroupList';
export const getRelationshipListURL =baseURL+'/master/GetRelationshipList';
export const getReferralSourceListURL =baseURL+'/master/GetReferralSourceList';
export const getReferralChannelListURL =baseURL+'/master/GetReferralChannelList';
export const viewNetworkListURL =baseURL+'/mastertwo/ViewNetworkList';
export const viewInsPackageListURL =baseURL+'/mastertwo/ViewInsPackageList';
export const getChargeTypeListURL =baseURL+'/master/GetChargeTypes';
export const getCompanyTypeListURL =baseURL+'/master/GetCompanyTypes';
export const viewInsPlanListURL =baseURL+'/mastertwo/ViewInsPlanList';
export const viewInsPlanDetailsListURL =baseURL+'/mastertwo/ViewInsPlanDetail';
export const viewServiceCategoryListBySelectedPlanURL =baseURL+'/mastertwo/GetServiceCategory';
export const viewSmsTemplateListURL =baseURL+'/mastertwo/ViewSmsTemplate';
export const viewAppointmentStatusListURL =baseURL+'/mastertwo/ViewAppointmentStatus';
export const addCityURL =baseURL+'/master/AddNewCity';
export const addJobTitleURL =baseURL+'/master/AddNewJobTitle';
export const addRegionURL =baseURL+'/master/AddNewRegion';
export const addNewDeptCategoryURL =baseURL+'/master/AddNewCategory';
export const addNewDepartmentURL =baseURL+'/master/AddNewDepartment';
export const addTitleURL =baseURL+'/master/AddNewTitle';
export const addNewNationalityURL =baseURL+'/master/AddNewNationality';
export const addNewMaritalStatusURL =baseURL+'/master/AddNewMaritalStatus';
export const addNewPatientClassURL =baseURL+'/master/AddNewPatientClass';
export const addNewOccupationURL =baseURL+'/master/AddNewOccupation';
export const addNewServiceURL =baseURL+'/mastertwo/AddEnquiryService';
export const addNewReasonURL =baseURL+'/mastertwo/AddEnquiryReason';
export const AddPatientEnquiryURL =baseURL+'/enquiry/AddPatientEnquiry';
export const UpdatePatientEnquiryURL =baseURL+'/enquiry/UpdatePatientEnquiry';
export const DeletePatientEnquiryURL =baseURL+'/enquiry/DeletePatientEnquiry';
export const GetShiftListURL =baseURL+'/master/GetShiftList';
export const GetDoctorMasterListURL =baseURL+'/user/ViewDoctorList';
export const viewAppointmentListURL =baseURL+'/appointment/ViewAppointment';
export const getSingleAppointmentURL =baseURL+'/appointment/GetSingleAppointment';
export const viewBlockAppointmentListURL =baseURL+'/appointment/ViewBlockAppointment';
export const GetBlockAppointmentListURL =baseURL+'/appointment/GetSingleBlockAppointment';


export const GetSinglePatientEnquiryURL =baseURL+'/enquiry/GetSinglePatientEnquiry';
export const ViewPatientEnquiryURL =baseURL+'/enquiry/ViewPatientEnquiry';
export const AddReferralDoctorURL =baseURL+'/mastertwo/AddReferralDoctor';
export const addNewEducationURL =baseURL+'/master/AddNewEducation';
export const addNewReligionURL =baseURL+'/master/AddNewReligion';
export const addNewEthnicURL =baseURL+'/master/AddNewEthnic';
export const addNewLanguageURL =baseURL+'/master/AddNewLanguage';
export const addNewIncomeRangeURL =baseURL+'/master/AddNewIncomeRange';
export const addNetworkURL =baseURL+'/mastertwo/AddNetwork';
export const addInsPackageURL =baseURL+'/mastertwo/AddInsPackage';
export const addInsPlanURL =baseURL+'/mastertwo/AddInsPlan';
export const addPlanDetailsURL =baseURL+'/mastertwo/AddInsPlanDetail';
export const addSmsTemplateURL =baseURL+'/mastertwo/AddSMSTemplate';
export const addAppointmentStatusURL =baseURL+'/mastertwo/AddAppointmentStatus';
export const addShiftURL =baseURL+'/master/AddNewShift';
export const addBlockAppointmentURL =baseURL+'/appointment/AddBlockAppointment';
export const enableAdminAccessURL =baseURL+'/master/EnableAdminAccess';
export const getAdminAccessNurseURL =baseURL+'/master/GetAdminAccessNurse';
export const removeAdminAccessURL =baseURL+'/master/RemoveAdminAccess';


export const GetDoctorListURL =baseURL+'/user/GetDoctorList';
export const GetStaffListURL =baseURL+'/user/GetStaffList';
export const GetReferralDoctorListURL =baseURL+'/mastertwo/GetReferralDoctorList';
export const GetReferralClinicListURL =baseURL+'/mastertwo/GetReferralClinicList';
export const GetEnquiryServiceListURL =baseURL+'/mastertwo/EnquiryServiceList';
export const GetEnquiryReasonListURL =baseURL+'/mastertwo/EnquiryReasonList';
export const updateCityURL =baseURL+'/master/UpdateCity';
export const updateRegionURL =baseURL+'/master/UpdateRegion';
export const updateTitleURL =baseURL+'/master/UpdateTitle';
export const updateJobTitleURL =baseURL+'/master/UpdateJobTitle';
export const updateCategoryURL =baseURL+'/master/UpdateCategory';
export const updateDepartmentURL =baseURL+'/master/UpdateDepartment';
export const updateNationalityURL =baseURL+'/master/UpdateNationality';
export const updatePatientClassURL =baseURL+'/master/UpdatePatientClass';
export const updateOccupationURL =baseURL+'/master/UpdateOccupation';
export const updateServiceURL =baseURL+'/mastertwo/UpdateEnquiryService';
export const updateReasonURL =baseURL+'/mastertwo/UpdateEnquiryReason';
export const UpdateReferralDoctorURL =baseURL+'/mastertwo/UpdateReferralDoctor';
export const ViewReferralDoctorURL =baseURL+'/mastertwo/ViewReferralDoctor';
export const updateEducationURL =baseURL+'/master/UpdateEducation';
export const updateReligionURL =baseURL+'/master/UpdateReligion';
export const updateEthnicURL =baseURL+'/master/UpdateEthnic';
export const updateLanguageURL =baseURL+'/master/UpdateLanguage';
export const updateIncomeRangeURL =baseURL+'/master/UpdateIncomeRange';
export const updateNetworkURL =baseURL+'/mastertwo/UpdateNetwork';
export const updateInsPackageURL =baseURL+'/mastertwo/UpdateInsPackage';
export const updateInsPlanURL =baseURL+'/mastertwo/UpdateInsPlan';
export const updateInsPlanDetailURL =baseURL+'/mastertwo/UpdateInsPlanDetail';
export const updateSmsTemplateURL =baseURL+'/mastertwo/UpdateSMSTemplate';
export const updateAppointmentStatusURL =baseURL+'/mastertwo/UpdateAppointmentStatus';
export const updateShiftURL =baseURL+'/master/UpdateShift';
export const updateDoctorSettingsURL =baseURL+'/master/UpdateDoctorSetting';
export const updateBlockAppointmentURL =baseURL+'/appointment/UpdateBlockAppointment';
export const GetVisitTypeListURL =baseURL+'/master/GetVisitTypeList';
export const GetExamDropdownListURL =baseURL+'/master/GetExamList';
export const GetStatusDropdownListURL =baseURL+'/mastertwo/GetAppointmentStatusList';



export const deleteCityURL =baseURL+'/master/DeleteCity';
export const deleteRegionURL =baseURL+'/master/DeleteRegion';
export const deleteTitleURL =baseURL+'/master/DeleteTitle';
export const deleteJobTitleURL =baseURL+'/master/DeleteJobTitle';
export const deleteCategoryURL =baseURL+'/master/DeleteCategory';
export const deleteDepartmentURL =baseURL+'/master/DeleteDepartment';
export const deleteNationalityURL =baseURL+'/master/DeleteNationality';
export const deletePatientClassURL =baseURL+'/master/DeletePatientClass';
export const deleteOccupationURL =baseURL+'/master/DeleteOccupation';
export const deleteReasonURL =baseURL+'/mastertwo/DeleteReason';
export const deleteReferralDoctorURL =baseURL+'/mastertwo/DeleteReferralDoctor';
export const deleteServiceURL =baseURL+'/mastertwo/DeleteService';
export const deleteEducationURL =baseURL+'/master/DeleteEducation';
export const deleteReligionURL =baseURL+'/master/DeleteReligion';
export const deleteEthnicURL =baseURL+'/master/DeleteEthnic';
export const deleteLanguageURL =baseURL+'/master/DeleteLanguage';
export const deleteIncomeRangeURL =baseURL+'/master/DeleteIncomeRange';
export const deleteNetworkURL =baseURL+'/mastertwo/DeleteNetwork';
export const deleteInsPackageURL =baseURL+'/mastertwo/DeleteInsPackage';
export const deleteInsPlanURL =baseURL+'/mastertwo/DeleteInsPlan';
export const deleteInsPlanDetailURL =baseURL+'/mastertwo/DeleteInsPlanDetail';
export const deleteInsPlanDetailDescURL =baseURL+'/mastertwo/DeletePlanDesc';
export const deleteSmsTemplateURL =baseURL+'/mastertwo/DeleteSmsTemplate';
export const deleteAppointmentStatusURL =baseURL+'/mastertwo/DeleteAppointmentStatus';
export const deleteShiftURL =baseURL+'/master/DeleteShift';
export const deleteBlockAppointmentURL =baseURL+'/appointment/DeleteBlockAppointment';

export const masterGetSignalDataURL =baseURL+'/master';
export const masterGetSignalDataURL1 =baseURL+'/mastertwo';

// Menu
export const createMenuURL =baseURL+'/mainmenu/CreateMainMenu';
export const updateMenuURL =baseURL+'/mainmenu/UpdateMainMenu';
export const getMenuDataURL =baseURL+'/mainmenu/MainMenuList';
export const getSingleMainMenuURL =baseURL+'/mainmenu/GetSingleMainMenu'; // /{1} need to pass for single
export const reorderMainMenuURL =baseURL+'/mainmenu/ReorderMainMenu';
export const deleteMainMenuURL =baseURL+'/mainmenu/DeleteMainMenu ';
export const getRoleWiseMainMenuURL =baseURL+'/mainmenu/GetRoleWiseMainMenu ';
export const assignMainMenuURL =baseURL+'/mainmenu/AssignMainMenu ';

// dashboard menu
export const getDashboardMenuDataURL =baseURL+'/dashboard/DashboardMenuList';
export const createDashboardMenuURL =baseURL+'/dashboard/CreateDashboardMenu';
export const updateDashboardMenuURL =baseURL+'/dashboard/UpdateDashboardMenu';
export const getSingleDashboardMenuURL =baseURL+'/dashboard/GetSingleDashboardMenu'; // /{1} need to pass for single
export const reorderDashboardMenuURL =baseURL+'/dashboard/ReorderDashboardMenu';
export const deleteDashboardModuleURL =baseURL+'/dashboard/DeleteDashboardModule';
export const getRoleWiseDashboardMenuURL =baseURL+'/dashboard/GetRoleWiseDashboardMenu';
export const assignDashboardMenuURL =baseURL+'/dashboard/AssignDashboardMenu';
export const displayDashboardMenuURL =baseURL+'/dashboard/DisplayDashboardMenu';

//Submenu
export const GetSideMenuList =baseURL+'/submenu/GetSideMenuList';
export const subMenuListURL =baseURL+'/submenu/SubMenuList';
export const createSubMenuURL =baseURL+'/submenu/CreateSubMenu';
export const updateSubMenuURL =baseURL+'/submenu/UpdateSubMenu';
export const getSingleSubMenuURL =baseURL+'/submenu'; // /{1} need to pass for single
export const deleteSubMenuURL =baseURL+'/submenu/DeleteSubMenu'; 
export const reorderSubMenuURL =baseURL+'/submenu/ReorderSubMenu'; 
export const getRoleWiseSubMenuURL =baseURL+'/submenu/GetRoleWiseSubMenu'; 
export const assignSubMenuURL =baseURL+'/submenu/AssignSubMenu'; 
export const getMenuWiseSubMenuURL =baseURL+'/submenu/GetMenuWiseSubMenu'; 

//create patient
export const CreatePatientURL =baseURL+'/patient/CreatePatient';
export const UpdatePatientURL =baseURL+'/patient/UpdatePatient';
export const GetSinglePatientURL =baseURL+'/patient/GetSinglePatient';
export const SavePatientSubdDataURL =baseURL+'/patient/SavePatientSubdData';
export const SavePatientInsuranceURL =baseURL+'/patient/SavePatientInsurance';
export const DeletePatientInsuranceURL =baseURL+'/patient/DeletePatientInsurance';
export const SaveReferralURL =baseURL+'/patient/SaveReferral';
export const ViewPatientURL =baseURL+'/patient/ViewPatientList';
export const CheckPolicyNumberURL =baseURL+'/patient/CheckPolicyNumber';
export const CheckMrNumberURL =baseURL+'/patient/CheckMrNumber';
export const CheckEmirateIdURL =baseURL+'/patient/CheckEmirateId';
export const SearchPatientRelationURL =baseURL+'/patient/SearchPatientRelation';
export const GetInsCompanyListURL =baseURL+'/master/GetInsCompanyList';
export const GetInsMainCompanyListURL =baseURL+'/master/GetInsMainCompanyList';
export const GetInsSubCompanyListURL =baseURL+'/master/GetInsSubCompanyList';
export const GetBenefitPackageCompanyWiseURL = baseURL+'/mastertwo/GetPackageInsCompanyWise';
export const GetNetworkInsCompanyWiseURL =baseURL+'/mastertwo/GetNetworkInsCompanyWise';
export const GetPlanNetworkWiseURL =baseURL+'/mastertwo/GetPlanNetworkWise';
export const GetNetworkPlansCompanyWiseURL =baseURL+'/mastertwo/GetNetworkPlans';
export const CopyInsurancePlanDetailsURL =baseURL+'/mastertwo/CopyPlanDetail';
export const AddNewInsCompanyURL =baseURL+'/master/AddNewInsCompany';
export const ViewInsCompanyURL =baseURL+'/master/ViewInsCompany';
export const GetSingleInsCompanyURL =baseURL+'/master/GetSingleInsCompany';
export const UpdateInsCompanyURL =baseURL+'/master/UpdateInsCompany';
export const UpdateInsCompanyStatusURL =baseURL+'/master/UpdateInsCompanyStatus';

export const SaveHospitalSettingURL =baseURL+'/hospital/SaveHospitalSetting';
export const SaveBookAppointmentURL =baseURL+'/appointment/BookAppointment';
export const updateBookAppointmentURL =baseURL+'/appointment/UpdateAppointment';
export const DeleteBookAppointmentURL =baseURL+'/appointment/DeleteAppointment';
export const SearchPatientNameURL =baseURL+'/patient/SearchPatientName';
export const AddWaitingListURL =baseURL+'/enquiry/AddWaitingList';
export const ViewWaitingListURL =baseURL+'/enquiry/ViewWaitingList';
export const AddServiceURL =baseURL+'/enquiry/AddPhoneEnquiry';




