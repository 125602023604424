import axios from 'axios';
import Swal from "sweetalert2";
import {
    loginConfirmedAction,
    Logout,
} from '../store/actions/AuthActions';
import { 
    loginURL, 
    sendOtpResetPasswordURL,
    resetUserPasswordURL,
    loginWithCenterNameURL
} from '../jsx/constant/configuration';

export function signUp(email, password) {
    //axios call
    const postData = {
        email,
        password,
        returnSecureToken: true,
    };
    return axios.post(
        `https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=AIzaSyD3RPAp3nuETDn9OQimqn_YF6zdzqWITII`,
        postData,
    );
}

export function login(email, password) {
    const postData = {
        username:email,
        password:password,
        // returnSecureToken: true,
    };
    return axios.post(
        loginURL,
        postData, {
            headers: {
            	'Content-Type': 'application/json',
            },
        }
    );
}

export function loginWithCenterLocation(email, password,hospital_detail_id) {
    const postData = {
        username:email,
        password:password,
        hospital_detail_id: hospital_detail_id
        // returnSecureToken: true,
    };
    return axios.post(
        loginWithCenterNameURL,
        postData, {
            headers: {
            	'Content-Type': 'application/json',
            },
        }
    );
}

export function sendOtp(mobile) {
    const postData = {
        mobile:mobile
    };
    return axios.post(
        sendOtpResetPasswordURL,
        postData,
    );
}

export function resetPassword(postData) {
    return axios.post(
        resetUserPasswordURL,
        postData,
    );
}

export function formatError(errorResponse) {
    switch (errorResponse) {
        case 'EMAIL_EXISTS':            
            Swal.fire({
                icon: 'error',
                title: 'Oops',
                text: 'Email already exists',                        
            })
            break;
        case 'USERNAME_NOT_FOUND':
            Swal.fire({
                icon: 'error',
                title: 'Username',
                text: 'Username not found. Contact Admin',                        
            })
           break;
        case 'INVALID_PASSWORD':
            Swal.fire({
                icon: 'error',
                title: 'Password',
                text: 'Invalid Password',                        
            })
            break;
        case 'USERNAME_OR_PASSWORD':
            Swal.fire({
                icon: 'error',
                title: 'Username or Password',
                text: 'Please enter correct username and password',                        
            })
            break;
        case 'USER_DISABLED':
            return 'User Disabled';
        case 'USER_NOT_FOUND':
            Swal.fire({
                icon: 'error',
                title: 'Server down',
                text: 'Please contact admin!',                        
            })
            break;
        case 'REGISTERED_MOBILE':
            Swal.fire({
                icon: 'error',
                title: 'Mobile Number',
                text: 'Please enter registered mobile number',                        
            })
            break;
        case 'VALID_OTP':
            Swal.fire({
                icon: 'error',
                title: 'OTP',
                text: 'Please enter valid OTP',                        
            })
            break;
        case 'MIN_PASSWORD':
            Swal.fire({
                icon: 'error',
                title: 'Password',
                text: 'Password should be minimum 8 characters.',                        
            })
            break;
        default:
            return '';
    }
}

export function saveTokenInLocalStorage(tokenDetails) {
    tokenDetails.expireDate = new Date(
        new Date().getTime() + tokenDetails.expiresIn * 1000,
    );
    localStorage.setItem('userDetails', JSON.stringify(tokenDetails));
}

export function runLogoutTimer(dispatch, timer, navigate) {
    setTimeout(() => {
        //dispatch(Logout(history));
        dispatch(Logout(navigate));
    }, timer);
}

export function checkAutoLogin(dispatch, navigate) {
    const tokenDetailsString = localStorage.getItem('userDetails');
    let tokenDetails = '';
    if (!tokenDetailsString) {
        dispatch(Logout(navigate));
		return;
    }

    tokenDetails = JSON.parse(tokenDetailsString);
    let expireDate = new Date(tokenDetails.expireDate);
    let todaysDate = new Date();

    if (todaysDate > expireDate) {
        dispatch(Logout(navigate));
        return;
    }
		
    dispatch(loginConfirmedAction(tokenDetails));
	
    const timer = expireDate.getTime() - todaysDate.getTime();
    runLogoutTimer(dispatch, timer, navigate);
}

export function isLogin() {
    const tokenDetailsString = localStorage.getItem('userDetails');

    if (tokenDetailsString) {
        return true;
    }else{
        return false;
    }
}

