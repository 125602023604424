import { REASON_ACTION_TYPES } from '../../actions/primaryMaster/ReasonActionTypes';

const initialState = {
    reasonName: "",
    reason_id: "",
    detailsData:[],
    loading:false,
    openModel :false
  // Other initial state...
};

const ReasonReducer = (state = initialState, action) => {
  switch (action.type) {
    case REASON_ACTION_TYPES.SET_REASON_NAME:
        return {
            ...state,
            reasonName: action.payload,
        };
    case REASON_ACTION_TYPES.SET_REASON_ID:
        return {
            ...state,
            reason_id: action.payload,
        };
    case REASON_ACTION_TYPES.SET_DETAILS_DATA:
        return {
            ...state,
            detailsData: action.payload,
        };
    case REASON_ACTION_TYPES.SET_LOADING:
        return {
            ...state,
            loading: action.payload,
        };
    case REASON_ACTION_TYPES.SET_OPEN_MODAL:
        return {
            ...state,
            openModel: action.payload,
        };
    // Other cases...
    default:
      return state;
  }
};

export default ReasonReducer;
 