import { INSURANCE_PLAN_ACTION_TYPES } from '../../actions/primaryMaster/InsuranceplanActionTypes';

const initialState = {
    insurance_company_detail_id:"",
    insurance_network_id:"",
    plan_name:"",
    annual_benefit:"",
    approval_required:0, 
    ndiff_price:"",
    bill_exceeds:"",
    free_flowup:"",
app_valid_days:"",
    app_disc_mode:0, 
        cash_mode:0, 
    max_ceiling:"",
    plan_id:"",
    network_separate_price:0,
    detailsData:[],
    loading:false,
    openModel :false,
    is_status: "",

  // Other initial state...
};

const InsurancePlanReducer = (state = initialState, action) => {
  switch (action.type) {
    case INSURANCE_PLAN_ACTION_TYPES.SET_DETAILS_DATA:
        return {
            ...state,
            detailsData: action.payload,
        };
        case INSURANCE_PLAN_ACTION_TYPES.SET_PLAN_ID:
            return {
                ...state,
                plan_id: action.payload,
            };
    case INSURANCE_PLAN_ACTION_TYPES.SET_PLAN_NAME:
        return {
            ...state,
            plan_name: action.payload,
        };
    case INSURANCE_PLAN_ACTION_TYPES.SET_INSURANCE_COMPANY_DETAIL_ID:
        return {
            ...state,
            insurance_company_detail_id: action.payload,
        };
    case INSURANCE_PLAN_ACTION_TYPES.SET_IS_STATUS:
        return {
            ...state,
            is_status: action.payload,
        };
    case INSURANCE_PLAN_ACTION_TYPES.SET_INSURANCE_NETWORK_ID:
        return {
            ...state,
            insurance_network_id: action.payload,
        };

                        case INSURANCE_PLAN_ACTION_TYPES.SET_NETWORK_SEPARATE_PRICE:
                            return {
                                ...state,
                                network_separate_price: action.payload,
                            };
                       
    case INSURANCE_PLAN_ACTION_TYPES.SET_LOADING:
        return {
            ...state,
            loading: action.payload,
        };
    case INSURANCE_PLAN_ACTION_TYPES.SET_OPEN_MODAL:
        return {
            ...state,
            openModel: action.payload,
        };
    // Other cases...
    default:
      return state;
  }
};

export default InsurancePlanReducer;
 