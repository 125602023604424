import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";

const AddCallsPage = () => {
  const navigate = useNavigate();
  const [startDate, setStartDate] = useState(new Date());
  const [area, setArea] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [comments, setComments] = useState("");
  const [type, setType] = useState("");
  const [callType, setCallType] = useState("");
  const [areas, setAreas] = useState([]);
  const [customers, setCustomers] = useState([]);

  console.log("ABC", comments);

  // Fetch Areas from API
  useEffect(() => {
    axios
      .get("https://miracalemoney.in/dealerreport/api/v1/GetArea")
      .then((response) => {
        console.log("API Response:", response.data);
        if (response.data.status === 1 && response.data.arealist) {
          setAreas(response.data.arealist);
        } else {
          console.error("Invalid response structure for areas:", response);
        }
      })
      .catch((error) => {
        console.error("Error fetching areas:", error);
      });
  }, []);

  // Fetch Customers based on Area ID
  const fetchCustomers = (areaId) => {
    const userId = localStorage.getItem("user_id");
    axios
      .post(
        "https://miracalemoney.in/dealerreport/api/v1/GetAreaCustomerDetails",
        {
          area_id: areaId,
          user_id: userId,
        }
      )
      .then((response) => {
        if (response.data.status === 1 && response.data.customers) {
          setCustomers(response.data.customers);
        } else {
          console.error("Invalid response structure for customers:", response);
        }
      })
      .catch((error) => {
        console.error("Error fetching customers:", error);
      });
  };

  // Handle Area Change
  const handleAreaChange = (e) => {
    const selectedArea = e.target.value;
    setArea(selectedArea);
    if (selectedArea) {
      fetchCustomers(selectedArea);
    } else {
      setCustomers([]);
    }
  };

  // Handle form submission

  const handleSubmit = () => {
    const userId = localStorage.getItem("user_id");
    const doj = startDate.toISOString().split("T")[0]; // Date in yyyy-mm-dd format

    // Debugging logs
    console.log("Area ID (selected):", area);
    console.log("Customer ID (selected):", customerName);
    console.log("Areas data:", areas);
    console.log("Customers data:", customers);

    // Find the selected area by matching the ID
    const selectedAreaData = areas.find((a) => a.id === area);
    const selectedCustomerData = customers.find((c) => c.id === customerName);

    // Debugging logs
    console.log("Selected area data:", selectedAreaData);
    console.log("Selected customer data:", selectedCustomerData);

    // Check if the area and customer data exist
    if (!selectedAreaData || !selectedCustomerData) {
      alert("Please select a valid area and customer.");
      return;
    }

    // Prepare data to be sent
    const data = {
      calls: {
        user_id: userId,
        type: "Add",
        doj: doj,
        types: callType, // 0 - pending, 1 - completed
        customer_name: selectedCustomerData.name, // Send the customer name, not the ID
        reach_by: type, // Online, Visit, or Phone
        area_name: selectedAreaData.area, // Send the area name, not the ID
        comment: comments, // Pass the actual value of the comments state
      },
    };

    console.log(data);

    // Send POST request to API
    axios
      .post("https://miracalemoney.in/dealerreport/api/v1/CallsCrud", data)
      .then((response) => {
        console.log(response.status);
        if ((response.status = 200)) {
          console.log("Call added successfully:", response.data);
          alert(JSON.stringify(response.data));
          // Reset the form after successful submission
          setArea("");
          setCustomerName("");
          setComments("");
          setType("");
          setCallType("");
          setCustomers([]);
          navigate("/dashboard");
        } else {
          console.error("Error adding call:", response.data);
          alert("Error adding call");
        }
      })
      .catch((error) => {
        console.error("Error sending request:", error);
        alert("Network error. Please try again.");
      });
  };

  // Handle Cancel button click - Redirect to home screen
  const handleCancel = () => {
    navigate("/dashboard");
  };

  return (
    <div>
      <header className="header">
        <div className="call-header">
          {/* <div className="menu-icon">&#9776;</div> */}
          <h1>Calls</h1>
        </div>
        <div>
          <div onClick={handleCancel} className="cancel-icon">
            &#10005;
          </div>
        </div>
      </header>
      <main className="addclass-container">
        <div className="button-gray">
          <label id="label-id">Date: </label>
          <DatePicker
            ariaLabelledBy="label-id"
            selected={startDate}
            onChange={(date) => setStartDate(date)}
          />
        </div>

        <div className="input-container">
          <label htmlFor="area">Area:</label>
          <select
            id="area"
            value={area}
            onChange={handleAreaChange}
            style={styles.select}
          >
            <option value="">Select Area</option>
            {areas && areas.length > 0 ? (
              areas.map((area) => (
                <option key={area.id} value={area.id}>
                  {area.area}
                </option>
              ))
            ) : (
              <option value="">No Areas Available</option>
            )}
          </select>

          <label htmlFor="customerName">Customer Name:</label>
          <select
            id="customerName"
            value={customerName}
            onChange={(e) => setCustomerName(e.target.value)}
            style={styles.select}
            disabled={!area}
          >
            <option value="">Select Customer</option>
            {customers && customers.length > 0 ? (
              customers.map((customer) => (
                <option key={customer.id} value={customer.id}>
                  {customer.name}
                </option>
              ))
            ) : (
              <option value="">No Customers Available</option>
            )}
          </select>

          <label htmlFor="type">Type:</label>
          <select
            id="type"
            value={type}
            onChange={(e) => setType(e.target.value)}
            style={styles.select}
          >
            <option value="">Select Type</option>
            <option value="online">Online</option>
            <option value="visit">Visit</option>
            <option value="phone">Phone</option>
          </select>

          <label htmlFor="callType">Call Type:</label>
          <select
            id="callType"
            value={callType}
            onChange={(e) => setCallType(e.target.value)}
            style={styles.select}
          >
            <option value="">Select Call Type</option>
            <option value="1">Completed</option>
            <option value="0">Pending</option>
          </select>

          <input
            type="text"
            value={comments}
            onChange={(e) => setComments(e.target.value)}
            placeholder="Comments"
          />
        </div>

        <div className="button-row">
          <button onClick={handleSubmit}>Add Calls</button>
          <button className="cancel-button" onClick={handleCancel}>
            Cancel
          </button>
        </div>
      </main>
    </div>
  );
};

const styles = {
  select: {
    width: "100%",
    padding: "10px",
    fontSize: "16px",
    border: "1px solid #ccc",
    borderRadius: "4px",
    backgroundColor: "#f4f4f4",
    marginBottom: "10px",
  },
};

export default AddCallsPage;
