// store/actions/cityActionTypes.js

export const REFFERAL_ACTION_TYPES = {
    SET_DETAILS_DATA: 'SET_DETAILS_DATA',
    SET_DOCTOR_ID:'SET_DOCTOR_ID',
    SET_NAME:"SET_NAME",
    SET_REFFERAL_CLINIC_ID:"SET_REFFERAL_CLINIC_ID",
    SET_QUALIFICATION:"SET_QUALIFICATION",
    SET_LICENSE_NO:"SET_LICENSE_NO",
    SET_ADDRESS:"SET_ADDRESS",
    SET_PHONE_NO:"SET_PHONE_NO",
    SET_MOBILE_NO:"SET_MOBILE_NO",
    SET_EMAIL:"SET_EMAIL",
    SET_IS_ACTIVE:"SET_IS_ACTIVE",
    SET_CITY_ID:"SET_CITY_ID",
    SET_COUNTRY_ID:"SET_COUNTRY_ID",
    SET_REGION_ID:"SET_REGION_ID",
    SET_LOADING:"SET_LOADING",
    SET_OPEN_MODAL:"SET_OPEN_MODAL"
};
  
