import { LANGUAGE_ACTION_TYPES } from '../../actions/primaryMaster/LanguageActionTypes';

const initialState = {
    languageName: "",
    shortCode: "",
    language_id: "",
    detailsData:[],
    loading:false,
    openModel :false
  // Other initial state...
};

const LanguageReducer = (state = initialState, action) => {
  switch (action.type) {
    case LANGUAGE_ACTION_TYPES.SET_LANGUAGE_NAME:
        return {
            ...state,
            languageName: action.payload,
        };
    case LANGUAGE_ACTION_TYPES.SET_SHORT_CODE:
        return {
            ...state,
            shortCode: action.payload,
        };
    case LANGUAGE_ACTION_TYPES.SET_LANGUAGE_ID:
        return {
            ...state,
            language_id: action.payload,
        };
    case LANGUAGE_ACTION_TYPES.SET_DETAILS_DATA:
        return {
            ...state,
            detailsData: action.payload,
        };
    case LANGUAGE_ACTION_TYPES.SET_LOADING:
        return {
            ...state,
            loading: action.payload,
        };
    case LANGUAGE_ACTION_TYPES.SET_OPEN_MODAL:
        return {
            ...state,
            openModel: action.payload,
        };
    // Other cases...
    default:
      return state;
  }
};

export default LanguageReducer;
 