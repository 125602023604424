// store/actions/cityActionTypes.js

export const RELIGION_ACTION_TYPES = {
    SET_DETAILS_DATA: 'SET_DETAILS_DATA',
    SET_RELIGION_NAME:"SET_RELIGION_NAME",
    SET_SHORT_CODE:"SET_SHORT_CODE",
    SET_RELIGION_ID:"SET_RELIGION_ID",
    SET_LOADING:"SET_LOADING",
    SET_OPEN_MODAL:"SET_OPEN_MODAL"
};
  