import { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";

const Completed = () => {
    const navigate = useNavigate();
    const [startDate, setStartDate] = useState(new Date());
    const [records, setRecords] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    // Fetch records from the API
    const fetchRecords = async (selectedDate) => {
        setLoading(true);
        setError(null);

        const userId = localStorage.getItem("user_id") || "default_user_id"; // Replace "default_user_id" with a fallback value if necessary
        const formattedDate = new Date(selectedDate).toISOString().split("T")[0]; // Format date as YYYY-MM-DD

        try {
            const response = await axios.post(
                "https://miracalemoney.in/dealerreport/api/v1/history",
                {
                    history: {
                        user_id: userId,
                        type: "CallList",
                        status: 1,
                        date: formattedDate,
                    },
                }
            );

            console.log("API Response:", response.data);

            if (response.data.status === 1 && response.data.record) {
                setRecords(response.data.record);
            } else {
                setRecords([]);
                setError("No records found.");
            }
        } catch (err) {
            console.error("Error fetching records:", err);
            setError("Failed to fetch records. Please try again.");
        } finally {
            setLoading(false);
        }
    };

    // Fetch records on date change
    useEffect(() => {
        fetchRecords(startDate);
    }, [startDate]);

      // Handle Cancel button click - Redirect to home screen
  const handleCancel = () => {
    navigate("/dashboard");
  };

    return (
        <div>
            <header className="header">
                <div className="call-header">
                    {/* <div className="menu-icon">&#9776;</div> */}
                    <h1>Completed</h1>
                </div>
                <div onClick={handleCancel} className="cancel-icon">
            &#10005;
          </div>
            </header>
            <main className="addclass-container">
                <div className="button-gray">
                    <label id="label-id">Date: </label>
                    <DatePicker
                        ariaLabelledBy="label-id"
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                    />
                </div>

                {loading && <p>Loading records...</p>}
                {error && <p style={{ color: "red" }}>{error}</p>}

                {/* Display fetched records */}
                {records.map((record, index) => (
                    <div className="card" key={record.id}>
                        <h2>Customer Type: {record.types}</h2>
                        <div className="card-content">
                            <div>S.No: {index + 1}</div>
                            <div>Customer Name: {record.customer_name}</div>
                            <div>Command Name: {record.comment}</div>
                            <div>Area: {record.area_name}</div>
                            <div>Reach By: {record.reach_by}</div>
                            {/* <div>Mobile: {record.mobile}</div> */}
                            <div>Date: {record.doj}</div>
                        </div>
                    </div>
                ))}

                {!loading && records.length === 0 && !error && (
                    <p>No records to display.</p>
                )}
            </main>
        </div>
    );
};

export default Completed;
