import React  from 'react';
import {connect, useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { Logout } from '../../../store/actions/AuthActions';
import { isAuthenticated } from '../../../store/selectors/AuthSelectors';
import { logoutAPI } from '../../../services/APIService';
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
// import { SVGICON } from '../../constant/theme';


function withRouter(Component) {
	function ComponentWithRouterProp(props) {
		let location = useLocation();
		let navigate = useNavigate();
		let params = useParams();
		return (
			<Component
				{...props}
				router={{ location, navigate, params }}
			/>
		);
	}

	return ComponentWithRouterProp;
}

function LogoutPage(props){
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const userData = useSelector((state) => state.auth.auth);
	const userToken = userData.idToken;
	const userId = userData.userId;
	const logId = userData.logId;

	const handleSessionLogout = async () => {
		await logoutAPI(userId, logId,userToken).then((resp) => {
			if(resp.data.status ===1){
				dispatch(Logout(navigate))
			}
		}).catch((error) => {
			if (error.response.data.status === 2) {
				toast.error(error.response.data.message + ". Please log in again to continue.", {
					position: toast.POSITION.TOP_RIGHT,
					autoClose: 1000,
				});
				setTimeout(() => {
					dispatch(Logout(navigate))
				}, 2000)
			} else {
				toast.error(error.response.data.message + " Please Try After Sometimes" ||
					"Too Many Attempts. Please Try after sometimes", {
					position: toast.POSITION.TOP_RIGHT,
					autoClose: 1000,
				});
			}
		});
	};
		function onLogout() {
		 handleSessionLogout()
		}
		
		return(
				<>
						<button className="dropdown-item ai-icon ms-1 logout-btn" onClick={onLogout}>
						<svg 
							id="icon-logout" xmlns="http://www.w3.org/2000/svg" className="text-danger" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
							<path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
							<polyline points="16 17 21 12 16 7"></polyline>
							<line x1="21" y1="12" x2="9" y2="12"></line></svg> {" "}
								<span className="ms-2">Logout </span>              
						</button>
				</>
		)
} 
const mapStateToProps = (state) => {
		return {
				isAuthenticated: isAuthenticated(state),
		};
};

export default withRouter(connect(mapStateToProps)(LogoutPage));