import { SERVICE_ACTION_TYPES } from '../../actions/primaryMaster/ServiceActionTypes';

const initialState = {
    serviceName: "",
    service_id: "",
    detailsData:[],
    loading:false,
    openModel :false
  // Other initial state...
};

const ServiceReducer = (state = initialState, action) => {
  switch (action.type) {
    case SERVICE_ACTION_TYPES.SET_SERVICE_NAME:
        return {
            ...state,
            serviceName: action.payload,
        };
    case SERVICE_ACTION_TYPES.SET_SERVICE_ID:
        return {
            ...state,
            service_id: action.payload,
        };
    case SERVICE_ACTION_TYPES.SET_DETAILS_DATA:
        return {
            ...state,
            detailsData: action.payload,
        };
    case SERVICE_ACTION_TYPES.SET_LOADING:
        return {
            ...state,
            loading: action.payload,
        };
    case SERVICE_ACTION_TYPES.SET_OPEN_MODAL:
        return {
            ...state,
            openModel: action.payload,
        };
    // Other cases...
    default:
      return state;
  }
};

export default ServiceReducer;
 