import { INCOME_RANGE_ACTION_TYPES } from '../../actions/primaryMaster/IncomeRangeActionTypes';

const initialState = {
    incomeRangeName: "",
    incomeRange_id: "",
    detailsData:[],
    loading:false,
    openModel :false
  // Other initial state...
};

const IncomeRangeReducer = (state = initialState, action) => {
  switch (action.type) {
    case INCOME_RANGE_ACTION_TYPES.SET_INCOME_RANGE_NAME:
        return {
            ...state,
            incomeRangeName: action.payload,
        };
    case INCOME_RANGE_ACTION_TYPES.SET_INCOME_RANGE_ID:
        return {
            ...state,
            incomeRange_id: action.payload,
        };
    case INCOME_RANGE_ACTION_TYPES.SET_DETAILS_DATA:
        return {
            ...state,
            detailsData: action.payload,
        };
    case INCOME_RANGE_ACTION_TYPES.SET_LOADING:
        return {
            ...state,
            loading: action.payload,
        };
    case INCOME_RANGE_ACTION_TYPES.SET_OPEN_MODAL:
        return {
            ...state,
            openModel: action.payload,
        };
    // Other cases...
    default:
      return state;
  }
};

export default IncomeRangeReducer;
 