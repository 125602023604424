import React, { useState, useRef, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Title from "../../utils/Titles/Title";
import * as getData from '../../../services/APIService';
import * as getAPIAction from "../../../store/actions/GetAllMasterList";
import { ALL_MASTER_ACTION_TYPES } from "../../../store/actions/AllMasterActionTypes";
import  Searchdropdown from '../../utils/Searchdropdown'
import  Multiselectwithcheckbox from '../../utils/Multiselectwithcheckbox'
import Buttons from '../../utils/Button'
import Table from '../../utils/Newtable/Table'
import { tableData } from '../../utils/json/tableData'
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { NavMenuOn, NavMenuToggle } from "../../layouts/nav/NavHader";
import { ThemeContext } from "../../../context/ThemeContext";
import { SVGICON } from "../../constant/theme";
import Spinner from "react-bootstrap/Spinner";
import { logoutFunction } from "../../../store/actions/LogoutActions";
import { useDispatch, useSelector } from 'react-redux';
import { insuranceUIDatas } from "../../utils/json/insuranceUIDatas";
import * as _ from "lodash" 
import { setLoading } from "../../../store/actions/CommonActions";
import Loader from "../Loader/Loader";
import TextFeildCheckBox from '../../utils/TextFeildCheckBox';
import TextFeildRadioBox from '../../utils/TextFeildRadioBox';
import TextInput from '../../utils/TextInput';
import ServiceCategoryTable from "../PatientRegistration/ServiceCategoryTable";
import Popup from "../Masters/Element/Popup";

const moment = require('moment');
const InsuranceDetails = () => {

  const navigate = useNavigate();
  const hash = window.location.hash;
  const queryString = hash.split('?')[1];
  const params = new URLSearchParams(queryString);
  const { toggleSideMenu } = useContext(ThemeContext);
  const [plan_detail_id, setPlandetailid] = useState(params.size > 0 ? params.get('plandetailID') : 0);
  const [editrestrict , seteditrestrict] = useState(false);

  const [showFieldLoad, setShowFieldLoad] = useState(false);
  const [insuranceUIArray , setInsuranceUIArray] = useState([])
  const [insuranceUIIndex, setInsuranceUIIndex] = useState(0);
  const [clientList, setClientList] = useState([]);


  const [AskNavigationOpenModal , setAskNavigationOpenModal] = useState(false);
  const [checkChanges , setCheckChanges] = useState(false);

  const [multipleDepartmentListdata, setMultipleDepartmentListdata] = useState([]);
  const [loadedDescriptionData, setLoadedDescriptionData] = useState([]);

  const loading = useSelector((state) => state.common.showLoading)

  const [title , setTitle] = useState("Company Plan Details");
  
  const departmentCategoryAllList = useSelector(state => state.allMasterList.departmentCategoryAllList);
  const departmentList = useSelector(state => state.allMasterList.departmentList);
 
  const companyList = useSelector((state) => state.allMasterList.maincompanylist);
  const networklist = useSelector((state) => state.allMasterList.insnetworklist);
  const insplanlist = useSelector((state) => state.allMasterList.networkplanlist);

  const DepartmentListTableHead = tableData?.[0]?.plan_detail?.find((master) => master.hasOwnProperty("clinical_details"))?.clinical_details?.[0]?.tableHead || [];

  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.auth.auth);
  const userToken = userData.idToken;
  const userId = userData.userId;
  // const hospital_detail_id = parseInt(userData.hospital_detail_id)
  const logId = parseInt(userData.logId);
  const emailValidator = (email) => {
    const Regexp = new RegExp(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)
    return Regexp.test(email)
  }
  const initialFormData = {
    hospital_detail_id : "",
    insurance_company_detail_code: "",
    insurance_company_detail_id: "",
    insurance_network_id:"",
    insurance_plan_id: "",
    dept_category_id: [],
    discontinue_network: 0,
    discontinue_plan: 0,
    free_followup_days : "",
    max_ceiling : "",
    plan_require_approval: 0,
    validity_approve_days:"",
    limit_per_invoice:"",
    co_insurance_exist_patient : 0,
    deduct_exist_patient: 0,
    discount_all_network:0,
    discount_all_plan : 0,
    factor_all_network : 0,
    factor_all_plans : 0,
    before_discount: 1, //0 or 1
    after_discount: 0,
  }


  const initialdeptdesc = {
    department_id:1,
    department_name: "",
    // active: 0, // ---
    out_patient:0, //1 or 0
    out_patient_discount:"",//amount
    in_patient:0, //1 or 0
    // approval_validity: 0, // --
    // max_limit: 0, // --
    in_patient_discount: "", //Amount
    co_ins_ongross:0, //1 or 0
    co_ins_onnet:1, //1 or 0
    co_pay_percentage: "", //Percentage
    dedcut_amount: "",//Amount
    per_request:0, //1 or 0
    factor: "", //Number
    bill_exceeds: "", //Amount
    sort_by: "" //Number
  }

  const [formData, setFormData] = useState(initialFormData);
  const [deptdescData , setdeptdescData] = useState(initialdeptdesc);
  const [deptselectedValues, setDeptSelectedValues] = useState([]);

  const [checkedDepartmentCategory, setCheckedDepartmentCategory] = React.useState([]);
  const [selectedDeptCategoryFromAPI , setSelectedDeptCategoryFromAPI] = React.useState([]);

  const [activeDeptToUncheck, setActiveDeptToUncheck] = React.useState(-1);
  const [confirmPopup, setConfirmPopup] = React.useState(false);
  const [popuplateConfirmPopup, setPopulateConfirmPopup] = React.useState(false);

  const [storePopulateMetaInfo , setStorePopulateMetaInfo] = React.useState({});
  useEffect(() => {

    document.title = 'Insurances Plan Details';
    //setTitle(params.size > 0 ? 'Edit' : 'Add');
    toggleSideMenu(true); NavMenuOn();

  },[])

  const validateForm = () => {
    const validationErrors = {};
    const pattern = /^[a-zA-Z0-9 ]*$/;
    if (!formData.hospital_detail_id) {
      validationErrors.hospital_detail_id = 'Location is required';
    }
    if (!formData.insurance_company_detail_id) {
      validationErrors.insurance_company_detail_id = 'Company name is required';
    }
    if (!formData.insurance_network_id) {
      validationErrors.insurance_network_id = 'Company network is required';
    }
    if (!formData.insurance_plan_id) {
      validationErrors.insurance_plan_id = 'Plan name is required';
    }
    if (!formData.dept_category_id) {
      validationErrors.dept_category_id = 'Department Category is required';
    }
    // if (!formData.free_followup_days) {
    //   validationErrors.free_followup_days = 'Free Follow-Up Days is required';
    // }
    // if (!formData.max_ceiling) {
    //   validationErrors.max_ceiling = 'Max Ceiling is required';
    // }
    // if (!formData.validity_approve_days) {
    //   validationErrors.validity_approve_days = 'Validity Approval Days is required';
    // }
    // if (!formData.limit_per_invoice) {
    //   validationErrors.limit_per_invoice = 'Limit Per Invoice is required';
    // }
    return validationErrors;
  };

// const searchhandlechange=(value) => {
//   if(value)
//   {
//     const selectedcompany = companyList.filter((item) => (item.company_id === value))
//     // console.log(selectedcompany[0].short_code);
//     setFormData({ ...formData, ['mediator_id']: value, "mediator_short_code": selectedcompany[0].short_code });

//     setErrors({ ...errors, ['mediator_id'] : "" , "mediator_short_code" : ""});
//   }
// }

  //Handle function of all form inputs
  const handleChange = (e) => {
    // to make edit at 1st time false
    seteditrestrict(false);

    // code to update formdata
   
    const { name, value, type, files } = e.target;
    const newValue = type === 'file' ? files[0] : type === "checkbox" ? (e.target.checked ? 1 : 0 ): value;
    const pattern = /^[a-zA-Z0-9 ]*$/;
    const numPattern = /^[0-9]*$/

    if(name==="insurance_company_detail_id") {
      
      dispatch({ type: ALL_MASTER_ACTION_TYPES.SET_NETWORK_LIST1, payload: [] });
      dispatch({ type: ALL_MASTER_ACTION_TYPES.SET_PLAN_NETWORK_LIST, payload: [] });

      dispatch(getAPIAction.GetNetworkInsCompanyWise(userId, logId, userToken, navigate, newValue));

      const company = companyList.find((data) => data.company_id === newValue);

      if(newValue === "") {
        setFormData(prevData => ({ ...prevData, [name]: newValue, insurance_company_detail_code : "" }));
      }
      else {
        setFormData(prevData => ({ ...prevData, [name]: newValue, insurance_company_detail_code : company.short_code }));
      }
    } else if(name==="insurance_network_id") {

      dispatch({ type: ALL_MASTER_ACTION_TYPES.SET_PLAN_NETWORK_LIST, payload: [] });

      dispatch(getAPIAction.GetPlanNetworkWise(userId, logId, userToken, navigate, newValue));
      setFormData({ ...formData, [name]: newValue });
    } 
    // else if(name==="dept_category_id") {
    //   seteditrestrict(false); // on edit, if dept_category is changed... should not load data from API.
    //   const { options } = e.target;
    //   const selectedOptions = [];
    //   for (let i = 0, l = options.length; i < l; i++) {
    //     if (options[i].selected) {
    //       selectedOptions.push(options[i].value);
    //     }
    //   }
    
    //   setDeptSelectedValues(selectedOptions);
    //   dispatch(getAPIAction.getAllDepartmentListbycategoryAPI(userId, logId, userToken, navigate, selectedOptions));
    //   setFormData({ ...formData, [name]: selectedOptions });
    // } 
    else if(name === 'plan_discount') {
      if (newValue === 'before_discount') {
        setFormData({ ...formData, before_discount: 1, after_discount: 0 });
      } else {
        setFormData({ ...formData, before_discount: 0, after_discount: 1 });
      }
    } else if(name === "free_followup_days" || name === "max_ceiling" || name === "validity_approve_days" || name === "limit_per_invoice") {
      if(numPattern.test(newValue)) {
        setFormData({ ...formData, [name]: newValue });
      }
    }
    else {
      // dispatch({ type: ALL_MASTER_ACTION_TYPES.SET_CATDEPARTMENT_LIST, payload: [] });
      setFormData({ ...formData, [name]: newValue });
    }

    setErrors({ ...errors, [name]: '' });
  };


  const handleToggleController = (dept) => {

    // if the plan detail is an existing one, try to uncheck the saved Dept Category, fire a popup to confirm the action.
    // selectedDeptCategoryFromAPI gets saved category_ids to track unchecking of Dept Category 
    
    if(plan_detail_id !== 0 && selectedDeptCategoryFromAPI.length !== 0) {
      const isApiCheckedDeptCategoryUnchecked = selectedDeptCategoryFromAPI.includes(dept);
      // console.log(isApiCheckedDeptCategoryUnchecked, dept, typeof dept);
      if(isApiCheckedDeptCategoryUnchecked) {
        // confirm popup
        setConfirmPopup(true);
        setActiveDeptToUncheck(dept);
        return;
      }

    }

    handleToggle(dept);

  }

  const handleToggle = (dept) => {
    // to make edit at 1st time false
    seteditrestrict(false);

    // code to update Department Category

    // console.log(dept);
    const currentIndex = checkedDepartmentCategory.indexOf(dept);

    // console.log(currentIndex,dept);
    const newChecked = [...checkedDepartmentCategory];

    if (currentIndex === -1) {
      newChecked.push(dept);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setCheckedDepartmentCategory(newChecked);
    setFormData({ ...formData, ["dept_category_id"]: newChecked })
    if(newChecked.length == 0) {
      dispatch({ type: ALL_MASTER_ACTION_TYPES.SET_CATDEPARTMENT_LIST, payload: [] });
    } 
    else {
      dispatch(getAPIAction.getAllDepartmentListbycategoryAPI(userId, logId, userToken, navigate, newChecked));
    }
  };

  const confirmPopupAction = async (type) => {

    if(type === "Yes") {

      if(activeDeptToUncheck !== -1) {

        await deleteUncheckedInsPlanDetailDeptCategory(activeDeptToUncheck);
  
      }
      else {
        console.log("ACTIVE DEPT TO UNCHECK HAS ", activeDeptToUncheck); //possibly -1 but not happen
      }

    }

    setActiveDeptToUncheck(-1);
    setConfirmPopup(false);  
    
  }

  const deleteUncheckedInsPlanDetailDeptCategory = async (dept_category_id) => {

    dispatch(setLoading(true))

    await getData
      .deleteInsPlanDetailDesc( userId, plan_detail_id, dept_category_id, userToken)
      .then((resp) => {
        if (resp.data.status === 1) {

          const dept_id = activeDeptToUncheck;
          const currentIndex = selectedDeptCategoryFromAPI.indexOf(dept_id);

          const apiCheckedDeptCategory = [...selectedDeptCategoryFromAPI];

          apiCheckedDeptCategory.splice(currentIndex, 1);

          // console.log("REMAINING DEPT CATEGORY TO UNSELECT" , apiCheckedDeptCategory);

          setSelectedDeptCategoryFromAPI(apiCheckedDeptCategory);

          handleToggle(dept_category_id);

          toast.success(resp.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          
        } else {

          toast.error(resp.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
        }

      })
      .catch((error) => {
        // console.log(error)
        if (error.response.data.status === 2) {
          toast.error(
            error.response.data.message +
            ". Please log in again to continue.",
            {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 1000,
            }
          );
          setTimeout(() => {
            dispatch(logoutFunction(userId, logId, userToken, navigate));
          }, 2000);
        } else {
          toast.error(
            error.response.data.message + " Please Try After Sometimes" ||
            "Too Many Attempts. Please Try after sometimes",
            {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 1000,
            }
          );
        }
      });

    dispatch(setLoading(false));
  }
  
  useEffect(() => {
    console.log("Selected Depatment Category");
    console.log(checkedDepartmentCategory)
  }, [checkedDepartmentCategory])

  useEffect(() => {
    console.log("Department List");
    console.log(departmentList)
  }, [departmentList])

  const shouldLog = useRef(true);
  useEffect(() => {
    if (shouldLog.current) {
      shouldLog.current = false;
     dispatch(getAPIAction.GetInsMainCompanyList(userId, logId, userToken, navigate));
    //  dispatch(getAPIAction.getAllInsurancePlanListAPI(userId, logId, userToken, navigate));
     dispatch(getAPIAction.getAllDepartmentCategoryAPI(userId, logId, userToken, navigate));

     dispatch({ type: ALL_MASTER_ACTION_TYPES.SET_NETWORK_LIST1, payload: [] });
     dispatch({ type: ALL_MASTER_ACTION_TYPES.SET_PLAN_NETWORK_LIST, payload: [] });
     dispatch({ type: ALL_MASTER_ACTION_TYPES.SET_CATDEPARTMENT_LIST, payload: [] });
    }

  }, []);
  useEffect(() => {
    if (plan_detail_id !== 0) {
      seteditrestrict(true);
      GetSingleInsPlanDetail(plan_detail_id)
      setTitle("Edit Insurance Plan Details")

    }
    getClientListAPI();

  }, [plan_detail_id])


    //Get Center name API call
    const getClientListAPI = async () => {
        await getData.getClientList(userToken).then((resp) => {
            setClientList(resp.data.data);
            // console.log(resp.data.data)
        }).catch((error) => {
            if (error.response.data.status === 2) {
                toast.error(error.response.data.message + ". Please log in again to continue.", {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 1000,
                });
                setTimeout(() => {
                    dispatch(logoutFunction(userId, logId, userToken, navigate));
                }, 2000)
            } else {
                toast.error(error.response.data.message + " Please Try After Sometimes" ||
                    "Too Many Attempts. Please Try after sometimes", {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 1000,
                });
            }
        });
    };

  const GetSingleInsPlanDetail = async (plan_detail_id) => {
    setShowFieldLoad(true);


    await getData.getSingleInsPlanDetail(plan_detail_id, userToken).then((resp) => {


      if (resp.data && resp.data.status === 0) {
        toast.error(resp.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
      } else {

        var detailsData = resp.data.data;
        console.log(detailsData);
        setFormData(
          prevFormData => ({
            ...prevFormData,
         
            hospital_detail_id: detailsData.plan[0].hospital_detail_id,
            insurance_company_detail_code: detailsData.plan[0].insurance_company_detail_code,
            insurance_company_detail_id: detailsData.plan[0].insurance_company_detail_id,
            insurance_network_id: detailsData.plan[0].insurance_network_id,
            insurance_plan_id: detailsData.plan[0].insurance_plan_id,
            dept_category_id: detailsData.category_ids,
            discontinue_network: detailsData.plan[0].discontinue_network,
            discontinue_plan: detailsData.plan[0].discontinue_plan,
            free_followup_days : detailsData.plan[0].free_followup_days===null?"":parseInt(detailsData.plan[0].free_followup_days),
            max_ceiling : detailsData.plan[0].max_ceiling===null?"":parseInt(detailsData.plan[0].max_ceiling),
            plan_require_approval: detailsData.plan[0].plan_require_approval,
            validity_approve_days: detailsData.plan[0].validity_approve_days===null?"":parseInt(detailsData.plan[0].validity_approve_days),
            limit_per_invoice: detailsData.plan[0].limit_per_invoice===null?"":parseInt(detailsData.plan[0].limit_per_invoice),
            co_insurance_exist_patient : detailsData.plan[0].co_insurance_exist_patient,
            deduct_exist_patient: detailsData.plan[0].deduct_exist_patient,
            discount_all_network:detailsData.plan[0].discount_all_network,
            discount_all_plan : detailsData.plan[0].discount_all_plan,
            factor_all_network : detailsData.plan[0].factor_all_network,
            factor_all_plans : detailsData.plan[0].factor_all_plans
         
        }));

        const category_ids = detailsData.category_ids.map((id) => parseInt(id));
        setCheckedDepartmentCategory(category_ids)
        setSelectedDeptCategoryFromAPI(category_ids);

        dispatch(getAPIAction.GetNetworkInsCompanyWise(userId, logId, userToken, navigate, detailsData.plan[0].insurance_company_detail_id));
        dispatch(getAPIAction.GetPlanNetworkWise(userId, logId, userToken, navigate, detailsData.plan[0].insurance_network_id));
        dispatch(getAPIAction.getAllDepartmentListbycategoryAPI(userId, logId, userToken, navigate,  detailsData.category_ids));

        const descdata= detailsData.description;

        if (descdata.length > 0) {
          const updatedData = descdata.map((items, index) => ({
            ...descdata[index], // Assuming descdata is an array of objects
            dept_category_id: items.dept_category_id,
            department_id:items.department_id,
            active: items.is_active,
            out_patient: items.out_patient,
            out_patient_discount: items.out_patient_discount,
            in_patient: items.in_patient,
            in_patient_discount: items.in_patient_discount,
            co_ins_ongross: items.co_ins_ongross,
            co_ins_onnet: items.co_ins_onnet,
            co_pay_percentage: items.co_pay_percentage,
            dedcut_amount: items.dedcut_amount,
            per_request: items.per_request,
            factor: items.factor,
            bill_exceeds: items.bill_exceeds,
            sort_by: items.sort_by,
          }));
        
          setLoadedDescriptionData(descdata);
        }
      
      
      }

      setShowFieldLoad(false);

    }).catch((error) => {

      if (error.response.data.status === 2) {
        toast.error(error.response.data.message + ". Please log in again to continue.", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        setTimeout(() => {
          dispatch(logoutFunction(userId, logId, userToken, navigate));
        }, 2000)
      } else {
        toast.error(error.response.data.message + " Please Try After Sometimes" ||
          "Too Many Attempts. Please Try after sometimes", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      }
    });
  };

  const formsubmit = async (e, type) => {
    e.preventDefault();
    dispatch(setLoading(true))
    const validationErrors = validateForm();
    console.log(formData)
    console.log(validationErrors);
    

    if (Object.keys(validationErrors).length === 0) {
      
      const postFormData = _.cloneDeep(formData);

      if (plan_detail_id === 0) {
        const formatmultipleDepartmentListdata = Object.assign([], multipleDepartmentListdata);
        // for(let i=0;i<formatmultipleDepartmentListdata.length; i++) {
        //   delete formatmultipleDepartmentListdata[i].department_name;
        // }

        const categories = [];

        for(let i=0;i<formatmultipleDepartmentListdata.main_list.length;i++) {
            
          for(let j=0;j<formatmultipleDepartmentListdata.main_list[i].category_list.length;j++) {
          
              delete formatmultipleDepartmentListdata.main_list[i].category_list[j].department_name;
              
              categories.push(formatmultipleDepartmentListdata.main_list[i].category_list[j]);
          }

        }

        delete postFormData.dept_category_id;

        const postData = { "user_id": userId,  ...postFormData  , "description" : [...categories]};

        console.log(formatmultipleDepartmentListdata)
        await getData
          .addPlanDetails(postData, userToken)
          .then((resp) => {


            if (resp.data.status === 1) {
              setPlandetailid(resp.data.plan_detail_id);
              if (type === 0) {
                handleAllFormReset();
                // navigate('/plan-details-list')
              }
              else {

                // navigate('/plan-details?plandetailsID=' + resp.data.plan_detail_id)
              }
              toast.success(resp.data.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 1000,
              });
            } else {

              toast.error(resp.data.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 1000,
              });
            }
          })
          .catch((error) => {
            // console.log(error)
            if (error.response.data.status === 2) {
              toast.error(
                error.response.data.message +
                ". Please log in again to continue.",
                {
                  position: toast.POSITION.TOP_RIGHT,
                  autoClose: 1000,
                }
              );
              setTimeout(() => {
                dispatch(logoutFunction(userId, logId, userToken, navigate));
              }, 2000);
            } else {
              toast.error(
                error.response.data.message + " Please Try After Sometimes" ||
                "Too Many Attempts. Please Try after sometimes",
                {
                  position: toast.POSITION.TOP_RIGHT,
                  autoClose: 1000,
                }
              );
            }
          });
      }
      else {
        // const formatmultipleDepartmentListdata = Object.assign([], multipleDepartmentListdata);
        // for(let i=0;i<formatmultipleDepartmentListdata.length; i++) {
        //   let t = {
        //     desc_id: formatmultipleDepartmentListdata[i].id,
        //     department_id: formatmultipleDepartmentListdata[i].department_id,
        //     out_patient: formatmultipleDepartmentListdata[i].out_patient, 
        //     out_patient_discount: formatmultipleDepartmentListdata[i].out_patient_discount,
        //     in_patient: formatmultipleDepartmentListdata[i].in_patient, 
        //     in_patient_discount:  formatmultipleDepartmentListdata[i].in_patient_discount, 
        //     co_ins_ongross:formatmultipleDepartmentListdata[i].co_ins_ongross, 
        //     co_ins_onnet:formatmultipleDepartmentListdata[i].co_ins_onnet, 
        //     co_pay_percentage: formatmultipleDepartmentListdata[i].co_pay_percentage, 
        //     dedcut_amount: formatmultipleDepartmentListdata[i].dedcut_amount,
        //     per_request:formatmultipleDepartmentListdata[i].per_request, 
        //     factor: formatmultipleDepartmentListdata[i].factor, 
        //     bill_exceeds: formatmultipleDepartmentListdata[i].bill_exceeds, 
        //     sort_by: formatmultipleDepartmentListdata[i].sort_by 
        //   }

        //   formatmultipleDepartmentListdata[i] = t;
        // }

        const formatmultipleDepartmentListdata = Object.assign([], multipleDepartmentListdata);

        const categories = [];

        for(let i=0;i<formatmultipleDepartmentListdata.main_list.length;i++) {
            
          for(let j=0;j<formatmultipleDepartmentListdata.main_list[i].category_list.length;j++) {
          
              delete formatmultipleDepartmentListdata.main_list[i].category_list[j].department_name;
              
              categories.push(formatmultipleDepartmentListdata.main_list[i].category_list[j]);
          }

        }

        delete postFormData.dept_category_id;

        const postData = { "user_id": userId, "plan_detail_id": plan_detail_id,  ...postFormData  , "description" : [...categories]};

        await getData.updateInsPlanDetail(postData, userToken)
          .then((resp) => {
            if (resp.data.status === 1) {

              seteditrestrict(true);
              GetSingleInsPlanDetail(plan_detail_id);
              toast.success(resp.data.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 1000,
              });
          
                // navigate('/plan-details-list')
              
            } else {

              toast.error(resp.data.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 1000,
              });
            }
          })
          .catch((error) => {
            // console.log(error)
            if (error.response.data.status === 2) {
              toast.error(
                error.response.data.message +
                ". Please log in again to continue.",
                {
                  position: toast.POSITION.TOP_RIGHT,
                  autoClose: 1000,
                }
              );
              setTimeout(() => {
                dispatch(logoutFunction(userId, logId, userToken, navigate));
              }, 2000);
            } else {
              toast.error(
                error.response.data.message + " Please Try After Sometimes" ||
                "Too Many Attempts. Please Try after sometimes",
                {
                  position: toast.POSITION.TOP_RIGHT,
                  autoClose: 1000,
                }
              );
            }
          });
      }
    }
    else {

      setErrors(validationErrors);

    }
    dispatch(setLoading(false))
  }

  const isValidURL = (url) => {
    // Regular expression for URL validation
    const pattern = /^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]+\.[a-z]{2,}(\.[a-z]{2,})?$/;
    return pattern.test(url);
  };

  const handleAllFormReset = () => {
    setFormData(initialFormData);
   
    setInsuranceUIIndex(0);
    setCheckChanges(false);
  }

  //old
  // const checkboxChange = (e, focusData, accessor) => {
  //   if (e.target.name === "co_ins_onnet"){
         
  //     focusData["co_ins_onnet"] = 1
  //     focusData["co_ins_ongross"] = 0

  //     } else if (e.target.name === "co_ins_ongross"){
  //       focusData["co_ins_onnet"] = 0
  //       focusData["co_ins_ongross"] = 1
  //     }
  //     else{
  //     focusData[accessor] = e.target.value == 1 ? 0 : 1;
  //     }
  //   const mulipledeptlistdata = Object.assign([], multipleDepartmentListdata);
  //   const focusDataIndex = mulipledeptlistdata.findIndex((data , index) => data.department_id === focusData.department_id);
  //   mulipledeptlistdata[focusDataIndex] = focusData;
    
  //   setMultipleDepartmentListdata(mulipledeptlistdata);
  // }

  //old
  // const handleInputChange = (e,focusDataIndex, focusData, accessor) => {
  //   // console.log(e , focusDataIndex, focusData, accessor);
  //   focusData[accessor] = e.target.value;
    
  //   const mulipledeptlistdata = Object.assign([], multipleDepartmentListdata);
  //   mulipledeptlistdata[focusDataIndex] = focusData;
  //   setMultipleDepartmentListdata(mulipledeptlistdata);
  // }


  // checkboxChange by SC

  const checkboxChange = (index, subIndex, list_name, e, focusData, accessor) => {

    // to make edit at 1st time false
    seteditrestrict(false);


    // code for update checkbox in table
    const updateMultipleDeptList = _.cloneDeep(multipleDepartmentListdata);

    if (accessor === "co_ins_onnet"){
         
      focusData["co_ins_onnet"] = 1
      focusData["co_ins_ongross"] = 0
    
    } else if (accessor === "co_ins_ongross"){
      focusData["co_ins_onnet"] = 0
      focusData["co_ins_ongross"] = 1
    }
    else{
      focusData[accessor] = e.target.value == 1 ? 0 : 1;
    }

    updateMultipleDeptList[list_name][index].category_list[subIndex] = focusData;
    
    setMultipleDepartmentListdata(updateMultipleDeptList)
}

  // handleInputChange by SC

  const handleInputChange = (e, index, subIndex, list_name, focusData, accessor) => {

    // to make edit at 1st time false
    seteditrestrict(false);

    // code for update input in table
    console.log(e, index, subIndex, list_name, focusData, accessor)

    const updateMultipleDeptList = _.cloneDeep(multipleDepartmentListdata);

    const { value } = e.target;

    // if subindex is -1, and focusData is "all", means this function is invoked because of category_name have to apply the "value" to all subsequent datas

    if(subIndex === -1 && focusData === "all") {

      if(value === "") return;

      const metainfo = {
        list_name : list_name,
        index : index,
        accessor : accessor,
        value : value,
      }
      setStorePopulateMetaInfo(metainfo);
      setPopulateConfirmPopup(true);
      
      return;
    }
    else {
      updateMultipleDeptList[list_name][index].category_list[subIndex][accessor] = value;
    }

    

    setMultipleDepartmentListdata(updateMultipleDeptList)
  }

  const populateValueToFields = (action_type) => {

    const { index , list_name , accessor , value } = storePopulateMetaInfo;

    if(action_type === "Yes") {
      const updateMultipleDeptList = _.cloneDeep(multipleDepartmentListdata);

      const exemptedDept = ["VACCINES", "DENTAL", "MEDICINES"];
      
      for(let i=0; i<updateMultipleDeptList[list_name][index].category_list.length; i++) {

        if(!exemptedDept.includes(updateMultipleDeptList[list_name][index].category_list[i]?.department_name)) {

          updateMultipleDeptList[list_name][index].category_list[i][accessor] = value;

        }

      }

      
      setMultipleDepartmentListdata(updateMultipleDeptList);
    }

    setStorePopulateMetaInfo({});
    setPopulateConfirmPopup(false);

  }

  
  useEffect(() => {
    
    const parseTableData = [];
    if(editrestrict) { // load description from API on edit mode on 1st time.
      
      // for(let i=0; i<departmentList?.length; i++) {
      //   const t = Object.assign({}, loadedDescriptionData[i])
      //   t.department_name = departmentList[i].name;
      //   parseTableData.push(t);
      // }

      const deptDescFromAPI = _.cloneDeep(loadedDescriptionData);

      for(let i=0; i<departmentList?.length; i++) {
        let formatData = {
          category : departmentList[i].category_name,
          category_list : []
        }
        
        let singleDeptList = []

        for(let j=0;j<deptDescFromAPI.length; j++) {


          if(deptDescFromAPI[j].category_name === departmentList[i].category_name) {

            for(let k=0;k<departmentList[i].department.length;k++) {

              if(deptDescFromAPI[j].department[k].department_id == departmentList[i].department[k].id) {

                let deptdata = _.cloneDeep(deptDescFromAPI[i].department[k]);

                console.log("DEPT DATA : " , deptdata);

                let t = {
                  ...deptdata, // Assuming descdata is an array of objects
                  dept_category_id: deptdata.dept_category_id,
                  department_id:deptdata.department_id,

                  department_name: departmentList[i].department[k].name,
                  
                  // active: deptdata.is_active,
                  out_patient: parseInt(deptdata.out_patient),
                  out_patient_discount: parseInt(deptdata.out_patient_discount),
                  in_patient: parseInt(deptdata.in_patient),
                  in_patient_discount: parseInt(deptdata.in_patient_discount),
                  co_ins_ongross: parseInt(deptdata.co_ins_ongross),
                  co_ins_onnet: parseInt(deptdata.co_ins_onnet),
                  co_pay_percentage: parseInt(deptdata.co_pay_percentage),
                  dedcut_amount: parseInt(deptdata.dedcut_amount),
                  per_request: parseInt(deptdata.per_request),
                  factor: parseInt(deptdata.factor),
                  bill_exceeds: parseInt(deptdata.bill_exceeds),
                  sort_by: parseInt(deptdata.sort_by),
                }

                deptDescFromAPI[j].department[k] = t;

                console.log("DEPT NAME : ", departmentList[i].department[k].name)

              } 

            }

            singleDeptList = deptDescFromAPI[j].department;

          }

        }      

        formatData.category_list = singleDeptList;
        console.log(departmentList[i]);
        console.log(i , " Edit Mode " , formatData);
        parseTableData.push(formatData);
      }
    }
    else {
      for(let i=0; i<departmentList?.length; i++) {
        let formatData = {
          category : departmentList[i].category_name,
          category_list : []
        }
        
        let singleDeptList = []

        // if multipledepartmentlist has existing data
        // if data is exist in multipledepartmentlistdata after selecting new dept category
        if(multipleDepartmentListdata.length !== 0) {
            
          let isExistCategory = false;

          for(let j=0;j<multipleDepartmentListdata['main_list'].length; j++) {

            if(multipleDepartmentListdata['main_list'][j].category === departmentList[i].category_name) {
              
              singleDeptList = multipleDepartmentListdata['main_list'][j].category_list;

              isExistCategory = true;

              break;

            }

          }

          // if data is not exist in multipledepartmentlistdata after selecting new dept category
          if(!isExistCategory) {

            for(let j=0;j<departmentList[i].department.length;j++) {

              const t = Object.assign({} , deptdescData);

              // if the plan detail is in edit mode, set desc_id is 0
              if(plan_detail_id !== 0) t.desc_id = 0;
    
              t.department_id = departmentList[i].department[j].id;
              t.department_name = departmentList[i].department[j].name;
              t.dept_category_id = departmentList[i].category_id

              singleDeptList.push(t);
            }

          }
        }
        else { // if all data is cleared or unchecked

          for(let j=0;j<departmentList[i].department.length;j++) {

            const t = Object.assign({} , deptdescData);

            // if data is cleared while in edit mode, set desc_id is 0
            if(plan_detail_id !== 0) t.desc_id = 0;
  
            t.department_id = departmentList[i].department[j].id;
            t.department_name = departmentList[i].department[j].name;
            t.dept_category_id = departmentList[i].category_id

            singleDeptList.push(t);
          }

        }

        formatData.category_list = singleDeptList;
        
        console.log(i , " " , formatData)
        parseTableData.push(formatData);
      }
    }

    const formattedMultipleDeptList = {
      main_list : parseTableData,
      sub_list : []
    }
    setMultipleDepartmentListdata(formattedMultipleDeptList)

  }, [departmentList, departmentCategoryAllList])

  // useEffect(() => {
  //   seteditrestrict(false);
  // }, [multipleDepartmentListdata])

  const getCenterList = async () => {
    await getData.getCenterList().then((resp) => {
      setClientList(resp.data.data);
      // setShowFieldLoad(false);
    }).catch((error) => {
      console.log(error);
    });
  };


  return (
    <>
      <div className="row">

        {/* <div className="col-md-9 col-xs-12"><Title icon={SVGICON.backCaret} title="Insurance" onClick={() => navigate("/insurance-list")} /></div> */}
        <div className="col-10 col-md-10 col-xs-8 col-sm-8"><Title title={`${title}`} /></div>
      </div>
      <div className="row bg_wrap b-radius no_margin" style={{ "padding": "18px 0px" }}>
        <div className="col-md-12">

          <div className="bg_wrap b-radius m-tab" style={{ 'minHeight': 'auto' }}>

            {
           
           insuranceUIDatas[0]?.insurance.map((formFeilds) => (
               
                <>
                  <div className="row">

                    <div className="col-md-12 col-xs-12 padding_fixed ">
                      <div className="row no_margin">
                        <div className="col-md-8 col-xs-12">
                        <div className="row no_margin ">     
                      {formFeilds.InsuranceDetails && formFeilds.InsuranceDetails.map((input) => (
                        <>
                        <div className={`col-md-${(input.name === "insurance_company_detail_code") ? "2" : (input.name === "insurance_company_detail_id")  ? "4" : 
                                                    (input.name === "hospital_detail_id"  ) ? "6":  input.name ==="co_insurance_exist_patient" || 
                                      
                                                    input.name === "deduct_exist_patient"?"6" : 
                                                    
                                                    input.name === "plan_require_approval" ? "6":"6"} col-xs-12 padding_fixed 
                                                    ${(input.name === "insurance_network_id" || input.name === "insurance_plan_id") ? "flex" : ""}`} key={input.id}>
                        {(input.type === "select") ?
                            // (input.name === "mediator_id") ? formData.form_type == 2 ?
                         <>
                            <Searchdropdown {...input}
                            options={
                              input.name === "hospital_detail_id" ? 
                              clientList :
                              input.name === "insurance_company_detail_id" ? 
                              companyList : 
                              input.name === "insurance_network_id" ? 
                              networklist : 
                              input.name === "insurance_plan_id" ? 
                              insplanlist : 
                              input.name === "dept_category_id" ? 
                              departmentCategoryAllList : 
                              []
                            }
                            onChange={handleChange}
                            value={formData[input.name]}
                            error={errors[input.name]}
                            showFieldLoad={showFieldLoad}
                            // error={errors[input.name]}
                          /> 
                      {  input.name === "insurance_network_id" ?   <Buttons.SaveIcon icon={SVGICON.searchDoc} onClick={() =>window.open('/#/network', '_blank') } />:input.name === "insurance_plan_id" ?<Buttons.SaveIcon icon={SVGICON.folderOpen} onClick={() => window.open('/#/insurance-plan-list', '_blank')} />:null}</>
                              :  (input.type === "checkBox") ? 
                                <TextFeildCheckBox {...input}
                                  onChange={handleChange}
                                  checked={formData[input.name] == 1 ? true : false}
                                  error={errors[input.name]}
                                 /> 
                              : (input.type === "radio") ? 
                                <TextFeildRadioBox
                                  {...input}
                                  options={input.options}
                                  onChange={handleChange}
                                  value={formData.before_discount ? 'before_discount' : 'after_discount'}
                                  checked={formData.before_discount == 1 ? true : false}
                                  style={{marginTop:'-10px'}}
                                  error={errors[input.name]}
                                />
                              
                              : (input.type === "text") ? 
                                <TextInput {...input} 
                                  onClick={input.name}
                                  onChange={handleChange}
                                  showFieldLoad={showFieldLoad}
                                  value={formData[input.name]}
                                  error={errors[input.name]}
                                /> 
                              : null }

                          </div>
                        
                             
</>
                        ))}
                        </div>
                        </div>
                        <div className={`col-md-4 right-align col-xs-12`} >
                             
                             <Multiselectwithcheckbox 
                             options={ departmentCategoryAllList
                             }
                             label={"Department Category"}
                             name={"dept_category_id"}
                             checkedList={checkedDepartmentCategory}
                             onClick={handleToggleController}
                             value={formData["dept_category_id"]}
                             error={errors["dept_category_id"]}
                             showFieldLoad={showFieldLoad}
                             // error={errors[input.name]}
                           /> 
</div>
                      </div>
                    </div>
                  </div>

                  
                </>
              ))
            }

          </div>

        </div>

        <div className="row no_margin">
            <div className="col-md-12 col-xs-12 no_padding tableMinHeightTab ins-plan-dtl-tbl">

                {/* <Table
                    key={DepartmentListTableHead.id}
                    columns={DepartmentListTableHead}
                    rowname={"id"}
                
                    hide={true}
                    rowdata={multipleDepartmentListdata}
                    serialNoRequired={true}
                    noNegativeValues={true}

                    checkboxchange={checkboxChange}
                    handleInputChange={handleInputChange}

                /> */}

                <ServiceCategoryTable
                  columns={DepartmentListTableHead}
                  rowname={"id"}
                  rowdata={multipleDepartmentListdata}
                  
                  checkboxchange={checkboxChange}
                  handleInputChange={handleInputChange}
                  sublisting={true}
                  listingInfo= {{
                    listingLabel : "category_list",
                    listingField : "category",
                    headerCheckbox : false,
                    dataCheckbox: true
                  }}

                  // serialNoRequired={true}
                />
            </div>
        </div>
      

        <div className="grid-child magrinTB">
          <div className="col-md-8 grid-child">
            {loading === true ? (
                <Spinner animation="border" variant="primary" />
              ) : null}
            <Buttons.SaveButtons onClick={(e) => formsubmit(e, 0)} name="Save"  />
            <Buttons.BackButton name="close" className="close-btn" onClick={() =>  navigate("/plan-details-list")} />
         
          </div>
        </div>

        <ToastContainer />

        <Popup 
            show={confirmPopup}
            handleClose={() => setConfirmPopup(false)}
            size="md"
        >
            <div className="text-center">
                <p className="fs-18">Do you want to uncheck the customised Department Category?</p>

                <div class="row">
                    <div className="modal-footer">
                        <div className="d-flex justify-content-center">
                            <Buttons.SaveButtons name="Yes" onClick={() => confirmPopupAction("Yes")} />
                            <Buttons.ResetButtons name="No" onClick={() => confirmPopupAction("No")} />

                        </div>
                    </div>
                </div>
            </div>
        </Popup>

        <Popup 
            show={popuplateConfirmPopup}
            handleClose={() => setPopulateConfirmPopup(false)}
            size="md"
        >
            <div className="text-center">
                <p className="fs-18">Are you sure to fill {storePopulateMetaInfo["value"]}% &nbsp;
                  
                  {
                    storePopulateMetaInfo["accessor"] === "out_patient_discount" ? "out patient discount" :
                    storePopulateMetaInfo["accessor"] === "in_patient_discount" ? "in patient discount" :
                    storePopulateMetaInfo["accessor"]
                  }
                  
                  &nbsp;for all protocols?</p>

                <div class="row">
                    <div className="modal-footer">
                        <div className="d-flex justify-content-center">
                            <Buttons.SaveButtons name="Yes" onClick={() => populateValueToFields("Yes")} />
                            <Buttons.ResetButtons name="No" onClick={() => populateValueToFields("No")} />

                        </div>
                    </div>
                </div>
            </div>
        </Popup>

        {loading ? (
            <Loader />
        ) : null}
      </div>
    </>
  )
}

export default InsuranceDetails