import { NETWORK_ACTION_TYPES } from '../../actions/primaryMaster/NetworkActionTypes';

const initialState = {
    name: "",
    network_type: "",
    insurance_company_detail_id: "",
    is_status: "",
    network_id: "",
    detailsData:[],
    loading:false,
    openModel :false,
  // Other initial state...
};

const NetworkReducer = (state = initialState, action) => {
  switch (action.type) {
    case NETWORK_ACTION_TYPES.SET_DETAILS_DATA:
        return {
            ...state,
            detailsData: action.payload,
        };
    case NETWORK_ACTION_TYPES.SET_NAME:
        return {
            ...state,
            name: action.payload,
        };
    case NETWORK_ACTION_TYPES.SET_NETWORK_TYPE:
        return {
            ...state,
            network_type: action.payload,
        };
    case NETWORK_ACTION_TYPES.SET_INSURANCE_COMPANY_DETAIL_ID:
        return {
            ...state,
            insurance_company_detail_id: action.payload,
        };
    case NETWORK_ACTION_TYPES.SET_IS_STATUS:
        return {
            ...state,
            is_status: action.payload,
        };
    case NETWORK_ACTION_TYPES.SET_NETWORK_ID:
        return {
            ...state,
            network_id: action.payload,
        };
    case NETWORK_ACTION_TYPES.SET_LOADING:
        return {
            ...state,
            loading: action.payload,
        };
    case NETWORK_ACTION_TYPES.SET_OPEN_MODAL:
        return {
            ...state,
            openModel: action.payload,
        };
    // Other cases...
    default:
      return state;
  }
};

export default NetworkReducer;
 