import { SMS_ACTION_TYPES } from '../../actions/primaryMaster/SmsActionTypes';

const initialState = {
    sms_id:"",
    name:"",
    short_code:"",
    english_message:"",
    arabic_message:"",
    detailsData:[],
    loading:false,
    openModel :false,
  // Other initial state...
};

const SmsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SMS_ACTION_TYPES.SET_DETAILS_DATA:
        return {
            ...state,
            detailsData: action.payload,
        };
    case SMS_ACTION_TYPES.SET_NAME:
        return {
            ...state,
            name: action.payload,
        };
    case SMS_ACTION_TYPES.SET_SHORT_CODE:
        return {
            ...state,
            short_code: action.payload,
        };
    case SMS_ACTION_TYPES.SET_ENGLISH_MESSAGE:
        return {
            ...state,
            english_message: action.payload,
        };
    case SMS_ACTION_TYPES.SET_ARABIC_MESSAGE:
        return {
            ...state,
            arabic_message: action.payload,
        };
    case SMS_ACTION_TYPES.SET_SMS_ID:
        return {
            ...state,
            sms_id: action.payload,
        };
    case SMS_ACTION_TYPES.SET_LOADING:
        return {
            ...state,
            loading: action.payload,
        };
    case SMS_ACTION_TYPES.SET_OPEN_MODAL:
        return {
            ...state,
            openModel: action.payload,
        };
    // Other cases...
    default:
      return state;
  }
};

export default SmsReducer;
 