import { SHIFT_ACTION_TYPES } from "../../actions/primaryMaster/ShiftActionTypes";

const initialState = {
    shift_id: "",
    shift_name: "",
    start_time: "",
    end_time: "",
    total_hours: "",
    start_date: "",
    end_date: "",
    work_session: "",
    is_enabled: 1,
    detailsData:[],
    loading:false,
    openModel :false,
};

const ShiftReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHIFT_ACTION_TYPES.SET_DETAILS_DATA:
        return {
            ...state,
            detailsData: action.payload,
    };
    case SHIFT_ACTION_TYPES.SET_SHIFT_ID:
      return {
        ...state,
        shift_id: action.payload,
      };
    case SHIFT_ACTION_TYPES.SET_SHIFT_NAME:
      return {
        ...state,
        shift_name: action.payload,
      };
    case SHIFT_ACTION_TYPES.SET_START_TIME:
      return {
        ...state,
        start_time: action.payload,
      };
    case SHIFT_ACTION_TYPES.SET_END_TIME:
      return {
        ...state,
        end_time: action.payload,
      };
    case SHIFT_ACTION_TYPES.SET_TOTAL_HOURS:
      return {
        ...state,
        total_hours: action.payload,
      };
    case SHIFT_ACTION_TYPES.SET_START_DATE:
      return {
        ...state,
        start_date: action.payload,
      };
    case SHIFT_ACTION_TYPES.SET_END_DATE:
      return {
        ...state,
        end_date: action.payload,
      };
    case SHIFT_ACTION_TYPES.SET_WORK_SESSION:
      return {
        ...state,
        work_session: action.payload,
      };
    case SHIFT_ACTION_TYPES.SET_IS_ENABLED:
      return {
        ...state,
        is_enabled: action.payload,
      };
    case SHIFT_ACTION_TYPES.SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case SHIFT_ACTION_TYPES.SET_OPEN_MODAL:
      return {
        ...state,
        openModel: action.payload,
      };
    // Other cases...
    default:
      return state;
  }
};

export default ShiftReducer;
