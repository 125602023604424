// store/actions/cityActionTypes.js

export const APP_STATUS_ACTION_TYPES = {
    SET_DETAILS_DATA: 'SET_DETAILS_DATA',
    SET_NAME:"SET_NAME",
    SET_BG_COLOR:"SET_BG_COLOR",
    SET_FONT_COLOR:"SET_FONT_COLOR",
    SET_MALAFFIE_STATUS:"SET_MALAFFIE_STATUS",
    SET_MALAFFIE_DESCRIPTION:"SET_MALAFFIE_DESCRIPTION",
    SET_BOOKING_ORDER:"SET_BOOKING_ORDER",
    SET_STATUS_ID:"SET_STATUS_ID",
    SET_LOADING:"SET_LOADING",
    SET_OPEN_MODAL:"SET_OPEN_MODAL",
    SET_SHOW_DELETE_MODAL:"SET_SHOW_DELETE_MODAL"
};
  