// store/actions/cityActionTypes.js

export const ETHNIC_ACTION_TYPES = {
    SET_DETAILS_DATA: 'SET_DETAILS_DATA',
    SET_ETHNIC_NAME:"SET_ETHNIC_NAME",
    SET_SHORT_CODE:"SET_SHORT_CODE",
    SET_ETHNIC_ID:"SET_ETHNIC_ID",
    SET_LOADING:"SET_LOADING",
    SET_OPEN_MODAL:"SET_OPEN_MODAL"
};
  