import React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import SVGICON from '../constant/theme';
const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const FileUploadButton = ({ value, label, title, name, type, onChange, required, error, size , accept,reload=true}) => {
    return (
        <div class="wrap-field wrap-upload-button">
            <Button
                component="label"
                role={undefined}
                variant="contained"
                tabIndex={-1}
                startIcon={SVGICON.upload}
                onChange={onChange}
            >
                Upload
               { reload && <VisuallyHiddenInput name={name} onChange={onChange} type="file" accept={accept} />} 
            </Button>
        </div>

    );
}
export default FileUploadButton;