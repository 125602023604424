// store.js
import { configureStore } from '@reduxjs/toolkit';
import PostsReducer from './reducers/PostsReducer';
import thunk from 'redux-thunk';
import { AuthReducer } from './reducers/AuthReducer';
import { CommonReducer } from './reducers/CommonReducer';
import todoReducers from './reducers/Reducers';
import CityReducer from './reducers/primaryMaster/CityReducer';
import RegionReducer from './reducers/primaryMaster/RegionReducer';
import AllMasterListReducer from './reducers/AllMasterListReducer';
import PatientClassReducer from './reducers/primaryMaster/PatientClassReducer';
import OccupationReducer from './reducers/primaryMaster/OccupationReducer';
import ReasonReducer from './reducers/primaryMaster/ReasonReducer';
import RefferalReducer from './reducers/primaryMaster/RefferalReducer';
import ServiceReducer from './reducers/primaryMaster/ServiceReducer';
import EducationReducer from './reducers/primaryMaster/EducationReducer';
import ReligionReducer from './reducers/primaryMaster/ReligionReducer';
import EthnicReducer from './reducers/primaryMaster/EthnicReducer';
import LanguageReducer from './reducers/primaryMaster/LanguageReducer';
import IncomeRangeReducer from './reducers/primaryMaster/IncomeRangeReducer';
import NetworkReducer from './reducers/primaryMaster/NetworkReducer';
import InsurancePlanReducer from './reducers/primaryMaster/InsurancePlanReducer';
import SmsReducer from './reducers/primaryMaster/SmsReducer';
import AppointmentStatusReducer from './reducers/primaryMaster/AppointmentStatusReducer';
import BenefitReducer from './reducers/primaryMaster/BenefitReducer';
import AllMasterMenuListReducer from './reducers/AllMasterMenuListReducer';
import SearchReducers from './reducers/SearchReducers';
import AllMenuListReducer from './reducers/AllMenuListReducer';
import ShiftReducer from './reducers/primaryMaster/ShiftReducer';
// import Refferal from '../jsx/components/Masters/Refferal';
// import ChargeTypeReducer from './reducers/primaryMaster/ChargeTypeReducer';
// import CompanyTypeReducer from './reducers/primaryMaster/CompanyTypeReducer';

const store = configureStore({
  reducer: {
    posts: PostsReducer,
    auth: AuthReducer,
    common:CommonReducer,
    todoReducers,
    city:CityReducer,
    region:RegionReducer,
    allMasterList:AllMasterListReducer,
    patientCls:PatientClassReducer,
    occupation:OccupationReducer,
    education:EducationReducer,
    religion:ReligionReducer,
    ethnic:EthnicReducer,
    language:LanguageReducer,
    incomeRange:IncomeRangeReducer,
    allMasterMenuList:AllMasterMenuListReducer,
    search:SearchReducers,
    allMenuList: AllMenuListReducer,
    reason:ReasonReducer,
    service:ServiceReducer,
    refferal:RefferalReducer,
    network:NetworkReducer,
    benefit:BenefitReducer,
    insuranceplan:InsurancePlanReducer,
    sms:SmsReducer,
    appstatus:AppointmentStatusReducer,
    shift:ShiftReducer,
    // companytype:CompanyTypeReducer,
    // Add other reducers as needed
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk),
  devTools: process.env.NODE_ENV !== 'production',
});

export default store;
