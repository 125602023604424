// store/actions/cityActionTypes.js

export const LANGUAGE_ACTION_TYPES = {
    SET_DETAILS_DATA: 'SET_DETAILS_DATA',
    SET_LANGUAGE_NAME:"SET_LANGUAGE_NAME",
    SET_SHORT_CODE:"SET_SHORT_CODE",
    SET_LANGUAGE_ID:"SET_LANGUAGE_ID",
    SET_LOADING:"SET_LOADING",
    SET_OPEN_MODAL:"SET_OPEN_MODAL"
};
  