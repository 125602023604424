// delete indurance//
import React, { useState, useRef, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Title from "../../utils/Titles/Title";
import * as getData from '../../../services/APIService';
import * as getAPIAction from "../../../store/actions/GetAllMasterList";
import { ALL_MASTER_ACTION_TYPES } from "../../../store/actions/AllMasterActionTypes";
import  Searchdropdown from '../../utils/Searchdropdown'
import Buttons from '../../utils/Button'
import Table from '../../utils/Newtable/Table'
import { tableData } from '../../utils/json/tableData'
import { toast, ToastContainer } from "react-toastify";
import TextFeildCheckBox from '../../utils/TextFeildCheckBox';
import "react-toastify/dist/ReactToastify.css";
import * as insuranceplanActionData from "./../../../store/actions/primaryMaster/InsuranceplanAction";
import Spinner from "react-bootstrap/Spinner";
import { logoutFunction } from "../../../store/actions/LogoutActions";
import { useDispatch, useSelector } from 'react-redux';
import { insuranceUIDatas } from "../../utils/json/insuranceUIDatas";
import * as _ from "lodash" 
import TextInput from "../../utils/TextInput";
import { setLoading } from "../../../store/actions/CommonActions";
import Loader from "../Loader/Loader";
import { NavMenuOn, NavMenuToggle } from "../../layouts/nav/NavHader";
import { ThemeContext } from "../../../context/ThemeContext";

const moment = require('moment');
const InsurancePlan = () => {

  const navigate = useNavigate();
  const hash = window.location.hash;
  const queryString = hash.split('?')[1];
  const params = new URLSearchParams(queryString);
  const [plan_id, setPlanid] = useState(params.size > 0 ? params.get('planID') : 0);
  const [editrestrict , seteditrestrict] = useState(false);
  const { toggleSideMenu } = useContext(ThemeContext);

  const [showFieldLoad, setShowFieldLoad] = useState(false);

  let errorsObj = { plan_name: "", insurance_network_id: "",hospital_detail_id:"", insurance_company_detail_id: "",network_separate_price:"", is_status:""};
	const userData = useSelector((state) => state.auth.auth);
	const userToken = userData.idToken;
	const userId = userData.userId;
	const [hospital_detail_id, sethospital_detail_id] = useState(parseInt(userData.hospital_detail_id));
	const [clientList, setClientList] = useState([]);
	const insurance_company_detail_id = useSelector((state) => state.insuranceplan.insurance_company_detail_id);
    const insurance_network_id = useSelector((state) => state.insuranceplan.insurance_network_id);
    const plan_name = useSelector((state) => state.insuranceplan.plan_name);
   
	const network_separate_price = useSelector((state) => state.insuranceplan.network_separate_price);
	const [editMode, setEditMode] = useState(false);
	const [searchedVal, setSearchedVal] = useState("");


	const maincompanylist = useSelector((state) => state.allMasterList.maincompanylist);
    const networklist = useSelector((state) => state.allMasterList.insnetworklist);
	const detailsData = useSelector((state) => state.insuranceplan.detailsData);
	const filterdata = useSelector((state) => state.search.filterdata);
	const insplanlist = useSelector((state) => state.allMasterList.insplanlist);

  const loading = useSelector((state) => state.common.showLoading)

  const [title , setTitle] = useState("Add Insurance Plan");

  const [errors, setErrors] = useState(errorsObj);
  const dispatch = useDispatch();

  const logId = parseInt(userData.logId);
  const emailValidator = (email) => {
    const Regexp = new RegExp(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)
    return Regexp.test(email)
  }
 

  const createInsplan = (e) => {
	
	dispatch(insuranceplanActionData.setLoading(true));
	e.preventDefault();
	let error = false;
	const errorObj = { ...errorsObj };

	if (plan_name === "") {
		errorObj.plan_name = "Name Is Required";
		error = true;
	}
	if (insurance_network_id === "") {
		errorObj.insurance_network_id = "Network  Is Required";
		error = true;
	}
	if (hospital_detail_id === "") {
		errorObj.hospital_detail_id = "Choose Location Center";
		error = true;
	}
	if (insurance_company_detail_id === "") {
		errorObj.insurance_company_detail_id = "Choose Insurance Company";
		error = true;
	}
	// if (is_status === "") {
	// 	errorObj.is_status = "Choose Status";
	// 	error = true;
	// }
	
	setErrors(errorObj);
	if (error) {
		console.log(errorObj)
		dispatch(insuranceplanActionData.setLoading(false));
		return;
	}
	
	dispatch(
		insuranceplanActionData.addInsplanAPI(
			userId,
			logId,
			userToken,
			navigate,
			editMode,
			detailsData,
			plan_id,
			hospital_detail_id,
			insurance_company_detail_id,
insurance_network_id,
plan_name,
network_separate_price,
		)
	);
};


  const shouldLog = useRef(true);
  useEffect(() => {
    if (shouldLog.current) {
      shouldLog.current = false;
     dispatch(getAPIAction.GetInsMainCompanyList(userId, logId, userToken, navigate));
	 getClientListAPI();
    }

    toggleSideMenu(true); 
    NavMenuOn();

  }, []);
  const getinsplandata  = async (rowData) => {
		
	setShowFieldLoad(true);
	
	try {
		const response = await getData.getSingleInsPlan(rowData, userToken);

		dispatch(insuranceplanActionData.setDetailsData(response.data.data));
sethospital_detail_id(response.data.data[0].hospital_detail_id)
		dispatch(insuranceplanActionData.setPlanName(response.data.data[0].plan_name));
		dispatch(getAPIAction.GetNetworkInsCompanyWise(userId, logId, userToken, navigate, response.data.data[0].insurance_company_detail_id));
		dispatch(
			insuranceplanActionData.setInsuranceNetwork_id(response.data.data[0].insurance_network_id)
		);
		dispatch(
			insuranceplanActionData.setInsurance_company_detail_id(response.data.data[0].insurance_company_detail_id)
		);
	
		dispatch(
			insuranceplanActionData.setNetworkSeparatePrice(response.data.data[0].network_separate_price)
		);
		
		setPlanid(response.data.data[0].plan_id)
		
		setShowFieldLoad(false);
		
		setErrors({});
	} catch (error) {
		// console.error('Error fetching details:', error);
		setShowFieldLoad(false);
		if (error.response.data.status === 2) {
			toast.error(
				error.response.data.message + ". Please log in again to continue.",
				{
					position: toast.POSITION.TOP_RIGHT,
					autoClose: 1000,
				}
			);
			setTimeout(() => {
				dispatch(logoutFunction(userId, logId, userToken, navigate));
			}, 2000);
		} else {
			toast.error(
				error.response.data.message + " Please Try After Sometimes" ||
					"Too Many Attempts. Please Try after sometimes",
				{
					position: toast.POSITION.TOP_RIGHT,
					autoClose: 1000,
				}
			);
		}
	}
};
  useEffect(() => {
    if (plan_id !== 0) {
		setEditMode(true);
      seteditrestrict(true);
      getinsplandata(plan_id)

    }


  }, [plan_id])
  //Get Center name API call
  const getClientListAPI = async () => {
	await getData.getClientList(userToken).then((resp) => {
		setClientList(resp.data.data);
	}).catch((error) => {
		if (error.response.data.status === 2) {
			toast.error(error.response.data.message + ". Please log in again to continue.", {
				position: toast.POSITION.TOP_RIGHT,
				autoClose: 1000,
			});
			setTimeout(() => {
				dispatch(logoutFunction(userId, logId, userToken, navigate));
			}, 2000)
		} else {
			toast.error(error.response.data.message + " Please Try After Sometimes" ||
				"Too Many Attempts. Please Try after sometimes", {
				position: toast.POSITION.TOP_RIGHT,
				autoClose: 1000,
			});
		}
	});
};
  const isValidURL = (url) => {
    // Regular expression for URL validation
    const pattern = /^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]+\.[a-z]{2,}(\.[a-z]{2,})?$/;
    return pattern.test(url);
  };


  return (
    <>
      <div className="row">

        {/* <div className="col-md-9 col-xs-12"><Title icon={SVGICON.backCaret} title="Insurance" onClick={() => navigate("/insurance-list")} /></div> */}
        <div className="col-10 col-md-10 col-xs-8 col-sm-8"><Title title={`${editMode ? 'Edit' : 'Add'} Insurance Plan`} /></div>
      </div>
      <div className="row bg_wrap b-radius no_margin" style={{ "padding": "18px 0px" }}>
        <div className="col-md-12">

          <div className="bg_wrap b-radius m-tab" style={{ 'minHeight': 'auto' }}>

            {
           
           insuranceUIDatas[0]?.insurance.map((formFeilds) => (
               
                <>
                  <div className="row">

                    <div className="col-md-12 col-xs-12 padding_fixed">
                      <div className="row no_margin">
                      {formFeilds.Insuranceplan && formFeilds.Insuranceplan.map((input) => (
              
                          <div className="col-md-3 col-xs-12 padding_fixed" key={input.id}>
                        { input.type === "select" ? (
							<>
								<Searchdropdown
									{...input}
									options={ 
										input.label === "Company Name" 
										? maincompanylist 
										: input.label === "Network Name" 
										? networklist :
										input.label === "Location Center"?clientList
										: null
									}
									value={ 
										input.label === "Company Name" 
										? insurance_company_detail_id 
										: input.label === "Network Name" 
										? insurance_network_id 
										:input.label === "Location Center"?hospital_detail_id
										: null 
									}
									onChange={(e) => {
									
										if (input.label === "Network Name") {
											dispatch(insuranceplanActionData.setInsuranceNetwork_id(e.target.value));
											setErrors((prevErrors) => ({
												...prevErrors,
												insurance_network_id: "",
											}));
										}
										else if (input.label === "Location Center") {
											sethospital_detail_id(e.target.value)
											setErrors((prevErrors) => ({
												...prevErrors,
												hospital_detail_id: "",
											}));
										}
										else {
										    dispatch({ type: ALL_MASTER_ACTION_TYPES.SET_NETWORK_LIST1, payload: [] });
											dispatch(insuranceplanActionData.setInsurance_company_detail_id(e.target.value));
											dispatch(getAPIAction.GetNetworkInsCompanyWise(userId, logId, userToken, navigate, e.target.value));
											setErrors((prevErrors) => ({
												...prevErrors,
												insurance_company_detail_id: "",
											}));
										}
									}}
								/>
								<div>
									{
										input.label === "Network Name"
										? errors.insurance_network_id && (
												<div className="text-danger fs-13">
													{errors.insurance_network_id}
												</div>
											)
										: input.label === "Company Name" 
										? errors.insurance_company_detail_id && (
												<div className="text-danger fs-13">
													{errors.insurance_company_detail_id}
												</div>
											)
											: input.label === "Loaction Center" 
										? errors.hospital_detail_id && (
												<div className="text-danger fs-13">
													{errors.hospital_detail_id}
												</div>
											)
										: null
									}
								</div>
							</>
						) : (input.type === "checkbox") ? (<TextFeildCheckBox {...input}
							onChange={(e) => {
							 if(input.name === "network_separate_price")
										{
											dispatch(
												insuranceplanActionData.setNetworkSeparatePrice(
													e.target.checked?1:0
												)
											)
										} 
							
									}}
									checked={(input.name === "network_separate_price") ? (network_separate_price == 1 ? true : false)  : ""}

					
					   />):(<>
						<TextInput
							{...input}
							value={
								input.name === "plan_name" ? plan_name :null

							}
							onFocus={(e) => {
								if (e.target.value === "" && errors.plan_name) {
									setErrors((prevErrors) => ({
										...prevErrors,
										plan_name: "",
									}));
								} 
								
							}}
							onChange={(e) =>{
								if(input.name === "plan_name")
									{
										dispatch(
											insuranceplanActionData.setPlanName(
												e.target.value.replace(/[^a-zA-Z\s]/, "")
											)
										)
										setErrors((prevErrors) => ({
											...prevErrors,
											plan_name: "",
										}));
									}
								
							}}
						/>
						<div>
							{input.label === "Plan Name"
								? errors.name && (
										<div className="text-danger fs-13">
											{errors.plan_name}
										</div>
									)
								
								: null} 
						</div>
					</>)}

                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </>
              ))
            }

          </div>

        </div>

     
      

        <div className="grid-child magrinTB">
          <div className="col-md-8 grid-child">
            
            <Buttons.SaveButtons onClick={createInsplan} name="Save"  />
              
            <Buttons.BackButton name="close" className="close-btn" onClick={() =>  navigate("/insurance-plan-list")} />
         
          </div>
        </div>

        <ToastContainer />
        {loading ? (
            <Loader />
        ) : null}
      </div>
    </>
  )
}

export default InsurancePlan