// store/actions/cityActionTypes.js

export const PATIENT_CLASS_ACTION_TYPES = {
    SET_DETAILS_DATA: 'SET_DETAILS_DATA',
    SET_PATIENT_CLASS:"SET_PATIENT_CLASS",
    SET_SHORT_CODE:"SET_SHORT_CODE",
    SET_PATIENT_CLASS_ID:"SET_PATIENT_CLASS_ID",
    SET_LOADING:"SET_LOADING",
    SET_OPEN_MODAL:"SET_OPEN_MODAL"
};
  