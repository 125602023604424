import axios from 'axios';
import * as urlAPI from  '../jsx/constant/configuration';


// const getHeadersWithTokenPost = () => {
//     const userToken = localStorage.getItem('userToken');
//     if (userToken) {
//         const trimmedToken = userToken.trim().replace(/^"(.*)"$/, '$1');

//         return {
//             'Content-Type': 'multipart/form-data',
//             Authorization: `Bearer ${trimmedToken}`
//         };
//     }
//     console.warn('User token not found in localStorage');
//     return {};
// };

// export const getUserId = () => {
//     const userID = localStorage.getItem('userId');
//     if (userID) {
//         // console.log(userID,"usadas")
//         return userID;
//     }
//     return {};
// };
// export const getRoleId = () => {
//     const roleID = localStorage.getItem('roleId');
//     if (roleID) {
//         // console.log(roleID,"usadas")
//         return roleID;
//     }
//     return {};
// };
// export const userIdToken =parseInt(getUserId('user'));
// export const roleIdToken = parseInt(getRoleId('role'));

const getHeadersWithTokens= (userToken) => {
    if (userToken) {
        return {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userToken}`
        };
    }
    console.warn('User token not found in localStorage');
    return {};
};

const getHeadersWithTokenPosts = (userToken) => {
    if (userToken) {
        return {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${userToken}`
        };
    }
    console.warn('User token not found in localStorage');
    return {};
};

export function logoutAPI(userId, logId, userToken) {
    const headers = getHeadersWithTokens(userToken);
    const postData = {
        user_id:userId,
        log_id:logId,
        // returnSecureToken: true,
    };
    return axios.post(
        urlAPI.logoutURL,
        postData, 
        {headers}
    );
}

export function changePasswordAPI(postData, userToken) {
    const headers = getHeadersWithTokens(userToken);
    
    return axios.post(
        urlAPI.changePasswordURL,
        postData, 
        {headers}
    );
}

export function getUserProfile(userId, userToken) {
    const headers = getHeadersWithTokens(userToken);
    const postData = {
        user_id: userId,
    }
    return axios.post(
        urlAPI.profileURL, 
        postData,
        {headers}
    );
}

export function getClientDataList(userToken, userId, roleId) {
    const headers = getHeadersWithTokens(userToken);
    const postDataClient = {
        user_id: userId,
        role_id: roleId
    }
    return axios.post(
        urlAPI.getClientDataURL, 
        postDataClient,
        {headers}
    );
}

export function getSingleClientDataList(hospitalId,userToken,userId, roleId) {
    const headers = getHeadersWithTokens(userToken);
    const postDataClient = {
        user_id: userId,
        role_id: roleId,
        hospital_id: hospitalId,
    }
    return axios.post(
        urlAPI.getSingleClientDataURL, 
        postDataClient,
        {headers}
    );
}

export function postClientData(postData,userToken) {
    const headers = getHeadersWithTokenPosts(userToken);
    return axios.post(
        urlAPI.getClientPostURL,
        postData, 
        {headers}
    );
}
export function updateClientData(postData,userToken) {
    const headers = getHeadersWithTokenPosts(userToken);
    return axios.post(
        urlAPI.updateClientPostURL,
        postData, 
        {headers}
    );
}

export function getSingleUserDataList(hospitalId,roleId, userToken) {
    const headers = getHeadersWithTokens(userToken);
    const postDataClient = {
        user_id: hospitalId,
        role_id: roleId,
    }
    return axios.post(
        urlAPI.getUserSingleDataURL, 
        postDataClient,
        {headers}
    );
}

export function updateUserData(postData, userToken) {
    const headers = getHeadersWithTokenPosts(userToken);
    return axios.post(
        urlAPI.updateUserPostURL,
        postData, 
        {headers}
    );
}
export function postUserData(postData, userToken) {
    const headers = getHeadersWithTokenPosts(userToken);
    return axios.post(
        urlAPI.getUserPostURL,
        postData, 
        {headers}
    );
}

export function GetInsCompanyList(userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.get(
        urlAPI.GetInsCompanyListURL, 
        {headers}
    );
}

export function GetInsMainCompanyList(userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.get(
        urlAPI.GetInsMainCompanyListURL, 
        {headers}
    );
}

export function GetInsSubCompanyList(companyId,userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.get(
        urlAPI.GetInsSubCompanyListURL + `/${companyId}`, 
        {headers}
    );
}

export function GetNetworkInsCompanyWise(companyId,userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.get(
        urlAPI.GetNetworkInsCompanyWiseURL + `/${companyId}`,
        {headers}
    );
}
export function GetPlanNetworkWise(network_id,userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.get(
        urlAPI.GetPlanNetworkWiseURL + `/${network_id}`,
        {headers}
    );
}
export function GetBenefitPackageCompanyWise(companyId,userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.get(
        urlAPI.GetBenefitPackageCompanyWiseURL + `/${companyId}`,
        {headers}
    );
}

export function GetNetworkPlanCompanyWise(postData,userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.post(
        urlAPI.GetNetworkPlansCompanyWiseURL,
        postData,
        {headers}
    );
}

export function CopyInsurancePlanDetails(postData,userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.post(
        urlAPI.CopyInsurancePlanDetailsURL,
        postData,
        {headers}
    );
}

export function getCountryList(userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.get(
        urlAPI.getCountryURL, 
        {headers}
    );
}

///Get All City API List
export function getAllCityFunction(userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.get(
        urlAPI.getAllCityListURL, 
        {headers}
    );
}

///Get All Region API List
export function getAllRegionFunction(userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.get(
        urlAPI.getAllRegionListURL, 
        {headers}
    );
}

///Get All Job Title API List
export function getJobTitleList(userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.get(
        urlAPI.getJobTitlListURL, 
        {headers}
    );
}

///Get All Gender API List
export function getGenderList(userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.get(
        urlAPI.getGenderListURL, 
        {headers}
    );
}

///Get MartialStatus
export function getMaritalStatusList(userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.get(
        urlAPI.getMaritalStatusListURL, 
        {headers}
    );
}

///Get Patient class 
export function getPatientClassList(userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.get(
        urlAPI.getPatientClassListURL, 
        {headers}
    );
}
///Get Blood Group 
export function getBloodGroupList(userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.get(
        urlAPI.getBloodGroupListURL, 
        {headers}
    );
}
///Get GetReferralSourceList Group 
export function getReferralSourceList(userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.get(
        urlAPI.getReferralSourceListURL, 
        {headers}
    );
}
///Get GetReferralChannelList Group 
export function getReferralChannelList(userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.get(
        urlAPI.getReferralChannelListURL, 
        {headers}
    );
}
///Get Occupation 
export function getOccupationList(userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.get(
        urlAPI.getOccupationListURL, 
        {headers}
    );
}
///Get servicelist 
export function GetEnquiryServiceList(userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.get(
        urlAPI.GetEnquiryServiceListURL, 
        {headers}
    );
}
///Get reason list 
export function GetEnquiryReasonList(userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.get(
        urlAPI.GetEnquiryReasonListURL, 
        {headers}
    );
}
///Get refferal doctorlist 
export function getReferralDoctorList(userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.get(
        urlAPI.GetReferralDoctorListURL, 
        {headers}
    );
}
export function getReferralClinicList(userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.get(
        urlAPI.GetReferralClinicListURL, 
        {headers}
    );
}
///Get doctorlist 
export function getDoctorList(userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.get(
        urlAPI.GetDoctorListURL, 
        {headers}
    );
}
///Get stafflist 
export function getStaffList(userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.get(
        urlAPI.GetStaffListURL, 
        {headers}
    );
}
///Get chargeTypes 
export function getChargeTypeList(userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.get(
        urlAPI.getChargeTypeListURL, 
        {headers}
    );
}
///Get companytype 
export function getCompanyTypeList(userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.get(
        urlAPI.getCompanyTypeListURL, 
        {headers}
    );
}
///Get Education 
export function getEducationList(userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.get(
        urlAPI.getEducationListURL, 
        {headers}
    );
}

///Get Religion 
export function getReligionList(userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.get(
        urlAPI.getReligionListURL, 
        {headers}
    );
}

///Get Ethnic 
export function getEthnicList(userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.get(
        urlAPI.getEthnicListURL, 
        {headers}
    );
}
///Get Industry 
export function getIndustryList(userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.get(
        urlAPI.getIndustryListURL, 
        {headers}
    );
}
///Get Languages 
export function getLanguageList(userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.get(
        urlAPI.getLanguageListURL, 
        {headers}
    );
}

///Get IncomeRange 
export function getIncomeRangeList(userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.get(
        urlAPI.getIncomeRangeListURL, 
        {headers}
    );
}

///Get Relationship 
export function getRelationshipList(userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.get(
        urlAPI.getRelationshipListURL, 
        {headers}
    );
}

///Get Nework 
export function viewNetworkList(userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.get(
        urlAPI.viewNetworkListURL, 
        {headers}
    );
}
///Get shift list
export function GetShiftList(userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.get(
        urlAPI.GetShiftListURL, 
        {headers}
    );
}
///Get Doctor Master list
export function GetDoctorMasterList(userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.get(
        urlAPI.GetDoctorMasterListURL, 
        {headers}
    );
}
///Get Visit Type list
export function GetVisitTypeList(userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.get(
        urlAPI.GetVisitTypeListURL, 
        {headers}
    );
}
export function GetExamDropdownList(userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.get(
        urlAPI.GetExamDropdownListURL, 
        {headers}
    );
}
export function GetStatusDropdownList(userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.get(
        urlAPI.GetStatusDropdownListURL, 
        {headers}
    );
}

//Get sms Template
export function viewSmsTemplateList(userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.get(
        urlAPI.viewSmsTemplateListURL, 
        {headers}
    );
}
//Get Appointment Status
export function viewAppointmentStatusList(userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.get(
        urlAPI.viewAppointmentStatusListURL, 
        {headers}
    );
}
//Get insurance plan
export function viewInsPlanList(userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.get(
        urlAPI.viewInsPlanListURL, 
        {headers}
    );
}
//Get insurance plan details
export function viewInsPlanDetailsList(userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.get(
        urlAPI.viewInsPlanDetailsListURL, 
        {headers}
    );
}
//Get service category list by plan, network and insurance cmpy
export function viewServiceCategoryListBySelectedPlan(userToken, postData) {
    const headers = getHeadersWithTokens(userToken);
    return axios.post(
        urlAPI.viewServiceCategoryListBySelectedPlanURL, 
        postData,
        {headers}
    );
}
///Get Benefit Package 
export function viewInsPackageList(userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.get(
        urlAPI.viewInsPackageListURL, 
        {headers}
    );
}
export function getRegionList(countryId, userToken) {
    const headers = getHeadersWithTokens(userToken);
    const postData = {
        country_id: countryId,
    };
    return axios.post(
        urlAPI.getRegionListURL,
        postData, 
        {headers}
    );
}

export function getCityList(countryId, regionId, userToken) {
    const headers = getHeadersWithTokens(userToken);
    const postData = {
        country_id: countryId,
        region_id: regionId,
    };
    return axios.post(
        urlAPI.getCityListURL,
        postData, 
        {headers}
    );
}

export function getHealthAuthorityRegionList(userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.get(
        urlAPI.getHealthAuthorityRegionListURL, 
        {headers}
    );
}

///Get All Department  API List
export function getAllDepartmentFunction(userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.get(
        urlAPI.getAllDepartmentListURL, 
        {headers}
    );
}

///Get All Department Cateogry API List
export function getAllDepartmentCategoryFunction(userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.get(
        urlAPI.getAllDepartmentCategoryListURL, 
        {headers}
    );
}

//Get Client details
export function getClientList(userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.get(
        urlAPI.getClientListURL, 
        {headers}
    );
}

//Get Client details on login
export function getCenterList() {
    // const headers = getHeadersWithTokens(userToken);
    return axios.get(
        urlAPI.getCenterListURL,
    );
}

//Get User Details
export function getUserListData(user_id,role_id, userToken) {
    const headers = getHeadersWithTokens(userToken);
    const postData = {
        user_id: user_id,
        role_id: role_id,
    };
    return axios.post(
        urlAPI.getUserListDataURL, 
        postData,
        {headers}
    );
}

export function getDepartmentList(categoryId, userToken) {
    const headers = getHeadersWithTokens(userToken);
    const postData = {
        category_id: categoryId,
    };
    return axios.post(
        urlAPI.getDepartmentListURL,
        postData, 
        {headers}
    );
}
export function AddPatientEnquiry(postData, userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.post(
        urlAPI.AddPatientEnquiryURL,
        postData, 
        {headers}
    );
}
export function UpdatePatientEnquiry(postData, userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.post(
        urlAPI.UpdatePatientEnquiryURL,
        postData, 
        {headers}
    );
}
export function addCity(postData, userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.post(
        urlAPI.addCityURL,
        postData, 
        {headers}
    );
}
export function AddNewInsCompany(postData, userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.post(
        urlAPI.AddNewInsCompanyURL,
        postData, 
        {headers}
    );
}
export function UpdateInsCompany(postData, userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.post(
        urlAPI.UpdateInsCompanyURL,
        postData, 
        {headers}
    );
}
export function UpdateInsCompanyStatus(postData, userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.post(
        urlAPI.UpdateInsCompanyStatusURL,
        postData, 
        {headers}
    );
}
export function updateCity(postData,userToken) {

    const headers = getHeadersWithTokens(userToken);
    return axios.post(
        urlAPI.updateCityURL,
        postData, 
        {headers}
    );
}

export function addJobTitle(postData,userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.post(
        urlAPI.addJobTitleURL,
        postData, 
        {headers}
    );
}

export function updateJobTitle(postData, userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.post(
        urlAPI.updateJobTitleURL,
        postData, 
        {headers}
    );
}

export function addRegion(postData, userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.post(
        urlAPI.addRegionURL,
        postData, 
        {headers}
    );
}

export function updateRegion(postData, userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.post(
        urlAPI.updateRegionURL,
        postData, 
        {headers}
    );
}

export function addTitle(postData, userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.post(
        urlAPI.addTitleURL,
        postData, 
        {headers}
    );
}

export function updateTitle(postData, userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.post(
        urlAPI.updateTitleURL,
        postData, 
        {headers}
    );
}

export function addNewDeptCategory(postData, userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.post(
        urlAPI.addNewDeptCategoryURL,
        postData, 
        {headers}
    );
}

export function updateCategory(postData, userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.post(
        urlAPI.updateCategoryURL,
        postData, 
        {headers}
    );
}

export function addNewDepartment(postData, userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.post(
        urlAPI.addNewDepartmentURL,
        postData, 
        {headers}
    );
}

export function updateDepartment(postData, userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.post(
        urlAPI.updateDepartmentURL,
        postData, 
        {headers}
    );
}

export function addNewNationality(postData, userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.post(
        urlAPI.addNewNationalityURL,
        postData, 
        {headers}
    );
}

export function updateNationality(postData, userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.post(
        urlAPI.updateNationalityURL,
        postData, 
        {headers}
    );
}

export function getRoleList(roleId, userToken) {
    const headers = getHeadersWithTokens(userToken);
    const postData = {
        role_id: roleId,
    };
    return axios.post(
        urlAPI.getRoleListURL,
        postData, 
        {headers}
    );
}

export function addNewMaritalStatus(postData, userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.post(
        urlAPI.addNewMaritalStatusURL,
        postData, 
        {headers}
    );
}

export function addNewPatientClass(postData, userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.post(
        urlAPI.addNewPatientClassURL,
        postData, 
        {headers}
    );
}

export function updatePatientClass(postData, userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.post(
        urlAPI.updatePatientClassURL,
        postData, 
        {headers}
    );
}

export function addNewOccupation(postData, userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.post(
        urlAPI.addNewOccupationURL,
        postData, 
        {headers}
    );
}

export function updateOccupation(postData, userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.post(
        urlAPI.updateOccupationURL,
        postData, 
        {headers}
    );
}
export function addNewReason(postData, userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.post(
        urlAPI.addNewReasonURL,
        postData, 
        {headers}
    );
}

export function updateReason(postData, userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.post(
        urlAPI.updateReasonURL,
        postData, 
        {headers}
    );
}
export function AddReferralDoctor(postData, userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.post(
        urlAPI.AddReferralDoctorURL,
        postData, 
        {headers}
    );
}

export function UpdateReferralDoctor(postData, userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.post(
        urlAPI.UpdateReferralDoctorURL,
        postData, 
        {headers}
    );
}
export function addNewService(postData, userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.post(
        urlAPI.addNewServiceURL,
        postData, 
        {headers}
    );
}

export function updateService(postData, userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.post(
        urlAPI.updateServiceURL,
        postData, 
        {headers}
    );
}

export function addNewEducation(postData, userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.post(
        urlAPI.addNewEducationURL,
        postData, 
        {headers}
    );
}

export function updateEducation(postData, userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.post(
        urlAPI.updateEducationURL,
        postData, 
        {headers}
    );
}

export function addNewReligion(postData, userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.post(
        urlAPI.addNewReligionURL,
        postData, 
        {headers}
    );
}

export function updateReligion(postData, userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.post(
        urlAPI.updateReligionURL,
        postData, 
        {headers}
    );
}

export function addNewEthnic(postData, userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.post(
        urlAPI.addNewEthnicURL,
        postData, 
        {headers}
    );
}

export function updateEthnic(postData, userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.post(
        urlAPI.updateEthnicURL,
        postData, 
        {headers}
    );
}

export function addNewLanguage(postData, userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.post(
        urlAPI.addNewLanguageURL,
        postData, 
        {headers}
    );
}

export function updateLanguage(postData, userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.post(
        urlAPI.updateLanguageURL,
        postData, 
        {headers}
    );
}

export function addNewIncomeRange(postData, userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.post(
        urlAPI.addNewIncomeRangeURL,
        postData, 
        {headers}
    );
}

export function updateIncomeRange(postData, userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.post(
        urlAPI.updateIncomeRangeURL,
        postData, 
        {headers}
    );
}
export function addNetwork(postData, userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.post(
        urlAPI.addNetworkURL,
        postData, 
        {headers}
    );
}

export function updateNetwork(postData, userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.post(
        urlAPI.updateNetworkURL,
        postData, 
        {headers}
    );
}

export function addSmsTemplate(postData, userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.post(
        urlAPI.addSmsTemplateURL,
        postData, 
        {headers}
    );
}

export function updateSmsTemplate(postData, userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.post(
        urlAPI.updateSmsTemplateURL,
        postData, 
        {headers}
    );
}
export function addAppointmentStatus(postData, userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.post(
        urlAPI.addAppointmentStatusURL,
        postData, 
        {headers}
    );
}

export function updateAppointmentStatus(postData, userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.post(
        urlAPI.updateAppointmentStatusURL,
        postData, 
        {headers}
    );
}
export function addInsPlan(postData, userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.post(
        urlAPI.addInsPlanURL,
        postData, 
        {headers}
    );
}
export function updateInsPlanDetail(postData, userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.post(
        urlAPI.updateInsPlanDetailURL,
        postData, 
        {headers}
    );
}
export function updateInsPlan(postData, userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.post(
        urlAPI.updateInsPlanURL,
        postData, 
        {headers}
    );
}
export function deleteBlockAppointment(postData, userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.post(
        urlAPI.deleteBlockAppointmentURL,
        postData, 
        {headers}
    );
}
export function addPlanDetails(postData, userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.post(
        urlAPI.addPlanDetailsURL,
        postData, 
        {headers}
    );
}

export function addBenefit(postData, userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.post(
        urlAPI.addInsPackageURL,
        postData, 
        {headers}
    );
}

export function updateBenefit(postData, userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.post(
        urlAPI.updateInsPackageURL,
        postData, 
        {headers}
    );
}

export function addShift(postData, userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.post(
        urlAPI.addShiftURL,
        postData, 
        {headers}
    );
}

export function updateShift(postData, userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.post(
        urlAPI.updateShiftURL,
        postData, 
        {headers}
    );
}

export function updateDoctorSettings(postData, userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.post(
        urlAPI.updateDoctorSettingsURL,
        postData, 
        {headers}
    );
}
export function enableAdminAccess(postData, userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.post(
        urlAPI.enableAdminAccessURL,
        postData, 
        {headers}
    );
}

export function getAdminAccessNurse(postData, userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.post(
        urlAPI.getAdminAccessNurseURL,
        postData, 
        {headers}
    );
}

export function removeAdminAccess(postData, userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.post(
        urlAPI.removeAdminAccessURL,
        postData, 
        {headers}
    );
}

export function viewAppointmentList(postData, userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.post(
        urlAPI.viewAppointmentListURL,
        postData, 
        {headers}
    );
}

export function getSingleAppointment(postData, userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.post(
        urlAPI.getSingleAppointmentURL,
        postData, 
        {headers}
    );
}

export function viewBlockAppointmentList(postData, userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.post(
        urlAPI.viewBlockAppointmentListURL,
        postData, 
        {headers}
    );
}

export function addBlockAppointment(postData, userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.post(
        urlAPI.addBlockAppointmentURL,
        postData, 
        {headers}
    );
}

export function updateBlockAppointment(postData, userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.post(
        urlAPI.updateBlockAppointmentURL,
        postData, 
        {headers}
    );
}

export function deleteCity(user_id, city_id, userToken) {
    const headers = getHeadersWithTokens(userToken);
    const postData = {
        user_id: user_id,
        city_id: city_id,
    };
    return axios.post(
        urlAPI.deleteCityURL,
        postData, 
        {headers}
    );
}
export function deletePatientEnquiry(user_id, patient_id, userToken) {
    const headers = getHeadersWithTokens(userToken);
    const postData = {
        user_id: user_id,
        patient_id: patient_id,
    };
    return axios.post(
        urlAPI.DeletePatientEnquiryURL,
        postData, 
        {headers}
    );
}
export function deletePatientInsurance(user_id, patient_detail_id,patient_insurance_id, userToken) {
    const headers = getHeadersWithTokens(userToken);
    const postData = {
        user_id: user_id,
        patient_detail_id:patient_detail_id,
        patient_insurance_id:patient_insurance_id
    };
    return axios.post(
        urlAPI.DeletePatientInsuranceURL,
        postData, 
        {headers}
    );
}
export function deleteRegion(user_id, region_id, userToken) {
    const headers = getHeadersWithTokens(userToken);
    const postData = {
        user_id: user_id,
        region_id: region_id,
    };
    return axios.post(
        urlAPI.deleteRegionURL,
        postData, 
        {headers}
    );
}

export function deleteTitle(user_id, title_id, userToken) {
    const headers = getHeadersWithTokens(userToken);
    const postData = {
        user_id: user_id,
        title_id: title_id,
    };
    return axios.post(
        urlAPI.deleteTitleURL,
        postData, 
        {headers}
    );
}

export function deleteJobTitle(user_id, job_title_id,userToken) {
    const headers = getHeadersWithTokens(userToken);
    const postData = {
        user_id: user_id,
        job_title_id: job_title_id,
    };
    return axios.post(
        urlAPI.deleteJobTitleURL,
        postData, 
        {headers}
    );
}

export function deleteCategory(user_id, category_id, userToken) {
    const headers = getHeadersWithTokens(userToken);
    const postData = {
        user_id: user_id,
        category_id: category_id,
    };
    return axios.post(
        urlAPI.deleteCategoryURL,
        postData, 
        {headers}
    );
}

export function deleteDepartment(user_id, department_id, userToken) {
    const headers = getHeadersWithTokens(userToken);
    const postData = {
        user_id: user_id,
        department_id: department_id,
    };
    return axios.post(
        urlAPI.deleteDepartmentURL,
        postData, 
        {headers}
    );
}

export function deleteNationality(user_id, nationality_id, userToken) {
    const headers = getHeadersWithTokens(userToken);
    const postData = {
        user_id: user_id,
        nationality_id: nationality_id,
    };
    return axios.post(
        urlAPI.deleteNationalityURL,
        postData, 
        {headers}
    );
}

export function deletePatientClass(user_id, class_id, userToken) {
    const headers = getHeadersWithTokens(userToken);
    const postData = {
        user_id: user_id,
        class_id: class_id,
    };
    return axios.post(
        urlAPI.deletePatientClassURL,
        postData, 
        {headers}
    );
}

export function deleteOccupation(user_id, occupation_id, userToken) {
    const headers = getHeadersWithTokens(userToken);
    const postData = {
        user_id: user_id,
        occupation_id: occupation_id,
    };
    return axios.post(
        urlAPI.deleteOccupationURL,
        postData, 
        {headers}
    );
}
export function deleteReason(user_id, reason_id, userToken) {
    const headers = getHeadersWithTokens(userToken);
    const postData = {
        user_id: user_id,
        reason_id: reason_id,
    };
    return axios.post(
        urlAPI.deleteReasonURL,
        postData, 
        {headers}
    );
}
export function deleteReferralDoctor(user_id, doctor_id, userToken) {
    const headers = getHeadersWithTokens(userToken);
    const postData = {
        user_id: user_id,
        doctor_id: doctor_id,
    };
    return axios.post(
        urlAPI.deleteReferralDoctorURL,
        postData, 
        {headers}
    );
}
export function DeleteService(user_id, service_id, userToken) {
    const headers = getHeadersWithTokens(userToken);
    const postData = {
        user_id: user_id,
        service_id: service_id,
    };
    return axios.post(
        urlAPI.deleteServiceURL,
        postData, 
        {headers}
    );
}

export function deleteEducation(user_id, education_id, userToken) {
    const headers = getHeadersWithTokens(userToken);
    const postData = {
        user_id: user_id,
        education_id: education_id,
    };
    return axios.post(
        urlAPI.deleteEducationURL,
        postData, 
        {headers}
    );
}

export function deleteReligion(user_id, religion_id, userToken) {
    const headers = getHeadersWithTokens(userToken);
    const postData = {
        user_id: user_id,
        religion_id: religion_id,
    };
    return axios.post(
        urlAPI.deleteReligionURL,
        postData, 
        {headers}
    );
}

export function deleteEthnic(user_id, ethnic_id, userToken) {
    const headers = getHeadersWithTokens(userToken);
    const postData = {
        user_id: user_id,
        ethnic_id: ethnic_id,
    };
    return axios.post(
        urlAPI.deleteEthnicURL,
        postData, 
        {headers}
    );
}

export function deleteLanguage(user_id, language_id, userToken) {
    const headers = getHeadersWithTokens(userToken);
    const postData = {
        user_id: user_id,
        language_id: language_id,
    };
    return axios.post(
        urlAPI.deleteLanguageURL,
        postData, 
        {headers}
    );
}

export function deleteIncomeRange(user_id, income_id, userToken) {
    const headers = getHeadersWithTokens(userToken);
    const postData = {
        user_id: user_id,
        income_id: income_id,
    };
    return axios.post(
        urlAPI.deleteIncomeRangeURL,
        postData, 
        {headers}
    );
}

export function deleteNetwork(user_id, network_id, userToken) {
    const headers = getHeadersWithTokens(userToken);
    const postData = {
        user_id: user_id,
        network_id: network_id,
    };
    return axios.post(
        urlAPI.deleteNetworkURL,
        postData, 
        {headers}
    );
}
export function deleteInsPlan(user_id, plan_id, userToken) {
    const headers = getHeadersWithTokens(userToken);
    const postData = {
        user_id: user_id,
        plan_id: plan_id,
    };
    return axios.post(
        urlAPI.deleteInsPlanURL,
        postData, 
        {headers}
    );
}
export function deleteInsPlanDetail(user_id, plan_detail_id, userToken) {
    const headers = getHeadersWithTokens(userToken);
    const postData = {
        user_id: user_id,
        plan_detail_id: plan_detail_id,
    };
    return axios.post(
        urlAPI.deleteInsPlanDetailURL,
        postData, 
        {headers}
    );
}

export function deleteInsPlanDetailDesc(user_id, plan_detail_id, dept_category_id, userToken) {
    const headers = getHeadersWithTokens(userToken);
    const postData = {
        user_id: user_id,
        plan_detail_id: plan_detail_id,
        dept_category_id: dept_category_id
    };
    return axios.post(
        urlAPI.deleteInsPlanDetailDescURL,
        postData, 
        {headers}
    );
}

export function deleteAppointmentStatus(user_id, status_id, userToken) {
    const headers = getHeadersWithTokens(userToken);
    const postData = {
        user_id: user_id,
        status_id: status_id,
    };
    return axios.post(
        urlAPI.deleteAppointmentStatusURL,
        postData, 
        {headers}
    );
}
export function deleteSmsTemplate(user_id, sms_id, userToken) {
    const headers = getHeadersWithTokens(userToken);
    const postData = {
        user_id: user_id,
        sms_id: sms_id,
    };
    return axios.post(
        urlAPI.deleteSmsTemplateURL,
        postData, 
        {headers}
    );
}
export function deleteBenefit(user_id, package_id, userToken) {
    const headers = getHeadersWithTokens(userToken);
    const postData = {
        user_id: user_id,
        package_id: package_id,
    };
    return axios.post(
        urlAPI.deleteInsPackageURL,
        postData, 
        {headers}
    );
}

export function deleteShift(user_id, shift_id, userToken) {
    const headers = getHeadersWithTokens(userToken);
    const postData = {
        user_id: user_id,
        shift_id: shift_id,
    };
    return axios.post(
        urlAPI.deleteShiftURL,
        postData, 
        {headers}
    );
}

export function getTitleList(userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.get(
        urlAPI.getTitleListURL, 
        {headers}
    );
}

export function getNationalityList(userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.get(
        urlAPI.getNationalityListURL, 
        {headers}
    );
}

export function getSingleCity(id, userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.get(
        urlAPI.masterGetSignalDataURL+'/GetSingleCity/'+id, 
        {headers}
    );
}
export function getSingleReferralDoctor(id, userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.get(
        urlAPI.masterGetSignalDataURL1+'/GetSingleReferralDoctor/'+id, 
        {headers}
    );
}

export function getSingleRegion(id, userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.get(
        urlAPI.masterGetSignalDataURL+'/GetSingleRegion/'+id, 
        {headers}
    );
}

export function getSingleTitle(id, userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.get(
        urlAPI.masterGetSignalDataURL+'/GetSingleTitle/'+id, 
        {headers}
    );
}

export function getSingleJobTitle(id, userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.get(
        urlAPI.masterGetSignalDataURL+'/GetSingleJobTitle/'+id, 
        {headers}
    );
}

export function getSingleCategory(id, userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.get(
        urlAPI.masterGetSignalDataURL+'/GetSingleCategory/'+id, 
        {headers}
    );
}

export function getSingleNationality(id, userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.get(
        urlAPI.masterGetSignalDataURL+'/GetSingleNationality/'+id, 
        {headers}
    );
}

export function getSingleDepartment(id, userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.get(
        urlAPI.masterGetSignalDataURL+'/GetSingleDepartment/'+id, 
        {headers}
    );
}

export function getSinglePatientClass(id, userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.get(
        urlAPI.masterGetSignalDataURL+'/GetSinglePatientClass/'+id, 
        {headers}
    );
}

export function getSingleOccupation(id, userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.get(
        urlAPI.masterGetSignalDataURL+'/GetSingleOccupation/'+id, 
        {headers}
    );
}
export function getSingleReason(id, userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.get(
        urlAPI.masterGetSignalDataURL1+'/GetSingleEnquiryReason/'+id, 
        {headers}
    );
}
export function getSingleService(id, userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.get(
        urlAPI.masterGetSignalDataURL1+'/GetSingleEnquiryService/'+id, 
        {headers}
    );
}
export function getSingleEducation(id, userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.get(
        urlAPI.masterGetSignalDataURL+'/GetSingleEducation/'+id, 
        {headers}
    );
}

export function getSingleReligion(id, userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.get(
        urlAPI.masterGetSignalDataURL+'/GetSingleReligion/'+id, 
        {headers}
    );
}

export function getSingleEthnic(id, userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.get(
        urlAPI.masterGetSignalDataURL+'/GetSingleEthnic/'+id, 
        {headers}
    );
}

export function getSingleLanguage(id, userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.get(
        urlAPI.masterGetSignalDataURL+'/GetSingleLanguage/'+id, 
        {headers}
    );
}

export function getSingleIncomeRange(id, userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.get(
        urlAPI.masterGetSignalDataURL+'/GetSingleIncomeRange/'+id, 
        {headers}
    );
}

export function getSingleNetwork(id, userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.get(
        urlAPI.masterGetSignalDataURL1+'/GetSingleNetwork/'+id, 
        {headers}
    );
}
export function getSingleInsPlan(id, userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.get(
        urlAPI.masterGetSignalDataURL1+'/GetSingleInsPlan/'+id, 
        {headers}
    );
}
export function getSingleInsPlanDetail(id, userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.get(
        urlAPI.masterGetSignalDataURL1+'/GetSingleInsPlanDetail/'+id, 
        {headers}
    );
}
export function getSingleSmsTemplate(id, userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.get(
        urlAPI.masterGetSignalDataURL1+'/GetSingleSMSTemplate/'+id, 
        {headers}
    );
}
export function getSingleAppointmentStatus(id, userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.get(
        urlAPI.masterGetSignalDataURL1+'/GetSingleAppointmentStatus/'+id, 
        {headers}
    );
}
export function getSingleBenefit(id, userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.get(
        urlAPI.masterGetSignalDataURL1+'/GetSingleInsPackage/'+id, 
        {headers}
    );
}

export function getSingleShift(id, userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.get(
        urlAPI.masterGetSignalDataURL+'/GetSingleShift/'+id, 
        {headers}
    );
}

// Menu

export function getMenuData(userToken, userId) {
    const headers = getHeadersWithTokens(userToken);
    const postDataClient = {
        user_id: userId,
    }
    return axios.post(
        urlAPI.getMenuDataURL, 
        postDataClient,
        {headers}
    );
}

export function getSubMenuData(userToken, userId,roleID) {
    const headers = getHeadersWithTokens(userToken);
    const postDataClient = {
        user_id: userId,
        role_id:roleID
    }
    return axios.post(
        urlAPI.GetSideMenuList, 
        postDataClient,
        {headers}
    );
}
export function postCreateMenu(postData,userToken) {
    const headers = getHeadersWithTokenPosts(userToken);
    return axios.post(
        urlAPI.createMenuURL,
        postData, 
        {headers}
    );
}
export function updateMenu(postData,userToken) {
    const headers = getHeadersWithTokenPosts(userToken);
    return axios.post(
        urlAPI.updateMenuURL,
        postData, 
        {headers}
    );
}

export function updateReOrderMenu(postData,userToken) {
    const headers = getHeadersWithTokenPosts(userToken);
    return axios.post(
        urlAPI.reorderMainMenuURL,
        postData, 
        {headers}
    );
}
export function updateReOrderDashMenu(postData,userToken) {
    const headers = getHeadersWithTokenPosts(userToken);
    return axios.post(
        urlAPI.reorderDashboardMenuURL,
        postData, 
        {headers}
    );
}
export function getSingleMainMenu(userToken, menuId) {
    const headers = getHeadersWithTokens(userToken);
    return axios.get(
        urlAPI.getSingleMainMenuURL+"/"+menuId, 
        {headers}
    );
}

export function deleteMainMenu(userId, menu_id, userToken) {
    const headers = getHeadersWithTokens(userToken);
    const postData = {
        user_id: userId,
        menu_id : menu_id
    }
    return axios.post(
        urlAPI.deleteMainMenuURL,
        postData, 
        {headers}
    );
}

export function getRoleWiseMainMenu(userId, role_id, userToken) {
    const headers = getHeadersWithTokens(userToken);
    const postData = {
        user_id: userId,
        role_id : role_id
    }
    return axios.post(
        urlAPI.getRoleWiseMainMenuURL,
        postData, 
        {headers}
    );
}

export function assignMainMenu(postData, userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.post(
        urlAPI.assignMainMenuURL,
        postData, 
        {headers}
    );
}

//dashboard

export function getDashboardMenuData(userToken, userId) {
    const headers = getHeadersWithTokens(userToken);
    const postDataClient = {
        user_id: userId,
    }
    return axios.post(
        urlAPI.getDashboardMenuDataURL, 
        postDataClient,
        {headers}
    );
}


export function postCreateDashboardMenu(postData,userToken) {
    const headers = getHeadersWithTokenPosts(userToken);
    return axios.post(
        urlAPI.createDashboardMenuURL,
        postData, 
        {headers}
    );
}
export function updateDashboardMenu(postData,userToken) {
    const headers = getHeadersWithTokenPosts(userToken);
    return axios.post(
        urlAPI.updateDashboardMenuURL,
        postData, 
        {headers}
    );
}

export function getSingleDashboardMenu(userToken,menuId) {
    const headers = getHeadersWithTokens(userToken);
    return axios.get(
        urlAPI.getSingleDashboardMenuURL+"/"+menuId, 
        {headers}
    );
}

export function deleteDashboardModule(userId, module_id, userToken) {
    const headers = getHeadersWithTokens(userToken);
    const postData = {
        user_id: userId,
        module_id : module_id
    }
    return axios.post(
        urlAPI.deleteDashboardModuleURL,
        postData, 
        {headers}
    );
}

export function getRoleWiseDashboardMenu(userId, role_id, userToken) {
    const headers = getHeadersWithTokens(userToken);
    const postData = {
        user_id: userId,
        role_id : role_id
    }
    return axios.post(
        urlAPI.getRoleWiseDashboardMenuURL,
        postData, 
        {headers}
    );
}

export function assignDashboardMenu(postData, userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.post(
        urlAPI.assignDashboardMenuURL,
        postData, 
        {headers}
    );
}

export function displayDashboardMenu(userId, role_id, userToken) {
    const headers = getHeadersWithTokens(userToken);
    const postData = {
        user_id: userId,
        role_id : role_id
    }
    return axios.post(
        urlAPI.displayDashboardMenuURL,
        postData, 
        {headers}
    );
}


//Submenu

export function subMenuList(userToken, userId) {
    const headers = getHeadersWithTokens(userToken);
    const postDataClient = {
        user_id: userId,
    }
    return axios.post(
        urlAPI.subMenuListURL, 
        postDataClient,
        {headers}
    );
}

export function createSubMenu(postData,userToken) {
    const headers = getHeadersWithTokenPosts(userToken);
    return axios.post(
        urlAPI.createSubMenuURL,
        postData, 
        {headers}
    );
}
export function CreatePatient(postData,userToken) {
    const headers = getHeadersWithTokenPosts(userToken);
    return axios.post(
        urlAPI.CreatePatientURL,
        postData, 
        {headers}
    );
}
export function UpdatePatient(postData,userToken) {
    const headers = getHeadersWithTokenPosts(userToken);
    return axios.post(
        urlAPI.UpdatePatientURL,
        postData, 
        {headers}
    );
}
export function SavePatientInsurance(postData,userToken) {
    const headers = getHeadersWithTokenPosts(userToken);
    return axios.post(
        urlAPI.SavePatientInsuranceURL,
        postData, 
        {headers}
    );
}
export function SavePatientSubdData(postData,userToken) {
    const headers = getHeadersWithTokenPosts(userToken);
    return axios.post(
        urlAPI.SavePatientSubdDataURL,
        postData, 
        {headers}
    );
}
export function SearchPatientRelation(postData, userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.post(
        urlAPI.SearchPatientRelationURL,
        postData, 
        {headers}
    );
  
}
export function SaveReferral(postData,userToken) {
    const headers = getHeadersWithTokenPosts(userToken);
    return axios.post(
        urlAPI.SaveReferralURL,
        postData, 
        {headers}
    );
}
export function CheckPolicyNumber(postData,userToken) {
    const headers = getHeadersWithTokenPosts(userToken);
    return axios.post(
        urlAPI.CheckPolicyNumberURL,
        postData, 
        {headers}
    );
}
export function CheckMrNumber(postData,userToken) {
    const headers = getHeadersWithTokenPosts(userToken);
    return axios.post(
        urlAPI.CheckMrNumberURL,
        postData, 
        {headers}
    );
}
export function checkEmirateID(postData,userToken) {
    const headers = getHeadersWithTokenPosts(userToken);
    return axios.post(
        urlAPI.CheckEmirateIdURL,
        postData, 
        {headers}
    );
}
export function getSinglePatientEnquiry(id, userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.get(
        urlAPI.GetSinglePatientEnquiryURL+'/'+id, 
        {headers}
    );
}
export function GetSinglePatient(userId,patient_id,userToken) {
    const headers = getHeadersWithTokens(userToken);
    const postData = {
        user_id: userId,
        patient_id : patient_id
    }
    return axios.post(
        urlAPI.GetSinglePatientURL, 
        postData, 
        {headers}
    );
}
export function GetSingleInsCompany(userId,company_id,userToken) {
    const headers = getHeadersWithTokens(userToken);
    const postData = {
        user_id: userId,
        company_id : company_id
    }
    return axios.post(
        urlAPI.GetSingleInsCompanyURL, 
        postData, 
        {headers}
    );
}
export function ViewPatientList(postData,userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.post(
        urlAPI.ViewPatientURL, 
        postData, 
        {headers}
    );
}

export function ViewReferralDoctorList(userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.get(
        urlAPI.ViewReferralDoctorURL, 
        {headers}
    );
}
export function ViewInsCompany(postData,userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.post(
        urlAPI.ViewInsCompanyURL, 
        postData, 
        {headers}
    );
}
export function ViewPatientEnquiry(postData,userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.post(
        urlAPI.ViewPatientEnquiryURL, 
        postData, 
        {headers}
    );
}
export function updateSubMenu(postData,userToken) {
    const headers = getHeadersWithTokenPosts(userToken);
    return axios.post(
        urlAPI.updateSubMenuURL,
        postData, 
        {headers}
    );
}

export function getSingleSubMenu(subMenuId,userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.get(
        urlAPI.getSingleSubMenuURL+"/GetSingleSubMenu/"+subMenuId, 
        {headers}
    );
}

export function deleteSubMenu(userId, sub_menu_id, userToken) {
    const headers = getHeadersWithTokens(userToken);
    const postData = {
        user_id: userId,
        sub_menu_id : sub_menu_id
    }
    return axios.post(
        urlAPI.deleteSubMenuURL,
        postData, 
        {headers}
    );
}

export function reorderSubMenu(postData,userToken) {
    const headers = getHeadersWithTokenPosts(userToken);
    return axios.post(
        urlAPI.reorderSubMenuURL,
        postData, 
        {headers}
    );
}

export function getRoleWiseSubMenu(postData, userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.post(
        urlAPI.getRoleWiseSubMenuURL,
        postData, 
        {headers}
    );
}

export function getMenuWiseSubMenu(mainmenu_id, userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.get(
        urlAPI.getMenuWiseSubMenuURL+"/"+mainmenu_id,
        {headers}
    );
}

export function assignSubMenu(postData, userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.post(
        urlAPI.assignSubMenuURL,
        postData, 
        {headers}
    );
}
export function SaveHospitalSetting(postData, userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.post(
        urlAPI.SaveHospitalSettingURL,
        postData, 
        {headers}
    );
}
export function SaveBookAppointment(postData, userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.post(
        urlAPI.SaveBookAppointmentURL,
        postData, 
        {headers}
    );
}

export function updateBookAppointment(postData, userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.post(
        urlAPI.updateBookAppointmentURL,
        postData, 
        {headers}
    );
}


export function DeleteBookAppointment(postData, userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.post(
        urlAPI.DeleteBookAppointmentURL,
        postData, 
        {headers}
    );
}
export function SearchPatientName(postData, userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.post(
        urlAPI.SearchPatientNameURL,
        postData, 
        {headers}
    );
  
}

export function AddWaitingList(postData, userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.post(
        urlAPI.AddWaitingListURL,
        postData, 
        {headers}
    );
  
}

export function ViewWaitingList(postData, userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.post(
        urlAPI.ViewWaitingListURL,
        postData, 
        {headers}
    );
  
}

export function AddServiceList(postData, userToken) {
    const headers = getHeadersWithTokens(userToken);
    return axios.post(
        urlAPI.AddServiceURL,
        postData, 
        {headers}
    );
  
}