import { PATIENT_CLASS_ACTION_TYPES } from '../../actions/primaryMaster/PatientClassActionTypes';

const initialState = {
    patientClass: "",
    shortCode: "",
    patientClass_id: "",
    detailsData:[],
    loading:false,
    openModel :false
  // Other initial state...
};

const PatientClassReducer = (state = initialState, action) => {
  switch (action.type) {
    case PATIENT_CLASS_ACTION_TYPES.SET_PATIENT_CLASS:
        return {
            ...state,
            patientClass: action.payload,
        };
    case PATIENT_CLASS_ACTION_TYPES.SET_SHORT_CODE:
        return {
            ...state,
            shortCode: action.payload,
        };
    case PATIENT_CLASS_ACTION_TYPES.SET_PATIENT_CLASS_ID:
        return {
            ...state,
            patientClass_id: action.payload,
        };
    case PATIENT_CLASS_ACTION_TYPES.SET_DETAILS_DATA:
        return {
            ...state,
            detailsData: action.payload,
        };
    case PATIENT_CLASS_ACTION_TYPES.SET_LOADING:
        return {
            ...state,
            loading: action.payload,
        };
    case PATIENT_CLASS_ACTION_TYPES.SET_OPEN_MODAL:
        return {
            ...state,
            openModel: action.payload,
        };
    // Other cases...
    default:
      return state;
  }
};

export default PatientClassReducer;
 