import { lazy, Suspense, useState, useEffect } from 'react';

/// Components
import Index from "./jsx";
import { connect, useDispatch } from 'react-redux';
import { Route, Routes, useLocation, useNavigate, useParams } from 'react-router-dom';
// action
import { checkAutoLogin } from './services/AuthService';
// import { isLogin } from './services/AuthService';
import { isAuthenticated } from './store/selectors/AuthSelectors';
/// Style
import "./vendor/bootstrap-select/dist/css/bootstrap-select.min.css";
import "./css/style.css";
import "./css/custom.css";
// import Error404 from './jsx/pages/Error404';
import OfflineModal from './jsx/components/Offline/OfflineModal';
import HomePage from './unitech/components/Home';
import './unitech/css/style.css';
import AddCallsPage from './unitech/components/AddCallsPage';
import Completed from './unitech/components/Completed';
import OrdersFlow from './unitech/components/OrdersFlow';
import PendingList from './unitech/components/PendingList';
import CustomerList from './unitech/components/CustomerList'
import PendingCalls from './unitech/components/PendingCalls'


const ForgotPassword = lazy(() => {
  return new Promise(resolve => {
    setTimeout(() => resolve(import('./jsx/pages/ForgotPassword')), 500);
  });
});

const Login = lazy(() => {
  return new Promise(resolve => {
    setTimeout(() => resolve(import('./jsx/pages/Login')), 500);
  });
});

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();

    return (
      <Component
        {...props}
        router={{ location, navigate, params }}
      />
    );
  }

  return ComponentWithRouterProp;
}





function App(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    checkAutoLogin(dispatch, navigate);
    // eslint-disable-next-line
  }, []);

  // offline mode check
  // eslint-disable-next-line
  const [isOffline, setIsOffline] = useState(!navigator.onLine);
  const [showOfflineModal, setShowOfflineModal] = useState(false);

  useEffect(() => {

    const handleOnline = () => {
      setIsOffline(false);
      setShowOfflineModal(false); // Close the modal when online
    };

    const handleOffline = () => {
      setIsOffline(true);
      setShowOfflineModal(true);
    };

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  const handleRefresh = () => {
    window.location.reload();
  };

  const handleModalClose = () => {
    setShowOfflineModal(false);
  };


  let routeblog = (
    <Routes>
      <Route path='/login' element={<Login />} />
      <Route path='/dashboard' element={<HomePage />} />
      <Route path='/forgotPassword' element={<ForgotPassword />} />
      <Route path='/orders-flow' element={<OrdersFlow />} />
      <Route path='/add-calls' element={<AddCallsPage />} />
      <Route path='/Pending-calls' element={<PendingCalls />} />
      <Route path='/completed' element={<Completed />} />
      {/* <Route path='/orders-flow' element={<OrdersFlow />} /> */}
      <Route path='/pending-list' element={<PendingList />} />
      <Route path='/customer-list' element={<CustomerList />} />
    </Routes>
  );
  if (props.isAuthenticated) {
    return (
      <>
        <Suspense fallback={
          <div id="preloader">
            <div className="sk-three-bounce">
              <div className="sk-child sk-bounce1"></div>
              <div className="sk-child sk-bounce2"></div>
              <div className="sk-child sk-bounce3"></div>
            </div>
          </div>
        }
        >
          {routeblog}
        </Suspense>
        
        <OfflineModal show={showOfflineModal} onRefresh={handleRefresh} onHide={handleModalClose} />

      </>
    );
  } else {
    return (
      <div className="vh-100">
        <Suspense fallback={
          <div id="preloader">
            <div className="sk-three-bounce">
              <div className="sk-child sk-bounce1"></div>
              <div className="sk-child sk-bounce2"></div>
              <div className="sk-child sk-bounce3"></div>
            </div>
          </div>
        }
        >
          {routeblog}

        </Suspense>
        <OfflineModal show={showOfflineModal} onRefresh={handleRefresh} onHide={handleModalClose} />
      </div>
    );
  }
};


const mapStateToProps = (state) => {
  return {
    isAuthenticated: isAuthenticated(state),
  };
};

export default withRouter(connect(mapStateToProps)(App));

