import { useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";

const OrdersFlow = () => {
    const navigate = useNavigate();
    const [records, setRecords] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [startDate, setStartDate] = useState(new Date());

    const fetchRecords = async (selectedDate) => {
        setLoading(true);
        setError(null);

        const userId = localStorage.getItem("user_id") || "default_user_id"; // Replace with a fallback value if necessary
        const formattedDate = new Date(selectedDate).toISOString().split("T")[0]; // Format date as YYYY-MM-DD

        try {
            const response = await axios.get(
                `https://miracalemoney.in/dealerreport/api/v1/GetAllCustomer/${userId}`
            );
            console.log("API Response:", response.data);

            if (response.data.status === 1 && response.data.customers) {
                setRecords(response.data.customers);
            } else {
                setRecords([]);
                setError("No records found.");
            }
        } catch (err) {
            console.error("Error fetching records:", err);
            setError("Failed to fetch records. Please try again.");
        } finally {
            setLoading(false);
        }
    };

    const handleCancel = () => {
        navigate("/dashboard");
      };
    

    useEffect(() => {
        fetchRecords(startDate);
    }, [startDate]);

    return (
        <div>
            <header className="header">
                <div className="call-header">
                    {/* <div className="menu-icon">&#9776;</div> */}
                    <h1>Orders Flow</h1>
                </div>
                <div onClick={handleCancel} className="cancel-icon">
            &#10005;
          </div>
            </header>
            <main className="orders-container">
                <div className="order-header">
                    <div>Sr.No</div>
                    <div>Customer Name</div>
                    <div>Mobile Number</div>
                </div>
                {loading ? (
                    <div>Loading...</div>
                ) : error ? (
                    <div className="error">{error}</div>
                ) : records.length > 0 ? (
                    records.map((record, index) => (
                        <div className="order-row" key={record.id}>
                            <div>{index + 1}</div>
                            <div>{record.name}</div>
                            <div>{record.mobile}</div>
                        </div>
                    ))
                ) : (
                    <div>No records found.</div>
                )}
            </main>
        </div>
    );
};

export default OrdersFlow;
