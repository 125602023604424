// store/actions/cityActionTypes.js

export const BENEFIT_ACTION_TYPES = {
    SET_DETAILS_DATA: 'SET_DETAILS_DATA',
    SET_NAME:"SET_NAME",
    SET_PRODUCT_NAME:"SET_PRODUCT_NAME",
    SET_PAYER_ID:"SET_PAYER_ID",
    SET_INSURANCE_COMPANY_DETAIL_ID:"SET_INSURANCE_COMPANY_DETAIL_ID",
    SET_PACKAGE_ID:"SET_PACKAGE_ID",
    SET_LOADING:"SET_LOADING",
    SET_OPEN_MODAL:"SET_OPEN_MODAL",
    SET_SHOW_DELETE_MODAL:"SET_SHOW_DELETE_MODAL"
};
  