import React from 'react'
import { Spinner } from 'react-bootstrap'

const Loader = () => {
  return (
    <>
        <div className='position-absolute top-0 start-0 w-100 h-100' style={{zIndex: 9999, backgroundColor: 'rgba(0,0,0,0.2)'}}>
            <Spinner
                className='position-fixed top-50 start-50'
                animation="border"
                variant="primary"
            />
        </div>
    </>
  )
}

export default Loader