import { APP_STATUS_ACTION_TYPES } from '../../actions/primaryMaster/AppStatusActionTypes';

const initialState = {
    sms_id:"",
    name:"",
    bg_color:"",
    font_color:"",
    malaffi_status:"",
    malaffi_description: "",
    booking_order: "",
    detailsData:[],
    loading:false,
    openModel :false,
  // Other initial state...
};

const AppointmentStatusReducer = (state = initialState, action) => {
  switch (action.type) {
    case APP_STATUS_ACTION_TYPES.SET_DETAILS_DATA:
        return {
            ...state,
            detailsData: action.payload,
        };
    case APP_STATUS_ACTION_TYPES.SET_NAME:
        return {
            ...state,
            name: action.payload,
        };
    case APP_STATUS_ACTION_TYPES.SET_BG_COLOR:
        return {
            ...state,
            bg_color: action.payload,
        };
    case APP_STATUS_ACTION_TYPES.SET_FONT_COLOR:
        return {
            ...state,
            font_color: action.payload,
        };
    case APP_STATUS_ACTION_TYPES.SET_MALAFFIE_STATUS:
        return {
            ...state,
            malaffi_status: action.payload,
        };
        case APP_STATUS_ACTION_TYPES.SET_MALAFFIE_DESCRIPTION:
            return {
                ...state,
                malaffi_description: action.payload,
            };
            case APP_STATUS_ACTION_TYPES.SET_BOOKING_ORDER:
                return {
                    ...state,
                    booking_order: action.payload,
                };
    case APP_STATUS_ACTION_TYPES.SET_STATUS_ID:
        return {
            ...state,
            status_id: action.payload,
        };
    case APP_STATUS_ACTION_TYPES.SET_LOADING:
        return {
            ...state,
            loading: action.payload,
        };
    case APP_STATUS_ACTION_TYPES.SET_OPEN_MODAL:
        return {
            ...state,
            openModel: action.payload,
        };
    // Other cases...
    default:
      return state;
  }
};

export default AppointmentStatusReducer;
 