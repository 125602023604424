import { SEARCH_ACTION_TYPES } from '../actions/SearchActionType';

const initialState = {
    filterdata: [],
};

const SearchReducer = (state = initialState, action) => {
  switch (action.type) {
    case SEARCH_ACTION_TYPES.FILTER_DATA:
      return {
        ...state,
        filterdata: action.payload,
      };
    // Other cases...
    default:
      return state;
  }
};

export default SearchReducer;