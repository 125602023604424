// store/actions/cityActionTypes.js

export const ALL_MASTER_ACTION_TYPES = {
    SET_MARITAL_STATUS_LIST : 'SET_MARITAL_STATUS_LIST',
    SET_PATIENT_CLASS_LIST : 'SET_PATIENT_CLASS_LIST',
    SET_BLOOD_GROUP_LIST : 'SET_BLOOD_GROUP_LIST',
    SET_OCCUPATION_LIST : 'SET_OCCUPATION_LIST',
    SET_EDUCATION_LIST : 'SET_EDUCATION_LIST',
    SET_RELIGION_LIST : 'SET_RELIGION_LIST',
    SET_ETHNIC_LIST : 'SET_ETHNIC_LIST',
    SET_INDUSTRY_LIST : 'SET_INDUSTRY_LIST',
    SET_LANGUAGE_LIST : 'SET_LANGUAGE_LIST',
    SET_INCOME_RANGE_LIST : 'SET_INCOME_RANGE_LIST',
    SET_DEPARTMENT_LIST : 'SET_DEPARTMENT_LIST',
    SET_CATDEPARTMENT_LIST : 'SET_CATDEPARTMENT_LIST',
    SET_DEPARTMENT_CATEGORY_LIST : 'SET_DEPARTMENT_CATEGORY_LIST',
    SET_JOBTITLE_LIST : 'SET_JOBTITLE_LIST',
    SET_TITLE_LIST : 'SET_TITLE_LIST',
    SET_GENDER_LIST : 'SET_GENDER_LIST',
    SET_COUNTRY_LIST : 'SET_COUNTRY_LIST',
    SET_CITY_ALL_LIST : 'SET_CITY_ALL_LIST',
    SET_CITY_LIST : 'SET_CITY_LIST',
    SET_CITY_LIST1 : 'SET_CITY_LIST1',
    SET_CITY_LIST2 : 'SET_CITY_LIST2',
    SET_CITY_LIST3 : 'SET_CITY_LIST3',
    SET_REGION_LIST : 'SET_REGION_LIST',   
    SET_REGION_LIST1 : 'SET_REGION_LIST1',   
    SET_REGION_LIST2 : 'SET_REGION_LIST2',
    SET_REGION_LIST3 : 'SET_REGION_LIST3',
    SET_REGION_ALL_LIST: 'SET_REGION_ALL_LIST',
    SET_NATIONALITY_LIST: 'SET_NATIONALITY_LIST',
    SET_RELATIONSHIP_LIST: 'SET_RELATIONSHIP_LIST',
    SET_REFERRAL_SOURCE_LIST: 'SET_REFERRAL_SOURCE_LIST',
    SET_REFERRAL_CHANNEL_LIST: 'SET_REFERRAL_CHANNEL_LIST',
    SET_NEWORK_LIST: 'SET_NEWORK_LIST',
    SET_CHARGE_TYPE_LIST : 'SET_CHARGE_TYPE_LIST',
    SET_COMPANY_TYPE_LIST : 'SET_COMPANY_TYPE_LIST',
    SET_COMPANY_LIST : 'SET_COMPANY_LIST',
    SET_MAIN_COMPANY_LIST : 'SET_MAIN_COMPANY_LIST',
    SET_SUB_COMPANY_LIST : 'SET_SUB_COMPANY_LIST',
    SET_INSURANCE_LIST:'SET_INSURANCE_LIST',
    SET_DOCTOR_LIST : 'SET_DOCTOR_LIST',
    SET_STAFF_LIST : 'SET_STAFF_LIST',
    SET_REFFERAL_DOCTOR_LIST : 'SET_REFFERAL_DOCTOR_LIST',
    SET_SERVICE_LIST : 'SET_SERVICE_LIST',
    SET_REASON_LIST : 'SET_REASON_LIST',
    SET_REFFERAL_CLINIC_LIST : 'SET_REFFERAL_CLINIC_LIST',
    SET_PATIENT_ENQUIRY_LIST:'SET_PATIENT_ENQUIRY_LIST',
    SET_APPOINTMENT_LIST:'SET_APPOINTMENT_LIST',
    SET_NETWORK_LIST:'SET_NETWORK_LIST',
    SET_PLAN_NETWORK_LIST:'SET_PLAN_NETWORK_LIST',
    SET_NETWORK_LIST1:'SET_NETWORK_LIST1',
    SET_BENEFIT_LIST:'SET_BENEFIT_LIST',
    SET_INS_PLAN_LIST:'SET_INS_PLAN_LIST',
    SET_SMS_TEMPLATE_LIST:'SET_SMS_TEMPLATE_LIST',
    SET_APPOINTMENT_STATUS_LIST:'SET_APPOINTMENT_STATUS_LIST',
    SET_PLAN_DETAILS_LIST: 'SET_PLAN_DETAILS_LIST',
    SET_PLAN_SERVICE_CATEGORY_LIST: 'SET_PLAN_SERVICE_CATEGORY_LIST',
    SET_NETWORK_AND_PLAN_LIST1: 'SET_NETWORK_AND_PLAN_LIST1',
    SET_NETWORK_AND_PLAN_LIST2: 'SET_NETWORK_AND_PLAN_LIST2',
    SET_SHIFT_LIST: 'SET_SHIFT_LIST',
    SET_DOCTOR_MASTER_LIST: 'SET_DOCTOR_MASTER_LIST',
    SET_SHIFT_DROPDOWN_LIST: 'SET_SHIFT_DROPDOWN_LIST',
    SET_DOCTOR_MASTER_DROPDOWN_LIST: 'SET_DOCTOR_MASTER_DROPDOWN_LIST',
    SET_VISITTYPE_DROPDOWN_LIST: 'SET_VISITTYPE_DROPDOWN_LIST',
    SET_EXAM_DROPDOWN_LIST:'SET_EXAM_DROPDOWN_LIST',
    SET_STATUS_DROPDOWN_LIST:'SET_STATUS_DROPDOWN_LIST',
    SET_ENQUIRY_REASON_DROPDOWN_LIST:'SET_ENQUIRY_REASON_DROPDOWN_LIST',
    SET_ENQUIRY_SERVICE_DROPDOWN_LIST:'SET_ENQUIRY_SERVICE_DROPDOWN_LIST',
    SET_APPOINTMENT_SERVICE_DROPDOWN_LIST:'SET_APPOINTMENT_SERVICE_DROPDOWN_LIST',
};
  