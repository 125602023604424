// store/actions/cityActionTypes.js

export const CITY_ACTION_TYPES = {
    SET_DETAILS_DATA: 'SET_DETAILS_DATA',
    SET_CITY_TITLE:"SET_CITY_TITLE",
    SET_CITY_SHORTNAME:"SET_CITY_SHORTNAME",
    SET_CITY_ID:"SET_CITY_ID",
    SET_COUNTRY_ID:"SET_COUNTRY_ID",
    SET_REGION_ID:"SET_REGION_ID",
    SET_LOADING:"SET_LOADING",
    SET_OPEN_MODAL:"SET_OPEN_MODAL",
    SET_SHOW_DELETE_MODAL:"SET_SHOW_DELETE_MODAL"
    
  };
  