// store/actions/cityActionTypes.js

export const SMS_ACTION_TYPES = {
    SET_DETAILS_DATA: 'SET_DETAILS_DATA',
    SET_NAME:"SET_NAME",
    SET_SHORT_CODE:"SET_SHORT_CODE",
    SET_ENGLISH_MESSAGE:"SET_ENGLISH_MESSAGE",
    SET_ARABIC_MESSAGE:"SET_ARABIC_MESSAGE",
    SET_SMS_ID:"SET_SMS_ID",
    SET_LOADING:"SET_LOADING",
    SET_OPEN_MODAL:"SET_OPEN_MODAL",
    SET_SHOW_DELETE_MODAL:"SET_SHOW_DELETE_MODAL"
};
  