import { logoutFunction } from '../LogoutActions';
import * as getData from '../../../services/APIService';
import { toast } from "react-toastify";
import { setLoading } from '../CommonActions';


// Change the parameters to shiftlist
export const addBlockAppointmentAPI = (userId, logId, userToken, navigate,editMode,detailsData, doctor_id, hospital_detail_id, block_id, appointment) => async (dispatch) => {

    if (editMode) {
        var Changes = [

			// detailsData[0].start_datetime === start_datetime ? '' :
			// 	{
			// 		field_name : 'start_datetime',
			// 		old_value : detailsData[0].start_datetime,
			// 		new_value : start_datetime
			// 	},
			// 	detailsData[0].end_datetime === end_datetime ? '' :
			// 	{
			// 		field_name : 'end_datetime',
			// 		old_value : detailsData[0].end_datetime,
			// 		new_value : end_datetime
			// 	},
            //     detailsData[0].remarks === remarks ? '' :
			// 	{
			// 		field_name : 'remarks',
			// 		old_value : detailsData[0].remarks,
			// 		new_value : remarks
			// 	},
			// 	detailsData[0].recurrence_rule === recurrence_rule ? '' :
			// 	{
			// 		field_name : 'recurrence_rule',
			// 		old_value : detailsData[0].recurrence_rule,
			// 		new_value : recurrence_rule
			// 	},

		];
		Changes = Changes.filter(n => n)
		const postData = {
			user_id : userId,
			block_id : block_id,
			appointment: appointment,
            doctor_id: doctor_id,
            hospital_detail_id: hospital_detail_id,
			// logs : Changes
		};
		await getData.updateBlockAppointment(postData, userToken).then((resp) => {
			// setRegionList(resp.data.data);
			// console.log(resp.data);
			if (resp.data.status === 1) {
			  
				dispatch(setLoading(false));
				toast.success(resp.data.message, {
					position: toast.POSITION.TOP_RIGHT,
					autoClose: 1000,
				});

			   
				// dispatch(setShiftName(''));
				// dispatch(setStartTime(''));
				// dispatch(setEndTime(''));
				// dispatch(setTotalHours(''));
				// dispatch(setStartDate(''));
				// dispatch(setEndDate(''));
                // dispatch(setWorkSession(''));
                // dispatch(setIsEnabled(''));
			
				// dispatch(setOpenModal(false));

				// dispatch( getAllShiftListAPI(userId, logId, userToken, navigate));

			} else {
				dispatch(setLoading(false));
			 
				toast.error(resp.data.message, {
					position: toast.POSITION.TOP_RIGHT,
					autoClose: 1000,
				});
			}

		}).catch((error) => {
			if (error.response.data.status === 2) {
				toast.error(error.response.data.message + ". Please log in again to continue.", {
					position: toast.POSITION.TOP_RIGHT,
					autoClose: 1000,
				});
				setTimeout(() => {
					dispatch(logoutFunction(userId, logId, userToken, navigate));
				}, 2000)
			} else {
				toast.error(error.response.data.message + " Please Try After Sometimes" ||
					"Too Many Attempts. Please Try after sometimes", {
					position: toast.POSITION.TOP_RIGHT,
					autoClose: 1000,
				});
			}
		});
    }
    else {
        const postData = {
			user_id : userId,
            doctor_id : doctor_id,
            hospital_detail_id: hospital_detail_id,
            appointment: appointment
		};

        await getData.addBlockAppointment(postData, userToken).then((resp) => {
			// setNetworkList(resp.data.data);

			if (resp.data.status === 1) {

				dispatch(setLoading(false));
				toast.success(resp.data.message, {
					position: toast.POSITION.TOP_RIGHT,
					autoClose: 1000,
				});
			   
				// dispatch(setShiftName(''));
				// dispatch(setStartTime(''));
				// dispatch(setEndTime(''));
				// dispatch(setTotalHours(''));
				// dispatch(setStartDate(''));
				// dispatch(setEndDate(''));
                // dispatch(setWorkSession(''));
                // dispatch(setIsEnabled(''));
			
				// dispatch(setOpenModal(false));

				// dispatch( getAllShiftListAPI(userId, logId, userToken, navigate));

			} else {
				dispatch(setLoading(false));
				toast.error(resp.data.message, {
					position: toast.POSITION.TOP_RIGHT,
					autoClose: 1000,
				});
			}

		}).catch((error) => {
			if (error.response.data.status === 2) {
				toast.error(error.response.data.message + ". Please log in again to continue.", {
					position: toast.POSITION.TOP_RIGHT,
					autoClose: 1000,
				});
				setTimeout(() => {
					dispatch(logoutFunction(userId, logId, userToken, navigate));
				}, 2000)
			} else {
				toast.error(error.response.data.message + " Please Try After Sometimes" ||
					"Too Many Attempts. Please Try after sometimes", {
					position: toast.POSITION.TOP_RIGHT,
					autoClose: 1000,
				});
			}
		});
    }

}