import { ALL_MENU_ACTION_TYPES } from '../actions/AllMenuActionTypes';

const initialState = {
    allMenuList: [],
};

const AllMenuListReducer = (state = initialState, action) => {
  switch (action.type) {
    case ALL_MENU_ACTION_TYPES.SET_ALL_MENU_LIST:
      return {
        ...state,
        allMenuList: action.payload,
      };
    // Other cases...
    default:
      return state;
  }
};

export default AllMenuListReducer;