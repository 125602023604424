import { ALL_MASTER_MENU_ACTION_TYPES } from '../actions/AllMasterMenuActionTypes';

const initialState = {
    dashboardMenuList: [],
    mainMenuList: [],
    subMenuList: [],
    rolesList: [],
};

const AllMasterMenuListReducer = (state = initialState, action) => {
  switch (action.type) {
    case ALL_MASTER_MENU_ACTION_TYPES.SET_DASHBOARD_MENU_LIST:
      return {
        ...state,
        dashboardMenuList: action.payload,
      };
    case ALL_MASTER_MENU_ACTION_TYPES.SET_MAIN_MENU_LIST:
      return {
        ...state,
        mainMenuList: action.payload,
      };
    case ALL_MASTER_MENU_ACTION_TYPES.SET_SUB_MENU_LIST:
      return {
        ...state,
        subMenuList: action.payload,
      };
    case ALL_MASTER_MENU_ACTION_TYPES.SET_ROLES_LIST:
      return {
        ...state,
        rolesList: action.payload,
      };

    // Other cases...
    default:
      return state;
  }
};

export default AllMasterMenuListReducer;