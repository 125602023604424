import { RELIGION_ACTION_TYPES } from '../../actions/primaryMaster/ReligionActionTypes';

const initialState = {
    religionName: "",
    shortCode: "",
    religion_id: "",
    detailsData:[],
    loading:false,
    openModel :false
  // Other initial state...
};

const ReligionReducer = (state = initialState, action) => {
  switch (action.type) {
    case RELIGION_ACTION_TYPES.SET_RELIGION_NAME:
        return {
            ...state,
            religionName: action.payload,
        };
    case RELIGION_ACTION_TYPES.SET_SHORT_CODE:
        return {
            ...state,
            shortCode: action.payload,
        };
    case RELIGION_ACTION_TYPES.SET_RELIGION_ID:
        return {
            ...state,
            religion_id: action.payload,
        };
    case RELIGION_ACTION_TYPES.SET_DETAILS_DATA:
        return {
            ...state,
            detailsData: action.payload,
        };
    case RELIGION_ACTION_TYPES.SET_LOADING:
        return {
            ...state,
            loading: action.payload,
        };
    case RELIGION_ACTION_TYPES.SET_OPEN_MODAL:
        return {
            ...state,
            openModel: action.payload,
        };
    // Other cases...
    default:
      return state;
  }
};

export default ReligionReducer;
 