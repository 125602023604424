import { ALL_MASTER_ACTION_TYPES } from '../actions/AllMasterActionTypes';

const initialState = {
    maritalStatusList: [],
    patientClassList: [],
    bloodgrouplist:[],
    occupationList: [],
    educationList: [],
    religionList: [],
    ethnicList: [],
    industryList: [],
    languageList: [],
    incomeRangeList: [],
    departmentAllList: [],
    departmentCategoryAllList: [],
    jobTitleAllList: [],
    titleAllList: [],
    genderAllList: [],
    countryList: [],
    cityAllList: [],
    cityList: [],
    cityList1: [],
    cityList2: [],
    cityList3: [],
    regionList: [],
    regionList1: [],
    regionList2: [],
    regionList3: [],
    regionAllList: [],
    nationalityList: [],
    relationshipList: [],
    referralSourceList:[],
    referralChannelList:[],
    chargetypelist:[],
    companytypelist:[],
    companylist:[],
    maincompanylist: [],
    subcompanylist: [],
    insurancelist:[],
    doctorlist:[],
    stafflist:[],
    refferaldoctorlist:[],
    reasonlist:[],
    servicelist:[],
    refferalcliniclist:[],
    enquriypatientlist:[],
    appointmentlist:[],
    networklist:[],
    insnetworklist:[],
    networkplanlist:[],
    benefitlist:[],
    insplanlist:[],
    smslist:[],
    appstatuslist:[],
    plandetailslist: [],
    planservicecategorylist: [],
    networkanditsplanslist1:[], // used for copy network & its plan (from)
    networkanditsplanslist2:[], // used for copy network & its plan (to)
    shiftlist: [],
    doctormasterlist: [],
    shiftdropdownlist: [],
    doctordropdownlist: [],
    visittypedropdownlist:[],
    examdropdownlist:[],
    statusdropdownlist:[],
    enquiryreasondropdownlist: [],
    enquiryservicedropdownlist: [],
    apptservicedropdownlist: []
};

const AllMasterListReducer = (state = initialState, action) => {
  switch (action.type) {
    case ALL_MASTER_ACTION_TYPES.SET_MARITAL_STATUS_LIST:
      return {
        ...state,
        maritalStatusList: action.payload,
      };
    case ALL_MASTER_ACTION_TYPES.SET_PATIENT_CLASS_LIST:
      return {
        ...state,
        patientClassList: action.payload,
      };
      case ALL_MASTER_ACTION_TYPES.SET_BLOOD_GROUP_LIST:
        return {
          ...state,
          bloodgrouplist: action.payload,
        };
    case ALL_MASTER_ACTION_TYPES.SET_OCCUPATION_LIST:
      return {
        ...state,
        occupationList: action.payload,
      };
    case ALL_MASTER_ACTION_TYPES.SET_EDUCATION_LIST:
      return {
        ...state,
        educationList: action.payload,
      };
    case ALL_MASTER_ACTION_TYPES.SET_RELIGION_LIST:
      return {
        ...state,
        religionList: action.payload,
      };
    case ALL_MASTER_ACTION_TYPES.SET_ETHNIC_LIST:
      return {
        ...state,
        ethnicList: action.payload,
      };
      case ALL_MASTER_ACTION_TYPES.SET_INDUSTRY_LIST:
        return {
          ...state,
          industryList: action.payload,
        };
    case ALL_MASTER_ACTION_TYPES.SET_LANGUAGE_LIST:
      return {
        ...state,
        languageList: action.payload,
      };
    case ALL_MASTER_ACTION_TYPES.SET_INCOME_RANGE_LIST:
      return {
        ...state,
        incomeRangeList: action.payload,
      };
    case ALL_MASTER_ACTION_TYPES.SET_DEPARTMENT_LIST:
      return {
        ...state,
        departmentAllList: action.payload,
      };
    case ALL_MASTER_ACTION_TYPES.SET_CATDEPARTMENT_LIST:
      return {
        ...state,
        departmentList: action.payload,
      };
    case ALL_MASTER_ACTION_TYPES.SET_DEPARTMENT_CATEGORY_LIST:
      return {
        ...state,
        departmentCategoryAllList: action.payload,
      };
    case ALL_MASTER_ACTION_TYPES.SET_JOBTITLE_LIST:
      return {
        ...state,
        jobTitleAllList: action.payload,
      };
    case ALL_MASTER_ACTION_TYPES.SET_TITLE_LIST:
      return {
        ...state,
        titleAllList: action.payload,
      };
    case ALL_MASTER_ACTION_TYPES.SET_GENDER_LIST:
      return {
        ...state,
        genderAllList: action.payload,
      };
    case ALL_MASTER_ACTION_TYPES.SET_COUNTRY_LIST:
      return {
        ...state,
        countryList: action.payload,
      };
    case ALL_MASTER_ACTION_TYPES.SET_CITY_ALL_LIST:
      return {
        ...state,
        cityAllList: action.payload,
      };
    case ALL_MASTER_ACTION_TYPES.SET_CITY_LIST:
      return {
        ...state,
        cityList: action.payload,
      };
    case ALL_MASTER_ACTION_TYPES.SET_CITY_LIST1:
      return {
        ...state,
        cityList1: action.payload,
      };
    case ALL_MASTER_ACTION_TYPES.SET_CITY_LIST2:
      return {
        ...state,
        cityList2: action.payload,
      };
    case ALL_MASTER_ACTION_TYPES.SET_CITY_LIST3:
      return {
        ...state,
        cityList3: action.payload,
      };
    case ALL_MASTER_ACTION_TYPES.SET_REGION_LIST:
      return {
        ...state,
        regionList: action.payload,
      };
    case ALL_MASTER_ACTION_TYPES.SET_REGION_LIST1:
      return {
        ...state,
        regionList1: action.payload,
      };
    case ALL_MASTER_ACTION_TYPES.SET_REGION_LIST2:
        return {
          ...state,
          regionList2: action.payload,
        };
    case ALL_MASTER_ACTION_TYPES.SET_REGION_LIST3:
        return {
          ...state,
          regionList3: action.payload,
        };
    case ALL_MASTER_ACTION_TYPES.SET_REGION_ALL_LIST:
      return {
        ...state,
        regionAllList: action.payload,
      };
    case ALL_MASTER_ACTION_TYPES.SET_NATIONALITY_LIST:
      return {
        ...state,
        nationalityList: action.payload,
      };
    case ALL_MASTER_ACTION_TYPES.SET_RELATIONSHIP_LIST:
      return {
        ...state,
        relationshipList: action.payload,
      };
    case ALL_MASTER_ACTION_TYPES.SET_REFERRAL_SOURCE_LIST:
        return {
          ...state,
          referralSourceList: action.payload,
      };
      case ALL_MASTER_ACTION_TYPES.SET_REFERRAL_CHANNEL_LIST:
        return {
          ...state,
          referralChannelList: action.payload,
      };
      case ALL_MASTER_ACTION_TYPES.SET_NETWORK_LIST:
        return {
          ...state,
          networklist: action.payload,
      };
      case ALL_MASTER_ACTION_TYPES.SET_NETWORK_LIST1:
        return {
          ...state,
          insnetworklist: action.payload,
      };
      case ALL_MASTER_ACTION_TYPES.SET_PLAN_NETWORK_LIST:
        return {
          ...state,
          networkplanlist: action.payload,
      };
      case ALL_MASTER_ACTION_TYPES.SET_CHARGE_TYPE_LIST:
        return {
          ...state,
          chargetypelist: action.payload,
        };
        case ALL_MASTER_ACTION_TYPES.SET_COMPANY_TYPE_LIST:
          return {
            ...state,
            companytypelist: action.payload,
          };
          case ALL_MASTER_ACTION_TYPES.SET_COMPANY_LIST:
            return {
              ...state,
              companylist: action.payload,
            };
            case ALL_MASTER_ACTION_TYPES.SET_MAIN_COMPANY_LIST:
            return {
              ...state,
              maincompanylist: action.payload,
            };
            case ALL_MASTER_ACTION_TYPES.SET_SUB_COMPANY_LIST:
              return {
                ...state,
                subcompanylist: action.payload,
              };
            case ALL_MASTER_ACTION_TYPES.SET_PATIENT_ENQUIRY_LIST:
              return {
                ...state,
              enquriypatientlist: action.payload,
              };
            case ALL_MASTER_ACTION_TYPES.SET_INSURANCE_LIST:
            return {
              ...state,
              insurancelist: action.payload,
            };
            case ALL_MASTER_ACTION_TYPES.SET_DOCTOR_LIST:
              return {
                ...state,
                doctorlist: action.payload,
              };
              case ALL_MASTER_ACTION_TYPES.SET_STAFF_LIST:
                return {
                  ...state,
                  stafflist: action.payload,
                };
              case ALL_MASTER_ACTION_TYPES.SET_REFFERAL_DOCTOR_LIST:
                return {
                  ...state,
                  refferaldoctorlist: action.payload,
                };
                case ALL_MASTER_ACTION_TYPES.SET_REFFERAL_CLINIC_LIST:
                  return {
                    ...state,
                    refferalcliniclist: action.payload,
                  };
              case ALL_MASTER_ACTION_TYPES.SET_SERVICE_LIST:
                return {
                  ...state,
                  servicelist: action.payload,
                };
                case ALL_MASTER_ACTION_TYPES.SET_REASON_LIST:
                  return {
                    ...state,
                    reasonlist: action.payload,
                  };
                  case ALL_MASTER_ACTION_TYPES.SET_APPOINTMENT_LIST:
                  return {
                    ...state,
                    reasonlist: action.payload,
                  };
                
                  case ALL_MASTER_ACTION_TYPES.SET_INS_PLAN_LIST:
                    return {
                      ...state,
                      insplanlist: action.payload,
                    };
                    case ALL_MASTER_ACTION_TYPES.SET_SMS_TEMPLATE_LIST:
                      return {
                        ...state,
                        smslist: action.payload,
                      };
                      case ALL_MASTER_ACTION_TYPES.SET_APPOINTMENT_STATUS_LIST:
                        return {
                          ...state,
                          appstatuslist: action.payload,
                        };
                  case ALL_MASTER_ACTION_TYPES.SET_BENEFIT_LIST:
                  return {
                    ...state,
                    benefitlist: action.payload,
                  };
                  case ALL_MASTER_ACTION_TYPES.SET_PLAN_DETAILS_LIST:
                  return {
                    ...state,
                    plandetailslist: action.payload,
                  };
                  case ALL_MASTER_ACTION_TYPES.SET_PLAN_SERVICE_CATEGORY_LIST:
                  return {
                    ...state,
                    planservicecategorylist: action.payload,
                  };
                  case ALL_MASTER_ACTION_TYPES.SET_NETWORK_AND_PLAN_LIST1:
                  return {
                    ...state,
                    networkanditsplanslist1: action.payload,
                  };
                  case ALL_MASTER_ACTION_TYPES.SET_NETWORK_AND_PLAN_LIST2:
                  return {
                    ...state,
                    networkanditsplanslist2: action.payload,
                  }
                  case ALL_MASTER_ACTION_TYPES.SET_SHIFT_LIST:
                  return {
                    ...state,
                    shiftlist: action.payload,
                  }
                  case ALL_MASTER_ACTION_TYPES.SET_DOCTOR_MASTER_LIST:
                  return {
                    ...state,
                    doctormasterlist: action.payload,
                  };
                  case ALL_MASTER_ACTION_TYPES.SET_SHIFT_DROPDOWN_LIST:
                  return {
                    ...state,
                    shiftdropdownlist: action.payload,
                  };
                  case ALL_MASTER_ACTION_TYPES.SET_DOCTOR_MASTER_DROPDOWN_LIST:
                  return {
                    ...state,
                    doctordropdownlist: action.payload,
                  };
                  case ALL_MASTER_ACTION_TYPES.SET_VISITTYPE_DROPDOWN_LIST:
                    return {
                      ...state,
                      visittypedropdownlist: action.payload,
                    };
                    case ALL_MASTER_ACTION_TYPES.SET_EXAM_DROPDOWN_LIST:
                    return {
                      ...state,
                      examdropdownlist: action.payload,
                    };
                    case ALL_MASTER_ACTION_TYPES.SET_STATUS_DROPDOWN_LIST:
                    return {
                      ...state,
                      statusdropdownlist: action.payload,
                    };
                    case ALL_MASTER_ACTION_TYPES.SET_ENQUIRY_REASON_DROPDOWN_LIST:
                    return {
                      ...state,
                      enquiryreasondropdownlist: action.payload,
                    };
                    case ALL_MASTER_ACTION_TYPES.SET_ENQUIRY_SERVICE_DROPDOWN_LIST:
                    return {
                      ...state,
                      enquiryservicedropdownlist: action.payload,
                    };
                    case ALL_MASTER_ACTION_TYPES.SET_APPOINTMENT_SERVICE_DROPDOWN_LIST:
                    return {
                      ...state,
                      apptservicedropdownlist: action.payload,
                    };
                    
    // Other cases...
    default:
      return state;
  }
};

export default AllMasterListReducer;
 