import { EDUCATION_ACTION_TYPES } from '../../actions/primaryMaster/EducationActionTypes';

const initialState = {
    educationName: "",
    education_id: "",
    detailsData:[],
    loading:false,
    openModel :false
  // Other initial state...
};

const EducationReducer = (state = initialState, action) => {
  switch (action.type) {
    case EDUCATION_ACTION_TYPES.SET_EDUCATION_NAME:
        return {
            ...state,
            educationName: action.payload,
        };
    case EDUCATION_ACTION_TYPES.SET_EDUCATION_ID:
        return {
            ...state,
            education_id: action.payload,
        };
    case EDUCATION_ACTION_TYPES.SET_DETAILS_DATA:
        return {
            ...state,
            detailsData: action.payload,
        };
    case EDUCATION_ACTION_TYPES.SET_LOADING:
        return {
            ...state,
            loading: action.payload,
        };
    case EDUCATION_ACTION_TYPES.SET_OPEN_MODAL:
        return {
            ...state,
            openModel: action.payload,
        };
    // Other cases...
    default:
      return state;
  }
};

export default EducationReducer;
 