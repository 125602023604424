import React, { useState, useRef, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Title from "../../utils/Titles/Title";
import Buttons from '../../utils/Button';
import Table from '../../utils/Newtable/Table';
import { tableData } from '../../utils/json/tableData';
import { registrationUIDatas } from '../../utils/json/registrationUIDatas';
import Dropdown from '../../utils/Dropdown'
import DatePickers from '../../utils/DatePickers'
import TextFeildRadioBox from '../../utils/TextFeildRadioBox';
import InputPhone from '../../utils/InputPhone'
import TextInput from '../../utils/TextInput'
import * as getData from '../../../services/APIService';
import { useSelector, useDispatch } from "react-redux";
import { logoutFunction } from "../../../store/actions/LogoutActions";
import { SVGICON } from "../../constant/theme";
import { TextField } from "@mui/material";
import TextFeildCheckBox from '../../utils/TextFeildCheckBox'
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { NavMenuOn, NavMenuToggle } from "../../layouts/nav/NavHader";
import { ThemeContext } from "../../../context/ThemeContext";
import Searchdropdown from "../../utils/Searchdropdown";
import * as getAPIAction from "../../../store/actions/GetAllMasterList";
// import Loader from "../Loader/Loader";
// import { setLoading } from "../../../store/actions/CommonActions";
const moment = require('moment');
const RegistrationList = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const companyList = useSelector(
		(state) => state.allMasterList.insurancelist
	);
	const doctorList = useSelector(
		(state) => state.allMasterList.doctorlist
	);;
	const [activeRadioField, setActiveRadioField] = useState([registrationUIDatas[0]?.registration[0]?.registrationListCategoryinputFilter["register_date"]]);
	const [selectedOption, setSelectedOption] = useState("register_date");
	const dashboardTableHead = tableData[0]?.dashboard?.[0]?.recentOrders?.[0]?.tableHead || [];
	const [patientlist, setpatientlist] = useState([]);
	const [clientList, setClientList] = useState([]);
	const userData = useSelector((state) => state.auth.auth);
	const userToken = userData.idToken;
	const userId = userData.userId;
	const logId = parseInt(userData.logId);
	// const loading = useSelector((state) => state.common.showLoading)
	const hospital_detail_id = parseInt(userData.hospital_detail_id)
	const initialfilterData = {
		mr_number: '',
		first_name: '',
		last_name: '',
		primary_contact: '+971',
		ins_card_no: '',
		company_id: '',
		doctor_id: '',
		register_date: '',
		invoice_no: '',
		emirate_ids: '',
	}
	const [searchhospitalid, setsearchhospitalid] = useState(0);
	const [filterFields, setFilterFields] = useState(initialfilterData)
	const { toggleSideMenu } = useContext(ThemeContext);
	const shouldLog = useRef(true);

	useEffect(() => {
		document.title = 'RegistrationList';
		if (shouldLog.current) {
			shouldLog.current = false;
			// dispatch(setLoading(true))
			//Get patient List
			getClientListAPI();
			dispatch(getAPIAction.getDoctorListAPI(userId, logId, userToken, navigate));
			dispatch(getAPIAction.GetInsuranceList(userId, logId, userToken, navigate));
			loadPatientListByToday();
			setFilterFields({ ['register_date']: moment().format('YYYY-MM-DD') })
			searchsubmit();
			// getpatientlist();
		}
		// eslint-disable-next-line
	}, [shouldLog]);

	useEffect(() => {
		if (Object.keys(userData).length !== 0) {
			setsearchhospitalid(hospital_detail_id);
		}
	}, [userData])
	const getClientListAPI = async () => {
		await getData.getClientList(userToken).then((resp) => {
			setClientList(resp.data.data);
		}).catch((error) => {
			if (error.response.data.status === 2) {
				toast.error(error.response.data.message + ". Please log in again to continue.", {
					position: toast.POSITION.TOP_RIGHT,
					autoClose: 1000,
				});
				setTimeout(() => {
					dispatch(logoutFunction(userId, logId, userToken, navigate));
				}, 2000)
			} else {
				toast.error(error.response.data.message + " Please Try After Sometimes" ||
					"Too Many Attempts. Please Try after sometimes", {
					position: toast.POSITION.TOP_RIGHT,
					autoClose: 1000,
				});
			}
		});
	};
	const handlecategoryChange = (e) => {
		console.log("hiii",e.target.value);
		if(e.target.value === null) {
			setActiveRadioField([]);	
			setFilterFields(initialfilterData)
			
			setSelectedOption('');
		}
		else {
			setActiveRadioField([registrationUIDatas[0]?.registration[0]?.registrationListCategoryinputFilter[e.target.value]]);
			setFilterFields(initialfilterData)

			setSelectedOption(e.target.value);
		}
	}
	// update values of enabled text or phone input 
	const handleRadioValueModifier = (e) => {
		const { name, value, type, files } = e.target;
		const newValue = type === 'file' ? files[0] : value;
		setFilterFields({ [name]: newValue });
	}
	const resetcenter = (e) => {
		setsearchhospitalid(0);
	}
	const handleRefresh=()=>
		{
			searchsubmit();
		}
	const subhandleChange = (e, label) => {

		setsearchhospitalid(e.target.value)
	}
	const handleedit = (patientId) => {
		// toggleSideMenu(true); NavMenuOn();
		//navigate('/Registration?patientId=' + patientId)
		window.open('/#/Registration?patientId=' + patientId, '_blank');
		//window.location.href = '/Registration?patientId=' + patientId;
	}

	const loadPatientListByToday = async () => {
		const postData = {
			"user_id": userId,
			"hospital_detail_id": searchhospitalid,
			"search_field": "register_date",
			"search_value": moment(new Date()).format('DD-MM-YYYY')
		}
		await viewPatientList(postData)
	}
	const searchsubmit = async (e) => {

		//e.preventDefault();
		const postData = {
			"user_id": userId,
			"hospital_detail_id": searchhospitalid,
			"search_field": selectedOption,
			"search_value": selectedOption === "register_date" ? moment(filterFields[selectedOption]['$d']).format('DD-MM-YYYY') : filterFields[selectedOption]
		}
		await viewPatientList(postData)

		// dispatch(setLoading(false))
	}

	const viewPatientList = async (postData) => {
		await getData.ViewPatientList(postData, userToken).then((resp) => {
			if (resp.data && resp.data.status === 0) {
				toast.error(resp.data.message, {
					position: toast.POSITION.TOP_RIGHT,
					autoClose: 2000,
				});
			} else {
				const patients = resp.data.data.patient;
				// console.log("hi list",patients)
				patients.forEach((patient, index) => {
	                const { first_name, middle_name, last_name, id, main_company_name, emirate_ids } = patient;
	                const fullName = `${first_name} ${middle_name !== null ? middle_name : ""} ${last_name}`;
	                const reg_no = `RID${id}`;
	                const company_name = `${main_company_name === null || main_company_name === "" ? 'CASH' : main_company_name}`;
	                const formattedEmirateIds = emirate_ids ? formatNumber(emirate_ids.toString()) : '';
	                patients[index]['emirate_ids'] = formattedEmirateIds;
	                patients[index]['main_company_name'] = company_name;
	                patients[index]['reg_no'] = reg_no;
	                patients[index]['patient_name'] = fullName;
	            });
	            // console.log('patients',patients);
				setpatientlist(patients)
			}
		}).catch((error) => {
			if (error.response.data.status === 2) {
				toast.error(error.response.data.message + ". Please log in again to continue.", {
					position: toast.POSITION.TOP_RIGHT,
					autoClose: 1000,
				});
				setTimeout(() => {
					dispatch(logoutFunction(userId, logId, userToken, navigate));
				}, 2000)
			} else {
				toast.error(error.response.data.message + " Please Try After Sometimes" ||
					"Too Many Attempts. Please Try after sometimes", {
					position: toast.POSITION.TOP_RIGHT,
					autoClose: 1000,
				});
			}
		});
	}


    function formatNumber(number) {
        const numStr = number.toString();
        
        const part1 = numStr.slice(0, 3);    // First 3 digits
        const part2 = numStr.slice(3, 7);    // Next 4 digits
        const part3 = numStr.slice(7, 14);   // Next 7 digits
        const part4 = numStr.slice(14);      // Remaining digit(s)

		let finalPart = `${part1}-${part2}-${part3}-${part4}`
		if(finalPart === "___-____-_______-_") {
			finalPart = "";
		}

        return finalPart;
    }

	// const getpatientlist = async () => {
	// 	const postData={
	// 		"user_id": userId,
	// 		"hospital_detail_id" : searchhospitalid, //All location means send value as 0
	// 	// 	"search_field": selectedOption, 
	// 	//    "search_value": filterFields[selectedOption]
	// 	  "search_field" : "first_name",
	// 	    "search_value" : "Gugan"
	// 	   }
	//     await getData.ViewPatientList(postData, userToken).then((resp) => {
	//         if (resp.data && resp.data.status === 0) {
	//             toast.error(resp.data.message, {
	//                 position: toast.POSITION.TOP_RIGHT,
	//                 autoClose: 2000,
	//             });
	//         } else {
	// 			const  patients=resp.data.data.patient;
	// 			patients.forEach((patient,index) => {
	// 			  const { first_name, middle_name, last_name } = patient;
	// 			  const fullName = `${first_name} ${middle_name!==null?middle_name:""} ${last_name}`;
	// 			  patients[index]['patient_name'] = fullName;
	// 			});
	//             setpatientlist(patients)
	// 		}
	//     }).catch((error) => {
	//         //  console.log(error);
	//         if (error.response.data.status === 2) {
	//             toast.error(error.response.data.message + ". Please log in again to continue.", {
	//                 position: toast.POSITION.TOP_RIGHT,
	//                 autoClose: 1000,
	//             });
	//             setTimeout(() => {
	//                 dispatch(logoutFunction(userId, logId, userToken, navigate));
	//             }, 2000)
	//         } else {
	//             toast.error(error.response.data.message + " Please Try After Sometimes" ||
	//                 "Too Many Attempts. Please Try after sometimes", {
	//                 position: toast.POSITION.TOP_RIGHT,
	//                 autoClose: 1000,
	//             });
	//         }
	//     });
	// };
	return (
		<>
			<div className="row">
				<div className="col-md-6 col-lg-3 col-xs-12"><Title icon={SVGICON.edit} title="Patient List" /></div>
				{registrationUIDatas[0]?.registration[0]?.locationCenter.map((input, index) => (
					<div className={`col-lg-${input.label === "Location" ? "3" : "2"} col-md-6 `} key={index}>
						{input.name === "hospital_id" ? (
							<Searchdropdown {...input} options={clientList} value={searchhospitalid} onChange={(e) => subhandleChange(e)} />
						) : (
							<TextFeildCheckBox {...input} checked={searchhospitalid === 0 ? true : false} onChange={resetcenter} />
						)}
					</div>
				))}
				<div className="col-lg-2 col-md-3 col-xs-12 right-align refresh-btn">	<Buttons.ResetButtons
                        name="Refresh"
                        onClick={handleRefresh}
                    /></div>
				<div className="col-lg-2 col-md-3 col-xs-12">
			
				<Buttons.AddButtons name="Add" onClick={() => { window.open('/#/Registration', '_blank'); }} />
					{/* <Buttons.AddButtons name="Add" onClick={() => { toggleSideMenu(true); NavMenuOn(); navigate('/Registration'); }} /> */}
				</div>
			</div>
			<div className="bg_wrap" style={{ "padding": "10px 0px" }}>
				<div className="b-radius dashTable" style={{ margin: "8px 0px", padding: "0px 10px" }}>
					<div className="container-fluid">
						<div className="row">
							<div className="col-md-4 col-xs-12 padding_fixed">
								{registrationUIDatas[0]?.registration[0]?.registrationListCategoryFilter.map((input) => {
									return <Searchdropdown {...input} options={input.options} value={selectedOption}
										onChange={(e) => handlecategoryChange(e)}
									/>
								}
								)}
							</div>
							<div className="col-md-4 col-xs-12 padding_fixed">
								<div className="wrap-field">{activeRadioField && activeRadioField.map((input, tabIndex) => (
									<div key={input.id} >

										{(input.type === "text") ? <TextInput {...input}
											onChange={(e) => handleRadioValueModifier(e)}

											value={filterFields[input.name]}

										/> : (input.type === "select") ?

											<Searchdropdown {...input}
												options={input.name === "company_id" ? companyList : input.name === "doctor_id" ? doctorList : []}
												onChange={(e) => handleRadioValueModifier(e)}

												value={filterFields[input.name]}
											/>

											: (input.type === "datePicker") ? <DatePickers {...input}

												onChange={(date) => handleRadioValueModifier({ target: { name: 'register_date', value: date } })}

												selected={(input.label === "Regn Date") ? filterFields[input.name] : ""}
												value={filterFields[input.name]}

											/> : (input.type === "phone") ? <InputPhone {...input}
												onChange={(value) => handleRadioValueModifier({ target: { name: input.name, value: value } })}
												value={filterFields[input.name]}
											/>

												// : (input.type === "checkBox") ? <TextFeildCheckBox {...input}
												// /> 

												// : (input.type === "file") ? <Fileinput  {...input}
												// 	onChange={(e) => subhandleChange(e, input.label)} /> 

												: ""}

									</div>

								))}</div>
							</div>
							<div className="col-md-4 col-xs-12 right-align padding_fixed srch-btn">
								<Buttons.SearchButtons name="Search" onClick={searchsubmit} />
							</div>
						</div>
						<div className="row">
							<div className="col-md-12 col-xs-12 no_padding"style={{ whiteSpace: 'nowrap' }}>
								<Table
									columns={dashboardTableHead}
									// serialNoRequired={false}
									rowname={"id"}
									rowdata={patientlist}
									patienteditclick={handleedit}
									hide={true}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
{/* 
			{loading ? (
				<Loader />
			) : null} */}

			<ToastContainer />
		</>
	);
};
export default RegistrationList;