import React from 'react';
import { Modal } from 'react-bootstrap';

const OfflineModal = ({ show, onRefresh, onHide }) => {
  return (
    <Modal show={show} onHide={onHide} backdrop="static" keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>Offline</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>You are currently offline. Please check your internet connection and try again.</p>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-primary" onClick={onRefresh}>
          Refresh
          </button>
      </Modal.Footer>
    </Modal>
  );
};

export default OfflineModal;
