// store/actions/cityActionTypes.js

export const NETWORK_ACTION_TYPES = {
    SET_DETAILS_DATA: 'SET_DETAILS_DATA',
    SET_NAME:"SET_NAME",
    SET_NETWORK_TYPE:"SET_NETWORK_TYPE",
    SET_NETWORK_ID:"SET_NETWORK_ID",
    SET_INSURANCE_COMPANY_DETAIL_ID:"SET_INSURANCE_COMPANY_DETAIL_ID",
    SET_IS_STATUS:"SET_IS_STATUS",
    SET_LOADING:"SET_LOADING",
    SET_OPEN_MODAL:"SET_OPEN_MODAL",
    SET_SHOW_DELETE_MODAL:"SET_SHOW_DELETE_MODAL"
};
  