import React, { useState, useRef, useEffect } from "react";
import { Tab } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Image } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Title from "../../utils/Titles/Title";
import * as getData from '../../../services/APIService';
import * as getAPIAction from "../../../store/actions/GetAllMasterList";
import { ALL_MASTER_ACTION_TYPES } from "../../../store/actions/AllMasterActionTypes";
import { apppointmentbookUIDatas } from '../../utils/json/appointmentbookUIDatas';
import TextInput from '../../utils/TextInput';
import Dropdown from '../../utils/Dropdown'
import Multiselect from '../../utils/Multiselect'
import DatePickers from '../../utils/DatePickers'
import TimePikcers from '../../utils/TimePikcers'
import InputPhone from '../../utils/InputPhone'
import TextFeildCheckBox from '../../utils/TextFeildCheckBox'
import TextFeildRadioBox from '../../utils/TextFeildRadioBox'
import Fileinput from '../../utils/Fileinput'
import FileUploadButton from '../../utils/FileUploadButton'
import Buttons from '../../utils/Button'
import Table from '../../utils/Newtable/Table'
import { tableData } from '../../utils/json/tableData'
import Popup from "../Masters/Element/Popup";
import { SVGICON } from "../../constant/theme";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Spinner from "react-bootstrap/Spinner";
import { logoutFunction } from "../../../store/actions/LogoutActions";
import { useDispatch, useSelector } from 'react-redux';
import { matchIsValidTel } from 'mui-tel-input';
import FormLabel from '@mui/material/FormLabel';
import dayjs from "dayjs";
import Searchdropdown from "../../utils/Searchdropdown";
import { setLoading } from "../../../store/actions/CommonActions";
const moment = require('moment');
const AppointmentBook = ({setopenform,editpid,setSwitchPhoneCallModel}) => {

  const navigate = useNavigate();
  const dispatch = useDispatch();

const userData = useSelector((state) => state.auth.auth);
	const userToken = userData.idToken;
	const userId = userData.userId;
	const logId = userData.logId;
	const roleId = parseInt(userData.rollId);
const shouldLog = useRef(true);

const [patient_id, setpatient_id] =  useState(editpid?editpid: 0);
const loading = useSelector((state) => state.common.showLoading)

useEffect(() => {
  document.title = 'AppointmentBook';
  if (shouldLog.current) {
    dispatch(getAPIAction.getDoctorListAPI(userId, logId, userToken, navigate));
    dispatch(getAPIAction.getStaffListAPI(userId, logId, userToken, navigate));
    dispatch(getAPIAction.getRefferalDoctorListAPI(userId, logId, userToken, navigate));
    dispatch(getAPIAction.getAllDepartmentListAPI(userId, logId, userToken, navigate));
   
     dispatch(getAPIAction.getEnquiryReasonListAPI(userId, logId, userToken, navigate));
     dispatch(getAPIAction.getEnquiryServiceListAPI(userId, logId, userToken, navigate));
  }
  // eslint-disable-next-line
}, []);
useEffect(() => {
  if (shouldLog.current) {
      shouldLog.current = false;
      if (patient_id !== 0) {
          GetSinglePatient();

      }
  }
 
  // eslint-disable-next-line
}, [patient_id]);

  const departmentList = useSelector(state => state.allMasterList.departmentAllList);
  const doctorList = useSelector(state => state.allMasterList.doctorlist);
  const refferaldoctorList = useSelector(state => state.allMasterList.refferaldoctorlist);
  const referralList = [];
  const apptReasonList =  useSelector(state => state.allMasterList.reasonlist);
  const apptServicesList =  useSelector(state => state.allMasterList.servicelist);
  const timeIntervalList = [{"id":5,"name":5},
    {"id":10,"name":10},
    {"id":15,"name":15},
    {"id":20,"name":20},
    {"id":25,"name":25},
    {"id":30,"name":30},
    {"id":35,"name":35},
    {"id":40,"name":40},
    {"id":45,"name":45},
    {"id":50,"name":50},
    {"id":55,"name":55},
    {"id":60,"name":60},
    {"id":90,"name":90},
    {"id":120,"name":120}
];

  const staffList = useSelector(state => state.allMasterList.stafflist);
  const [errors, setErrors] = useState({});

  const initialFormData = {
    enquiry_no: "",
    first_name: "",
    middle_name: "",
    last_name: "",
    contact_no: "+971",
    department_id: "",
    doctor_id: '',
    comments: "",
    referral_doctor_id: "",
    enquiry_reason_id: "",
    appt_services_id: "",
    time_interval: "",
    appointment_date: "",
    from_time: "",
    to_time: "",
    staff_id: ""
  }
 
  const [formData, setFormData] = useState(initialFormData); 
  const [detailsData, setDetailsData] = useState([]);
  const [showFieldLoad, setShowFieldLoad] = useState(false);
  const validateForm = () => {
    const validationErrors = {};
    const pattern = /^[a-zA-Z0-9 ]*$/;
    if (!pattern.test(formData.first_name)) {
      validationErrors.first_name = 'Special characters are not allowed';
    }
    if (!formData.first_name) {
      validationErrors.first_name = 'Patient First Name is required';
    }
    if (!pattern.test(formData.last_name)) {
      validationErrors.last_name = 'Special characters are not allowed';
    }
    if (!formData.last_name) {
      validationErrors.last_name = 'Patient Last Name is required';
    }
    if (!matchIsValidTel(formData.contact_no, {
      onlyCountryies: [],
      excludedCountryies: [],
      continents: []
    })) {
        validationErrors.contact_no = 'Contact Number is required';
    }
    if (!formData.department_id) {
      validationErrors.department_id = 'Department is required';
    }
    if (!formData.appointment_date) {
      validationErrors.appointment_date = 'Appointment date is required';
    }
    if (!formData.doctor_id) {
      validationErrors.doctor_id = 'Doctor name is required';
    }
    if (!formData.staff_id) {
      validationErrors.staff_id = 'Staff name is required';
    }
    if (!formData.enquiry_reason_id) {
      validationErrors.enquiry_reason_id = 'Appointment reason is required';
    }
 
    return validationErrors;
  };
  const GetSinglePatient = async () => {
    setShowFieldLoad(true);


    await getData.getSinglePatientEnquiry(patient_id, userToken).then((resp) => {


        if (resp.data && resp.data.status === 0) {
            toast.error(resp.data.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
            });
        } else {

            var detailsData = resp.data.data[0];
           
           
            setDetailsData(resp.data.data[0])
            setFormData({
                ...detailsData,
                enquiry_no: detailsData.patient_id,
                first_name: detailsData.first_name,
                middle_name: detailsData.middle_name,
                last_name: detailsData.last_name,
                contact_no: detailsData.contact_no,
                department_id: detailsData.department_id,
                doctor_id: detailsData.doctor_id,
                comments: detailsData.comments,
                referral_doctor_id: detailsData.referral_doctor_id,
                enquiry_reason_id: detailsData.enquiry_reason_id,
                appt_services_id: detailsData.appt_services_id,
                time_interval: detailsData.time_interval,
                appointment_date:detailsData.appointment_date !== null ? moment(detailsData.appointment_date, 'DD-MM-YYYY').format('YYYY-MM-DD') : null,
                from_time: detailsData.from_time,
                to_time: detailsData.to_time,
                staff_id: detailsData.staff_id
            });
         
        }

        setShowFieldLoad(false);

    }).catch((error) => {
        //   console.log(error);
        //   return false;
        if (error.response.data.status === 2) {
            toast.error(error.response.data.message + ". Please log in again to continue.", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 1000,
            });
            setTimeout(() => {
                dispatch(logoutFunction(userId, logId, userToken, navigate));
            }, 2000)
        } else {
            toast.error(error.response.data.message + " Please Try After Sometimes" ||
                "Too Many Attempts. Please Try after sometimes", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 1000,
            });
        }
    });
};
  //Handle function of all form inputs
  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    const newValue = type === 'file' ? files[0] : value;


    if(name === "from_time") {
      if(formData["to_time"] === "") {
        setFormData({ ...formData, [name]: newValue , 'to_time' : newValue });
      }
      else {
        setFormData({ ...formData, [name]: newValue });
        if(dayjs(newValue,'hh:mm A').isAfter(dayjs(formData["to_time"], 'hh:mm A'))) {
          setErrors({...errors , [name] : "From time must be less than to time"})
        }
        else {
          setErrors({...errors , "from_time" : ""})
        }
        return;
      }
    }
    else if(name === "to_time") {
      if(formData["from_time"] === "") {
        setFormData({ ...formData, [name]: newValue , "from_time" : newValue });
      }
      else {
        setFormData({ ...formData, [name]: newValue });
        if(dayjs(newValue,'hh:mm A').isBefore(dayjs(formData["from_time"], 'hh:mm A'))) {
          setErrors({...errors , "from_time" : "From time must be less than to time"})
          return;
        }
        else {
          setErrors({...errors , "from_time" : ""})
        }
        return;
      }
    }
    else if(name === "first_name" || name === "middle_name" || name === "last_name"|| name === "comments") {
      const sanitizedInput = newValue.replace(/[^a-zA-Z ]/g, '');
      setFormData({ ...formData, [name]: sanitizedInput });
    }
    else {
      setFormData({ ...formData, [name]: newValue });
    }


    
    if (name === 'contact_no' ) {
        if (matchIsValidTel(newValue, {
            onlyCountryies: [],
            excludedCountryies: [],
            continents: []
        })) {
          setErrors({ ...errors, [name]: '' });
        }
        else {
          setErrors({ ...errors, [name]: 'Enter valid contact number' }); 
        }
    }
    else {
        setErrors({ ...errors, [name]: '' });
    }
    
  };

  const formsubmit = async(e, type) => {
    e.preventDefault();
    dispatch(setLoading(true));
    const validationErrors = validateForm();

    if (Object.keys(validationErrors).length === 0) {
      
      if (patient_id === 0) {
        const postData = { "user_id": userId, ...formData };
        await getData
          .AddPatientEnquiry(postData, userToken)
          .then((resp) => {


            if (resp.data.status === 1) {
              setpatient_id(resp.data.patient_id);
             setopenform(false);
             setSwitchPhoneCallModel(1);
              toast.success(resp.data.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 1000,
              });
            } else {
              toast.error(resp.data.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 1000,
              });
            }
          })
          .catch((error) => {
            // console.log(error)
            if (error.response.data.status === 2) {
              toast.error(
                error.response.data.message +
                ". Please log in again to continue.",
                {
                  position: toast.POSITION.TOP_RIGHT,
                  autoClose: 1000,
                }
              );
              setTimeout(() => {
                dispatch(logoutFunction(userId, logId, userToken, navigate));
              }, 2000);
            } else {
              toast.error(
                error.response.data.message + " Please Try After Sometimes" ||
                "Too Many Attempts. Please Try after sometimes",
                {
                  position: toast.POSITION.TOP_RIGHT,
                  autoClose: 1000,
                }
              );
            }
          });
      }
      else {
        const postData = { "user_id": userId, "patient_id": patient_id, ...formData};
        await getData
          .UpdatePatientEnquiry(postData, userToken)
          .then((resp) => {
            if (resp.data.status === 1) {
              setopenform(false);
              setSwitchPhoneCallModel(1);
              toast.success(resp.data.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 1000,
              });
             
            } else {

              toast.error(resp.data.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 1000,
              });
            }
          })
          .catch((error) => {
            // console.log(error)
            if (error.response.data.status === 2) {
              toast.error(
                error.response.data.message +
                ". Please log in again to continue.",
                {
                  position: toast.POSITION.TOP_RIGHT,
                  autoClose: 1000,
                }
              );
              setTimeout(() => {
                dispatch(logoutFunction(userId, logId, userToken, navigate));
              }, 2000);
            } else {
              toast.error(
                error.response.data.message + " Please Try After Sometimes" ||
                "Too Many Attempts. Please Try after sometimes",
                {
                  position: toast.POSITION.TOP_RIGHT,
                  autoClose: 1000,
                }
              );
            }
          });
      }
    }
    else
    {
      setErrors(validationErrors);
    }
    dispatch(setLoading(false));
  }

  const handleAllFormClose = () => {
    setopenform(false);
  }

  return (
    <>
      <div className="row">

        <div className="col-md-9 col-xs-12"><Title icon={SVGICON.edit} title="Add New Enquiry" /></div>

      </div>
      <div className="row bg_wrap b-radius no_margin" style={{ "padding": "18px 0px" }}>
        <div className="col-md-12">
        
          <div className="bg_wrap b-radius m-tab" style={{ 'minHeight' : 'auto'}}>

            {
              apppointmentbookUIDatas[0]?.appointmentbook.map((formFields) => (
                <>
                  <div className="row">

                    <div className="col-md-12 col-xs-12 padding_fixed">
                      <div className="row no_margin">

                        {formFields.data && formFields.data.map((input) => (
                          <div className={`col-md-${input.name === 'enquiry_reason_id' ? "6" : "3"} col-xs-12 padding_fixed`} key={input.id}>
                              {(input.type === "text") ? <TextInput {...input}
                                  //options={input.type === "select" ? input.name === "personal_id" ? personalList : input.name === "title_id" ? titleAllList : input.name === "gender_id" ? genderAllList : input.name === "country_id" ? countryList : input.name === "region_id" ? regionList : input.name === "marital_status_id" ? maritalStatusList : input.name === "city_id" ? cityList : input.name === "religion_id" ? religionList : input.name === "blood_group_id" ? bloodgrouplist : input.name === "language_id" ? languageList : input.name === "income_range_id" ? incomeRangeList : input.name === "payment_mode_id" ? paymentModeList : input.name === "nationality_id" ? nationalityList : a : null}
                                  onChange={handleChange}
                                  showFieldLoad={showFieldLoad}
                                  //value={input.name==="personal_id"?formData.personal_id:input.name==="mr_number"?formData.mr_number:input.name==="address"?formData.address:input.name==="title_id"?formData.title_id:input.name==="gender_id"?formData.gender_id:input.name==="country_id"?formData.country_id:input.name==="first_name"?formData.first_name:input.name==="father_name"?formData.father_name:input.name==="region_id"?formData.region_id:input.name==="middle_name"?formData.middle_name:input.name==="marital_status_id"?formData.marital_status_id:input.name==="city_id"?formData.city_id:input.name==="last_name"?formData.last_name:input.name==="religion_id"?formData.religion_id:input.name==="mobile"?formData.mobile:input.name==="arabic_name"?formData.arabic_name:input.name==="nationality_id"?formData.nationality_id:input.name==="secondary_mobile"?formData.secondary_mobile:input.name==="date_of_birth"?formData.date_of_birth:input.name==="blood_group_id"?formData.blood_group_id:input.name==="email"?formData.email:input.name==="s_email"?formData.s_email:input.name==="language_id"?formData.language_id:input.name==="passport_no"?formData.passport_no:input.name==="income_range_id"?formData.income_range_id:input.name==="payment_mode_id"?formData.payment_mode_id:input.name==="vip"?formData.vip:input.name==="demo_pat"?formData.demo_pat:input.name==="family"?formData.family:""}
                                  value={formData[input.name]}
                                  error={errors[input.name]}
                              /> : (input.type === "select") ?<> <Searchdropdown {...input}
                                  options={input.name === "department_id" ? departmentList : input.name === "time_interval" ? timeIntervalList: input.name === "doctor_id" ? doctorList : input.name === "referral_doctor_id" ? refferaldoctorList : input.name === "enquiry_reason_id" ? apptReasonList : input.name === "staff_id" ? staffList : []}
                                  onChange={handleChange}
                                  showFieldLoad={showFieldLoad}
                                  //value={input.name==="personal_id"?formData.personal_id:input.name==="mr_number"?formData.mr_number:input.name==="address"?formData.address:input.name==="title_id"?formData.title_id:input.name==="gender_id"?formData.gender_id:input.name==="country_id"?formData.country_id:input.name==="first_name"?formData.first_name:input.name==="father_name"?formData.father_name:input.name==="region_id"?formData.region_id:input.name==="middle_name"?formData.middle_name:input.name==="marital_status_id"?formData.marital_status_id:input.name==="city_id"?formData.city_id:input.name==="last_name"?formData.last_name:input.name==="religion_id"?formData.religion_id:input.name==="mobile"?formData.mobile:input.name==="arabic_name"?formData.arabic_name:input.name==="nationality_id"?formData.nationality_id:input.name==="secondary_mobile"?formData.secondary_mobile:input.name==="date_of_birth"?formData.date_of_birth:input.name==="blood_group_id"?formData.blood_group_id:input.name==="email"?formData.email:input.name==="s_email"?formData.s_email:input.name==="language_id"?formData.language_id:input.name==="passport_no"?formData.passport_no:input.name==="income_range_id"?formData.income_range_id:input.name==="payment_mode_id"?formData.payment_mode_id:input.name==="vip"?formData.vip:input.name==="demo_pat"?formData.demo_pat:input.name==="family"?formData.family:""}
                                  value={formData[input.name]}
                                  error={errors[input.name]}
                              />
                              {/* {input.name==="referral_doctor_id"&&(<div><Buttons.SaveIcon icon={SVGICON.add} onClick={() => { navigate('/refferal'); }} /> </div>)} */}
                              </> : (input.type === "datePicker") ? <><DatePickers {...input}

                                  onChange={(date) => handleChange({ target: { name: 'appointment_date', value: date } })}
                                  showFieldLoad={showFieldLoad}
                                  selected={formData[input.name]}
                                  value={formData[input.name]}
                                  error={errors[input.name]}
                              /> </>: (input.type === "timePicker") ? <><TimePikcers {...input}

                              onChange={(date) => handleChange({ target: { name: input.name, value: date.format('hh:mm A') } })}
                              totime={input.name === "from_time" ? formData['to_time'] : null}
                              fromtime={input.name === "to_time" ? formData['from_time'] : null}
                              showFieldLoad={showFieldLoad}
                              selected={formData[input.name]}
                              value={formData[input.name]}
                              error={errors[input.name]}
                          /> </> : (input.type === "phone") ? <InputPhone {...input}
                                  showFieldLoad={showFieldLoad}
                                  onChange={(value) => handleChange({ target: { name: input.name, value: value } })}
                                  value={formData[input.name]}
                                  error={errors[input.name]}
                              /> 
                              // : 
                                  
                              //     (input.type === "radio") ? <TextFeildRadioBox

                              //     label={input.label}
                              //     options={input.options}
                              //     onChange={(e) => handleOptionChange(e, input.name)}
                              //     value={formData[input.name]}
                              //     error={errors[input.name]}
                              // /> 
                              //   : (input.type === "test") ? formData['appointment_date'] && (
                              //     <div class="wrap-field">
                              //         <FormLabel component="legend">Age (yy/mm/dd)*  <input type="text" style={{ width: '45px', height: '35px' }} value={calculateAge(formData['date_of_birth'], "year")} readonly />{" / "}<input type="text" style={{ width: '45px', height: '35px' }} readonly value={calculateAge(formData['date_of_birth'], "month")} />{" / "}<input type="text" style={{ width: '45px', height: '35px' }} value={calculateAge(formData['date_of_birth'], "day")} readonly /></FormLabel>
                              //     </div>
                              // ) 
                              : ""}

                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </>
              ))
            }

          </div>
        
        </div>
     

        <div className="grid-child magrinTB">
          <div className="col-md-8 grid-child">
              <Buttons.SaveButtons onClick={(e) => formsubmit(e, 0)} name="Save" />
              {loading === true ? (
                <Spinner animation="border" variant="primary" />
              ) : null}
              {/* <Buttons.ResetButtons name="Cancel" onClick={handleAllFormClose} /> */}
          </div>
        </div>
      </div>
    </>
  )
}

export default AppointmentBook