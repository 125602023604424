import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";

const CustomerList = () => {
    const navigate = useNavigate();
    const [areas, setAreas] = useState([]); // Area list
    const [area, setArea] = useState(""); // Selected area ID
    const [customers, setCustomers] = useState([]); // Customer list
    const [loading, setLoading] = useState(false); // Loading state
    const [error, setError] = useState(null); // Error state

    // Fetch Areas from API
    useEffect(() => {
        axios
            .get("https://miracalemoney.in/dealerreport/api/v1/GetArea")
            .then((response) => {
                console.log("API Response (Areas):", response.data);
                if (response.data.status === 1 && response.data.arealist) {
                    setAreas(response.data.arealist);
                } else {
                    console.error("Invalid response structure for areas:", response);
                    setError("Failed to load areas.");
                }
            })
            .catch((error) => {
                console.error("Error fetching areas:", error);
                setError("Failed to load areas. Please try again.");
            });
    }, []);

    // Fetch Customers based on Area ID
    const fetchCustomers = (areaId) => {
        setLoading(true);
        setError(null);
        const userId = localStorage.getItem("user_id");

        axios
            .post("https://miracalemoney.in/dealerreport/api/v1/GetAreaCustomerDetails", {
                area_id: areaId,
                user_id: userId,
            })
            .then((response) => {
                console.log("API Response (Customers):", response.data);
                if (response.data.status === 1 && response.data.customers) {
                    setCustomers(response.data.customers);
                } else {
                    setCustomers([]);
                    console.error("Invalid response structure for customers:", response);
                    setError("No customers found for the selected area.");
                }
            })
            .catch((error) => {
                console.error("Error fetching customers:", error);
                setError("Failed to fetch customers. Please try again.");
            })
            .finally(() => {
                setLoading(false);
            });
    };

    // Handle Area Selection
    const handleAreaChange = (e) => {
        const selectedAreaId = e.target.value;
        setArea(selectedAreaId);
        if (selectedAreaId) {
            fetchCustomers(selectedAreaId);
        } else {
            setCustomers([]);
        }
    };

    const handleCancel = () => {
        navigate("/dashboard");
      };
    

    return (
        <div>
            <header className="header">
                <div className="call-header">
                    <h1>Customer List</h1>
                </div>
                <div onClick={handleCancel} className="cancel-icon">
            &#10005;
          </div>
            </header>

            <main className="orders-container">
                {/* Area Dropdown */}
                <div className="dropdown-container">
                    <label htmlFor="area-select">Area:</label>
                    <select
                        id="area-select"
                        value={area}
                        onChange={handleAreaChange}
                        style={{ width: "100%", padding: "10px", fontSize: "16px", marginBottom: "10px" }}
                    >
                        <option value="">Select Area</option>
                        {areas.map((area) => (
                            <option key={area.id} value={area.id}>
                                {area.area}
                            </option>
                        ))}
                    </select>
                </div>

                {/* Loading/Error Messages */}
                {loading && <p>Loading customers...</p>}
                {error && <p style={{ color: "red" }}>{error}</p>}

                {/* Customer List */}
                <div className="order-header">
                    <div>Sr.No</div>
                    <div>Customer Name</div>
                    <div>Place</div>
                </div>
                {customers.length > 0 ? (
                    customers.map((customer, index) => (
                        <div className="order-row" key={customer.id}>
                            <div>{index + 1}</div>
                            <div>{customer.name}</div>
                            <div>{customer.place}</div>
                        </div>
                    ))
                ) : (
                    !loading && <p>No customers available for the selected area.</p>
                )}
            </main>
        </div>
    );
};

export default CustomerList;
