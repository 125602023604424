import {
    formatError,
    login,
    loginWithCenterLocation,
    runLogoutTimer,
    saveTokenInLocalStorage,

} from '../../services/AuthService';


export const SIGNUP_CONFIRMED_ACTION = '[signup action] confirmed signup';
export const SIGNUP_FAILED_ACTION = '[signup action] failed signup';
export const LOGIN_CONFIRMED_ACTION = '[login action] confirmed login';
export const LOGIN_FAILED_ACTION = '[login action] failed login';
export const LOADING_TOGGLE_ACTION = '[Loading action] toggle loading';
export const LOGOUT_ACTION = '[Logout action] logout action';



// export function signupAction(email, password, navigate) {

//     return (dispatch) => {
//         signUp(email, password)
//             .then((response) => {
//                 saveTokenInLocalStorage(response.data);
//                 runLogoutTimer(
//                     dispatch,
//                     3600 * 1000,
//                     //history,
//                 );
//                 dispatch(confirmedSignupAction(response.data));
//                 setTimeout(() => {
//                     navigate('/dashboard');
//                     //history.push('/dashboard');    
//                 }, 5000)
//             })
//             .catch((error) => {
//                 const errorMessage = formatError(error.response.data);
//                 dispatch(signupFailedAction(errorMessage));
//             });
//     };
// }

export function Logout(navigate) {
    localStorage.removeItem('userDetails');
    localStorage.clear();
    sessionStorage.clear();
    navigate('/login');

    return {
        type: LOGOUT_ACTION,
    };
    // return (dispatch) => {
    //     logoutAPI(userId, logId, userToken).then((response) => {
    //         if (response.data.status ==1) {
    //             localStorage.removeItem('userDetails');
    //             localStorage.clear();
    //             sessionStorage.clear();
    //             dispatch(logOutAction());
    //             navigate('/login');
    //         }
    //     }).catch((error) => {
    //         const errorMessage = formatError("USER_NOT_FOUND");
    //         dispatch(logOutAction());
    //     });;
    // }
}

// login using username and password
export function loginAction(email, password, navigate) {
    return (dispatch) => {
        login(email, password)
            .then((response) => {
                if (response.data.status === 1) {
                    const data = {
                        displayName: "",
                        email: response.data.data.email,
                        userId: response.data.data.user_id,
                        firstName: response.data.data.first_name,
                        fullName: response.data.data.full_name,
                        middleName: response.data.data.middle_name,
                        lastName: response.data.data.last_name,
                        mobileNumber: response.data.data.mobile,
                        rollId: response.data.data.role_id,
                        roleName: response.data.data.role_name,
                        logId: response.data.data.log_id,
                        hospital_detail_id: response.data.data.hospital_detail_id,
                        // expireDate: 'Thu Jan 04 2025 03:03:52 GMT {}',
                        expiresIn: 3600,
                        idToken: response.data.data.token,
                        // kind: "identitytoolkit#VerifyPasswordResponse",
                        // localId: "amt6dRyipIad8UCc0QpMV2MENSy1",
                        refreshToken: response.data.data.token,
                        registered: true
                    }
                    saveTokenInLocalStorage(data);
                    runLogoutTimer(
                        dispatch,
                        3600 * 1000,
                        navigate,
                    );
                    dispatch(loginConfirmedAction(data));
                    navigate('/dashboard');
                }
                else if(response.data.status === 0){
                    if(response.data.message.includes('Username not')){
                        const errorMessage = formatError('USERNAME_NOT_FOUND');
                        dispatch(loginFailedAction(errorMessage));
                    } else if(response.data.message ==='Invalid Password'){
                        const errorMessage = formatError('INVALID_PASSWORD');
                        dispatch(loginFailedAction(errorMessage));
                        navigate('/login');
                    }                    
                }
            })
            .catch((error) => {
                const errorMessage = formatError("USER_NOT_FOUND");
                dispatch(loginFailedAction(errorMessage));
            });
    };
}

// login using username, password and center location(hospital_detail_id)
export function loginFunction(email, password, hospital_detail_id, navigate) {
    return (dispatch) => {
        loginWithCenterLocation(email, password,hospital_detail_id)
            .then((response) => {
                if (response.data.status === 1) {
                    const data = {
                        displayName: "",
                        email: response.data.data.email,
                        userId: response.data.data.user_id,
                        firstName: response.data.data.first_name,
                        fullName: response.data.data.full_name,
                        middleName: response.data.data.middle_name,
                        lastName: response.data.data.last_name,
                        mobileNumber: response.data.data.mobile,
                        rollId: response.data.data.role_id,
                        roleName: response.data.data.role_name,
                        logId: response.data.data.log_id,
                        hospital_detail_id: response.data.data.hospital_detail_id,
                        // expireDate: 'Thu Jan 04 2025 03:03:52 GMT {}',
                        expiresIn: 3600,
                        idToken: response.data.data.token,
                        // kind: "identitytoolkit#VerifyPasswordResponse",
                        // localId: "amt6dRyipIad8UCc0QpMV2MENSy1",
                        refreshToken: response.data.data.token,
                        registered: true
                    }
                    saveTokenInLocalStorage(data);
                    runLogoutTimer(
                        dispatch,
                        3600 * 1000,
                        navigate,
                    );
                    dispatch(loginConfirmedAction(data));
                    navigate('/dashboard');
                }
                else if(response.data.status === 0){
                    if(response.data.message.includes('Username not')){
                        const errorMessage = formatError('USERNAME_NOT_FOUND');
                        dispatch(loginFailedAction(errorMessage));
                    } else if(response.data.message ==='Invalid Password'){
                        const errorMessage = formatError('INVALID_PASSWORD');
                        dispatch(loginFailedAction(errorMessage));
                        navigate('/login');
                    } else if(response.data.message.includes('username or password')){
                        const errorMessage = formatError('USERNAME_OR_PASSWORD');
                        dispatch(loginFailedAction(errorMessage));
                        navigate('/login');
                    }                    
                }
            })
            .catch((error) => {
                const errorMessage = formatError("USER_NOT_FOUND");
                dispatch(loginFailedAction(errorMessage));
            });
    };
}

export function loginFailedAction(data) {
    return {
        type: LOGIN_FAILED_ACTION,
        payload: data,
    };
}

export function loginConfirmedAction(data) {
    return {
        type: LOGIN_CONFIRMED_ACTION,
        payload: data,
    };
}

export function confirmedSignupAction(payload) {
    return {
        type: SIGNUP_CONFIRMED_ACTION,
        payload,
    };
}

export function signupFailedAction(message) {
    return {
        type: SIGNUP_FAILED_ACTION,
        payload: message,
    };
}

export function loadingToggleAction(status) {
    return {
        type: LOADING_TOGGLE_ACTION,
        payload: status,
    };
}

export function logOutAction() {
    return {
        type: LOGOUT_ACTION,
    };
}
