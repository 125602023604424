import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

const HomePage = () => {
    const [user, setUser] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        // Get user details from localStorage
        const user_id = localStorage.getItem("user_id");
        const token = localStorage.getItem("token");
        const username = localStorage.getItem("username");

        // Check if all credentials exist
        if (user_id && token && username) {
            setUser({ user_id, token, username });
        } else {
            // Redirect to login if not authenticated
            navigate("/login");
        }
    }, [navigate]);

    return (
        <div className="homepage-container">
            <header className="header">
                {/* <div className="menu-icon">&#9776;</div> */}
                <h1>Home</h1>
            </header>
            <main>
                <div className="logo-container">
                    <img src="logo.png" alt="Logo" className="logo" />
                </div>
                <h2 className="welcome-text">
                    Welcome to the Unitech - Calls App, {user?.username || "Guest"}.
                </h2>
                <div className="button-grid">
                    <button><Link to={'/add-calls'}>Add Calls</Link></button>
                    <button><Link to={'/pending-calls'}>Pending Calls</Link></button>
                    <button><Link to={'/completed'}>Completed Calls</Link></button>
                    <button><Link to={'/orders-flow'}>Orders Follow</Link></button>
                    <button><Link to={'/pending-list'}>Pending List</Link></button>
                    {/* <button><Link to={'/customer-list'}>Customer List</Link></button> */}
                    <button><Link to={'/dashboard'}>Exit</Link></button>
                    {/* <button><Link to={'/'}>Exit</Link></button> */}
                </div>
            </main>
            <footer>
                <p>V-1.0.0</p>
            </footer>
        </div>
    );
};

export default HomePage;
