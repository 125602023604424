import { BENEFIT_ACTION_TYPES } from '../../actions/primaryMaster/BenefitActionTypes';

const initialState = {
    name: "",
    product_name: "",
    insurance_company_detail_id: "",
    payer_ids: "",
    package_id: "",
    detailsData:[],
    loading : false,
    openModel :false,
  // Other initial state...
};

const BenefitReducer = (state = initialState, action) => {
  switch (action.type) {
    case BENEFIT_ACTION_TYPES.SET_DETAILS_DATA:
        return {
            ...state,
            detailsData: action.payload,
        };
    case BENEFIT_ACTION_TYPES.SET_NAME:
        return {
            ...state,
            name: action.payload,
        };
    case BENEFIT_ACTION_TYPES.SET_PRODUCT_NAME:
        return {
            ...state,
            product_name: action.payload,
        };
    case BENEFIT_ACTION_TYPES.SET_INSURANCE_COMPANY_DETAIL_ID:
        return {
            ...state,
            insurance_company_detail_id: action.payload,
        };
    case BENEFIT_ACTION_TYPES.SET_PAYER_ID:
        return {
            ...state,
            payer_ids: action.payload,
        };
    case BENEFIT_ACTION_TYPES.SET_PACKAGE_ID:
        return {
            ...state,
            package_id: action.payload,
        };
    case BENEFIT_ACTION_TYPES.SET_LOADING:
        return {
            ...state,
            loading: action.payload,
        };
    case BENEFIT_ACTION_TYPES.SET_OPEN_MODAL:
        return {
            ...state,
            openModel: action.payload,
        };
    // Other cases...
    default:
      return state;
  }
};

export default BenefitReducer;
 