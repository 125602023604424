import React from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import dayjs from 'dayjs';
const DatePickers = ({ value, label, name, onChange, selected, required, error, size, showFieldLoad, nofuturedate = true, nopastdate = false, width, custommindate = null , custommaxdate = null}) => {
  const today = new AdapterDayjs().date(new Date());
  return (
    <div className={`${label} wrap_picker wrap-field ${value === "" && showFieldLoad ? "wrap-fieldload" : ""}`} style={{ width: width }}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>

        <DatePicker
          format="DD-MM-YYYY"
          value={dayjs(value)}
          label={label}
          name={name}
          onChange={onChange}
          selected={selected}
          required={required}
          slotProps={error === undefined || error === "" ? {
            textField: {
              error: false,
              helperText: "",
              required: required
            },
          } : {
            textField: {
              error: true,
              helperText: error,
              required: required
            },
          }}

          size={size}
          maxDate={custommaxdate === null ? nofuturedate ? today : null : custommaxdate === "" ? null : custommaxdate}
          // minDate={nopastdate ? today : null}
          minDate={custommindate === null ? nopastdate ? today : null : custommindate === "" ? today : custommindate}
        />
      </LocalizationProvider>
    </div>
  );
}
export default DatePickers;