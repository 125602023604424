import { REGION_ACTION_TYPES } from '../../actions/primaryMaster/RegionActionTypes';

const initialState = {
    regionTitle: "",
    regionShortName: "",
    country_id: "",
    region_id: "",
    regionList: [],
    detailsData:[],
    loading:false,
    openModel :false,
  // Other initial state...
};

const RegionReducer = (state = initialState, action) => {
  switch (action.type) {
    case REGION_ACTION_TYPES.SET_DETAILS_DATA:
        return {
            ...state,
            detailsData: action.payload,
        };
    case REGION_ACTION_TYPES.SET_REGION_TITLE:
        return {
            ...state,
            regionTitle: action.payload,
        };
    case REGION_ACTION_TYPES.SET_REGION_SHORTNAME:
        return {
            ...state,
            regionShortName: action.payload,
        };
    case REGION_ACTION_TYPES.SET_REGION_ID:
        return {
            ...state,
            region_id: action.payload,
        };
    case REGION_ACTION_TYPES.SET_COUNTRY_ID:
        return {
            ...state,
            country_id: action.payload,
        };
    case REGION_ACTION_TYPES.SET_LOADING:
        return {
            ...state,
            loading: action.payload,
        };
    case REGION_ACTION_TYPES.SET_OPEN_MODAL:
        return {
            ...state,
            openModel: action.payload,
        };
    // Other cases...
    default:
      return state;
  }
};

export default RegionReducer;
 