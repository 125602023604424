// store/actions/cityActionTypes.js

export const INSURANCE_PLAN_ACTION_TYPES = {
    SET_DETAILS_DATA: 'SET_DETAILS_DATA',
    SET_PLAN_NAME:"SET_PLAN_NAME",
    SET_INSURANCE_NETWORK_ID:"SET_NETWORK_ID",
    SET_INSURANCE_COMPANY_DETAIL_ID:"SET_INSURANCE_COMPANY_DETAIL_ID",
    SET_LOADING:"SET_LOADING",
    SET_OPEN_MODAL:"SET_OPEN_MODAL",
    SET_SHOW_DELETE_MODAL:"SET_SHOW_DELETE_MODAL",
    SET_PLAN_ID:"SET_PLAN_ID",
    SET_NETWORK_SEPARATE_PRICE:"SET_NETWORK_SEPARATE_PRICE",
    SET_IS_STATUS:"SET_IS_STATUS",

};
  