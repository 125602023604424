import { ETHNIC_ACTION_TYPES } from '../../actions/primaryMaster/EthnicActionTypes';

const initialState = {
    ethnicName: "",
    shortCode: "",
    ethnic_id: "",
    detailsData:[],
    loading:false,
    openModel :false
  // Other initial state...
};

const EthnicReducer = (state = initialState, action) => {
  switch (action.type) {
    case ETHNIC_ACTION_TYPES.SET_ETHNIC_NAME:
        return {
            ...state,
            ethnicName: action.payload,
        };
    case ETHNIC_ACTION_TYPES.SET_SHORT_CODE:
        return {
            ...state,
            shortCode: action.payload,
        };
    case ETHNIC_ACTION_TYPES.SET_ETHNIC_ID:
        return {
            ...state,
            ethnic_id: action.payload,
        };
    case ETHNIC_ACTION_TYPES.SET_DETAILS_DATA:
        return {
            ...state,
            detailsData: action.payload,
        };
    case ETHNIC_ACTION_TYPES.SET_LOADING:
        return {
            ...state,
            loading: action.payload,
        };
    case ETHNIC_ACTION_TYPES.SET_OPEN_MODAL:
        return {
            ...state,
            openModel: action.payload,
        };
    // Other cases...
    default:
      return state;
  }
};

export default EthnicReducer;
 