import React, { useState, useRef, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Title from "../../utils/Titles/Title";
import Buttons from '../../utils/Button';
import Table from '../../utils/Newtable/Table';
import { tableData } from '../../utils/json/tableData';
import * as getData from '../../../services/APIService';
import * as getAPIAction from "../../../store/actions/GetAllMasterList";
import { logoutFunction } from "../../../store/actions/LogoutActions";
import { setLoading } from "../../../store/actions/CommonActions";
import SearchInput from "../Masters/Element/SearchInput";
import { searchAction } from "../../../store/actions/SearchAction";
import { useSelector, useDispatch } from "react-redux";
import { SVGICON } from "../../constant/theme";
import { toast, ToastContainer } from "react-toastify";
import { Tab } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import "react-toastify/dist/ReactToastify.css";
import Loader from "../Loader/Loader";
const moment = require('moment');
const AppointmentTableList = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const appointmentTableHead = tableData[0]?.appointment?.[0]?.appointmentlist?.[0]?.tableHead || [];
	const appointmentlist = useSelector(
		(state) => state.allMasterList.appointmentlist
	);
	const userData = useSelector((state) => state.auth.auth);
	const userToken = userData.idToken;
	const userId = userData.userId;
	const logId = parseInt(userData.logId);
	const hospital_detail_id = parseInt(userData.hospital_detail_id)
	const filterdata = useSelector((state) => state.search.filterdata);
	const [searchedVal, setSearchedVal] = useState("");
	const shouldLog = useRef(true);
	const loading = useSelector((state) => state.common.showLoading)
	useEffect(() => {
		if (shouldLog.current) {
			shouldLog.current = false;
			//Get insurance List
			// dispatch(getAPIAction.GetInsuranceList(userId, logId, userToken, navigate));
		}
		// eslint-disable-next-line
	}, [shouldLog]);

useEffect(()=>{
	document.title = 'Appointment';
	setTimeout(function() {
		dispatch(setLoading(false));
	}, 300);
	

},[appointmentlist])
	const handleedit = (companyId) => {
		// navigate('/insurance?companyID=' + companyId)
	}

	const handleSearchChange = (value) => {

		setSearchedVal(value);
		/// put a column name here need to search
		dispatch(searchAction(value, appointmentTableHead, appointmentlist));
	};

	return (
		<>

			<Title title="Appointment List" icon={SVGICON.edit} />
			<div className="row">
				<div className="col-12 col-md-6 col-xs-8">
					<SearchInput value={searchedVal} onChange={handleSearchChange} />
				</div>
				<div className="col-12 col-md-6 col-xs-4 right-align">
					<Buttons.AddButtons name="Add" onClick={() => { 
					//	navigate('/add-appointment'); 
						window.open('/#/Appointment', '_blank')
					}} />
				</div>
			</div>
			<div className="bg_wrap">
				<div className="b-radius dashTable">
					<div className="">

					{loading ? (
                    <Loader />
                ) : null}
						<div className="row">
							<div className="col-md-12 col-xs-12 scrollTable insurance-list-table">
								<Table
									columns={appointmentTableHead}
									rowname="company_id"
									serialNoRequired={false}
									rowdata={searchedVal === "" ? appointmentlist : filterdata}
									recordsPerPage={20}
									patienteditclick={handleedit}
									hide={true}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>


		</>
	);
};
export default AppointmentTableList;