// store/actions/shiftActionTypes.js

export const SHIFT_ACTION_TYPES = {
    SET_DETAILS_DATA: 'SET_DETAILS_DATA',
    SET_SHIFT_ID: 'SET_SHIFT_ID',
    SET_SHIFT_NAME: 'SET_SHIFT_NAME',
    SET_START_TIME: 'SET_START_TIME',
    SET_END_TIME: 'SET_END_TIME',
    SET_TOTAL_HOURS: 'SET_TOTAL_HOURS',
    SET_START_DATE: 'SET_START_DATE',
    SET_END_DATE: 'SET_END_DATE',
    SET_WORK_SESSION: 'SET_WORK_SESSION',
    SET_IS_ENABLED: 'SET_IS_ENABLED',
    SET_LOADING:"SET_LOADING",
    SET_OPEN_MODAL:"SET_OPEN_MODAL",
    SET_SHOW_DELETE_MODAL:"SET_SHOW_DELETE_MODAL"
};
  