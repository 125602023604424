import { CITY_ACTION_TYPES } from '../../actions/primaryMaster/CityActionTypes';

const initialState = {
    cityTitle: "",
    cityShortName: "",
    country_id: "",
    city_id:"",
    region_id: "",
    detailsData:[],
    loading:false,
    openModel :false,
    showDeleteModal:false
  // Other initial state...
};

const CityReducer = (state = initialState, action) => {
  switch (action.type) {
    case CITY_ACTION_TYPES.SET_DETAILS_DATA:
       return {
          ...state,
          detailsData: action.payload,
        };
    case CITY_ACTION_TYPES.SET_CITY_TITLE:
            return {
               ...state,
               cityTitle: action.payload,
             };
    case CITY_ACTION_TYPES.SET_CITY_SHORTNAME:
            return {
               ...state,
               cityShortName: action.payload,
             };
    case CITY_ACTION_TYPES.SET_CITY_ID:
                return {
                   ...state,
                   city_id: action.payload,
                 };
     case CITY_ACTION_TYPES.SET_COUNTRY_ID:
                    return {
                       ...state,
                       country_id: action.payload,
                     };
     case CITY_ACTION_TYPES.SET_REGION_ID:
                        return {
                           ...state,
                           region_id: action.payload,
                         };
     case CITY_ACTION_TYPES.SET_LOADING:
                        return {
                           ...state,
                           loading: action.payload,
                         };
     case CITY_ACTION_TYPES.SET_OPEN_MODAL:
                        return {
                           ...state,
                           openModel: action.payload,
                         };
                         case CITY_ACTION_TYPES.SET_SHOW_DELETE_MODAL:
                            return {
                               ...state,
                               showDeleteModal: action.payload,
                             };
    // Other cases...
    default:
      return state;
  }
};

export default CityReducer;
 