import {
    COMMON_ACTION_TYPES
} from '../actions/CommonActionTypes';

const initialState = {
   profile:[],
    errorMessage: '',
    successMessage: '',
    showLoading: false,
};

export function CommonReducer(state = initialState, action)  {
    switch (action.type) {
      case COMMON_ACTION_TYPES.SET_PROFILE:
         return {
            ...state,
            profile: action.payload,
          };
      case COMMON_ACTION_TYPES.SET_LOADING:
        return {
            ...state,
            showLoading: action.payload,
          };
     
      // Other cases...
      default:
        return state;
    }
  };

    
