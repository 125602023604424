import React, { useState, useRef, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Title from "../../utils/Titles/Title";
import Buttons from '../../utils/Button';
import Table from '../../utils/Newtable/Table';
import { tableData } from '../../utils/json/tableData';
import * as getData from '../../../services/APIService';
import * as getAPIAction from "../../../store/actions/GetAllMasterList";
import { logoutFunction } from "../../../store/actions/LogoutActions";
import { setLoading } from "../../../store/actions/CommonActions";
import SearchInput from "../Masters/Element/SearchInput";
import { searchAction } from "../../../store/actions/SearchAction";
import { useSelector, useDispatch } from "react-redux";
import { SVGICON } from "../../constant/theme";
import { toast, ToastContainer } from "react-toastify";
import { Tab } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import "react-toastify/dist/ReactToastify.css";
import { NavMenuOn, NavMenuToggle } from "../../layouts/nav/NavHader";
import Loader from "../Loader/Loader";
import { ThemeContext } from "../../../context/ThemeContext";
const moment = require('moment');
const Insurancelist = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [insuranceTab, setinsuranceTab] = useState(1);

	const handleInsuranceTab = (event, insuranceTab) => {
		setinsuranceTab(insuranceTab);
	  };
	const insuranceTableHead = tableData[0]?.insurance?.[0]?.insurancelist?.[0]?.tableHead || [];
	const insurancelist = useSelector(
		(state) => state.allMasterList.insurancelist
	);
	const userData = useSelector((state) => state.auth.auth);
	const userToken = userData.idToken;
	const userId = userData.userId;
	const logId = parseInt(userData.logId);
	const hospital_detail_id = parseInt(userData.hospital_detail_id)
	const filterdata = useSelector((state) => state.search.filterdata);
	const [searchedVal, setSearchedVal] = useState("");
	const { toggleSideMenu } = useContext(ThemeContext);
	const shouldLog = useRef(true);
	const loading = useSelector((state) => state.common.showLoading)
	useEffect(() => {
		if (shouldLog.current) {
			shouldLog.current = false;
			//Get insurance List
			dispatch(getAPIAction.GetInsuranceList(userId, logId, userToken, navigate));
		}
		// eslint-disable-next-line
	}, [shouldLog]);

useEffect(()=>{
	document.title = 'Insurance List';
	setTimeout(function() {
		dispatch(setLoading(false));
	}, 300);
	

},[insurancelist])
	const handleedit = (companyId) => {
		//navigate('/insurance?companyID=' + companyId);
		window.open('/#/Insurance?companyID=' + companyId, '_blank');
	}

	const handleSearchChange = (value) => {

		setSearchedVal(value);
		/// put a column name here need to search
		dispatch(searchAction(value, insuranceTableHead, insurancelist));
	};
const checkboxchange=async(e,value,type) =>
	{
		 
		dispatch(setLoading(true));
		 const postData = { "user_id": userId, "company_id": value.company_id,"field_value":value[type]===0?1:0,"field_name":type };
		
		await getData
          .UpdateInsCompanyStatus(postData, userToken)
          .then((resp) => {
            if (resp.data.status === 1) {
				dispatch(getAPIAction.GetInsuranceList(userId, logId, userToken, navigate));
              toast.success(resp.data.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 1000,
              });
			  
            } else {

              toast.error(resp.data.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 1000,
              });
            }
          })
          .catch((error) => {
            // console.log(error)
            if (error.response.data.status === 2) {
              toast.error(
                error.response.data.message +
                ". Please log in again to continue.",
                {
                  position: toast.POSITION.TOP_RIGHT,
                  autoClose: 1000,
                }
              );
              setTimeout(() => {
                dispatch(logoutFunction(userId, logId, userToken, navigate));
              }, 2000);
            } else {
              toast.error(
                error.response.data.message + " Please Try After Sometimes" ||
                "Too Many Attempts. Please Try after sometimes",
                {
                  position: toast.POSITION.TOP_RIGHT,
                  autoClose: 1000,
                }
              );
            }
          });
	}

	const handleRefresh = () => {
		dispatch(getAPIAction.GetInsuranceList(userId, logId, userToken, navigate));
		dispatch(searchAction(searchedVal, insuranceTableHead, insurancelist));
	}

	return (
		<>

			<Title title="Insurance" icon={SVGICON.edit} />
			<div className="row">
				<div className="col-12 col-md-6 col-xs-8">
					<SearchInput value={searchedVal} onChange={handleSearchChange} />
				</div>
				<div className="col-12 col-md-6 col-xs-4 right-align insurance-list">
					<Buttons.ResetButtons name="Refresh" onClick={handleRefresh} />
					<Buttons.AddButtons name="Add" onClick={() => { window.open('/#/Insurance', '_blank'); }} />
					{/*<Buttons.AddButtons name="Add" onClick={() => { toggleSideMenu(true); NavMenuOn(); window.open('/#/insurance-list', '_blank'); }} />*/}
				</div>
			</div>
			<div className="bg_wrap">
				<div className="b-radius dashTable">
					<div className="">

					{loading ? (
                    <Loader />
                ) : null}

						{/* <div className="col-md-12" style={{ 'padding': '0 10px' }}>
          <div className="bg_wrap b-radius m-tab tabBG">
            <TabContext value={insuranceTab}>
              <TabList onChange={handleInsuranceTab}
                aria-label="Tab">
                
                  <Tab key={1} value={1} label={"Main Company"} />
				  <Tab key={2} value={2} label={"Sub Company"} />
            
              </TabList>
              
                <TabPanel key={1} value={1}>
				<div className="row">
							<div className="col-md-12 col-xs-12 scrollTable">
								<Table
									columns={insuranceTableHead}
									rowname="company_id"
									serialNoRequired={false}
									rowdata={searchedVal === "" ? insurancelist.filter(item => item.sub_company.length === 0) : filterdata.filter(item => item.sub_company.length === 0)}
									patienteditclick={handleedit}
									checkboxchange={checkboxchange}
									hide={true}
								/>
							</div>
						</div>
				</TabPanel>
				<TabPanel key={2} value={2}>
					<div className="col-md-12 col-xs-12 scrollTable">
				<Table
									columns={insuranceTableHead}
									rowname="company_id"
									serialNoRequired={false}
									rowdata={searchedVal === "" ? insurancelist.filter(item => item.sub_company.length > 0) : filterdata.filter(item => item.sub_company.length > 0)}
									patienteditclick={handleedit}
									checkboxchange={checkboxchange}
									hide={true}
								/>
					</div>	
				</TabPanel>
              
            </TabContext>
						</div>
						</div> */}
						<div className="row">
							<div className="col-md-12 col-xs-12 scrollTable insurance-list-table">
								<Table
									columns={insuranceTableHead}
									rowname="company_id"
									//serialNoRequired={false}
									rowdata={searchedVal === "" ? insurancelist : filterdata}
									recordsPerPage={20}
									insuranceeditclick={handleedit}
									checkboxchange={checkboxchange}
									hide={true}
									nodataalignment="start"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>


		</>
	);
};
export default Insurancelist;