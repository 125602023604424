import React from "react";
import { Modal, ModalDialog } from "react-bootstrap";
import Buttons from '../../../utils/Button';
import Draggable from "react-draggable";
const Popup = (props) => {
  const { show, handleClose, size, modalTitle,onClick,loading,label,editmode, draggable,bigpopup} = props;
  return (
    
    <Modal className={`modal fade ${bigpopup ? 'modal-xxl' : ''}`} id="addOrderModal" onClose={handleClose} size={size} centered show={show} onHide={handleClose} dialogAs={draggable ? DraggableModalDialog : undefined}>
      <Modal.Header closeButton >
        <Modal.Title>{modalTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body >{props.children}</Modal.Body>
      {onClick?<Modal.Footer> <Buttons.CancelButtons  onClick={handleClose}
            name="Close"
          />{editmode?<Buttons.UpdateButtons
            name={label}
            onClick={onClick}
          />:<Buttons.SaveButtons
												name={label}
												onClick={onClick}
												disable = {loading}
											/>}</Modal.Footer>:null}
    </Modal>
  );
};

class DraggableModalDialog extends React.Component {
  render() {
      return (
        <Draggable handle=".modal-header">
          <ModalDialog {...this.props} className="drag-dialog" /> 
        </Draggable>
      )
  }
}

export default Popup;