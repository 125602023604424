import { REFFERAL_ACTION_TYPES } from '../../actions/primaryMaster/RefferalActionTypes';

const initialState = {
    name:"",
    doctor_id:0,
    referral_clinic_id:"",
    qualification:"",
    license_no:"",
    address:"",
    phone_no:"+971",
    mobile_no:"+971",
    email:"",
    is_status:"Active", //Active or In-Active
    country_id:"",
    region_id:"",
    city_id:"",
    detailsData:[],
    loading:false,
    openModel :false
  // Other initial state...
};

const ServiceReducer = (state = initialState, action) => {
  switch (action.type) {
    case REFFERAL_ACTION_TYPES.SET_DOCTOR_ID:
        return {
            ...state,
            doctor_id: action.payload,
        };
    case REFFERAL_ACTION_TYPES.SET_NAME:
        return {
            ...state,
            name: action.payload,
        };
        case REFFERAL_ACTION_TYPES.SET_REFFERAL_CLINIC_ID:
            return {
                ...state,
                referral_clinic_id: action.payload,
            };
            case REFFERAL_ACTION_TYPES.SET_QUALIFICATION:
                return {
                    ...state,
                    qualification: action.payload,
                };
                case REFFERAL_ACTION_TYPES.SET_LICENSE_NO:
                    return {
                        ...state,
                        license_no: action.payload,
                    };
                    case REFFERAL_ACTION_TYPES.SET_ADDRESS:
                        return {
                            ...state,
                            address: action.payload,
                        };
                        case REFFERAL_ACTION_TYPES.SET_PHONE_NO:
                            return {
                                ...state,
                                phone_no: action.payload,
                            };
                            case REFFERAL_ACTION_TYPES.SET_MOBILE_NO:
                                return {
                                    ...state,
                                    mobile_no: action.payload,
                                };
                                case REFFERAL_ACTION_TYPES.SET_EMAIL:
                                    return {
                                        ...state,
                                        email: action.payload,
                                    };
                                    case REFFERAL_ACTION_TYPES.SET_IS_ACTIVE:
                                        return {
                                            ...state,
                                            is_status: action.payload,
                                        };             
        case REFFERAL_ACTION_TYPES.SET_CITY_ID:
            return {
               ...state,
               city_id: action.payload,
             };
 case REFFERAL_ACTION_TYPES.SET_COUNTRY_ID:
                return {
                   ...state,
                   country_id: action.payload,
                 };
 case REFFERAL_ACTION_TYPES.SET_REGION_ID:
                    return {
                       ...state,
                       region_id: action.payload,
                     };
    case REFFERAL_ACTION_TYPES.SET_DETAILS_DATA:
        return {
            ...state,
            detailsData: action.payload,
        };
    case REFFERAL_ACTION_TYPES.SET_LOADING:
        return {
            ...state,
            loading: action.payload,
        };
    case REFFERAL_ACTION_TYPES.SET_OPEN_MODAL:
        return {
            ...state,
            openModel: action.payload,
        };
    // Other cases...
    default:
      return state;
  }
};

export default ServiceReducer;
 