import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const PendingList = () => {
    const navigate = useNavigate();
    const [pendingLists, setPendingLists] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    // Fetch records from the GET API
    const fetchPendingLists = async () => {
        setLoading(true);
        setError(null);
        const userId = localStorage.getItem("user_id") || "default_user_id";
        try {
            const response = await axios.get(
                `https://miracalemoney.in/dealerreport/api/v1/ViewPendingList/${userId}`
            );

            console.log("API Response:", response.data); // Log the full response

            if (response.data.status === 1 && Array.isArray(response.data.users)) {
                setPendingLists(response.data.users);
            } else {
                setPendingLists([]);
                setError("No pending lists found.");
            }
        } catch (err) {
            console.error("Error fetching pending lists:", err);
            setError("Failed to fetch pending lists. Please try again.");
        } finally {
            setLoading(false);
        }
    };

    // Fetch pending lists on component mount
    useEffect(() => {
        fetchPendingLists();
    }, []);

    const handleCancel = () => {
        navigate("/dashboard");
    };

    return (
        <div>
            <header className="header">
                <div className="call-header">
                    <h1>Pending List</h1>
                </div>
                <div onClick={handleCancel} className="cancel-icon">
                    &#10005;
                </div>
            </header>
            <main className="addclass-container">
                {loading && <p>Loading pending lists...</p>}
                {error && <p style={{ color: "red", marginTop: "10px" }}>{error}</p>}

                {!loading && pendingLists.length > 0 && (
                    <div>
                        {pendingLists.map((user, index) => (
                            <div className="card" key={user.id}>
                                <h2>Pending User</h2>
                                <div className="card-content">
                                    <div>S.No: {index + 1}</div>
                                    <div>ID: {user.id}</div>
                                    <div>Name: {user.name || "N/A"}</div>
                                    <div>Amount: {user.amount || "N/A"}</div>
                                    <div>Created On: {user.created_on || "N/A"}</div>
                                </div>
                            </div>
                        ))}
                    </div>
                )}

                {!loading && pendingLists.length === 0 && !error && (
                    <p>No pending users to display.</p>
                )}
            </main>
        </div>
    );
};

export default PendingList;
