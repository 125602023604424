import React, { useState, useRef, useEffect, useContext } from "react";
import { Tab } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Image } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Title from "../../utils/Titles/Title";
import * as getData from '../../../services/APIService';
import * as getAPIAction from "../../../store/actions/GetAllMasterList";
import { ALL_MASTER_ACTION_TYPES } from "../../../store/actions/AllMasterActionTypes";
import { apppointmentbookUIDatas } from '../../utils/json/appointmentbookUIDatas';
import TextInput from '../../utils/TextInput';
import  Dropdown from '../../utils/Dropdown'
import  Searchdropdown from '../../utils/Searchdropdown'
import Multiselect from '../../utils/Multiselect'
import DatePickers from '../../utils/DatePickers'
import InputPhone from '../../utils/InputPhone'
import TextFeildCheckBox from '../../utils/TextFeildCheckBox'
import TextFeildRadioBox from '../../utils/TextFeildRadioBox'
import Fileinput from '../../utils/Fileinput'
import FileUploadButton from '../../utils/FileUploadButton'
import Buttons from '../../utils/Button'
import Table from '../../utils/Newtable/Table'
import { tableData } from '../../utils/json/tableData'
import Popup from "../Masters/Element/Popup";
import { SVGICON } from "../../constant/theme";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { NavMenuOn, NavMenuToggle } from "../../layouts/nav/NavHader";

import Spinner from "react-bootstrap/Spinner";
import { logoutFunction } from "../../../store/actions/LogoutActions";
import { useDispatch, useSelector } from 'react-redux';
import { matchIsValidTel } from 'mui-tel-input';
import FormLabel from '@mui/material/FormLabel';
import { insuranceUIDatas } from "../../utils/json/insuranceUIDatas";
import * as _ from "lodash" 
import { setLoading } from "../../../store/actions/CommonActions";
import Loader from "../Loader/Loader";
import { ThemeContext } from "../../../context/ThemeContext";

const moment = require('moment');
const Insurances = () => {

  const navigate = useNavigate();
  const [serviceTab, setServiceTab] = useState(1);
  const hash = window.location.hash;
  const queryString = hash.split('?')[1];
  const params = new URLSearchParams(queryString);
  const [companyid, setcompanyid] = useState(params.size > 0 ? params.get('companyID') : 0);
  const handleServiceTab = (event, serviceTab) => {
    setServiceTab(serviceTab);
  };
  const [showFieldLoad, setShowFieldLoad] = useState(false);

  const [insuranceUIArray , setInsuranceUIArray] = useState([])
  const [insuranceUIIndex, setInsuranceUIIndex] = useState(0);

  const { toggleSideMenu } = useContext(ThemeContext);
  const [AskNavigationOpenModal , setAskNavigationOpenModal] = useState(false);
  const [checkChanges , setCheckChanges] = useState(false);

  const loading = useSelector((state) => state.common.showLoading)

  const [title , setTitle] = useState("Add");
  const ServiceRequestTableHead = tableData[0]?.appointment?.[0]?.ServiceRequest?.[0]?.tableHead || [];
  const AppointmentHistoryTableHead = tableData[0]?.appointment?.[0]?.AppointmentHistory?.[0]?.tableHead || [];

  const departmentList = useSelector(state => state.allMasterList.departmentAllList);
  const countryList = useSelector(state => state.allMasterList.countryList);
  const regionList = useSelector((state) => state.allMasterList.regionList);
  const cityList = useSelector((state) => state.allMasterList.cityList);
  const companytypelist = useSelector((state) => state.allMasterList.companytypelist);
  const chargetypelist = useSelector(
    (state) => state.allMasterList.chargetypelist
  );
  const companyList = useSelector((state) => state.allMasterList.companylist);
  const doctorList = [];
  const referralList = [];
  const apptReasonList = [];
  const apptServicesList = [];
  const timeIntervalList = [];
  const fromTimeList = [];
  const toTimeList = [];
  const staffList = [];

  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.auth.auth);
  const userToken = userData.idToken;
  const userId = userData.userId;
  // const roleId = parseInt(userData.rollId);
  const logId = parseInt(userData.logId);
  const emailValidator = (email) => {
    const Regexp = new RegExp(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)
    return Regexp.test(email)
  }
  const initialFormData = {
    form_type: "",
    name: "",
    short_code: "",
    company_type_id: "",
    charge_type_id: "",
    mediator_id: "",
    mediator_short_code: "",
    start_date: "",
    end_date: "",
    min_limit: "",
    max_limit: "",
    provider_code: "",
    payer_ids: "",
    claim_no: 0,
    outsource_lab:0,
    e_auth:0,
    activity_clinician:0,
    eligiblity:0,
    pharmacy_token:0,
    benefit_package:0,
    no_lab_xml:0
  }

  const initialBillingData = {
    address: "",
    country_id: "",
    region_id: "",
    city_id: "",
    pincode: "",
    billing_phone: "+971",
    billing_fax: "",
    website: ""
  }

  const initialContactData = {
    contact_person: "",
    designation: "",
    department: "",
    contact_phone: "+971",
    contact_mobile: "+971",
    contact_fax: "",
    contact_email: ""
  }

  const [formData, setFormData] = useState(initialFormData);
  const [billingData, setBillingData] = useState(initialBillingData);
  const [contactData, setContactData] = useState(initialContactData);

  useEffect(() => {

    document.title = 'Insurances';
    setTitle(params.size > 0 ? 'Edit' : 'Add');

    let insUIData1 = [];
    let insUIData2 = [];

    insUIData1 = _.cloneDeep(insuranceUIDatas[0]?.insurance)
    insUIData2 = _.cloneDeep(insuranceUIDatas[0]?.insurance);

    
    let removeID = [4,5];
    insUIData1[0].data = insUIData1[0]?.data.filter(obj => !removeID.includes(obj.id));
    
    setInsuranceUIArray([insUIData1, insUIData2]);

    toggleSideMenu(true); NavMenuOn(); 

  },[])

  const validateForm = () => {
    const validationErrors = {};
    const pattern = /^[a-zA-Z0-9 ]*$/;
    if (!formData.form_type) {
      validationErrors.form_type = 'Company mode is required';
    }
    if (!formData.name) {
      validationErrors.name = 'Company name is required';
    }
    if (!formData.short_code) {
      validationErrors.short_code = 'Company short code is required';
    }
    if (!formData.company_type_id) {
      validationErrors.company_type_id = 'Company type is required';
    }
    if (!formData.charge_type_id) {
      validationErrors.charge_type_id = 'Charge type is required';
    }
    if (!billingData.country_id) {
      validationErrors.country_id = 'Country is required';
    }
    if (!billingData.region_id) {
      validationErrors.region_id = 'Region is required';
    }
    if (!billingData.city_id) {
      validationErrors.city_id = 'City is required';
    }
    if (!billingData.website && !isValidURL(billingData.website)) {

      validationErrors.website = 'Please enter a valid Website URL';
      setServiceTab(1);
    }
    if (!pattern.test(formData.name)) {
      validationErrors.name = 'Special characters are not allowed';
    }
    if (!pattern.test(formData.short_code)) {
      validationErrors.short_code = 'Special characters are not allowed';
    }
    if (formData.form_type === 2) {
      if (!formData.mediator_id) {
        validationErrors.mediator_id = 'Mediator name is required';
      }
      if (!formData.mediator_short_code) {
        validationErrors.mediator_short_code = 'Mediator short code is required';
      }
    }
    if (formData.start_date != "" && formData.end_date != "") {
      if (formData.start_date > formData.end_date) {
        validationErrors.start_date = 'start date must be lesser than end date';
      }
      else if (formData.end_date < formData.start_date) {
        validationErrors.start_date = 'end date must be greater than start date';
      }

    }
    if (formData.min_limit != "" && formData.max_limit != "") {
      if (formData.min_limit > formData.max_limit) {
        validationErrors.min_limit = 'min limit must be lesser than max limit';
      }
      else if (formData.max_limit < formData.min_limit) {
        validationErrors.max_limit = 'max limit must be greater than min limit';
      }

    }
    if (!contactData.contact_person) {
      validationErrors.contact_person = 'Contact person name is required';
      setServiceTab(2);
    }
    if (!contactData.department) {
      validationErrors.department = 'Department is required';
      setServiceTab(2);
    }

    return validationErrors;
  };
const searchhandlechange=(value) =>
{
  if(value)
  {
    const selectedcompany = companyList.filter((item) => (item.company_id === value))
    // console.log(selectedcompany[0].short_code);
    setFormData({ ...formData, ['mediator_id']: value, "mediator_short_code": selectedcompany[0].short_code });

    setErrors({ ...errors, ['mediator_id'] : "" , "mediator_short_code" : ""});
  }
  

}
  //Handle function of all form inputs
  const handleChange = (e) => {
    
    const { name, value, type, files } = e.target;
    const newValue = type === 'file' ? files[0] : value;
    const pattern = /^[a-zA-Z0-9 ]*$/;
    const numPattern = /^[0-9]*$/

    console.log(e, name, newValue);
    setCheckChanges(true)
    if (type === "checkbox") {

      setFormData({ ...formData, [name]: e.target.checked ? 1 : 0 });

    }
    else if(name==="name")
    {
      if (pattern.test(newValue)) {
        setFormData({ ...formData, [name]: newValue });
      }
     
    }
    else if(name==="short_code")
    {
      if (pattern.test(newValue)) {
        setFormData({ ...formData, [name]: newValue });
      }
     
    }
    else if(name==="payer_ids")
    {
      if (pattern.test(newValue)) {
        setFormData({ ...formData, [name]: newValue.toUpperCase() });
      }
     
    }
    else if(name==="provider_code")
    {
      if (pattern.test(newValue)) {
        setFormData({ ...formData, [name]: newValue.toUpperCase() });
      }
     
    }
    else if (name === "form_type") {
      if(newValue === null) {
        setInsuranceUIIndex(0);

        setFormData({ ...formData, [name]: 0 });
      }
      else {
        setInsuranceUIIndex(newValue - 1);

        setFormData({ ...formData, [name]: newValue, "mediator_id": "", "mediator_short_code": "" });
      }

    }
    else if (name === "mediator_id") {
      const selectedcompany = companyList.filter((item) => (item.company_id === newValue))
      // console.log(selectedcompany[0].short_code);
      if(newValue === null) {
        setFormData({ ...formData, [name]: "", "mediator_short_code": "" });
      }
      else {
        setFormData({ ...formData, [name]: newValue, "mediator_short_code": selectedcompany[0].short_code });
      }
 
    }
    else {
      setFormData({ ...formData, [name]: newValue });
    }

    setErrors({ ...errors, [name]: '' });

  };

  const subhandleChange = (e, label) => {
    const { name, value, type, files } = e.target;
    const newValue = type === 'file' ? files[0] : value;

    setCheckChanges(true);
    if (label === "Billing Information") {

      if (name === 'country_id') {

        setBillingData(prevFormData => ({
          ...prevFormData,
          region_id: '',
          city_id: '',
        }));

        dispatch({ type: ALL_MASTER_ACTION_TYPES.SET_REGION_LIST, payload: [] });
        dispatch({ type: ALL_MASTER_ACTION_TYPES.SET_CITY_LIST, payload: [] });
        //Get State/Region Data
        dispatch(getAPIAction.getRegionListAPI(userId, logId, userToken, navigate, newValue));

        setErrors({ ...errors, [name]: '' });
        
      } else if (name === 'region_id') {
        setBillingData(prevFormData => ({
          ...prevFormData,
          city_id: '',
        }));
        dispatch({ type: ALL_MASTER_ACTION_TYPES.SET_CITY_LIST, payload: [] });
        dispatch(getAPIAction.getCityListAPI(userId, logId, userToken, navigate, billingData.country_id, newValue));

        setErrors({ ...errors, [name]: '' });
      } else if(name === "city_id") {
        setErrors({ ...errors, [name]: '' });
      } else if (name === 'address') {
        const sanitizedInput = newValue.replace(/[^0-9a-zA-Z//#., ]/g, '');
        setBillingData(prevdata => ({ ...prevdata, address: sanitizedInput }));
      }
      else if (name === 'website') {
        if (!isValidURL(newValue) && newValue != "") {
          setErrors({ ...errors, [name]: 'please enter valid website url' });

        }
        else {
          setErrors({ ...errors, [name]: '' });
        }
        setBillingData(prevdata => ({ ...prevdata, [name]: newValue }));
      }
      else if (name === 'billing_phone') {
        const mobileInput = newValue.split(' ').slice(1).join('');;
        if (mobileInput?.length > 10) {
          return;
        }
        if (matchIsValidTel(newValue, {
          onlyCountryies: [],
          excludedCountryies: [],
          continents: []
        }) || newValue === "") {
          setErrors({ ...errors, [name]: '' });
        }
        else {
          setErrors({ ...errors, [name]: 'Enter valid mobile number' });
        }

      }
      if (name !== 'email' && name !== 'address') {
        setBillingData({ ...billingData, [name]: newValue });
      }

    }
    else if (label === "Contact Information") {
      if(name === "contact_person" || name === "designation" || name === "department") {
        const sanitizedInput = newValue.replace(/[^a-zA-Z ]/g, '');
        setContactData(prevdata => ({ ...prevdata, [name]: sanitizedInput }));
        setErrors({ ...errors, [name]: '' })
      }
      else if (name === 'contact_email') {
        const sanitizedInput = newValue.replace(/[^a-z0-9._@]/g, '');

        if (emailValidator(sanitizedInput) || sanitizedInput === "")
          setErrors({ ...errors, [name]: '' });
        else
          setErrors({ ...errors, [name]: 'Email is invalid' });
        setContactData(prevdata => ({ ...prevdata, contact_email: sanitizedInput }));
      }
      else if (name === 'contact_phone' || name === 'contact_mobile') {
        const mobileInput = newValue.split(' ').slice(1).join('');;
        if (mobileInput?.length > 10) {
          return;
        }
        if (matchIsValidTel(newValue, {
          onlyCountryies: [],
          excludedCountryies: [],
          continents: []
        }) || newValue === "") {
          setErrors({ ...errors, [name]: '' });
        }
        else {
          setErrors({ ...errors, [name]: 'Enter valid mobile number' });
        }
      }

      if (name !== 'contact_email' && name !== "contact_person" && name !== "designation" && name !== "department") {
        setContactData({ ...contactData, [name]: newValue });
        if (name !== 'contact_phone' && name !== "contact_mobile") {
          setErrors({ ...errors, [name]: '' });
        }
      }
    }


  }
  const shouldLog = useRef(true);
  useEffect(() => {
    if (shouldLog.current) {
      shouldLog.current = false;
      dispatch(getAPIAction.getCountryListAPI(userId, logId, userToken, navigate));
      dispatch(getAPIAction.GetInsCompanyList(userId, logId, userToken, navigate));
      dispatch(getAPIAction.getCompanyTpyeListAPI(userId, logId, userToken, navigate));
      dispatch(getAPIAction.getChargeTpyeListAPI(userId, logId, userToken, navigate));

    }

  }, []);
  useEffect(() => {
    if (companyid !== 0) {

      GetSingleInsCompany(companyid)

    }


  }, [companyid])
  const GetSingleInsCompany = async () => {
    setShowFieldLoad(true);


    await getData.GetSingleInsCompany(userId, companyid, userToken).then((resp) => {


      if (resp.data && resp.data.status === 0) {
        toast.error(resp.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
      } else {

        var detailsData = resp.data.data[0];

        setFormData(
          prevFormData => ({
            ...prevFormData,
          form_type: parseInt(detailsData.form_type),
          name: detailsData.name,
          short_code: detailsData.short_code,
          company_type_id: detailsData.company_type_id,
          charge_type_id: detailsData.charge_type_id,
          mediator_id: detailsData.mediator_id,
          mediator_short_code: detailsData.mediator_short_code,
          start_date: detailsData.start_date !== null ? moment(detailsData.start_date, 'DD-MM-YYYY').format('YYYY-MM-DD') : null,
          end_date: detailsData.end_date !== null ? moment(detailsData.end_date, 'DD-MM-YYYY').format('YYYY-MM-DD') : null,
          min_limit: detailsData.min_limit,
          max_limit: detailsData.max_limit,
          provider_code: detailsData.provider_code,
          payer_ids: detailsData.payer_ids,
          claim_no: detailsData.claim_no,
          outsource_lab:detailsData.outsource_lab,
          e_auth:detailsData.e_auth,
          activity_clinician:detailsData.activity_clinician,
          eligiblity:detailsData.eligiblity,
          pharmacy_token:detailsData.pharmacy_token,
         benefit_package:detailsData.benefit_package,
          no_lab_xml:detailsData.no_lab_xml,
          is_status:detailsData.is_status,
        }));


        dispatch(getAPIAction.getRegionListAPI(userId, logId, userToken, navigate, detailsData.country_id));
        dispatch(getAPIAction.getCityListAPI(userId, logId, userToken, navigate, detailsData.country_id, detailsData.region_id));
        setBillingData
          ({
            ...billingData,
            address: detailsData.address,
            country_id: detailsData.country_id,
            region_id: detailsData.region_id,
            city_id: detailsData.city_id,
            pincode: detailsData.pincode,
            billing_phone: detailsData.billing_phone,
            billing_fax: detailsData.billing_fax,
            website: detailsData.website,
          });
        setContactData
          ({
            ...contactData,
            contact_person: detailsData.contact_person,
            designation: detailsData.designation,
            department: detailsData.department,
            contact_phone: detailsData.contact_phone,
            contact_mobile: detailsData.contact_mobile,
            contact_fax: detailsData.contact_fax,
            contact_email: detailsData.contact_email,

          });

          if (detailsData.mediator_id !== null && companyList.length > 0) {
            const selectedcompany = companyList.filter((item) => (item.company_id == detailsData.mediator_id));
            setFormData(prevFormData => ({ ...prevFormData, "mediator_short_code": selectedcompany[0].short_code }));
          }
      }

      setShowFieldLoad(false);

    }).catch((error) => {

      if (error.response.data.status === 2) {
        toast.error(error.response.data.message + ". Please log in again to continue.", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        setTimeout(() => {
          dispatch(logoutFunction(userId, logId, userToken, navigate));
        }, 2000)
      } else {
        toast.error(error.response.data.message + " Please Try After Sometimes" ||
          "Too Many Attempts. Please Try after sometimes", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      }
    });
  };
  const formsubmit = async (e, type) => {
    e.preventDefault();
    dispatch(setLoading(true))
    const validationErrors = validateForm();

    if (Object.keys(validationErrors).length === 0) {
      if (companyid === 0) {
        const postData = { "user_id": userId, ...formData, ...billingData, ...contactData };
        await getData
          .AddNewInsCompany(postData, userToken)
          .then((resp) => {


            if (resp.data.status === 1) {
              setcompanyid(resp.data.company_id);
              if (type === 0) {
                // navigate('/insurance-list')
                setFormData(initialFormData);
                setBillingData(initialBillingData);
                setContactData(initialContactData);
              }
              else {

                navigate('/insurance?companyID=' + resp.data.company_id)
              }
              toast.success(resp.data.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 1000,
              });
            } else {

              toast.error(resp.data.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 1000,
              });
            }
          })
          .catch((error) => {
            // console.log(error)
            if (error.response.data.status === 2) {
              toast.error(
                error.response.data.message +
                ". Please log in again to continue.",
                {
                  position: toast.POSITION.TOP_RIGHT,
                  autoClose: 1000,
                }
              );
              setTimeout(() => {
                dispatch(logoutFunction(userId, logId, userToken, navigate));
              }, 2000);
            } else {
              toast.error(
                error.response.data.message + " Please Try After Sometimes" ||
                "Too Many Attempts. Please Try after sometimes",
                {
                  position: toast.POSITION.TOP_RIGHT,
                  autoClose: 1000,
                }
              );
            }
          });
      }
      else {
        const postData = { "user_id": userId, "company_id": companyid, ...formData, ...billingData, ...contactData };
        await getData
          .UpdateInsCompany(postData, userToken)
          .then((resp) => {
            if (resp.data.status === 1) {

              toast.success(resp.data.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 1000,
              });
              if (type === 0) {
                // navigate('/insurance-list')
              }
            } else {

              toast.error(resp.data.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 1000,
              });
            }
          })
          .catch((error) => {
            // console.log(error)
            if (error.response.data.status === 2) {
              toast.error(
                error.response.data.message +
                ". Please log in again to continue.",
                {
                  position: toast.POSITION.TOP_RIGHT,
                  autoClose: 1000,
                }
              );
              setTimeout(() => {
                dispatch(logoutFunction(userId, logId, userToken, navigate));
              }, 2000);
            } else {
              toast.error(
                error.response.data.message + " Please Try After Sometimes" ||
                "Too Many Attempts. Please Try after sometimes",
                {
                  position: toast.POSITION.TOP_RIGHT,
                  autoClose: 1000,
                }
              );
            }
          });
      }
    }
    else {

      setErrors(validationErrors);

    }
    dispatch(setLoading(false))
  }
  const isValidURL = (url) => {
    // Regular expression for URL validation
    const pattern = /^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]+\.[a-z]{2,}(\.[a-z]{2,})?$/;
    return pattern.test(url);
  };
  const handleAllFormReset = () => {
    setFormData(initialFormData);
    setBillingData(initialBillingData);
    setContactData(initialContactData);
    setInsuranceUIIndex(0);
    setCheckChanges(false);
  }

  const entryChecker = () => {
    const Textfields = ['form_type', 'name', 'short_code', 'mediator_short_code', 'start_date', 'end_date', 'provider_code', 'payer_ids']
    const Numberfields = ['company_type_id', 'charge_type_id' ,'mediator_id','min_limit', 'max_limit'];

    const textStatus = Textfields.find((field) => formData[field] !== '')

    const numberStatus = Numberfields.find((field) => (formData[field] !== '' || formData[field] > 0))

    // const arrayStatus/ = Arrayfields.find((field) => (formData[field].length > 0))

    if(textStatus == undefined && numberStatus == undefined) {
        return false;
    }

    return true;

}

  const AskNavigation = () => {
    const entryCheckerStatus = entryChecker();

    if(!entryCheckerStatus) {
        navigate("/insurance-list");
        return
    }
    setAskNavigationOpenModal(true);
  }

  const AskNavigationCloseModal = () => {
        navigate("/insurance-list")
        setAskNavigationOpenModal(false);
  }


  return (
    <>
      <div className="row">

        {/* <div className="col-md-9 col-xs-12"><Title icon={SVGICON.backCaret} title="Insurance" onClick={() => navigate("/insurance-list")} /></div> */}
        <div className="col-10 col-md-10 col-xs-8 col-sm-8"><Title title={`${title} Insurance`} /></div>
      </div>
      <div className="row bg_wrap b-radius no_margin" style={{ "padding": "18px 0px" }}>
        <div className="col-md-12">

          <div className="bg_wrap b-radius m-tab" style={{ 'minHeight': 'auto' }}>

            {
              insuranceUIArray.length > 0 && 
              insuranceUIArray[insuranceUIIndex].map((formFields) => (
                <>
                  <div className="row">

                    <div className="col-md-12 col-xs-12 padding_fixed">
                      <div className="row no_margin">

                        {formFields.data && formFields.data.map((input) => (
                          <div className="col-md-3 col-xs-12 padding_fixed" key={input.id}>
                            {(input.type === "text") ? (input.name === "mediator_short_code") ? formData.form_type === 2 ? <TextInput {...input}
                              readOnly={input.name === "mediator_short_code" ? true : false}
                              onChange={handleChange}
                              value={formData[input.name]}
                              error={errors[input.name]}
                              showFieldLoad={showFieldLoad}
                            /> : null : <TextInput {...input}
                              showFieldLoad={showFieldLoad}
                              onChange={handleChange}
                              value={formData[input.name]}
                              error={errors[input.name]}
                            /> : (input.type === "select") ?
                              // (input.name === "mediator_id") ? formData.form_type == 2 ?
                               <Searchdropdown {...input}
                              options={input.name === "form_type" ? input.options : input.name === "company_type_id" ? companytypelist : input.name === "charge_type_id" ? chargetypelist : input.name === "mediator_id" ? companyList : []}
                              onChange={handleChange}
                              disabled={companyid}
                              //value={input.name==="personal_id"?formData.personal_id:input.name==="mr_number"?formData.mr_number:input.name==="address"?formData.address:input.name==="title_id"?formData.title_id:input.name==="gender_id"?formData.gender_id:input.name==="country_id"?formData.country_id:input.name==="first_name"?formData.first_name:input.name==="father_name"?formData.father_name:input.name==="region_id"?formData.region_id:input.name==="middle_name"?formData.middle_name:input.name==="marital_status_id"?formData.marital_status_id:input.name==="city_id"?formData.city_id:input.name==="last_name"?formData.last_name:input.name==="religion_id"?formData.religion_id:input.name==="mobile"?formData.mobile:input.name==="arabic_name"?formData.arabic_name:input.name==="nationality_id"?formData.nationality_id:input.name==="secondary_mobile"?formData.secondary_mobile:input.name==="date_of_birth"?formData.date_of_birth:input.name==="blood_group_id"?formData.blood_group_id:input.name==="email"?formData.email:input.name==="s_email"?formData.s_email:input.name==="language_id"?formData.language_id:input.name==="passport_no"?formData.passport_no:input.name==="income_range_id"?formData.income_range_id:input.name==="payment_mode_id"?formData.payment_mode_id:input.name==="vip"?formData.vip:input.name==="demo_pat"?formData.demo_pat:input.name==="family"?formData.family:""}
                              value={formData[input.name]}
                              error={errors[input.name]}
                              showFieldLoad={showFieldLoad}
                            /> 
                            //   : null : 
                            //   <Dropdown {...input}
                            //   options={input.name === "form_type" ? input.options : input.name === "company_type_id" ? companytypelist : input.name === "charge_type_id" ? chargetypelist : input.name === "mediator_id" ? companyList : []}
                            //   onChange={handleChange}
                            //   disabled={companyid}
                            //   showFieldLoad={showFieldLoad}
                            //   //value={input.name==="personal_id"?formData.personal_id:input.name==="mr_number"?formData.mr_number:input.name==="address"?formData.address:input.name==="title_id"?formData.title_id:input.name==="gender_id"?formData.gender_id:input.name==="country_id"?formData.country_id:input.name==="first_name"?formData.first_name:input.name==="father_name"?formData.father_name:input.name==="region_id"?formData.region_id:input.name==="middle_name"?formData.middle_name:input.name==="marital_status_id"?formData.marital_status_id:input.name==="city_id"?formData.city_id:input.name==="last_name"?formData.last_name:input.name==="religion_id"?formData.religion_id:input.name==="mobile"?formData.mobile:input.name==="arabic_name"?formData.arabic_name:input.name==="nationality_id"?formData.nationality_id:input.name==="secondary_mobile"?formData.secondary_mobile:input.name==="date_of_birth"?formData.date_of_birth:input.name==="blood_group_id"?formData.blood_group_id:input.name==="email"?formData.email:input.name==="s_email"?formData.s_email:input.name==="language_id"?formData.language_id:input.name==="passport_no"?formData.passport_no:input.name==="income_range_id"?formData.income_range_id:input.name==="payment_mode_id"?formData.payment_mode_id:input.name==="vip"?formData.vip:input.name==="demo_pat"?formData.demo_pat:input.name==="family"?formData.family:""}
                            //   value={formData[input.name]}
                            //   error={errors[input.name]}
                            // /> 
                              : (input.type === "datePicker") ? <><DatePickers {...input}

                              onChange={(date) => handleChange({ target: { name: input.name, value: date } })}
                              showFieldLoad={showFieldLoad}
                              selected={formData[input.name]}
                              value={formData[input.name]}
                              error={errors[input.name]}
                              // custommaxdate={(input.name) === "start_date" ? formData["end_date"] : null}
                              custommindate={(input.name) === "end_date" ? formData["start_date"] : null}
                            /> </>
                              : (input.type === "number") ? <><TextInput {...input}
                                showFieldLoad={showFieldLoad}
                                onChange={handleChange}
                                value={formData[input.name]}
                                error={errors[input.name]}
                              /></>
                                : (input.type === "checkBox") ? <TextFeildCheckBox {...input}
                                  onChange={handleChange}
                                  checked={formData[input.name] == 1 ? true : false}
                                /> : ""}

                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </>
              ))
            }

          </div>

        </div>
        <div className="col-md-12" style={{ 'padding': '0 10px' }}>
          <div className="bg_wrap b-radius m-tab tabBG">
            <TabContext value={serviceTab}>
              <TabList onChange={handleServiceTab}
                aria-label="Tab">
                {insuranceUIArray.length > 0 && insuranceUIArray[insuranceUIIndex][0]?.BillingAndContact.map(labelList => (
                  <Tab key={labelList.value} value={labelList.value} label={labelList.label} />
                ))}
              </TabList>
              {insuranceUIArray.length > 0 && insuranceUIArray[insuranceUIIndex][0]?.BillingAndContact.map((tab, index) => (
                <TabPanel key={tab.value} value={tab.value}>

                  <div className="row no_margin" style={{ "padding": '5px 2px 0px 2px' }} >

                    {tab && tab.data.map((input, tabIndex) => (
                      <div className="col-md-6 col-xs-12 padding_fixed" key={input.id} >

                        {(input.type === "text") ? <TextInput {...input}
                          onChange={(e) => subhandleChange(e, tab.label)}
                          showFieldLoad={showFieldLoad}
                          value={tab.label == "Billing Information" ? billingData[input.name] : tab.label == "Contact Information" ? contactData[input.name] : ""}
                          error={errors[input.name]}
                        /> : (input.type === "number") ? <TextInput {...input}
                          onChange={(e) => subhandleChange(e, tab.label)}
                          showFieldLoad={showFieldLoad}
                          value={tab.label == "Billing Information" ? billingData[input.name] : tab.label == "Contact Information" ? contactData[input.name] : ""}
                          error={errors[input.name]}
                        /> : (input.type === "url") ? <TextInput {...input}
                          onChange={(e) => subhandleChange(e, tab.label)}
                          showFieldLoad={showFieldLoad}
                          value={tab.label == "Billing Information" ? billingData[input.name] : tab.label == "Contact Information" ? contactData[input.name] : ""}
                          error={errors[input.name]}
                        /> : (input.type === "select") ?

                          <Searchdropdown {...input}
                            options={input.name === "country_id" ? countryList : input.name === "region_id" ? regionList : input.name === "city_id" ? cityList : []}
                            onChange={(e) => subhandleChange(e, tab.label)}
                            //value={input.name==="personal_id"?formData.personal_id:input.name==="mr_number"?formData.mr_number:input.name==="address"?formData.address:input.name==="title_id"?formData.title_id:input.name==="gender_id"?formData.gender_id:input.name==="country_id"?formData.country_id:input.name==="first_name"?formData.first_name:input.name==="father_name"?formData.father_name:input.name==="region_id"?formData.region_id:input.name==="middle_name"?formData.middle_name:input.name==="marital_status_id"?formData.marital_status_id:input.name==="city_id"?formData.city_id:input.name==="last_name"?formData.last_name:input.name==="religion_id"?formData.religion_id:input.name==="mobile"?formData.mobile:input.name==="arabic_name"?formData.arabic_name:input.name==="nationality_id"?formData.nationality_id:input.name==="secondary_mobile"?formData.secondary_mobile:input.name==="date_of_birth"?formData.date_of_birth:input.name==="blood_group_id"?formData.blood_group_id:input.name==="email"?formData.email:input.name==="s_email"?formData.s_email:input.name==="language_id"?formData.language_id:input.name==="passport_no"?formData.passport_no:input.name==="income_range_id"?formData.income_range_id:input.name==="payment_mode_id"?formData.payment_mode_id:input.name==="vip"?formData.vip:input.name==="demo_pat"?formData.demo_pat:input.name==="family"?formData.family:""}
                            value={tab.label == "Billing Information" ? billingData[input.name] : tab.label == "Contact Information" ? contactData[input.name] : ""}
                            error={errors[input.name]}
                            showFieldLoad={showFieldLoad}
                          /> 

                          // <Dropdown {...input}
                          //   options={input.name === "country_id" ? countryList : input.name === "region_id" ? regionList : input.name === "city_id" ? cityList : []}
                          //   onChange={(e) => subhandleChange(e, tab.label)}
                          //   showFieldLoad={showFieldLoad}
                          //   value={tab.label == "Billing Information" ? billingData[input.name] : tab.label == "Contact Information" ? contactData[input.name] : ""}
                          //   error={errors[input.name]}
                          // />

                          : (input.type === "datePicker") ? <DatePickers {...input}
                            showFieldLoad={showFieldLoad}
                            onChange={(date) => subhandleChange({ target: { name: 'regn_date', value: date } }, tab.label)}

                            selected={tab.label == "Billing Information" ? billingData[input.name] : tab.label == "Contact Information" ? contactData[input.name] : ""}
                            value={tab.label == "Billing Information" ? billingData[input.name] : tab.label == "Contact Information" ? contactData[input.name] : ""}
                            error={errors[input.name]}
                          /> : (input.type === "phone") ? <InputPhone {...input}
                            onChange={(value) => subhandleChange({ target: { name: input.name, value: value } }, tab.label)}
                            value={tab.label == "Billing Information" ? billingData[input.name] : tab.label == "Contact Information" ? contactData[input.name] : ""}
                            error={errors[input.name]}
                          />

                            : (input.type === "checkBox") ? <TextFeildCheckBox {...input}
                            onChange={handleChange}
                            checked={formData[input.name] == 1 ? true : false}
                            /> 

                            // : (input.type === "file") ? <Fileinput  {...input}
                            // 	onChange={(e) => subhandleChange(e, input.label)} /> 

                            : ""}

                      </div>

                    ))}

                  </div>

                </TabPanel>
              ))}
            </TabContext>

          </div>
        </div>

        <div className="grid-child magrinTB">
          <div className="col-md-8 grid-child">
            {loading === true ? (
                <Spinner animation="border" variant="primary" />
              ) : null}
            <Buttons.SaveButtons onClick={(e) => formsubmit(e, 0)} name="Save" disable={!checkChanges} />
            <Buttons.EditButtons onClick={(e) => formsubmit(e, 1)} name="Save And Continue" disable={!checkChanges} />
            <Buttons.ResetButtons name="Reset" onClick={handleAllFormReset} disabled={companyid ? true : false} />
            <Buttons.BackButton name="close" className="close-btn" onClick={() => AskNavigation()} />
          </div>
        </div>

        <Popup 
            show={AskNavigationOpenModal}
            handleClose={() => setAskNavigationOpenModal(false)}
            size="md"
        >
            <div className="text-center">
                <p className="fs-18">Do you want to close, You have some unsaved changes ?</p>

                <div class="row">
                    <div className="modal-footer">
                        <div className="d-flex justify-content-center">
                            <Buttons.SaveButtons name="Yes" onClick={() => AskNavigationCloseModal()} />
                            <Buttons.ResetButtons name="No" onClick={() => setAskNavigationOpenModal(false)} />

                        </div>
                    </div>
                </div>
            </div>
        </Popup>
        <ToastContainer />
        {loading ? (
            <Loader />
        ) : null}
      </div>
    </>
  )
}

export default Insurances